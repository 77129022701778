import { NavigationItemTypes } from './app';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  ASIAN_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  EDUCATIONAL_PORTAL_BASE_URL,
  EDUCATIONAL_PORTAL_GUIDES_URL,
  GAMES_BASE_URL,
  MY_BETS_BASE_URL,
  RESPONSIBLE_BETTING_BASE_URL
} from './locations';
import { MyBetsPeriods } from './myBets';
import { MY_BETS_LINK, MY_BETS_PL_LINK, RESPONSIBLE_BETTING, STATUS_LINK } from './tooltip';

export const getAccountNavigationSidebarItems = ({
  isAsianViewPage,
  isGamePage
}: {
  isAsianViewPage: boolean;
  isGamePage: boolean;
}) => {
  let baseUrl = ACCOUNT_BASE_URL;

  if (isAsianViewPage) {
    baseUrl = ASIAN_BASE_URL + ACCOUNT_BASE_URL;
  } else if (isGamePage) {
    baseUrl = GAMES_BASE_URL + ACCOUNT_BASE_URL;
  }

  return [
    {
      link: `${baseUrl}${MY_BETS_BASE_URL}/${MyBetsPeriods.Open.toLowerCase()}/1`,
      title: 'accountNavigation.mybets',
      isShowInfo: true,
      code: MY_BETS_LINK,
      infoText: 'tooltip.accountMenu.myBetsLink',
      type: NavigationItemTypes.MY_BETS
    },
    {
      link: `${baseUrl}${BETTING_PROFIT_LOSS_BASE_URL}/1`,
      title: 'accountNavigation.profitandloss',
      isShowInfo: true,
      code: MY_BETS_PL_LINK,
      infoText: 'tooltip.accountMenu.myBetsPLLink'
    },
    {
      link: `${baseUrl}${ACCOUNT_STATEMENT_BASE_URL}/1`,
      title: 'accountNavigation.statement',
      isShowInfo: true,
      code: STATUS_LINK,
      infoText: 'tooltip.accountMenu.statusLink'
    }
  ];
};

export const RESPONSIBLE_BETTING_MENU_ITEM = {
  link: `${ACCOUNT_BASE_URL}/${RESPONSIBLE_BETTING_BASE_URL}`,
  title: 'responsibleBetting.labels.responsibleBetting',
  isShowInfo: false,
  code: RESPONSIBLE_BETTING
};

export const getAccountSubHeaderNavigationItems = ({
  isResponsibleBetting,
  isMyBetsSettledTab,
  isAsianViewPage,
  isGamePage
}: {
  isResponsibleBetting: boolean;
  isMyBetsSettledTab?: boolean;
  isAsianViewPage: boolean;
  isGamePage: boolean;
}) => {
  let baseUrl = ACCOUNT_BASE_URL;

  if (isAsianViewPage) {
    baseUrl = ASIAN_BASE_URL + ACCOUNT_BASE_URL;
  } else if (isGamePage) {
    baseUrl = GAMES_BASE_URL + ACCOUNT_BASE_URL;
  }

  return [
    {
      link: `${baseUrl}${MY_BETS_BASE_URL}/${(isMyBetsSettledTab
        ? MyBetsPeriods.Settled
        : MyBetsPeriods.Open
      ).toLowerCase()}/1`,
      title: 'sidebar.nav.myBets',
      icon: 'biab_custom-icon-myBets'
    },
    {
      link: `${baseUrl}${BETTING_PROFIT_LOSS_BASE_URL}/1`,
      title: 'sidebar.nav.profitAndLoss',
      icon: 'biab_custom-icon-currency'
    },
    {
      link: `${baseUrl}${ACCOUNT_STATEMENT_BASE_URL}/1`,
      title: 'sidebar.nav.statement',
      icon: 'biab_custom-icon-list'
    },
    ...(isResponsibleBetting
      ? [
          {
            link: `${baseUrl}${RESPONSIBLE_BETTING_BASE_URL}`,
            title: 'responsibleBetting.labels.responsibleBetting',
            icon: 'fa2 fa2-hand-paper-o'
          }
        ]
      : [])
  ];
};

export const SettingsDisplayedContents = {
  QUICK_BETS: 'QUICK_BETS',
  PREFERENCES: 'PREFERENCES',
  DEFAULT_STAKE: 'DEFAULT_STAKE',
  NO_ITEMS: 'NO_ITEMS'
} as const;

export const SettingsBettingInlinePlacementTypes = {
  BETSLIP: 'BETSLIP',
  MIDDLE_SECTION: 'MIDDLE_SECTION',
  DEFAULT: 'DEFAULT'
} as const;

export const SettingsBettingCheckboxes = {
  CONFIRM_BETS_FOR_PLACEMENT: 'confirmBetsBeforePlacement',
  PLACE_BET_WITH_ENTER_KEY: 'placeBetWithEnterKey',
  DEFAULT_STAKE: 'defaultStake',
  AUTO_REFRESH: 'asianViewAutoRefresh',
  FILL_OR_KILL: 'fillOrKillEnabled'
} as const;

export const NavigationIds = {
  FANCY: '#date_Fancy Markets',
  MORE: '#date_More'
} as const;

export const educationalPortalSubHeaderNavigationItems = [
  {
    link: EDUCATIONAL_PORTAL_BASE_URL,
    title: 'education.labels.home'
  },
  {
    link: EDUCATIONAL_PORTAL_GUIDES_URL,
    title: 'education.labels.guides'
  }
];

export const IGNORE_REDIRECT_URLS = [EDUCATIONAL_PORTAL_BASE_URL];
