import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewInPlayTime from 'components/AsianViewInPlayTime';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import CashOut from 'components/CashOut';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES, SportIds } from 'constants/app';
import { AsianViewSections, COLUMNS_NAMES_HALF_TIME_FULL_TIME } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { setClosedEventId } from 'redux/modules/asianView';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import {
  getIsClosedMarketId,
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';

import AsianViewBackLayLabels from '../Event/MarketCell/AsianViewBackLayLabels';
import SelectionOdds from '../Event/MarketCell/SelectionOdds';
import SelectionOddsSkeleton from '../Event/MarketCell/SelectionOddsSkeleton';

import styles from './styles.module.scss';

type HalfTimeFullTimeEventProps = {
  competitionId: string;
  event: TAsianViewEvent;
  isFirst: boolean;
};

const HalfTimeFullTimeEvent = ({ event, competitionId, isFirst }: HalfTimeFullTimeEventProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sportId = SportIds.SOCCER } = useParams();

  const market = event.sections[AsianViewSections.TOP]?.[0];

  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const translation = useSelector(getTranslation);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market?.id ?? ''));
  const marketPricesId = useSelector(getMarketPricesId(market?.id ?? ''));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));
  const isClosedMarket = useSelector(getIsClosedMarketId(market?.id));

  const containerRef = useRef<HTMLDivElement>(null);

  const isLayBetting = useAsianLayBetting();
  const { ref, isIntersecting } = useMarketsPricesVisibleSocketParam({
    marketId: market.id,
    eventId: event.id,
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '300px' }
  });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const showCashOut = useMemo(() => {
    return event.sections.top?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [event.sections.top]);

  const brandingStyles = {
    [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0,
    [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0,
    [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0,
    [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0
  };

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  useEffect(() => {
    if (isClosedMarket) {
      dispatch(setClosedEventId(event.id));
    }
  }, [isClosedMarket]);

  return (
    <div
      ref={containerRef}
      data-market-id={market.id}
      data-event-id={event.id}
      data-market-prices="true"
      className={classNames({
        'biab_asian-view-event': isIntersecting && !!market,
        [styles.halfTimeFullTimeEvent]: isIntersecting && !!market,
        [branding.MAIN_CONTAINER_BORDER]: isIntersecting && !!market
      })}
    >
      {!isIntersecting && <div style={{ height }} />}
      {isIntersecting && !!market && (
        <>
          <div
            className={classNames(styles.halfTimeFullTimeEvent__header, branding.MAIN_CONTAINER_BORDER, {
              [styles.halfTimeFullTimeEvent__header__inPlay]: isInPlay,
              ...brandingStyles
            })}
          >
            <AsianViewInPlayCell
              marketStartTime={market.startTime}
              marketId={market.id}
              containerClassName={styles.halfTimeFullTimeEvent__inPlayCell}
              hideTime
            />
            <div className={styles.halfTimeFullTimeEvent__header__container}>
              <div className={styles.halfTimeFullTimeEvent__header__info}>
                <AsianViewInPlayTime marketId={market.id} />
                <AsianViewFavouriteStar
                  favourite={{
                    entryName: event.translations[translation],
                    entryId: event.id,
                    entryType: FAVORITES_TYPES.event,
                    sportId
                  }}
                />
                <p
                  className={styles.halfTimeFullTimeEvent__name}
                  data-tooltip-id={'tooltip'}
                  data-tooltip-html={event.translations[translation]}
                >
                  {event.translations[translation]}
                </p>
              </div>
              <div className={styles.halfTimeFullTimeEvent__statisticsAndCashout}>
                <ProfitLossTablePopUpButton
                  eventId={event.id}
                  className={classNames({
                    [styles.halfTimeFullTimeEvent__statisticsAndCashout__leftBlockMargin]: !showCashOut
                  })}
                  marketId={market.id}
                />
                {showCashOut && (
                  <CashOut
                    showLabel={false}
                    showInfoIcon={false}
                    className={{
                      container: classNames(
                        styles.halfTimeFullTimeEvent__cashout,
                        styles.halfTimeFullTimeEvent__statisticsAndCashout__leftBlockMargin
                      ),
                      icon: styles.halfTimeFullTimeEvent__cashout__icon
                    }}
                    disabled={!cashOutCounter}
                    isAvPLTableAvailable
                  />
                )}
                {event.videoStreamingEnabled && (
                  <VideoStream hideLabel visible={event.videoStreamingEnabled} isInHeader eventId={event.id} />
                )}
                {event.matchStatEnabled && (
                  <AsianViewMatchStatistics matchStatEnabled={event.matchStatEnabled} eventId={event.id} />
                )}
              </div>
            </div>
          </div>
          <div className={styles.halfTimeFullTimeEvent__runners}>
            {market.runners.map((runner, index) => {
              return (
                <div
                  className={classNames(styles.halfTimeFullTimeEvent__runner, branding.MAIN_CONTAINER_BORDER)}
                  key={runner.id}
                >
                  <div
                    className={classNames(
                      styles.halfTimeFullTimeEvent__runner__header,
                      branding.MAIN_CONTAINER_BORDER,
                      branding.SELECTION_HEADER
                    )}
                  >
                    {t(`asianView.labels.market.${COLUMNS_NAMES_HALF_TIME_FULL_TIME[index]}`)}
                  </div>
                  <div
                    className={classNames(styles.halfTimeFullTimeEvent__runner__prices, {
                      [styles.halfTimeFullTimeEvent__runner__prices__inPlay]: isInPlay,
                      ...brandingStyles
                    })}
                  >
                    {isFirst && isLayBetting && <AsianViewBackLayLabels />}
                    {marketPricesId ? (
                      <SelectionOdds
                        competitionId={competitionId}
                        eventId={event.id}
                        marketId={market?.id || ''}
                        runner={runner}
                      />
                    ) : (
                      <SelectionOddsSkeleton />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default HalfTimeFullTimeEvent;
