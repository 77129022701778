import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

const cookiesToReset = [
  CookieNames.BET_LIST_SORT_BY,
  CookieNames.BET_LIST_BET_TYPE,
  CookieNames.BET_LIST_MARKETS_COLLAPSED
];

const useResetCookiesOnLogout = () => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const [, , removeCookie] = useCookies(cookiesToReset);

  useUpdateEffect(() => {
    if (!isLoggedIn) {
      cookiesToReset.forEach(cookieName => {
        removeCookie(cookieName, { path: '/' });
      });
    }
  }, [isLoggedIn]);
};

export default useResetCookiesOnLogout;
