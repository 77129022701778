import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { MAX_PRICE, MIN_PRICE } from 'constants/app';
import { KEY_CODES } from 'constants/betslip';
import { componentsBranding, educationBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { round } from 'utils/liability';

import styles from './styles.module.scss';

const validateNumber = (value: string, min?: number, max?: number): string => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return ''; // Empty for invalid input
  if (min !== undefined && numValue < min) return min.toFixed(2);
  if (max !== undefined && numValue > max) return max.toFixed(2);

  return numValue.toString();
};

const getIsInputRestricted = (newValue: string, currentValue: string, limit: number) => {
  return (
    (newValue.length > limit && currentValue.length <= limit) ||
    (currentValue.length > limit && newValue.length > currentValue.length)
  );
};

const DECIMAL_REGEX = /^(?!0\d)(?:\d*\.?\d*)$|^$/;
const ODDS_REGEX = /^[1-9]+\d*\.?\d{0,2}$/;
const MAX_ODDS_LENGTH = 7;
const MAX_COMMISSION_LENGTH = 5;
const MAX_STAKE_LENGTH = 10;
const MAX_LIABILITY_LENGTH = 10;

interface LayBetCalculatorProps {
  isDirect?: boolean;
}

const LayBetCalculator = ({ isDirect = false }: LayBetCalculatorProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const isMobileView = isMobile && !isDirect;

  const hasTooltip = isDirect || !isMobile;
  const currency = useSelector(getCurrency);
  const [stake, setStake] = useState('10');
  const [odds, setOdds] = useState('2');
  const [liability, setLiability] = useState('10');
  const [commission, setCommission] = useState('1');
  const [isGlossaryOpen, setIsGlossaryOpen] = useState<boolean>(false);
  const stakeRef = useRef<HTMLInputElement>(null);

  const handleStakeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (getIsInputRestricted(value, stake, MAX_STAKE_LENGTH)) return;

    if (DECIMAL_REGEX.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      setStake(validValue);
      const validLiability = validateNumber((parseFloat(validValue) * (parseFloat(odds) - 1)).toString());
      setLiability(validLiability);
    }
  };

  const handleOddsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (getIsInputRestricted(value, odds, MAX_ODDS_LENGTH)) return;

    if (ODDS_REGEX.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      setOdds(validValue);
      const validLiability = round(validateNumber((parseFloat(stake) * (parseFloat(validValue) - 1)).toString()));
      setLiability(validValue ? validLiability.toString() : '');
    }
  };

  const handleOddsBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (ODDS_REGEX.test(value) || value === '') {
      const validValue = validateNumber(value, MIN_PRICE, MAX_PRICE);
      setOdds(validValue);
      const validLiability = round(validateNumber((parseFloat(stake) * (parseFloat(validValue) - 1)).toString()));
      setLiability(validValue ? validLiability.toString() : '');
    }
  };

  const handleLiabilityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (getIsInputRestricted(value, liability, MAX_LIABILITY_LENGTH)) return;

    if (DECIMAL_REGEX.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      setLiability(validValue);
      const test = validateNumber((parseFloat(validValue) / (parseFloat(odds) - 1)).toString());
      setStake(test);
    }
  };

  const handleCommissionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (getIsInputRestricted(value, commission, MAX_COMMISSION_LENGTH)) return;

    if (DECIMAL_REGEX.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      setCommission(validValue);
    }
  };

  const handleCommissionBlur = () => {
    const validValue = validateNumber(commission, 0, 100);
    setCommission(validValue || '0');
  };

  const calculateProfit = () => {
    if (!stake || !odds || !commission) {
      return 0;
    }
    const grossProfit = parseFloat(stake);
    const commissionValue = (parseFloat(stake) * parseFloat(commission)) / 100;
    return round(grossProfit - commissionValue);
  };

  const calculatePayout = () => {
    if (!stake || !odds || !commission) {
      return 0;
    }
    const grossPayout = parseFloat(stake) * parseFloat(odds);
    const commissionValue = (parseFloat(stake) * parseFloat(commission)) / 100;
    return round(grossPayout - commissionValue);
  };

  const { formattedAmount: liabilityValue } = useFormatCurrency(
    (parseFloat(liability) || 0) * -1,
    currency.currencyCode,
    {
      noRounding: true,
      isCheckIndian: true,
      isPositiveSign: true,
      noZeroSign: true,
      alwaysFlexibleDisplayFormat: true,
      useFlexibleFromMillion: true
    }
  );

  const { formattedAmount: commissionValue } = useFormatCurrency(
    (parseFloat(stake || '0') * parseFloat(commission || '0')) / 100,
    currency.currencyCode,
    {
      noRounding: true,
      isCheckIndian: true,
      isPositiveSign: false,
      noZeroSign: true,
      alwaysFlexibleDisplayFormat: true,
      useFlexibleFromMillion: true
    }
  );

  const { formattedAmount: profitValue } = useFormatCurrency(calculateProfit(), currency.currencyCode, {
    noRounding: true,
    isCheckIndian: true,
    isPositiveSign: true,
    noZeroSign: true,
    alwaysFlexibleDisplayFormat: true,
    useFlexibleFromMillion: true
  });

  const { formattedAmount: payoutValue } = useFormatCurrency(calculatePayout(), currency.currencyCode, {
    noRounding: true,
    isCheckIndian: true,
    isPositiveSign: false,
    noZeroSign: true,
    alwaysFlexibleDisplayFormat: true,
    useFlexibleFromMillion: true
  });

  const { formattedAmount: zeroValue } = useFormatCurrency(0, currency.currencyCode, {
    noRounding: true,
    isCheckIndian: true,
    isPositiveSign: true,
    noZeroSign: true,
    alwaysFlexibleDisplayFormat: true,
    useFlexibleFromMillion: true
  });

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === KEY_CODES.TAB) {
      event.preventDefault();
      stakeRef.current?.focus();
    }
  };

  return (
    <>
      {isDirect && (
        <Helmet>
          <title data-rh="true">{t('education.labels.masterLayBetting')}</title>
        </Helmet>
      )}
      <div
        className={classNames(styles.wrapper, educationBranding.LAY_BETTING_CALCULATOR_BOTTOM, {
          [styles.wrapper__noBorder]: isDirect,
          [styles.embeded]: !isDirect
        })}
      >
        <div className={classNames(styles.border, { [styles.border__noBorder]: isDirect })}>
          <div className={styles.form}>
            <div className={styles.inputColumns}>
              <div className={classNames(styles.inputWrapper, { [styles.inputWrapper__mobile]: isMobileView })}>
                <label
                  data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                  data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.stake')) : undefined}
                  className={classNames(styles.inputLabel, {
                    [styles.inputLabel__mobile]: isMobileView,
                    'cursor-help': hasTooltip
                  })}
                >
                  {t('education.labels.stake')}, {currency.symbol}
                </label>
                <input
                  ref={stakeRef}
                  lang="en-US"
                  inputMode="decimal"
                  autoComplete="off"
                  className={classNames(styles.input, {
                    [styles.input__mobile]: isMobileView,
                    [styles.input__center]: isMobileView,
                    [componentsBranding.FORM_INPUT]: !isMobileView,
                    [mobileComponents.INPUT]: isMobileView
                  })}
                  type="text"
                  value={stake}
                  onChange={handleStakeChange}
                />
              </div>
              <div className={classNames(styles.inputWrapper, { [styles.inputWrapper__mobile]: isMobileView })}>
                <label
                  data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                  data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.odds')) : undefined}
                  className={classNames(styles.inputLabel, {
                    'cursor-help': hasTooltip,
                    [styles.inputLabel__mobile]: isMobileView
                  })}
                >
                  {t('education.labels.odds')}
                </label>
                <input
                  lang="en-US"
                  inputMode="decimal"
                  autoComplete="off"
                  className={classNames(styles.input, {
                    [styles.input__mobile]: isMobileView,
                    [styles.input__center]: isMobileView,
                    [componentsBranding.FORM_INPUT]: !isMobileView,
                    [mobileComponents.INPUT]: isMobileView
                  })}
                  type="text"
                  value={odds}
                  onChange={handleOddsChange}
                  onBlur={handleOddsBlur}
                />
              </div>
            </div>
            <div className={styles.inputColumns}>
              <div className={classNames(styles.inputWrapper, { [styles.inputWrapper__mobile]: isMobileView })}>
                <label
                  data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                  data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.liabilityRisk')) : undefined}
                  className={classNames(styles.inputLabel, {
                    'cursor-help': hasTooltip,
                    [styles.inputLabel__mobile]: isMobileView
                  })}
                >
                  {t('education.labels.liabilityRisk')}, {currency.symbol}
                </label>
                <input
                  lang="en-US"
                  inputMode="decimal"
                  autoComplete="off"
                  className={classNames(styles.input, {
                    [styles.input__mobile]: isMobileView,
                    [styles.input__center]: isMobileView,
                    [componentsBranding.FORM_INPUT]: !isMobileView,
                    [mobileComponents.INPUT]: isMobileView
                  })}
                  type="text"
                  value={liability}
                  onChange={handleLiabilityChange}
                />
              </div>
              <div className={classNames(styles.inputWrapper, { [styles.inputWrapper__mobile]: isMobileView })}>
                <label
                  data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                  data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.commission')) : undefined}
                  className={classNames(styles.inputLabel, {
                    [styles.inputLabel__mobile]: isMobileView,
                    'cursor-help': hasTooltip
                  })}
                >
                  {t('education.labels.commission')}, %
                </label>
                <input
                  lang="en-US"
                  inputMode="decimal"
                  autoComplete="off"
                  className={classNames(styles.input, {
                    [styles.input__mobile]: isMobileView,
                    [styles.input__center]: isMobileView,
                    [componentsBranding.FORM_INPUT]: !isMobileView,
                    [mobileComponents.INPUT]: isMobileView
                  })}
                  type="text"
                  value={commission}
                  onChange={handleCommissionChange}
                  onBlur={handleCommissionBlur}
                  onKeyDown={keyDownHandler}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.results, { [styles.results__mobile]: isMobileView })}>
            <div
              className={classNames(styles.results__result, {
                [styles.results__result__mobile]: isMobileView
              })}
            >
              <div
                className={classNames(styles.results__header, {
                  [styles.results__header__mobile]: isMobileView
                })}
              >
                <i
                  className={classNames('fa2 fa2-success-16', styles.results__header__icon, {
                    [styles.results__header__icon__mobile]: isMobileView
                  })}
                />
                <span>{t('education.labels.ifBetWins')}</span>
              </div>
              <div className={styles.info__rows}>
                <div
                  className={classNames(styles.info__row, {
                    [styles.info__row__mobile]: isMobileView
                  })}
                >
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.commission')) : undefined}
                    className={classNames(styles.info__label, {
                      [styles.info__label__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {t('education.labels.commission')}
                  </span>
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.commission')) : undefined}
                    className={classNames(styles.info__value, {
                      [styles.info__value__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {commissionValue}
                  </span>
                </div>
                <div
                  className={classNames(styles.info__row, {
                    [styles.info__row__mobile]: isMobileView
                  })}
                >
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.profit')) : undefined}
                    className={classNames(styles.info__label, {
                      [styles.info__label__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {t('education.labels.profit')}
                  </span>
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.profit')) : undefined}
                    className={classNames(styles.info__value, styles.info__value__positive, {
                      [styles.info__value__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {profitValue}
                  </span>
                </div>
                <div
                  className={classNames(styles.info__row, {
                    [styles.info__row__mobile]: isMobileView
                  })}
                >
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.payout')) : undefined}
                    className={classNames(styles.info__label, {
                      [styles.info__label__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {t('education.labels.payout')}
                  </span>
                  <span
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.payout')) : undefined}
                    className={classNames(styles.info__value, {
                      [styles.info__value__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {payoutValue}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.results__result, styles.results__result__lose, {
                [styles.results__result__mobile]: isMobileView
              })}
            >
              <div
                className={classNames(styles.results__header, {
                  [styles.results__header__mobile]: isMobileView
                })}
              >
                <i
                  className={classNames(
                    'fa2 fa2-error-16',
                    styles.results__header__icon,
                    styles.results__header__icon__error,
                    { [styles.results__header__icon__mobile]: isMobileView }
                  )}
                />
                <span>{t('education.labels.ifBetLoses')}</span>
              </div>
              <div className={styles.info__rows}>
                <div
                  className={classNames(styles.info__row, {
                    [styles.info__row__mobile]: isMobileView
                  })}
                >
                  <div
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.commission')) : undefined}
                    className={classNames(styles.info__label, {
                      [styles.info__label__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {t('education.labels.commission')}
                  </div>
                  <div
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.commission')) : undefined}
                    className={classNames(styles.info__value, styles.info__value__zero, {
                      [styles.info__value__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {zeroValue}
                  </div>
                </div>
                <div
                  className={classNames(styles.info__row, {
                    [styles.info__row__mobile]: isMobileView
                  })}
                >
                  <div
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.loss')) : undefined}
                    className={classNames(styles.info__label, {
                      [styles.info__label__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {t('education.labels.loss')}
                  </div>
                  <div
                    data-tooltip-id={hasTooltip ? 'tooltip' : undefined}
                    data-tooltip-html={hasTooltip ? unescape(t('tooltip.education.loss')) : undefined}
                    className={classNames(styles.info__value, styles.info__value__negative, {
                      [styles.info__value__mobile]: isMobileView,
                      'cursor-help': hasTooltip
                    })}
                  >
                    {liabilityValue}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isMobileView && <p className={styles.note}>{t('education.labels.hoverToLearnMore')}</p>}
          {isMobileView && (
            <div className={styles.glossary__wrapper}>
              <button onClick={() => setIsGlossaryOpen(!isGlossaryOpen)} className={styles.glossary__header}>
                <i className={classNames('fa2 fa2-info-16', styles.glossary__header__icon)} />
                <span className={styles.glossary__header__title}>{t('education.labels.glossary')}</span>
                <i
                  className={classNames('fa2 fa2-chevron-down-icon', styles.glossary__header__collapse, {
                    [styles.glossary__header__collapse__open]: isGlossaryOpen
                  })}
                />
              </button>
              {isGlossaryOpen && (
                <div className={styles.glossary__content}>
                  <p>
                    <span className={styles.glossary__content__field}>
                      {t('education.labels.stake')}, {currency.symbol}:{' '}
                    </span>
                    {t('tooltip.education.stake')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>{t('education.labels.odds')}: </span>
                    {t('tooltip.education.odds')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>
                      {t('education.labels.liabilityRisk')}, {currency.symbol}:{' '}
                    </span>
                    {t('tooltip.education.liabilityRisk')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>{t('education.labels.commission')}, %: </span>
                    {t('tooltip.education.commission')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>{t('education.labels.profit')}: </span>
                    {t('tooltip.education.profit')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>{t('education.labels.loss')}: </span>
                    {t('tooltip.education.loss')}
                  </p>
                  <p>
                    <span className={styles.glossary__content__field}>{t('education.labels.payout')}: </span>
                    {t('tooltip.education.payout')}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LayBetCalculator;
