import { TFunction } from 'i18next';

import {
  COL_GAP,
  COL_LG,
  COL_MD,
  COL_SM,
  COL_XS,
  myBetsTableHeader,
  NavigationButtons,
  TABLE_PADDING
} from 'constants/myBets';

export const getPageHeadingText = (selectedTab?: string) => {
  switch (selectedTab) {
    case NavigationButtons.MyBets:
      return 'account.mybets.title';
    case NavigationButtons.Statement:
      return 'account.statement.title';
    case NavigationButtons.ProfitAndLoss:
      return 'accountNavigation.profitandloss';
    default:
      return 'account.mybets.title';
  }
};

export const getRangeDate = ({ date, t }: { date: Date; t: TFunction }) => {
  const day = date.getDate();

  return `${day < 10 ? '0' : ''}${day} ${t(`dates.shortMonth.${date.getMonth()}`)} ${date.getFullYear()}`;
};

export const getDescriptionColumnWidth = (
  tableWidth = 0,
  isWideScreenView: boolean,
  isCurrentPeriod: boolean,
  isAsianView: boolean,
  alternativeLayOdds: boolean,
  totalWinnings: boolean
) => {
  const columns = [
    { col: myBetsTableHeader.REF_ID, width: COL_SM, isVisible: isWideScreenView },
    { col: myBetsTableHeader.SETTLED, width: COL_MD, isVisible: !isCurrentPeriod },
    { col: myBetsTableHeader.PLACED, width: COL_MD, isVisible: true },
    { col: myBetsTableHeader.TYPE, width: COL_XS, isVisible: isWideScreenView },
    { col: myBetsTableHeader.ODDS_TYPE, width: COL_XS, isVisible: isAsianView },
    { col: myBetsTableHeader.BACK_ODDS, width: COL_XS, isVisible: !isAsianView && alternativeLayOdds },
    { col: myBetsTableHeader.LAY_ODDS, width: COL_XS, isVisible: !isAsianView && alternativeLayOdds },
    { col: myBetsTableHeader.ODDS, width: COL_XS, isVisible: !(!isAsianView && alternativeLayOdds) },
    { col: myBetsTableHeader.STAKE, width: COL_MD, isVisible: true },
    { col: myBetsTableHeader.RISK, width: COL_MD, isVisible: isAsianView },
    { col: myBetsTableHeader.LIABILITY, width: COL_MD, isVisible: !isAsianView && totalWinnings },
    { col: myBetsTableHeader.TOTAL_WINNINGS, width: COL_MD, isVisible: !isAsianView && totalWinnings },
    { col: myBetsTableHeader.POTENTIAL_PROFIT, width: COL_MD, isVisible: true },
    { col: myBetsTableHeader.STATUS, width: COL_LG, isVisible: isWideScreenView }
  ];

  const columnsWidth = columns.reduce((res, item) => {
    return res + (item.isVisible ? item.width : 0);
  }, 0);

  const columnsLength = columns.filter(({ isVisible }) => isVisible).length;

  return tableWidth - (columnsWidth + columnsLength * COL_GAP) - TABLE_PADDING;
};
