import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewMobileBetslip from 'components/AsianViewMobileBetslip';
import AsianViewBackLayLabels from 'components/AsianViewPageModule/components/Event/MarketCell/AsianViewBackLayLabels';
import MobileSelectionOdds from 'components/AsianViewPageModule/components/MobileEvent/MobileSelectionOdds';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { TAsianViewMarket } from 'redux/modules/asianView/type';

import styles from './styles.module.scss';

interface AdditionalSelectionsProps {
  asianHandicap?: TAsianViewMarket;
  goalLines?: TAsianViewMarket;
  matchOdds?: TAsianViewMarket;
  halfTime?: TAsianViewMarket;
  firstHalfGoalsMarkets: TAsianViewMarket[];
  competitionId: string;
  eventId: string;
  selectionsOrder: number;
  isFullTimeActive: boolean;
}

const AdditionalSelections = ({
  asianHandicap,
  goalLines,
  matchOdds,
  halfTime,
  firstHalfGoalsMarkets,
  competitionId,
  eventId,
  selectionsOrder,
  isFullTimeActive
}: AdditionalSelectionsProps) => {
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const isLayBetting = useAsianLayBetting();

  const selectionIndexes = selectionsOrder === 0 ? [0, 1, 2] : [0, 1];
  const hasFirstHalf = !!((firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id) || halfTime?.id);

  let betslipPlaceholder: string;

  if (isFullTimeActive || !hasFirstHalf || isLandscapeView || !isLayBetting) {
    betslipPlaceholder =
      selectionsOrder === 0
        ? `${asianHandicap?.id}_${goalLines?.id}_${matchOdds?.id}`
        : `${asianHandicap?.id}_${goalLines?.id}`;
  } else if ((!isFullTimeActive || isLandscapeView || !isLayBetting) && hasFirstHalf) {
    betslipPlaceholder = `${firstHalfGoalsMarkets ? firstHalfGoalsMarkets[selectionsOrder]?.id : ''}_${halfTime?.id}`;
  }

  return (
    <>
      {selectionsOrder !== 0 && (
        <div className={classNames('biab_asian-view-event-selections-separator', styles.separator)} />
      )}
      {isLayBetting && selectionsOrder === 0 && (
        <div className={classNames('biab_asian-view-event-backLay', styles.backLay)}>
          {(isFullTimeActive || !hasFirstHalf || isLandscapeView || !isLayBetting) && (
            <>
              <AsianViewBackLayLabels containerClassName={styles.backLay__wrapper} />
              <AsianViewBackLayLabels containerClassName={styles.backLay__wrapper} />
              <AsianViewBackLayLabels containerClassName={styles.backLay__wrapper} />
            </>
          )}
          {(!isFullTimeActive || isLandscapeView || !isLayBetting) && hasFirstHalf && (
            <>
              <AsianViewBackLayLabels containerClassName={styles.backLay__wrapper} />
              <AsianViewBackLayLabels containerClassName={styles.backLay__wrapper} />
            </>
          )}
        </div>
      )}
      {selectionIndexes.map(selectionIndex => (
        <div key={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}>
          <div
            key={selectionIndex}
            className={classNames('biab_asian-view-event-selections', styles.selections, {
              [styles.padding]: !isLayBetting && !selectionIndex && !selectionsOrder
            })}
          >
            {(isFullTimeActive || !hasFirstHalf || isLandscapeView || !isLayBetting) && (
              <>
                <MobileSelectionOdds
                  market={asianHandicap}
                  eventId={eventId}
                  competitionId={competitionId}
                  selectionIndex={selectionIndex}
                  runnerIndex={selectionsOrder}
                  betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}
                />
                <MobileSelectionOdds
                  market={goalLines}
                  eventId={eventId}
                  competitionId={competitionId}
                  selectionIndex={selectionIndex}
                  runnerIndex={selectionsOrder}
                  betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}
                />
                {selectionsOrder === 0 ? (
                  <MobileSelectionOdds
                    market={matchOdds}
                    eventId={eventId}
                    competitionId={competitionId}
                    selectionIndex={selectionIndex}
                    betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}
                  />
                ) : (
                  <div />
                )}
              </>
            )}
            {(!isFullTimeActive || isLandscapeView || !isLayBetting) && hasFirstHalf && (
              <>
                <MobileSelectionOdds
                  market={firstHalfGoalsMarkets && firstHalfGoalsMarkets[selectionsOrder]}
                  eventId={eventId}
                  competitionId={competitionId}
                  selectionIndex={selectionIndex}
                  betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}
                />
                {selectionsOrder === 0 ? (
                  <MobileSelectionOdds
                    market={halfTime}
                    eventId={eventId}
                    competitionId={competitionId}
                    selectionIndex={selectionIndex}
                    betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`}
                  />
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
          <AsianViewMobileBetslip betslipId={`${betslipPlaceholder}_${selectionsOrder}_${selectionIndex}`} isMargin />
        </div>
      ))}
    </>
  );
};

export default AdditionalSelections;
