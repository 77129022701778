import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useAccountUrlParams from 'hooks/useAccountUrlParams';
import MobileSelect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileSelect';
import { setMobileFilters } from 'redux/modules/myBets';
import { getMobileBettingPLSorting, getMobileSorting } from 'redux/modules/myBets/selectors';
import { SortByFields, TSortByType } from 'redux/modules/myBets/type';
import { TMobileSelectOption } from 'types/myBets';

const MobileFiltersSorting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isProfitLossPage } = useAccountUrlParams();
  const sorting = useSelector(getMobileSorting);
  const sortingBettingPL = useSelector(getMobileBettingPLSorting);
  const sortingPLOptions = [SortByFields.SETTLED_DATE, SortByFields.START_DATE] as TSortByType[];

  const sortingOptions: TMobileSelectOption[] = isProfitLossPage
    ? [
        {
          id: SortByFields.SETTLED_DATE,
          label: t('account.profitandloss.labels.settled'),
          isSelected:
            sortingBettingPL?.type === SortByFields.SETTLED_DATE ||
            (!!sortingBettingPL?.type && !sortingPLOptions.includes(sortingBettingPL?.type)),
          defaultIsSelected: sortingBettingPL?.type === SortByFields.SETTLED_DATE
        },
        {
          id: SortByFields.START_DATE,
          label: t('account.profitandloss.labels.start'),
          isSelected: !sortingBettingPL || sortingBettingPL?.type === SortByFields.START_DATE,
          defaultIsSelected: !sortingBettingPL || sortingBettingPL?.type === SortByFields.START_DATE
        }
      ]
    : [
        {
          id: SortByFields.DATE,
          label: t('account.labels.settledTime'),
          isSelected: !sorting || sorting?.type === SortByFields.DATE,
          defaultIsSelected: !sorting || sorting?.type === SortByFields.DATE
        },
        {
          id: SortByFields.PLACED_DATE,
          label: t('account.labels.placedTime'),
          isSelected: sorting?.type === SortByFields.PLACED_DATE,
          defaultIsSelected: sorting?.type === SortByFields.PLACED_DATE
        }
      ];

  const changeSortingHandler = (optionsChanged: TMobileSelectOption[], isSortingChanged: boolean) => {
    dispatch(
      setMobileFilters({
        sorting:
          (optionsChanged.find(({ isSelected }) => isSelected)?.id as TSortByType) ??
          (isProfitLossPage ? SortByFields.SETTLED_DATE : SortByFields.PLACED_DATE),
        isSortingChanged
      })
    );
  };

  return (
    <MobileSelect
      title={t('account.labels.sortBy')}
      defaultOptions={sortingOptions}
      onChangeCallback={changeSortingHandler}
    />
  );
};

export default MobileFiltersSorting;
