import { IconsConfigProps } from 'types';

import {
  AB,
  CLASSIC_NAV_CLASSES,
  DEFAULT,
  DEFAULT_SPORT_ICON,
  FA_ICON,
  FAVOURITE,
  HOW_TO,
  IN_PLAY,
  NONE,
  POKER_STAR,
  SPORT_ICON
} from './icons';

export const IconsConfig: IconsConfigProps = {
  SPORT_ICONS: {
    6423: 'fi biab_sport-icon flaticon-american-football-ball',
    3988: 'fi biab_sport-icon flaticon-athlete-running',
    61420: 'fi biab_sport-icon flaticon-rugby-ball',
    7511: 'fi biab_sport-icon flaticon-baseball',
    7522: 'fi biab_sport-icon flaticon-basketball-game',
    998918: 'fi biab_sport-icon flaticon-man-bowling',
    6: 'fi biab_sport-icon flaticon-boxing-gloves',
    // 4: 'fi biab_sport-icon flaticon-cricket-equipment',
    4: 'biab_sport-icon fa2 fa2-cricket',
    11: 'fi fi-filtering biab_sport-icon flaticon-bicycle',
    3503: 'fi biab_sport-icon flaticon-target',
    27454571: 'fi biab_sport-icon flaticon-gamepad-controller',
    6231: 'fi biab_sport-icon flaticon-hand-holding-up-a-financial-graph',
    2152880: 'fi biab_sport-icon flaticon-bag-pipe',
    3: 'fi biab_sport-icon flaticon-birdie',
    4339: 'fi biab_sport-icon flaticon-greyhound',
    'tc-4339': 'fi biab_sport-icon flaticon-greyhound',
    468328: 'fi biab_sport-icon flaticon-handball',
    7: 'biab_sport-icon fa2 fa2-horse',
    'tc-7': 'biab_sport-icon fa2 fa2-horse',
    7524: 'fi biab_sport-icon flaticon-ice-hockey-player-silhouette',
    26420387: 'fi biab_sport-icon flaticon-martial-arts-couple-fight',
    8: 'fi biab_sport-icon flaticon-race-flag',
    2378961: 'fi biab_sport-icon flaticon-political-candidate-speech',
    1477: 'fi biab_sport-icon flaticon-rugby',
    5: 'fi biab_sport-icon flaticon-rugby',
    6422: 'fi biab_sport-icon flaticon-bowling-pins',
    1: 'biab_sport-icon fa2 fa2-football',
    10: 'fi biab_sport-icon flaticon-special-tv-program-day-reminder-calendar-page-interface-symbol',
    2: 'biab_sport-icon fa2 fa2-tennis',
    998917: 'fi biab_sport-icon flaticon-volleyball',
    27105927: 'fi biab_sport-icon flaticon-snow',
    451485: 'fi biab_sport-icon flaticon-snow',
    627555: 'fi biab_sport-icon flaticon-badminton-racket-and-feather',
    72382: 'fi biab_sport-icon flaticon-pool-eight-ball',
    998916: 'fi biab_sport-icon flaticon-sailing-yacht',
    28361978: 'fi biab_sport-icon flaticon-lottery',
    7523: 'fi biab_sport-icon flaticon-hockey',
    678378: 'fi biab_sport-icon flaticon-stadium',
    606611: 'fi biab_sport-icon flaticon-volleyball-game',
    136332: 'fa biab_sport-icon fas flaticon-chess'
  },

  TOOLBAR_ICONS: {
    [AB]: {
      homeIcon: 'biab_custom-icon-home',
      sportsIcon: 'biab_toolbar-icon-sports',
      favoritesIcon: 'biab_toolbar-icon-favorites',
      searchIcon: 'biab_toolbar-icon-search',
      inPlayIcon: 'biab_toolbar-icon-in-play',
      accountIcon: 'biab_toolbar-icon-profile-stroke',
      myBetsIcon: 'fa2 fa2-myBets',
      cashoutIcon: 'biab_cash-out-navigation-icon-ab',
      gamesIcon: 'biab_games-icon-sm'
    },
    [DEFAULT]: {
      homeIcon: 'biab_custom-icon-home',
      sportsIcon: 'ion-ios-football-outline',
      favoritesIcon: 'ion-ios-star-outline',
      searchIcon: 'fa2 fa2-search',
      inPlayIcon: 'ion-ios-timer-outline',
      accountIcon: 'fa2 fa2-person',
      myBetsIcon: 'fa2 fa2-myBets',
      cashoutIcon: 'biab_cash-out-navigation-icon',
      gamesIcon: 'biab_games-icon-sm'
    }
  },

  SUBHEADER_ICONS: {
    [POKER_STAR]: {
      accountIcon: 'biab_general-icon ps-icon-account',
      settingsIcon: 'biab_general-icon ps-icon-settings',
      responsibleBettingIcon: 'biab_general-icon ps-icon-rg',
      homeIcon: 'biab_general-icon ps-icon-home',
      homeIconMobile: 'biab_general-icon ps-icon-home',
      inPlayIcon: 'biab_general-icon ps-icon-in-play',
      cashoutIcon: 'fa biab_cash-out-navigation-icon',
      myBetsIcon: 'biab_general-icon ps-icon-my-bets',
      sportsIcon: 'biab_general-icon ps-icon-a-z',
      searchIcon: 'biab_general-icon ps-icon-search',
      gamesIcon: 'icon_io biab_games-icon-sm',
      asianViewIcon: 'biab_sport-icon fa2 fa2-football'
    },
    [DEFAULT]: {
      accountIcon: 'fa2 fa2-person',
      settingsIcon: 'fa2 fa2-cog',
      responsibleBettingIcon: 'fa2 fa2-hand-paper-o',
      homeIcon: 'fa2 fa2-home-icon',
      homeIconMobile: 'biab_custom-icon-home',
      inPlayIcon: 'ion-ios-timer-outline',
      cashoutIcon: 'fa biab_cash-out-navigation-icon',
      defaultSport: 'fi biab_sport-icon flaticon-stadium',
      sportsIcon: 'biab_icon icon_io ion-ios-football-outline',
      myBetsIcon: 'fa2 fa2-myBets',
      searchIcon: 'biab_icon fa2 fa2-search',
      gamesIcon: 'biab_icon icon_io biab_games-icon-sm',
      asianViewIcon: 'biab_sport-icon fa2 fa2-football'
    },
    [CLASSIC_NAV_CLASSES]: {
      [IN_PLAY]: 'ion-ios-timer-outline biab_in-play-icon',
      [HOW_TO]: 'fa2 fa2-info-circle',
      [FAVOURITE]: 'ion-ios-star-outline',
      [NONE]: 'biab_no-icon',
      [DEFAULT_SPORT_ICON]: 'fi biab_sport-icon flaticon-stadium',
      [SPORT_ICON]: 'biab_sport-icon',
      [FA_ICON]: 'fa-icon',
      FAVOURITE_FILLED: 'fa2 fa2_fav-icons-active',
      asianViewIcon: 'biab_sport-icon fa2 fa2-football'
    }
  },
  NAVIGATION_ICONS: {
    [POKER_STAR]: {
      6423: 'biab_sport-icon ps-icon-american-football',
      3988: 'biab_sport-icon ps-icon-athletics',
      61420: 'biab_sport-icon ps-icon-australian-rules',
      7511: 'biab_sport-icon ps-icon-baseball',
      7522: 'biab_sport-icon ps-icon-basketball',
      998918: 'biab_sport-icon ps-icon-bowls',
      6: 'biab_sport-icon ps-icon-boxing',
      4: 'biab_sport-icon ps-icon-cricket',
      11: 'biab_sport-icon ps-icon-cycling',
      3503: 'biab_sport-icon ps-icon-darts',
      27454571: 'biab_sport-icon ps-icon-esports',
      6231: 'fi biab_sport-icon flaticon-hand-holding-up-a-financial-graph',
      2152880: 'fi biab_sport-icon flaticon-bag-pipe',
      3: 'biab_sport-icon ps-icon-golf',
      4339: 'fi biab_sport-icon flaticon-greyhound',
      'tc-4339': 'fi biab_sport-icon flaticon-greyhound',
      468328: 'biab_sport-icon ps-icon-handball',
      7: 'biab_sport-icon ps-icon-horse',
      'tc-7': 'biab_sport-icon ps-icon-horse',
      7524: 'biab_sport-icon ps-icon-ice-hockey',
      26420387: 'biab_sport-icon ps-icon-mixed-martial-arts',
      8: 'biab_sport-icon ps-icon-motor-sports',
      2378961: 'fi biab_sport-icon flaticon-political-candidate-speech',
      1477: 'biab_sport-icon ps-icon-rugby-league',
      5: 'biab_sport-icon ps-icon-rugby-union',
      6422: 'biab_sport-icon ps-icon-snooker',
      1: 'biab_sport-icon ps-icon-football',
      10: 'fi biab_sport-icon flaticon-special-tv-program-day-reminder-calendar-page-interface-symbol',
      2: 'biab_sport-icon ps-icon-tennis',
      998917: 'biab_sport-icon ps-icon-volleyball',
      27105927: 'biab_sport-icon ps-icon-winter-olympics',
      451485: 'biab_sport-icon ps-icon-winter-sports',
      627555: 'biab_sport-icon ps-icon-badminton',
      72382: 'fi biab_sport-icon flaticon-pool-eight-ball',
      998916: 'fi biab_sport-icon flaticon-sailing-yacht',
      28361978: 'fi biab_sport-icon flaticon-lottery',
      7523: 'biab_sport-icon ps-icon-hockey',
      678378: 'fi biab_sport-icon flaticon-stadium',
      606611: 'biab_sport-icon ps-icon-netball',
      2872194: 'biab_sport-icon ps-icon-beach-volleyball',
      2872196: 'biab_sport-icon ps-icon-canoeing',
      18643353: 'biab_sport-icon ps-icon-equestrian',
      4726642: 'biab_sport-icon ps-icon-surfing',
      620576: 'biab_sport-icon ps-icon-swimming',
      2593174: 'biab_sport-icon ps-icon-table-tennis',
      2901849: 'biab_sport-icon ps-icon-water-polo',
      27485048: 'biab_sport-icon ps-icon-wrestling',
      28361987: 'biab_sport-icon ps-icon-alpine-skiing',
      28361989: 'biab_sport-icon ps-icon-biathlon',
      28361988: 'biab_sport-icon ps-icon-cross-country-ski',
      28361990: 'biab_sport-icon ps-icon-freestyle-skiing',
      27610230: 'biab_sport-icon ps-icon-gaa-football',
      27610231: 'biab_sport-icon ps-icon-gaa-hurling',
      28347302: 'biab_sport-icon ps-icon-gymnastics',
      12: 'biab_sport-icon ps-icon-rowing',
      28361984: 'biab_sport-icon ps-icon-ski-jumping',
      27065662: 'biab_sport-icon ps-icon-triathlon',
      28361982: 'biab_sport-icon ps-icon-weightlifting'
    },
    DEFAULT_ICON: 'fi biab_sport-icon flaticon-stadium'
  }
};
