import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import useSoccerTimeByEventId from 'hooks/useSoccerTime';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';
import { getSoccerLabel } from 'utils/market';

import styles from './styles.module.scss';

type SoccerMarketScoreProps = {
  isCashOut?: boolean;
  eventId: string;
};

const SoccerMarketScore = ({ eventId, isCashOut }: SoccerMarketScoreProps) => {
  const { t } = useTranslation();

  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const period = useSelector(getEventsUpdatedDataPeriod(eventId));

  const { time, addedTime } = useSoccerTimeByEventId(eventId);

  const score = getSoccerLabel({
    t,
    elapsedRegularTime: time,
    elapsedAddedTime: addedTime,
    inPlayMatchStatus,
    period
  });

  return isCashOut ? (
    <table className={classNames('biab_soccer', styles.soccerCashOut)}>
      <tbody>
        <tr>
          <td rowSpan={2} className={styles.soccerCashOut__time}>
            {score}
          </td>
          <td className={classNames('biab_score', styles.soccerCashOut__score)}>{homeScore}</td>
        </tr>
        <tr>
          <td className={classNames('biab_score', styles.soccerCashOut__score)}>{awayScore}</td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div className={classNames('biab_soccer', styles.soccer)}>
      <span className={styles.soccer__time}>{score}</span>
      <div className={classNames('biab_score', styles.soccer__ScoreBlock)}>
        <span className={styles.soccer__score}>{homeScore}</span>
        <span className={styles.soccer__score}>{awayScore}</span>
      </div>
    </div>
  );
};

export default SoccerMarketScore;
