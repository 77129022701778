import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import { BETTING_TYPES, CommissionTypes } from 'constants/app';
import { accountPagesBranding, mobileIcons } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver,
  getTimezone,
  getTimezoneCookieEnabled,
  getWindowWidth
} from 'redux/modules/appConfigs/selectors';
import { TSettledBet } from 'redux/modules/betList/type';
import { OfferStates, TOfferState } from 'types/myBets';
import { parseMillisecondsToTime } from 'utils/date';
import { betsSideValue, getStatusTranslationKey, oddsValue, shouldSwapColors } from 'utils/myBetsValues';

import styles from './SettledBet.module.scss';

const SettledBet = ({ bet }: { bet: TSettledBet }) => {
  const { t } = useTranslation();
  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const windowWidth = useSelector(getWindowWidth);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const stakeRef = useRef<HTMLDivElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const [newLineColumn, setNewLineColumn] = useState<'' | 'stake' | 'result'>('');

  const isSwapColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket);
  const isBackIndicator = isSwapColors ? bet?.side === BetSides.Lay : bet?.side === BetSides.Back;

  const isPNC = bet.commissionType === CommissionTypes.PRICES;
  const isEachWay = bet.marketType === MARKET_TYPES.eachWay;
  const state = bet.offerState.toLowerCase();

  const isPositive = ['matched', 'won', ...(isPNC ? ['placed'] : [])].includes(state);
  const isNegative = ['unmatched', 'lost', ...(!isPNC ? ['placed'] : [])].includes(state);

  const statusValue = t(getStatusTranslationKey(bet.offerState as TOfferState, isPNC));
  const { date } = useParseMyBetsDates({
    dateToParse: bet.cancelledDate || bet.settledDate || bet.placedDate,
    divider: ','
  });

  const isSettled = bet?.offerState === OfferStates.WON || bet?.offerState === OfferStates.LOST;
  const isGame = bet?.betType === BettingTypes.GAME;

  const startTime = parseMillisecondsToTime(bet.marketStartDate || 0, timezone, timezoneCookieEnabled);
  const formatCurrencySetting = { noRounding: true, isCheckIndian: true, noSymbol: false };
  const { noFormattedAmount: stake } = useFormatCurrency(bet?.size || 0, bet?.currency, formatCurrencySetting);
  const profitValue = isSettled ? bet?.profit || bet?.profitNet : bet.size;
  const { noFormattedAmount: profit } = useFormatCurrency(profitValue || 0, bet?.currency, {
    isCheckIndian: true,
    noRounding: true,
    noSymbol: false,
    isPositiveSign: isPositive || isNegative,
    noZeroSign: true
  });
  const marketName = (
    <>
      {bet.raceName && (
        <>
          {startTime} {bet.raceName} -{' '}
        </>
      )}
      {isGame ? (
        <>
          {t('games.gameId')}: {bet.marketId}
        </>
      ) : (
        (!bet.raceName && !bet.outright && bet.marketNameWithParents) || bet.marketName
      )}
    </>
  );

  const stakeSize = useResizeObserver({
    ref: stakeRef,
    box: 'border-box'
  });
  const resultSize = useResizeObserver({
    ref: resultRef,
    box: 'border-box'
  });

  useEffect(() => {
    if (stakeRef.current && resultRef.current) {
      const { top: stakeTop } = stakeRef.current.getBoundingClientRect();
      const { top: resultTop } = resultRef.current.getBoundingClientRect();
      if (stakeTop > resultTop) {
        setNewLineColumn(newLineColumn === 'result' ? '' : 'stake');
      } else if (stakeTop < resultTop) {
        setNewLineColumn(newLineColumn === 'stake' ? '' : 'result');
      }
    }
  }, [windowWidth, newLineColumn, stakeSize, resultSize]);

  return (
    <div
      className={classNames(styles.bet, accountPagesBranding.BET_INDICATOR, {
        [styles.bet__lay]: !isBackIndicator,
        [accountPagesBranding.BET_SIDE_BACK]: isBackIndicator,
        [accountPagesBranding.BET_SIDE_LAY]: !isBackIndicator
      })}
    >
      <div className={styles.bet__top}>
        <div className={styles.bet__top__left}>
          <div className={styles.bet__selection}>
            <i
              className={classNames('fa2', styles.bet__selection__icon, {
                [styles.bet__selection__icon__positive]: isPositive,
                [styles.bet__selection__icon__negative]: isNegative,
                ['fa2-success-24']: isPositive,
                ['fa2-circle-x-24']: isNegative,
                ['fa2-return-24']: !isPositive && !isNegative,
                [mobileIcons.BET_WON]: isPositive,
                [mobileIcons.BET_LOST]: isNegative,
                [mobileIcons.BET_NEUTRAL]: !isPositive && !isNegative
              })}
            />
            <span className={styles.bet__selection__name}>
              {bet.selectionName}
              {bet.bettingType === BETTING_TYPES.line ? ` ${Number(bet.avgPriceMatched || bet.price)}` : ''}
            </span>
          </div>
          <span className={styles.bet__market}>{marketName}</span>
          {isEachWay && (
            <span className={styles.bet__ew}>
              {t('market.each.way.termsNoPref', {
                odds: bet?.eachWayDivisor,
                places: bet?.numberOfWinners
              })}
            </span>
          )}
          <span className={styles.bet__event}>{bet.mainEventName || bet.eventName}</span>
        </div>
        <div className={styles.bet__top__right}>
          <span className={styles.bet__odds}>@{oddsValue(bet)}</span>
          <span className={styles.bet__type}>
            {t(betsSideValue({ item: bet, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver }))}
          </span>
        </div>
      </div>
      <div className={styles.bet__middle}>
        <div className={classNames(styles.bet__stake, { [styles.column]: newLineColumn == 'result' })}>
          <span className={styles.bet__stake}>{t('account.mybets.labels.stake')}</span>
          <span ref={stakeRef} className={styles.bet__value}>
            {stake}
          </span>
        </div>
        <div className={classNames(styles.bet__result, { [styles.column]: newLineColumn == 'stake' })}>
          <span className={styles.bet__status}>{statusValue}</span>
          <span
            ref={resultRef}
            className={classNames(styles.bet__value, {
              [styles.bet__value__positive]: isPositive,
              [styles.bet__value__negative]: isNegative
            })}
          >
            {profit}
          </span>
        </div>
      </div>
      <div className={styles.bet__bottom}>
        <span>
          {t('account.mybets.labels.refID')}: {bet?.offerId}
        </span>{' '}
        /{' '}
        <span>
          {t('account.mybets.labels.settled')}: {date}
        </span>
        {bet.triggeredByCashOut && (
          <>
            {' '}
            / <span>{t('account.mybets.labels.triggeredByCashOut')}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default SettledBet;
