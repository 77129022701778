import { toNumber } from 'lodash';

import { BetsStatusesTypes, CurrentBetActions } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { TCurrentBetWithBetsGroupedById } from 'redux/modules/betList/type';
import { TCalculatedConsolidatedBetFields, TCurrentBet } from 'redux/modules/currentBets/type';
import { MatchType } from 'types/bets';
import { OfferStates } from 'types/myBets';
import { getIsRaceType } from 'utils/openedBets';

// Check if offer is MATCHED
export const isMatchedOffer = (bet: TCurrentBet, isFullyMatchedOnly = false) =>
  /** if precision of '1' is set for virtual currency */
  (toNumber(bet.sizeMatched) === 0 &&
    toNumber(bet.sizeRemaining) === 0 &&
    toNumber(bet.sizeCancelled) === 0 &&
    bet.offerState === BetsStatusesTypes.MATCHED) ||
  /** if it is fully matched */
  (toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) === 0) ||
  /** if it is partially matched */
  (!isFullyMatchedOnly && toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) > 0);

// Check if offer is UNMATCHED or PARTIALLY MATCHED
export const isUnmatchedOffer = ({
  bet,
  anyCancelled,
  showLapsed,
  unmatchedOffersIdsToShowLapsed,
  showLapsedAlways
}: {
  bet: TCurrentBet;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  unmatchedOffersIdsToShowLapsed?: Record<string, boolean>;
  showLapsedAlways?: boolean;
}) => {
  const notHidden = bet.action !== CurrentBetActions.HIDDEN;
  const isPlaced = bet.offerState === BetsStatusesTypes.PLACED;
  const isFullyMatched = bet.action === CurrentBetActions.FULLY_MATCHED;
  const displayLapsed =
    bet.offerState === BetsStatusesTypes.LAPSED &&
    (showLapsedAlways ||
      (showLapsed &&
        unmatchedOffersIdsToShowLapsed &&
        Object.keys(unmatchedOffersIdsToShowLapsed).includes(String(bet.offerId))));
  const displayCancelled = anyCancelled && bet.offerState === BetsStatusesTypes.CANCELLED;
  const cancelledAndCancelling =
    bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === CurrentBetActions.CANCELLING;
  const cancelledAndEditing =
    bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === CurrentBetActions.EDITING;
  const isMatchedWithRemaining = bet.offerState === BetsStatusesTypes.MATCHED && +(bet.sizeRemaining || 0) > 0;
  const isMatched = bet.offerState === BetsStatusesTypes.MATCHED;
  const isCancellingOrCancellingAllAction =
    bet.action === CurrentBetActions.CANCELLING || bet.action === CurrentBetActions.CANCELLING_ALL;
  const isPartiallyMatchedWasFullyMatched =
    bet.action === CurrentBetActions.PLACED_PARTIALLY_MATCHED &&
    bet.offerState === BetsStatusesTypes.MATCHED &&
    Number(bet.sizeRemaining) === 0;

  if (bet.disabledLayOdds && bet.side == BetSides.Lay) {
    return false;
  }

  return (
    notHidden &&
    (isPlaced ||
      isFullyMatched ||
      displayLapsed ||
      displayCancelled ||
      cancelledAndCancelling ||
      cancelledAndEditing ||
      isMatchedWithRemaining ||
      isPartiallyMatchedWasFullyMatched ||
      (isMatched && isCancellingOrCancellingAllAction))
  );
};

const roundPriceLineBet = (bet: TCurrentBet, priceField: 'price' | 'averagePriceRounded') => bet[priceField] ?? 0;

export const mapBet = (bet: TCurrentBet) => {
  return {
    ...bet,
    averagePriceRounded: roundPriceLineBet(bet, 'averagePriceRounded'),
    price: roundPriceLineBet(bet, 'price')
  };
};

export const sortByStartDate = (bets: TCurrentBet[]) => {
  return [...bets].sort((a, b) => {
    if (a.eventInPlay && !b.eventInPlay) return -1;
    if (!a.eventInPlay && b.eventInPlay) return 1;

    const diffA = Math.abs(a.marketStartDate! - Date.now());
    const diffB = Math.abs(b.marketStartDate! - Date.now());

    return diffA - diffB;
  });
};

export const sortByEventName = (bets: TCurrentBet[]) => {
  return [...bets].sort((a, b) => (a.eventName || '').localeCompare(b.eventName || ''));
};

export const sortByEventTypeId = (games: TCurrentBet[], typeIds: number[]) => {
  return [...games].sort((a, b) => typeIds.indexOf(a.eventTypeId || 0) - typeIds.indexOf(b.eventTypeId || 0));
};

export const sortByPlacedDate = (bets: TCurrentBet[]) => {
  return [...bets].sort((a, b) => b.placedDate! - a.placedDate! || b.offerId - a.offerId);
};

export const filterByMatchType = (bets: TCurrentBet[], matchType: MatchType) => {
  return bets.filter(bet => bet.matchType === matchType);
};

const extractLeadingNumber = (marketName: string): number | null => {
  const match = marketName.match(/^(\d+)/);
  return match ? parseInt(match[0], 10) : null;
};

export const sortMarketGroups = (groupedMarkets: Record<string, TCurrentBet[]>, locale = 'en') => {
  const PRIORITY_MARKET_TYPES = ['MATCH_ODDS', 'MATCH_ODDS_UNMANAGED', 'WIN', 'MONEY_LINE'];

  const priorityMarkets: TCurrentBet[][] = [];
  const alphabeticalMarkets: TCurrentBet[][] = [];
  const numericMarkets: TCurrentBet[][] = [];

  Object.values(groupedMarkets).forEach(group => {
    const { marketType, marketName } = group[0];

    if (PRIORITY_MARKET_TYPES.includes(marketType)) {
      priorityMarkets.push(group);
    } else if (extractLeadingNumber(marketName!) !== null) {
      numericMarkets.push(group);
    } else {
      alphabeticalMarkets.push(group);
    }
  });

  // Alphabetical markets sorting
  alphabeticalMarkets.sort((a, b) =>
    a[0].marketName!.localeCompare(b[0].marketName!, locale, {
      numeric: true,
      sensitivity: 'base'
    })
  );

  // Numeric markets sorting
  numericMarkets.sort((a, b) => {
    const aNumber = extractLeadingNumber(a[0].marketName!) ?? Infinity;
    const bNumber = extractLeadingNumber(b[0].marketName!) ?? Infinity;

    if (aNumber !== bNumber) return aNumber - bNumber;
    return a[0].marketName!.localeCompare(b[0].marketName!, locale, {
      numeric: true,
      sensitivity: 'base'
    });
  });

  return [...priorityMarkets, ...alphabeticalMarkets, ...numericMarkets];
};

export const getMappedUnmatchedAndMatchedBets = (
  bets: TCurrentBet[],
  options: { includeCancelledBetsList: number[]; ignoreBetsList: number[] }
) => {
  const { includeCancelledBetsList, ignoreBetsList } = options;

  return bets.reduce<TCurrentBet[]>((list, bet) => {
    const isUnmatchedBet = isUnmatchedOffer({ bet, anyCancelled: true });
    const isNotCancelled = bet.offerState !== BetsStatusesTypes.CANCELLED;
    const isNotFullyMatchedAction =
      bet.action !== CurrentBetActions.FULLY_MATCHED && bet.action !== CurrentBetActions.FULLY_MATCHED_AFTER_EDITING;
    const shouldShowCancelled = includeCancelledBetsList.includes(bet.offerId);
    const isIgnored = ignoreBetsList.includes(bet.offerId);

    if (isNotCancelled && isMatchedOffer(bet)) {
      list.push({ ...mapBet(bet), matchType: MatchTypes.MATCHED });
    }

    if (
      isUnmatchedBet &&
      !isIgnored &&
      (isNotCancelled || shouldShowCancelled) &&
      (isNotFullyMatchedAction || shouldShowCancelled)
    ) {
      list.push({ ...mapBet(bet), matchType: MatchTypes.UNMATCHED, offerState: OfferStates.PLACED });
    }

    return list;
  }, []);
};

export const groupByEvent = (bets: TCurrentBet[]) => {
  return bets.reduce<
    {
      eventData: TCurrentBet;
      bets: TCurrentBet[];
    }[]
  >((groupedBets, bet) => {
    const existingGroup = groupedBets.find(
      group =>
        ((group.eventData.mainEventId && group.eventData.mainEventId === bet.mainEventId) ||
          group.eventData.eventId === bet.eventId) &&
        (getIsRaceType(bet.eventTypeId) ? group.eventData.marketStartDate === bet.marketStartDate : true)
    );

    if (existingGroup) {
      existingGroup.bets.push(bet);
      existingGroup.eventData.isPNLAvailable = existingGroup.eventData.isPNLAvailable || bet.isPNLAvailable;
    } else {
      groupedBets.push({
        eventData: { ...bet },
        bets: [bet]
      });
    }

    return groupedBets;
  }, []);
};

export const getBetsAmountWithConsolidation = (bets: TCurrentBet[]): number => {
  return bets.reduce((sum, bet) => {
    if (bet.isCombined && bet.combinedAmount) {
      return sum + bet.combinedAmount;
    }

    return sum + 1;
  }, 0);
};

export const addBetsGroupedByOfferId = (bets: TCurrentBet[]): TCurrentBetWithBetsGroupedById[] => {
  const betMap = new Map<number | string, TCurrentBetWithBetsGroupedById>();

  bets.forEach(bet => {
    if (betMap.has(bet.offerId)) {
      const existingBet = betMap.get(bet.offerId)!;
      existingBet.betsByOfferId.push(bet);
    } else {
      betMap.set(bet.offerId, { ...bet, betsByOfferId: [] });
    }
  });

  return Array.from(betMap.values());
};

/**
 * Combine bets by marketId, selectionId, handicap, averagePrice (or price).
 * Calculate sum of size, profit and liability fields.
 */
export const mapConsolidateBets = (openedBets: TCurrentBet[]) => {
  const groupedBets = openedBets.reduce<Record<string, TCurrentBet[]>>((dict, bet) => {
    const isLineMarket = bet.bettingType === BettingTypes.LINE;
    const key = `${bet.marketId}-${bet.selectionId}${isLineMarket ? '' : `-${toNumber(bet.handicap || 0)}`}-${
      bet.side
    }${isLineMarket ? `-${bet.averagePriceRounded}` : ''}`;

    if (dict[key]) {
      dict[key] = [...dict[key], bet];
    } else {
      dict[key] = [bet];
    }

    return dict;
  }, {});

  return Object.values(groupedBets).reduce<TCurrentBet[]>((consolidatedBets, betsList) => {
    const betData = betsList[betsList.length - 1];

    const calculatedFields: TCalculatedConsolidatedBetFields[] = [
      'liability',
      'profit',
      'profitNet',
      'potentialProfit',
      'size',
      'sizeMatched',
      'sizePlaced',
      'totalWinnings'
    ];

    const calculatedFieldsData = calculatedFields.reduce<Record<TCalculatedConsolidatedBetFields, number>>(
      (fieldsData, field) => {
        fieldsData[field] = betsList.reduce(
          (totalValue: number, bet: TCurrentBet) => totalValue + toNumber(bet[field] || 0),
          0
        );

        return fieldsData;
      },
      {} as Record<TCalculatedConsolidatedBetFields, number>
    );

    if (betsList.length > 1) {
      const averagePrice = betsList.reduce<number>(
        (sum, bet) => sum + ((bet.averagePrice - 1) * Number(bet.size)) / calculatedFieldsData.size,
        1
      );
      const formattedPrice = (Math.round(averagePrice * 100) / 100).toFixed(2);
      calculatedFieldsData.averagePriceRounded = Number(formattedPrice);
    }

    const consolidatedBet = { ...betData, ...calculatedFieldsData };

    consolidatedBet.isCombined = true;
    consolidatedBet.combinedAmount = betsList.length;
    consolidatedBet.averagePrice = betData.averagePriceRounded;
    consolidatedBet.triggeredByCashOut = betsList.some(bet => bet.triggeredByCashOut);
    consolidatedBet.isPNLAvailable = betsList.some(bet => bet.isPNLAvailable);

    consolidatedBets.push(consolidatedBet);

    return consolidatedBets;
  }, []);
};
