import { useSelector } from 'react-redux';
import classNames from 'classnames';

import MarketRules from 'components/AsianViewPageModule/components/CompetitionTitle/MarketRules';
import CashOutButton from 'components/CashOutButton';
import ShowHideRunners from 'components/CashOutCard/partials/ShowHideRunners';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getLanguage, getTranslation } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { IMarket } from 'types/markets';

import BetsPlacedCounter from '../../BetsPlacedCounter';

import styles from './styles.module.scss';

type ContentItemProps = {
  market: IMarket;
  isTab?: boolean;
  isEnabledMarketPrices?: boolean;
};

const ContentItem = ({ market, isTab = false, isEnabledMarketPrices = true }: ContentItemProps) => {
  const language = useSelector(getLanguage);
  const domainLanguage = useSelector(getTranslation);
  const isMobile = useSelector(getIsMobileAsianView);

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.marketId,
    eventId: market.event.id,
    section: isTab
      ? MarketsPricesSocketParamsSections.AsianViewEventCashOut
      : MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '200px' },
    isEnabled: isEnabledMarketPrices
  });

  const marketCashOutName = market.outrightMarket
    ? `${market.competition.competitionNameTranslations?.[domainLanguage] || market.competition.name} - ${
        market.marketNameTranslations?.[language] || market.marketName
      }`
    : `${market.marketNameTranslations?.[language] || market.marketName}`;

  return (
    <div
      ref={isEnabledMarketPrices ? ref : undefined}
      className={classNames(styles.marketCashOut, branding.CASH_OUT_MARKET)}
      data-market-id={isEnabledMarketPrices ? market.marketId : undefined}
      data-event-id={isEnabledMarketPrices ? market.event.id : undefined}
      data-market-prices={isEnabledMarketPrices ? 'true' : undefined}
    >
      <div
        className={classNames(styles.marketCashOut__title, branding.CASH_OUT_MARKET_HEADER, {
          [styles.marketCashOut__title__outright]: market.outrightMarket,
          [styles.marketCashOut__title__mobile]: isMobile
        })}
      >
        {market.outrightMarket ? (
          <>
            <div className={styles.marketCashOut__outrightContainer}>
              <span
                className={styles.marketCashOut__name}
                data-tooltip-id="tooltip"
                data-tooltip-html={marketCashOutName}
              >
                {marketCashOutName}
              </span>
              {!isMobile && <MarketRules marketId={market.marketId} />}
            </div>
            <BetsPlacedCounter marketId={market.marketId} />
          </>
        ) : (
          <>
            <span
              className={styles.marketCashOut__name}
              data-tooltip-id="tooltip"
              data-tooltip-html={marketCashOutName}
            >
              {marketCashOutName}
            </span>
            {!isMobile && <MarketRules marketId={market.marketId} />}
            <BetsPlacedCounter marketId={market.marketId} />
          </>
        )}
      </div>
      <CashOutButton
        marketId={market.marketId}
        partialCashOutEnabled={market.partialCashOutEnabled}
        isEvent={isTab}
        isAsianView
        ignoreFlexibleDisplayFormat
      />
      {isTab && isMobile ? null : <ShowHideRunners market={market} isCashOut isAsianView isEvent={isTab} />}
    </div>
  );
};

export default ContentItem;
