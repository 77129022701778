import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { some } from 'lodash';

import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import ScoreWidget from 'components/ScoreWidget/ScoreWidget';
import { RACING_SPORT, SportIds } from 'constants/app';
import { mobileBranding } from 'constants/branding';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import BetMarket from 'pages/mobile/MobileBetListPage/components/OpenTab/components/BetEventsSortedByStartTime/components/BetMarket/BetMarket';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { TGroupedEvent } from 'redux/modules/currentBets/type';
import { sortMarketGroups } from 'utils/currentBets';
import { splitMarketNameIntoParticipants } from 'utils/market';

import styles from './BetEvent.module.scss';

interface BetEventProps {
  eventData: TGroupedEvent;
}

const BetEvent = ({ eventData }: BetEventProps) => {
  const locale = useSelector(getLanguage);
  const { isGameBet, eventName, racingEventName } = useOpenBetData(eventData);
  const isRacingSport = RACING_SPORT[String(eventData.eventTypeId)];
  const eventParticipants = splitMarketNameIntoParticipants(eventName || '');

  const hasMatchedBets = useMemo(() => {
    if (!eventData.betsByMarket) {
      return false;
    }

    return some(eventData.betsByMarket, bets => {
      return some(bets, bet => Number(bet.sizeMatched) > 0);
    });
  }, [eventData]);

  const betsByMarket = useMemo(() => sortMarketGroups(eventData.betsByMarket, locale), [eventData, locale]);

  return (
    <div
      className={classNames(styles.event, {
        [styles.event__inPlay]: eventData.inPlay,
        [mobileBranding.IN_PLAY_INDICATOR]: eventData.inPlay
      })}
    >
      <header className={classNames(styles.event__header, 'biab_inplay-sports-container')}>
        {!eventData.isOutright && (
          <>
            {!isGameBet && (
              <ScoreWidget
                eventId={eventData.eventId}
                marketStartTime={eventData.marketStartDate}
                isInPlay={!!eventData.inPlay || !!eventData.eventInPlay}
                sportId={String(eventData.eventTypeId)}
                className={classNames(styles.event__scoreContainer, {
                  [styles.event__scoreContainer__inPlay]: eventData.inPlay
                })}
              />
            )}
            {!isRacingSport && !isGameBet && (
              <>
                {eventParticipants.length === 2 ? (
                  <div className={styles.event__participants}>
                    <div>{eventParticipants[0]}</div>
                    <div>{eventParticipants[1]}</div>
                  </div>
                ) : (
                  <div className={styles.event__name}>{eventName}</div>
                )}
              </>
            )}
            {isRacingSport && <div className={styles.event__name}>{racingEventName}</div>}
            {isGameBet && <div className={styles.event__name}>{eventName}</div>}
            {String(eventData.eventTypeId) === SportIds.SOCCER && !eventData.isOutright && hasMatchedBets && (
              <div className={styles.event__pl}>
                <ProfitLossTablePopUpButton
                  eventId={eventData.eventId}
                  marketId={eventData.marketId}
                  isAsianView={false}
                  isActive={eventData.isPNLAvailable}
                />
              </div>
            )}
          </>
        )}
        {eventData.isOutright && <div className={styles.event__name}>{eventName}</div>}
      </header>
      {betsByMarket.map(bets => {
        return <BetMarket key={bets[0].marketId} marketId={bets[0].marketId} bets={bets} />;
      })}
    </div>
  );
};

export default memo(BetEvent);
