import classNames from 'classnames';
import { unescape } from 'lodash';

import { AsianPlacedBetMessageTypes } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { AsianPlacedBetMessageType } from 'types/asianView';

import styles from './styles.module.scss';

interface IPlacedMessage {
  message: string;
  type: AsianPlacedBetMessageType;
}
const PlacedMessage = ({ message, type }: IPlacedMessage) => {
  const isPlaced = type === AsianPlacedBetMessageTypes.PLACED;
  const isPlacedWithBetterOdds = type === AsianPlacedBetMessageTypes.PLACED_WITH_BETTER_ODDS;
  const isError = type === AsianPlacedBetMessageTypes.ERROR;

  return (
    <div
      className={classNames(styles.message, {
        [styles.message__success]: isPlaced || isPlacedWithBetterOdds,
        [styles.message__error]: isError,
        [branding.ERROR_MSG]: isError,
        [branding.PLACED_MSG]: isPlaced,
        [branding.PLACED_BETTER_MSG]: isPlacedWithBetterOdds
      })}
    >
      <i
        className={classNames(styles.message__icon, {
          'biab_custom-icon-success-circle': isPlaced,
          'biab_fav-icons-active': isPlacedWithBetterOdds,
          'fa2 fa2-warning-icon': isError,
          [styles.message__icon__success]: isPlaced || isPlacedWithBetterOdds,
          [styles.message__icon__error]: isError
        })}
      />
      <span className={styles.message__text} dangerouslySetInnerHTML={{ __html: unescape(message) }} />
    </div>
  );
};

export default PlacedMessage;
