import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { mobileBranding } from 'constants/branding';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';

import styles from './styles.module.scss';

export type Tab = {
  id: string;
  label: string;
};

interface TabsProps {
  /**
   * A list of tab elements
   */
  tabs: Tab[];

  /**
   * The value of the currently selected tab
   */
  value: number;

  /**
   * Callback fired when the value changes
   * @param event
   * @param newValue
   */
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

const Tabs = ({ tabs, value, onChange }: TabsProps) => {
  const dispatch = useDispatch();
  const device = useSelector(getAppDevice);

  const handleClickTab = (event: SyntheticEvent, newValue: number) => {
    event.preventDefault();
    dispatch(cleanInlineSelectedBets());
    onChange(event, newValue);
  };

  return (
    <div
      className={classNames(styles.tabs, {
        'biab_tabs-nav': device === Devices.DESKTOP,
        [mobileBranding.TABS_NAV]: device === Devices.MOBILE
      })}
      role="tablist"
    >
      {tabs.map(({ id, label }, index) => (
        <div
          key={id}
          role="tab"
          className={styles.tabs__tab}
          style={{ [device === Devices.MOBILE ? 'minWidth' : 'width']: `${100 / tabs.length}%` }}
        >
          <a
            data-sport-id={id}
            href="#"
            className={classNames({
              [`biab_active ${styles.active}`]: value === index
            })}
            onClick={event => handleClickTab(event, index)}
          >
            {label}
          </a>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
