import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { setAVBetSlipValidationMessage } from 'redux/modules/asianViewBetslip';

import styles from './styles.module.scss';

interface IMessage {
  identifier?: string;
  message: string;
  type: 'error' | 'success' | 'successUp';
  customClassName?: string;
}

const Message = ({ identifier, message, type, customClassName = '' }: IMessage) => {
  const dispatch = useDispatch();

  const isError = type === 'error';
  const isSuccess = type === 'success';
  const isSuccessUp = type === 'successUp';

  const closeHandler = () => {
    dispatch(setAVBetSlipValidationMessage({ identifier, message: '' }));
  };

  return (
    <div
      className={classNames(styles.wrap, customClassName, {
        [styles.wrap__error]: isError,
        [styles.wrap__success]: isSuccess || isSuccessUp
      })}
    >
      {isError && <i className={classNames(styles.leftIcon__error, 'fa2 fa2-warning-icon')} />}
      {isSuccess && (
        <i className={classNames(styles.leftIcon__success, 'biab_custom-icon-success-circle')} aria-hidden="true" />
      )}
      {isSuccessUp && <i className={classNames('ion-star', styles.leftIcon__successUp)} />}
      <p
        dangerouslySetInnerHTML={{ __html: unescape(message) }}
        className={classNames(styles.message, {
          [styles.message__success]: type === 'success' || type === 'successUp'
        })}
      />
      {isError && <i onClick={closeHandler} className={classNames('biab_tour-icon-close', styles.closeIcon)} />}
    </div>
  );
};

export default Message;
