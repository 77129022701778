import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ASIAN_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import { MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME } from 'constants/myBets';
import { getAccountSubHeaderNavigationItems } from 'constants/navigation';
import useLogOut from 'hooks/useLogOut';
import { getIsResponsibleBettingMenuEnabled } from 'redux/modules/appConfigs/selectors';

import styles from './styles.module.scss';

interface AccountMobileLayoutProps {
  children: ReactNode;
}

const AccountMobileLayout = ({ children }: AccountMobileLayoutProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const isResponsibleBetting = useSelector(getIsResponsibleBettingMenuEnabled);

  const { logout } = useLogOut();

  const isAsianPage = pathname.includes(ASIAN_BASE_URL);
  const isGamePage = location.pathname.includes(GAMES_BASE_URL);
  const accountSubHeaderItems = getAccountSubHeaderNavigationItems({
    isResponsibleBetting,
    isMyBetsSettledTab: localStorage.getItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME) === 'true',
    isAsianViewPage: isAsianPage,
    isGamePage
  });

  return (
    <div className={styles.accountMobileLayout__container}>
      <div className={styles.accountMobileLayout__header}>
        <h3 className={styles.accountMobileLayout__title}>{t('header.myAccount')}</h3>
      </div>
      <div className={styles.accountMobileLayout__bodyWrapper}>
        <div className={styles.accountMobileLayout__body}>
          <div className={styles.accountMobileLayout__section}>
            <div className={styles.accountMobileLayout__nav}>
              {accountSubHeaderItems.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.link}
                  className={classNames(
                    styles.accountMobileLayout__nav__item,
                    index > 1 ? '' : styles.accountMobileLayout__nav__item__bottom__gap
                  )}
                >
                  <i className={classNames(item.icon, styles.accountMobileLayout__nav__icon)} />
                  <span className={styles.accountMobileLayout__nav__title}>{t(item.title)}</span>
                </NavLink>
              ))}
            </div>
          </div>
          <div className={styles.accountMobileLayout__section}>{children}</div>
          <div className={styles.accountMobileLayout__section}>
            <p onClick={logout} className={styles.accountMobileLayout__logout__btn}>
              {t('header.logout')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMobileLayout;
