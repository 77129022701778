import { useSelector } from 'react-redux';
import classNames from 'classnames';

import HeaderTime from 'components/FeaturedMarkets/components/HeaderTime';
import MarketScore from 'components/ScoreWidget/components/MarketScore';
import { IS_SPECIAL_HEADER_ON_EVENT, SportIds } from 'constants/app';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import {
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getIsEventsUpdatedDataByEventId
} from 'redux/modules/marketsPrices/selectors';
import { IMarket } from 'types/markets';

import styles from './styles.module.scss';

type HeaderCashOutMarketProps = {
  market: IMarket;
};

const HeaderCashOutMarket = ({ market }: HeaderCashOutMarketProps) => {
  const language = useSelector(getLanguage);
  const isEventUpdatedData = useSelector(getIsEventsUpdatedDataByEventId(market.event.id));
  const eventUpdatesHomeName = useSelector(getEventUpdatedDataLocalizedHomeName(market.event.id));
  const eventUpdatesAwayName = useSelector(getEventUpdatedDataLocalizedAwayName(market.event.id));

  const notShowInPlay = isEventUpdatedData
    ? IS_SPECIAL_HEADER_ON_EVENT[market?.eventType.id]
    : !!market.closedDate && market.closedDate !== 1;

  const getTeamsName = () => {
    const homeTeamName = market.event?.homeTeamTranslations?.[language];
    const awayTeamName = market.event?.awayTeamTranslations?.[language];

    if (isEventUpdatedData && market.eventType.id === SportIds.TENNIS) {
      return [eventUpdatesHomeName, eventUpdatesAwayName];
    }
    if (market.eventType.id === SportIds.GRAY_HOUND_RACING) {
      return [
        market.runners[0].runnerNameTranslations?.[language],
        market.runners[1].runnerNameTranslations?.[language]
      ];
    }

    if (homeTeamName && awayTeamName) {
      return [homeTeamName, awayTeamName];
    }

    return [market.event?.name];
  };

  const teamName = getTeamsName();

  return (
    <div className={classNames('biab_cash-out-page-market-header', styles.container)}>
      <div className={styles.nameContainer}>
        <div
          className={classNames('biab_cash-out-page-market-header-cell biab_inplay-widget biab_in-play', styles.inPlay)}
        >
          <MarketScore eventId={market.event.id} sportId={market?.eventType?.id} isCashOut />
        </div>
        <div className="biab_cash-out-page-market-header-cell">
          {teamName?.length === 2 && (
            <div className={styles.teamsName}>
              <div className={styles.teamName}>{teamName[0]}</div>
              <div className={styles.teamName}>{teamName[1]}</div>
            </div>
          )}
          {teamName?.length === 1 && <div className={styles.teamsName}>{teamName[0]}</div>}
        </div>
      </div>
      <div className="biab_cash-out-page-market-header-cell biab_start-time-cell">
        {!notShowInPlay && (
          <div className="biab_market-time">
            <HeaderTime
              sportId={market.eventType.id}
              inPlay={market.inPlay}
              date={market.marketStartTime}
              isShowAlways
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderCashOutMarket;
