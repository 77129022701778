import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber, unescape } from 'lodash';

import { OddsTypes } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import {
  AsianPlacedBetMessageTypes,
  QUICK_BETTING_ERROR_NOTIFICATION_REMOVE_TIME_OUT,
  QUICK_BETTING_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT
} from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import { ERROR_BET_IS_EXPIRED, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import useAdjHandicap from 'hooks/useAdjHandicap';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAsianCurrentBetByOfferId } from 'redux/modules/asianViewCurrentBets/selectors';
import { removePlacedQuickBet, updatePlacedQuickBet } from 'redux/modules/asianViewQuickBetting';
import { TAsianQuickPlacedBet } from 'redux/modules/asianViewQuickBetting/type';
import { getMarketPricesBettingTypeById } from 'redux/modules/marketsPrices/selectors';
import { generateHandicapString } from 'utils/asianView';
import { getMarketTypes } from 'utils/market';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

interface INotificationProps {
  quickBet: TAsianQuickPlacedBet;
}
const Notification = ({ quickBet }: INotificationProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentBet = useSelector(getAsianCurrentBetByOfferId(toNumber(quickBet.offerId)));
  const bettingType = useSelector(getMarketPricesBettingTypeById(quickBet.marketId));
  const closeTimeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const bet = quickBet.offer || currentBet;
  const quickBetRef = useRef<TAsianQuickPlacedBet>(quickBet);
  const { isAsianHandicap, isTotalGoals } = getMarketTypes(quickBet?.marketType ?? '', bettingType);

  const oddsType = bet?.oddsType || quickBet.oddsType || OddsTypes.DEC;
  const oddsValue = convertOdds(bet?.averagePrice || quickBet.price || '', oddsType);
  const oddsTypeLabel = t(`asianView.labels.betslip.oddsType.${oddsType}`);
  const isBack = quickBet.betType === BetSides.Back;
  const isInPlay = !!(quickBet.isInPlay || bet?.inPlay);

  const isPlacedOffer = bet && bet.offerState === BetsStatusesTypes.MATCHED;
  const isCancelledOffer = quickBet.offerStatus === BetsStatusesTypes.CANCELLED;
  const isExpired = quickBet.offerStatus === BetsStatusesTypes.EXPIRED;

  const adjHandicap = useAdjHandicap({
    marketId: quickBet.marketId,
    marketType: quickBet.marketType,
    handicap: quickBet.handicap,
    runnerIndex: quickBet?.runnerIndex
  });
  const handicap = isTotalGoals ? quickBet.handicap : adjHandicap;

  const selectionName = bet?.selectionName ?? quickBet?.selectionName;
  const selectionNameUpdated =
    isAsianHandicap || isTotalGoals
      ? selectionName?.replace(
          generateHandicapString(handicap).replaceAll('+', isTotalGoals ? '' : '+'),
          generateHandicapString(handicap, true).replaceAll('+', isTotalGoals ? '' : '+')
        )
      : selectionName;

  const isPlacedWithBetterOdds =
    isPlacedOffer && (isBack ? bet?.averagePrice > bet?.price : bet?.averagePrice < bet?.price);

  let placedMessageType = null;
  let placedMessageText = '';

  const isError = placedMessageType === AsianPlacedBetMessageTypes.ERROR;
  const size = quickBet.size || 0;
  const price = quickBet.price || 0;
  const liability = isBack ? size : (+price - 1) * +size;
  const betLiability = isError ? liability : bet?.liability ?? 0;

  const { noFormattedAmount: liabilityFormatted, formattedAmount } = useFormatCurrency(
    betLiability || liability,
    bet?.currency,
    {
      isCheckIndian: true,
      noRounding: true
    }
  );

  const messageParams = {
    odds: convertOdds(bet?.averagePrice || '', oddsType),
    oddsType: oddsTypeLabel,
    amount: formattedAmount
  };

  if (!!quickBet.placementError) {
    placedMessageType = AsianPlacedBetMessageTypes.ERROR;
    placedMessageText = quickBet.placementError ?? '';
  } else if (isExpired) {
    placedMessageType = AsianPlacedBetMessageTypes.ERROR;
    placedMessageText = t(ERROR_BET_IS_EXPIRED);
  } else if (isCancelledOffer) {
    placedMessageType = AsianPlacedBetMessageTypes.ERROR;
    placedMessageText = t(VALIDATION_ERROR_BET_OUTDATED_ODDS);
  } else if (isPlacedOffer && isPlacedWithBetterOdds) {
    placedMessageType = AsianPlacedBetMessageTypes.PLACED_WITH_BETTER_ODDS;
    placedMessageText = t('asianView.labels.betslip.successPlacedBetWithBetterOdds', messageParams);
  } else if (isPlacedOffer) {
    placedMessageType = AsianPlacedBetMessageTypes.PLACED;
    placedMessageText = t('asianView.labels.betslip.successPlacedBet', messageParams);
  }

  let scoreLabel = isInPlay && isAsianHandicap ? bet?.score ?? quickBet?.score ?? '' : '';

  if (scoreLabel) {
    scoreLabel = `(${scoreLabel})`;
  }

  const isPlaced = placedMessageType === AsianPlacedBetMessageTypes.PLACED;
  const isPlacedWithBetterOddsStatus = placedMessageType === AsianPlacedBetMessageTypes.PLACED_WITH_BETTER_ODDS;

  const betTypeLabel = t(`asianView.labels.${quickBet.betType.toLowerCase()}`);
  const isLoading = !placedMessageText;
  const statusLabel = isPlaced ? `${t('pnc.betslip.labels.matched').toLowerCase()} ` : '';

  const onClose = () => {
    if (!isLoading && quickBetRef.current.isClosed) {
      dispatch(removePlacedQuickBet(quickBetRef.current.offerId || quickBetRef.current.identifier));
    } else {
      dispatch(
        updatePlacedQuickBet({
          ...quickBetRef.current,
          isHidden: true
        })
      );
    }
  };

  useEffect(() => {
    quickBetRef.current = quickBet;
  }, [quickBet]);

  useEffect(() => {
    return () => {
      if (!quickBetRef.current.noAnimation) {
        dispatch(
          updatePlacedQuickBet({
            ...quickBetRef.current,
            noAnimation: true
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeout = isError
        ? QUICK_BETTING_ERROR_NOTIFICATION_REMOVE_TIME_OUT
        : QUICK_BETTING_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT;

      if (closeTimeOutRef.current) {
        clearTimeout(closeTimeOutRef.current);
        closeTimeOutRef.current = null;
      }

      closeTimeOutRef.current = setTimeout(() => {
        onClose();
      }, timeout);
    }

    return () => {
      if (closeTimeOutRef.current) {
        clearTimeout(closeTimeOutRef.current);
        closeTimeOutRef.current = null;
      }
    };
  }, [isLoading]);

  return (
    <div
      className={classNames(styles.notification, {
        [styles.message__success]: isPlaced || isPlacedWithBetterOdds,
        [styles.message__error]: isError,
        [branding.ERROR_MSG]: isError,
        [branding.PLACED_MSG]: isPlaced,
        [branding.PLACED_BETTER_MSG]: isPlacedWithBetterOdds,
        [branding.PROCESSING_MSG]: !placedMessageType
      })}
    >
      {placedMessageType && (
        <i
          className={classNames(styles.notification__icon, {
            'biab_custom-icon-success-circle': isPlaced,
            'biab_fav-icons-active': isPlacedWithBetterOdds,
            'fa2 fa2-warning-icon': isError,
            [styles.notification__icon__success]: isPlaced || isPlacedWithBetterOddsStatus,
            [styles.notification__icon__error]: isError,
            [styles.notification__animation]: !quickBet.noAnimation
          })}
        />
      )}
      {!placedMessageType && (
        <i className={classNames('fa fa-spinner fa-pulse fa-fw', styles.notification__icon__loading)} />
      )}
      <div className={styles.notification__content}>
        <p className={styles.notification__text}>
          {betTypeLabel}:{' '}
          <strong>
            {selectionNameUpdated} {scoreLabel}
          </strong>
        </p>
        {isError && (
          <p className={classNames(styles.notification__text, styles.notification__market)}>
            {bet?.marketName ?? quickBet?.marketName}
          </p>
        )}
        {isError && (
          <p className={classNames(styles.notification__text, styles.notification__event)}>
            {bet?.eventName ?? quickBet.eventName}
          </p>
        )}
        <p className={styles.notification__text}>
          <strong>{liabilityFormatted}</strong> {statusLabel}@
          <strong>
            {oddsValue} ({oddsTypeLabel})
          </strong>
        </p>
        {isError && (
          <p
            className={classNames(styles.notification__text, styles.notification__errorMsg)}
            dangerouslySetInnerHTML={{ __html: unescape(placedMessageText) }}
          />
        )}
      </div>

      <i onClick={onClose} className={classNames('biab_custom-icon-close', styles.notification__close)} />
    </div>
  );
};

export default Notification;
