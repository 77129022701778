import { createSelector } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { EventStatuses, MarketStatuses } from 'constants/app';
import { RunnersStatuses } from 'constants/markets';
import { AppState } from 'redux/reducers';
import { BetDatabaseName } from 'types/bets';
import { AsianViewMarketPrices } from 'types/markets';

const getMarketPricesState = ({ marketsPrices }: AppState) => marketsPrices;
const getEventsUpdatedData = ({ marketsPrices }: AppState) => marketsPrices.eventsUpdatedData;

export const getMarketPricesById =
  (marketId?: string | null) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId] : undefined;

export const getMatchedAmountByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.tv ?? '';
export const getIsMarketInPlayByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.inPlay;
export const getIsBettingEnabledByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.bettingEnabled;
export const getIsMarketEnabledByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.enabled;
export const getStatusByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.status;
export const getMarketPricesCurrency =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.currency || '' : '';
export const getMarketPricesCommission =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.commission || 0;
export const getMarketPricesCurrencies = (marketIds: string[]) =>
  createSelector(getMarketPricesState, marketPrices =>
    JSON.stringify(marketIds.map(marketId => marketPrices.marketsPrices[marketId]?.currency || null))
  );
export const getInPlayMarketsIds = ({ marketsPrices }: AppState) => marketsPrices.inPlayMarketsIds;
export const getClosedMarketsIds = ({ marketsPrices }: AppState) => marketsPrices.closedMarketsIds;
export const getIsClosedMarketId = (marketId?: string) =>
  createSelector(getMarketPricesState, ({ closedMarketsIds }) =>
    marketId ? closedMarketsIds.includes(marketId) : false
  );

export const getSuspendedOrClosedMarketPrices = ({ marketsPrices }: AppState) =>
  marketsPrices.suspendedOrClosedMarketPrices;

export const getClosedMarketPrices = ({ marketsPrices }: AppState) => {
  const isClosedStatus =
    marketsPrices.suspendedOrClosedMarketPrices?.marketDefinition?.status === MarketStatuses.CLOSED;

  return isClosedStatus ? marketsPrices.suspendedOrClosedMarketPrices : null;
};

export const getMarketRunnerPricesById = (marketId?: string, runnerId?: string) =>
  createSelector(getMarketPricesById(marketId), marketPrices => {
    if (!marketId || !runnerId) return undefined;

    return marketPrices?.rc?.find(item => +item.id === +runnerId) ?? undefined;
  });
export const getIsMarketRunnerPricesById = (marketId?: string, runnerId?: string) =>
  createSelector(getMarketRunnerPricesById(marketId, runnerId), runnerPrices => !!runnerPrices);

export const getMarketPricesStatusById =
  (marketId: string | null) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition.status : undefined;
export const getIsMarketClosed =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices[marketId]?.marketDefinition?.status === MarketStatuses.CLOSED;
export const getIsMarketSuspended =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices[marketId]?.marketDefinition?.status === MarketStatuses.SUSPENDED;

export const getCurrentRcBySelectionId = (selectionId: string | number, marketId: string, handicap: number) =>
  createSelector(getMarketPricesById(marketId), marketPrices =>
    marketPrices?.rc?.find(rc => +rc.id === +selectionId && (rc.hc || 0) === +(handicap || 0))
  );

export const getMarketPricesBettingTypeById =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.bettingType || '';

export const getHighlightedSelectionByMarketId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.ftsl || '';

export const getMarketPricesScoreById = (marketId: string) =>
  createSelector(
    getMarketPricesById(marketId),
    getIsMarketInPlayByMarketPricesId(marketId),
    (marketPrices, isInPlay) => {
      return isInPlay && marketPrices?.hs !== undefined && marketPrices.as !== undefined
        ? `${marketPrices.hs}-${marketPrices.as}`
        : '';
    }
  );

export const getMarketPricesHomeScore =
  (marketId?: string | null) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.hs : undefined;
export const getMarketPricesAwayScore =
  (marketId?: string | null) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.as : undefined;
export const getMarketPricesAsianViewElapsedRegularTime =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    (marketsPrices.marketsPrices?.[marketId] as AsianViewMarketPrices)?.aert;
export const getMarketPricesRegularTime =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    (marketsPrices.marketsPrices?.[marketId] as AsianViewMarketPrices)?.te;

export const getMarketPricesAddedRegularTime =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    (marketsPrices.marketsPrices?.[marketId] as AsianViewMarketPrices)?.eat;

export const getMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.id;

export const getRunnerPricesByRunnerId = (marketId: string, runnerId: string, runnerHandicap: number) =>
  createSelector(getMarketPricesById(marketId), marketPrices =>
    marketPrices?.rc?.find(rc => rc.id === +runnerId && (rc.hc || 0) === (runnerHandicap || 0))
  );
export const getIsRunnerPricesByRunnerIdAndHandicap = (marketId: string, runnerId: string, runnerHandicap: number) =>
  createSelector(getRunnerPricesByRunnerId(marketId, runnerId, runnerHandicap), runnerPrices => !!runnerPrices);

export const getMarketPricesIsBettingEnabled =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.bettingEnabled;

export const getMarketPricesFirstKeyLineId =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[0]?.id : undefined;

export const getMarketPricesFirstKeyLineHandicap =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[0]?.hc : undefined;
export const getMarketPricesFirstKeyLineAdjustedHandicap =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[0]?.ahc : undefined;

export const getMarketPricesSecondKeyLineId =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[1]?.id : undefined;

export const getMarketPricesSecondKeyLineHandicap =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[1]?.hc : undefined;
export const getMarketPricesSecondKeyLineAdjustedHandicap =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition?.kl?.[1]?.ahc : undefined;

export const getMarketPricesNumberOfWinners =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.numberOfWinners;

export const getMarketPricesBettingType =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.bettingType ?? '';

export const getMarketPricesMarketType =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.marketType ?? '';

export const getMarketPricesComplete =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.complete ?? false;

export const getMarketPricesRunners = (marketId?: string) =>
  createSelector(getMarketPricesById(marketId), marketPrices => {
    return marketId ? marketPrices?.marketDefinition?.runners ?? [] : [];
  });

export const getMarketPricesStringifiedRunners = (marketId: string) =>
  createSelector(getMarketPricesById(marketId), marketPrices =>
    JSON.stringify(marketPrices?.marketDefinition?.runners ?? [])
  );

const getMarketPricesRunnerBySelectionId = (marketId: string, selectionId: number, handicap: number) =>
  createSelector(getMarketPricesById(marketId), marketPrices =>
    marketPrices?.marketDefinition?.runners?.find(
      runner => (runner.handicap ? runner.handicap === handicap : true) && runner.id === selectionId
    )
  );

export const getMarketPricesRunnerStatusBySelectionId = (marketId: string, selectionId: number, handicap: number) =>
  createSelector(getMarketPricesRunnerBySelectionId(marketId, selectionId, handicap), runner => runner?.status);
export const getMarketPricesIsSelectionNonRunner = (marketId: string, selectionId: number, handicap: number) =>
  createSelector(
    getMarketPricesRunnerStatusBySelectionId(marketId, selectionId, handicap),
    runnerStatus => runnerStatus === RunnersStatuses.REMOVED
  );

export const getMarketPricesRunnerLockedBySelectionId = (marketId: string, selectionId: number, handicap: number) =>
  createSelector(getCurrentRcBySelectionId(selectionId, marketId, handicap), currentRc => !!currentRc?.locked);

export const getMarketPricesRunnerRemovalDateBySelectionId = (
  marketId: string,
  selectionId: number,
  handicap: number
) => createSelector(getMarketPricesRunnerBySelectionId(marketId, selectionId, handicap), runner => runner?.removalDate);

export const getMarketPricesRunnerAdjustmentFactorBySelectionId = (
  marketId: string,
  selectionId: number,
  handicap: number
) =>
  createSelector(
    getMarketPricesRunnerBySelectionId(marketId, selectionId, handicap),
    runner => runner?.adjustmentFactor
  );

export const getMarketPricesMainEventId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.mainEventId ?? '';

export const getMarketPricesMainEventName =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.mainEventName ?? '';

export const getMarketPricesMarketNameWithParents =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketNameWithParents ?? '';

export const getMarketPricesOverRound =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.overround ?? 0;

export const getMarketPricesUnderRound =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.underround ?? 0;

export const getMarketPricesTurnInPlayEnabled =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.turnInPlayEnabled ?? false;

export const getIsMarketsPrices = ({ marketsPrices }: AppState) => !!marketsPrices.marketsPrices;

export const getBetOdds = (
  marketId: string,
  selectionId: number,
  handicap: number,
  betName: BetDatabaseName,
  depthValue: number
) =>
  createSelector(
    getCurrentRcBySelectionId(selectionId, marketId, handicap),
    currentRc => currentRc?.[betName]?.[depthValue]?.odds
  );
export const getBetAmount = (
  marketId: string,
  selectionId: number,
  handicap: number,
  betName: BetDatabaseName,
  depthValue: number
) =>
  createSelector(
    getCurrentRcBySelectionId(selectionId, marketId, handicap),
    currentRc => currentRc?.[betName]?.[depthValue]?.amount
  );

export const getIsMatchedAmountByMarketPricesId =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.marketsPrices?.[marketId]?.tv;

export const getHomeScoreByEventId =
  (eventId: string | null) =>
  ({ marketsPrices }: AppState) =>
    eventId ? marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.score ?? '' : '';

export const getAwayScoreByEventId =
  (eventId: string | null) =>
  ({ marketsPrices }: AppState) =>
    eventId ? marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.score ?? '' : '';

export const getHomeGameSequenceByEventId =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.gameSequence;

export const getAwayGameSequenceByEventId =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.gameSequence;

export const getIsEventsUpdatedDataByEventId =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    eventId
      ? !!marketsPrices.eventsUpdatedData?.[eventId] &&
        marketsPrices.eventsUpdatedData?.[eventId]?.status !== EventStatuses.FIXTURE
      : false;

export const getEventsUpdatedDataInPlayMatchStatus =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.inPlayMatchStatus;

export const getEventsUpdatedDataPeriod =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.period;

export const getEventsUpdatedDataTimeElapsed =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.timeElapsed ?? 0;
export const getEventsUpdatedDataEventTypeId =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.eventTypeId;
export const getEventsUpdatedDataStringifiedUpdateDetails = (eventId: string) =>
  createSelector(getEventsUpdatedData, eventsUpdatedData =>
    JSON.stringify(eventsUpdatedData?.[eventId]?.updateDetails)
  );

export const getEventsUpdatedDataElapsedRegularTime =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.elapsedRegularTime;
export const getEventsUpdatedDataElapsedAddedTime =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.elapsedAddedTime;

export const getEventsUpdatedDataBasketballActiveQuarter =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.quarterByQuarter?.length || 0;
export const getEventsUpdatedDataBasketballHomeQuarterByQuarter =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.quarterByQuarter;
export const getEventsUpdatedDataBasketballAwayQuarterByQuarter =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.quarterByQuarter;

export const getIsEventUpdatedDataCricketHomeFirstInning =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning1;
export const getIsEventUpdatedDataCricketHomeSecondInning =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning2;
export const getIsEventUpdatedDataCricketAwayFirstInning =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning1;
export const getIsEventUpdatedDataCricketAwaySecondInning =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning2;
export const getEventUpdatedDataCricketHomeFirstInningRuns =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning1?.runs;
export const getEventUpdatedDataCricketHomeSecondInningRuns =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning2?.runs;

export const getEventUpdatedDataCricketHomeFirstInningWickets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning1?.wickets;
export const getEventUpdatedDataCricketHomeSecondInningWickets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning2?.wickets;

export const getEventUpdatedDataCricketHomeFirstInningOvers =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning1?.overs;
export const getEventUpdatedDataCricketHomeSecondInningOvers =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.inning2?.overs;

export const getEventUpdatedDataCricketHomeHighlight =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.highlight;
export const getEventUpdatedDataCricketAwayHighlight =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.highlight;

export const getEventUpdatedDataCricketAwayFirstInningRuns =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning1?.runs;
export const getEventUpdatedDataCricketAwaySecondInningRuns =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning2?.runs;

export const getEventUpdatedDataCricketAwayFirstInningWickets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning1?.wickets;

export const getEventUpdatedDataCricketAwaySecondInningWickets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning2?.wickets;

export const getEventUpdatedDataCricketAwayFirstInningOvers =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning1?.overs;

export const getEventUpdatedDataCricketAwaySecondInningOvers =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.inning2?.overs;

export const getEventUpdatedDataTennisHomeSets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.sets;

export const getEventUpdatedDataTennisHomeGames =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.games;

export const getEventUpdatedDataTennisAwaySets =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.sets;

export const getEventUpdatedDataTennisAwayGames =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.games;
export const getEventUpdatedDataLocalizedHomeName =
  (eventId: string) =>
  ({
    marketsPrices,
    appConfigs: {
      properties: { domain, language }
    }
  }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.home?.localizedNames?.[`${domain}|${language}`];
export const getEventUpdatedDataLocalizedAwayName =
  (eventId: string) =>
  ({
    marketsPrices,
    appConfigs: {
      properties: { domain, language }
    }
  }: AppState) =>
    marketsPrices.eventsUpdatedData?.[eventId]?.score?.away?.localizedNames?.[`${domain}|${language}`];
export const getEventUpdatedDataStatus =
  (eventId: string | null) =>
  ({ marketsPrices }: AppState) =>
    eventId ? marketsPrices.eventsUpdatedData?.[eventId]?.status : undefined;

export const getMarketPricesMatchStatus =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    marketsPrices.marketsPrices?.[marketId]?.ms;

export const getIsValidEventUpdatedDataCricket = (eventId: string) => {
  return createSelector(
    getEventUpdatedDataCricketHomeFirstInningRuns(eventId),
    getEventUpdatedDataCricketHomeFirstInningWickets(eventId),
    getEventUpdatedDataCricketAwayFirstInningRuns(eventId),
    getEventUpdatedDataCricketAwayFirstInningWickets(eventId),
    (homeInningRuns, homeInningWickets, awayInningRuns, awayInningWickets) => {
      return (
        !isUndefined(homeInningRuns) ||
        !isUndefined(homeInningWickets) ||
        !isUndefined(awayInningRuns) ||
        !isUndefined(awayInningWickets)
      );
    }
  );
};

export const getIsMarketPricesKeyLineDefinition =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? !!marketsPrices.marketsPrices?.[marketId]?.marketDefinition?.keyLineDefinition : false;

export const getIsMarketPrices =
  (marketId: string) =>
  ({ marketsPrices }: AppState) =>
    !!marketsPrices.marketsPrices?.[marketId];
export const getStringifiedEventUpdatedData = ({ marketsPrices }: AppState) =>
  marketsPrices.stringifiedEventsUpdatedData;
export const getEventsUpdatedDataAsianViewElapsedRegularTime = (eventId: string) => (state: AppState) =>
  state.marketsPrices.eventsUpdatedData?.[eventId]?.asianViewElapsedRegularTime;

export const getMarketsPricesSocketParams = ({ marketsPrices }: AppState) => marketsPrices.marketsPricesSocketParams;
export const getEventsUpdatedDataLoading = ({ marketsPrices }: AppState) => marketsPrices.eventsUpdatedDataLoading;
export const getIsEventsUpdatedDataUpdateDetailsByEventId =
  (eventId: string) =>
  ({ marketsPrices }: AppState) =>
    eventId ? !!marketsPrices.eventsUpdatedData[eventId]?.updateDetails : false;

export const getIsMarketLayOdsDisabled =
  (marketId?: string) =>
  ({ marketsPrices }: AppState) =>
    marketId ? marketsPrices.marketsPrices[marketId]?.disabledLayOdds : false;
