import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CookieNames } from 'constants/app';
import { mobileComponents } from 'constants/branding';
import {
  AccountStatementSortByFields,
  BetsTypesByPeriods,
  BetTypes,
  ExchangeTypes,
  PLExchangeTypes
} from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountTransactionTypes from 'hooks/useAccountTransactionTypes';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { setIsMobileFiltersOpen } from 'redux/modules/myBets';
import {
  getIsMobileFiltersOpen,
  getMobileBettingPLSorting,
  getMobileSorting,
  getMobileStatementSorting
} from 'redux/modules/myBets/selectors';
import { SortByFields, TSortByType } from 'redux/modules/myBets/type';
import { Periods } from 'types';
import { BetType, MyBetsPeriod } from 'types/myBets';
import { addDays, getDaysDifference, getPeriod, getTodayDate, getTodayEndDate } from 'utils/date';

import styles from './styles.module.scss';

type FiltersButtonProps = {
  className?: string;
  showLabel?: boolean;
};

const FiltersButton = ({ className, showLabel }: FiltersButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cookies] = useCookies([
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.PL_NET_COMMISSION,
    CookieNames.PROFITANDLOSS_SPORTID
  ]);

  const isMobileFiltersOpen = useSelector(getIsMobileFiltersOpen);
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const mobileSorting = useSelector(getMobileSorting);
  const mobileBettingPLSorting = useSelector(getMobileBettingPLSorting);
  const mobileStatementSorting = useSelector(getMobileStatementSorting);

  const { isMyBetsPage, isProfitLossPage, isStatementPage } = useAccountUrlParams();
  const { availableProducts } = useAccountProducts();
  const { product, betsTypes, periodType, isAsianViewProduct, isCurrentPeriod, isLocalTime } = useMyBetsFilters();
  const { startDate, endDate, bettingDay, timezoneOffset } = useMyBetsPagesNavigation();
  const { isAllTransactionsSelected } = useAccountTransactionTypes();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const currentPeriod =
    startDate && endDate
      ? getPeriod(
          addDays(startDate, -1 * dayDifference),
          addDays(endDate, -1 * dayDifference),
          undefined,
          dayDifference
        )
      : '';

  let isMobileFiltersApplied = false;

  const betTypesList = Array.isArray(betsTypes)
    ? betsTypes.filter((type: BetType) => (isCurrentPeriod || isAsianViewProduct ? type !== BetTypes.Lapsed : true))
    : [];

  const betTypesListDefault = (BetsTypesByPeriods[periodType as MyBetsPeriod] as BetType[]).filter(betsType =>
    isCurrentPeriod || isAsianViewProduct ? betsType !== BetTypes.Lapsed : true
  );

  if (isMyBetsPage) {
    isMobileFiltersApplied =
      (product && availableProducts.includes(ExchangeTypes.AsianView) && product !== ExchangeTypes.AsianView) ||
      currentPeriod !== Periods.TODAY ||
      ((!isCurrentPeriod || !isAsianViewProduct) && betTypesList.length < betTypesListDefault.length) ||
      (!isCurrentPeriod && mobileSorting?.type !== SortByFields.DATE) ||
      (isAsianViewProduct && !isLocalTime);
  } else if (isProfitLossPage) {
    const plProduct = cookies[CookieNames.PROFITANDLOSS_BETTYPE];

    isMobileFiltersApplied =
      (plProduct && availableProducts.includes(PLExchangeTypes.AsianView) && plProduct !== PLExchangeTypes.AsianView) ||
      currentPeriod !== Periods.TODAY ||
      cookies[CookieNames.PL_NET_COMMISSION] ||
      cookies[CookieNames.PROFITANDLOSS_SPORTID] ||
      (plProduct === PLExchangeTypes.AsianView && !isLocalTime) ||
      (mobileBettingPLSorting &&
        mobileBettingPLSorting.type !== SortByFields.SETTLED_DATE &&
        ([SortByFields.SETTLED_DATE, SortByFields.START_DATE] as TSortByType[]).includes(mobileBettingPLSorting.type));
  } else if (isStatementPage) {
    isMobileFiltersApplied =
      (product && availableProducts.includes(ExchangeTypes.AsianView) && product !== ExchangeTypes.AsianView) ||
      currentPeriod !== Periods.TODAY ||
      (mobileStatementSorting && mobileStatementSorting?.name !== AccountStatementSortByFields.SETTLED_DATE) ||
      (isAsianViewProduct && !isLocalTime) ||
      !isAllTransactionsSelected;
  }

  const toggleMobileFiltersHandler = () => {
    dispatch(setIsMobileFiltersOpen(!isMobileFiltersOpen));
  };

  return (
    <button
      type="button"
      className={classNames(
        styles.btn,
        mobileComponents.BUTTON,
        mobileComponents.TERTIARY,
        {
          [mobileComponents.DISABLED]: isCancellingBets,
          [styles.btn__withLabel]: showLabel,
          [styles.btn__applied]: isMobileFiltersApplied
        },
        className
      )}
      onClick={toggleMobileFiltersHandler}
      disabled={isCancellingBets}
    >
      <i className="biab_custom-icon-filter-24" />
      {showLabel && t('account.labels.filters')}
    </button>
  );
};

export default FiltersButton;
