import { useSelector } from 'react-redux';

import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import BetEvent from 'pages/mobile/MobileBetListPage/components/OpenTab/components/BetEventsSortedByStartTime/components/BetEvent/BetEvent';
import CashOutIntervalRequestsInjection from 'pages/mobile/MobileBetListPage/components/OpenTab/components/CashOutIntervalRequestsInjection/CashOutIntervalRequestsInjection';
import NoFilteredBets from 'pages/mobile/MobileBetListPage/components/OpenTab/components/NoFilteredBets/NoFilteredBets';
import { getAreFiltersApplied, getOpenBetsGroupedByEvent } from 'redux/modules/betList/selectors';

const BetEventsSortedByStartTime = () => {
  const areFiltersApplied = useSelector(getAreFiltersApplied);
  const eventsData = useSelector(getOpenBetsGroupedByEvent);

  return (
    <>
      {!!eventsData.length && (
        <>
          <EventsUpdatesInjection
            eventIds={eventsData.map(data => data.eventId).filter(Boolean)}
            subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
          />
          <CashOutIntervalRequestsInjection observedState={eventsData} />
        </>
      )}
      {areFiltersApplied && !eventsData.length && <NoFilteredBets />}
      {eventsData.map(eventData => {
        return <BetEvent key={`${eventData.eventId}-${eventData.marketStartDate || ''}`} eventData={eventData} />;
      })}
    </>
  );
};

export default BetEventsSortedByStartTime;
