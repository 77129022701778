import { memo, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { getAwayScoreByEventId, getHomeScoreByEventId } from 'redux/modules/marketsPrices/selectors';
import { getProfitLossTablePopUpEventId } from 'redux/modules/profitLossTablePopUp/selectors';
import { ProfitLossTablePopUpDataItem } from 'redux/modules/profitLossTablePopUp/type';
import { addCommasToNumber } from 'utils';

import styles from './ProfitLossTable.module.scss';

type ProfitLossTableProps = {
  data: ProfitLossTablePopUpDataItem[];
  type: 'fullTime' | 'halfTime';
  displayedAllScores: boolean;
  onCloseTooltip: () => void;
};

const ProfitLossTable = ({ data, type, displayedAllScores, onCloseTooltip }: ProfitLossTableProps) => {
  const { t } = useTranslation();

  const eventId = useSelector(getProfitLossTablePopUpEventId);
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));

  const { isMobile, isLandscape } = useDevice();

  const [isExpanded, setIsExpanded] = useState(true);

  const showAllScores = type === 'halfTime' || displayedAllScores || isMobile;
  let homeScoreValue = homeScore;
  let awayScoreValue = awayScore;
  const maxScoreValue = type === 'halfTime' ? 4 : 8;

  if (homeScore && Number(homeScore) > maxScoreValue) {
    homeScoreValue = `${maxScoreValue}`;
  }

  if (awayScore && Number(awayScore) > maxScoreValue) {
    awayScoreValue = `${maxScoreValue}`;
  }

  const allTableData = useMemo(() => {
    const maxAwayScore = Math.max(...data.map(({ score }) => score.awayScore));
    const result: ProfitLossTablePopUpDataItem[][] = [];
    let skippedAwayItems = 0;
    const initialMaxScore = type === 'halfTime' ? 4 : 8;

    for (let i = 0; i <= maxAwayScore; i++) {
      if (maxAwayScore > initialMaxScore && i === initialMaxScore + skippedAwayItems && i !== maxAwayScore) {
        skippedAwayItems += 1;
        continue;
      }

      const rowData = data
        .filter(({ score }) => score.awayScore === i)
        .sort((a, b) => a.score.homeScore - b.score.homeScore);

      result.push(showAllScores ? rowData : rowData.slice(0, 5));
    }

    return result;
  }, [showAllScores, data]);

  const tableData = useMemo(() => {
    return showAllScores ? allTableData : allTableData.slice(0, 5);
  }, [showAllScores, allTableData]);

  return (
    <>
      <button
        className={classNames(styles.table__container__header, {
          [styles.table__container__header__collapsed]: !isExpanded
        })}
        onClick={() => setIsExpanded(prevState => !prevState)}
      >
        <i
          className={classNames('av-icon', styles.table__container__header__icon, {
            'av-icon-arrow-down': !isExpanded,
            'av-icon-arrow-up': isExpanded
          })}
        />
        <span>{t(`asianView.labels.plTable.${type === 'fullTime' ? 'FT' : 'HT'}`)}</span>
      </button>
      <div
        className={classNames(styles.table__container, {
          [styles.table__container__mobile]: isMobile && !isLandscape
        })}
        onScroll={isMobile ? onCloseTooltip : undefined}
      >
        {isExpanded && (
          <table
            className={classNames(styles.table, componentsBranding.PL_TABLE, {
              [styles.table__mobile]: isMobile && !isLandscape
            })}
          >
            <thead>
              <tr>
                <th
                  className={classNames(styles.table__awayGreyCell, {
                    [styles.table__awayGreyCell__mobile]: isMobile && !isLandscape,
                    [styles.table__emptyCell]: isMobile && !isLandscape
                  })}
                />
                <th
                  className={classNames(styles.table__homeGreyCell, styles.table__homeTitle__cell, {
                    [styles.table__stickyCell]: isMobile && !isLandscape,
                    [styles.table__homeTitle__cell__mobile]: isMobile && !isLandscape
                  })}
                  colSpan={tableData.length}
                >
                  {isMobile && !isLandscape ? (
                    <p className={styles.table__homeTitle}>{t('asianView.labels.column.home')}</p>
                  ) : (
                    t('asianView.labels.column.home')
                  )}
                </th>
              </tr>
              <tr>
                <th
                  className={classNames(styles.table__awayCell, styles.table__awayGreyCell, {
                    [styles.table__awayGreyCell__mobile]: isMobile && !isLandscape,
                    [styles.table__awayTitleCell]: isMobile && !isLandscape
                  })}
                >
                  {t('asianView.labels.column.away')}
                </th>
                {allTableData[0]?.map((_, index, arr) => {
                  return (
                    <th
                      key={index}
                      className={classNames(styles.table__homeGreyCell, {
                        [styles.table__stickyCell]: isMobile && !isLandscape,
                        [styles.table__homeGoalNumberCell]: isMobile && !isLandscape
                      })}
                    >
                      {index}
                      {index === arr.length - 1 && showAllScores ? '+' : ''}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex, arr) => {
                return (
                  <tr key={rowIndex}>
                    <td
                      className={classNames(styles.table__awayGreyCell, {
                        [styles.table__awayGreyCell__mobile]: isMobile && !isLandscape
                      })}
                    >
                      {rowIndex}
                      {rowIndex === arr.length - 1 && showAllScores ? '+' : ''}
                    </td>
                    {row.map(cell => {
                      const cellScore = `${cell.score.homeScore}-${cell.score.awayScore}`;

                      return (
                        <td
                          key={cellScore}
                          title={isMobile ? undefined : cellScore}
                          className={classNames({
                            [styles.positive]: Number(cell.profitLoss) > 0,
                            [styles.negative]: Number(cell.profitLoss) < 0,
                            [componentsBranding.PL_TABLE_CURRENT_SCORE]:
                              homeScoreValue && awayScoreValue && `${homeScoreValue}-${awayScoreValue}` === cellScore
                          })}
                          {...(isMobile
                            ? {
                                'data-tooltip-id': 'pl-table-cell-tooltip',
                                'data-tooltip-html': cellScore
                              }
                            : {})}
                        >
                          {addCommasToNumber(roundProfitLoss(cell.profitLoss, t))}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default memo(ProfitLossTable);

function roundProfitLoss(profitLoss: string, t: TFunction) {
  const profitLossNumber = Number(profitLoss);

  if (profitLossNumber > 0) {
    if (profitLossNumber >= 1000000) {
      return `${Number((profitLossNumber / 1000000).toFixed(1))}${t('displayFormat.symbol.millions')}`;
    } else if (profitLossNumber >= 1000) {
      return profitLossNumber.toFixed();
    }

    return profitLoss;
  }

  if (profitLossNumber <= -1000000) {
    return `${Number((profitLossNumber / 1000000).toFixed(1))}${t('displayFormat.symbol.millions')}`;
  } else if (profitLossNumber <= -1000) {
    return profitLossNumber.toFixed();
  }

  return profitLoss;
}
