import { createSelector } from '@reduxjs/toolkit';
import { groupBy, isEqual, partition } from 'lodash';

import { GAME } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { ORDERED_GAME_EVENT_TYPE_IDS } from 'constants/games';
import { defaultFilters } from 'redux/modules/betList';
import { getCurrentBetsAndGames } from 'redux/modules/currentBets/selectors';
import { TGroupedEvent } from 'redux/modules/currentBets/type';
import { AppState } from 'redux/reducers';
import { getMatchTypeFromBetTypeFilter } from 'utils/betList';
import {
  filterByMatchType,
  getMappedUnmatchedAndMatchedBets,
  groupByEvent,
  mapConsolidateBets,
  sortByEventName,
  sortByEventTypeId,
  sortByPlacedDate,
  sortByStartDate
} from 'utils/currentBets';

export const getIsBetListLoading = ({ betList }: AppState) => betList.loading;
export const getSettledBetList = ({ betList }: AppState) => betList.settled;
export const getBetListCounter = ({ betList }: AppState) => betList.betsCounter;

export const getIsFiltersSectionOpen = ({ betList }: AppState) => betList.isFiltersSectionOpen;
export const getFiltersFields = ({ betList }: AppState) => betList.filters;
export const getSortByFilterValue = ({ betList }: AppState) => betList.filters.sortBy;
export const getBetTypeFilterValue = ({ betList }: AppState) => betList.filters.betType;
export const getCancelledBets = ({ betList }: AppState) => betList.cancelledBets;
export const getHiddenBets = ({ betList }: AppState) => betList.hiddenBets;

export const getAreFiltersApplied = createSelector(getFiltersFields, filters => {
  return !isEqual(filters, defaultFilters);
});

export const getOpenBets = createSelector(
  getCurrentBetsAndGames,
  getCancelledBets,
  getHiddenBets,
  (bets, cancelledBets, hiddenBets) => {
    const actualBetList = bets.filter(bet => !bet.isStale);
    return getMappedUnmatchedAndMatchedBets(actualBetList, {
      includeCancelledBetsList: cancelledBets,
      ignoreBetsList: hiddenBets
    });
  }
);

export const getFilteredOpenBets = createSelector(getOpenBets, getBetTypeFilterValue, (bets, betTypeFilter) => {
  const matchType = getMatchTypeFromBetTypeFilter(betTypeFilter);
  return matchType ? filterByMatchType(bets, matchType) : bets;
});

export const getOpenBetsSortedByPlacement = createSelector(getFilteredOpenBets, bets => {
  return sortByPlacedDate(bets);
});

export const getOpenBetsGroupedByEvent = createSelector(getFilteredOpenBets, openBets => {
  const [allGames, allBets] = partition(openBets, bet => bet.betType === GAME);
  let [competitions, events] = partition(allBets, bet => bet.isOutright);
  let games = allGames;

  games = sortByEventTypeId(games, ORDERED_GAME_EVENT_TYPE_IDS);
  events = sortByStartDate(events);
  competitions = sortByEventName(competitions);

  const betsGroupedByEvent = groupByEvent([...games, ...events, ...competitions]);

  return betsGroupedByEvent.map<TGroupedEvent>(groupedData => {
    const groupedByMarket = groupBy(groupedData.bets, 'marketId');

    Object.entries(groupedByMarket).forEach(([marketId, marketBets]) => {
      const [matchedBets, otherBets] = partition(
        marketBets,
        bet => bet.matchType === MatchTypes.MATCHED && bet.betType !== GAME
      );

      const consolidatedMatchedBets = mapConsolidateBets(matchedBets);
      const sortedBets = sortByPlacedDate([...consolidatedMatchedBets, ...otherBets]);

      groupedByMarket[marketId] = sortedBets;
    });

    return {
      ...groupedData.eventData,
      betsByMarket: groupedByMarket
    } as TGroupedEvent;
  });
});
