import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MatchStatuses } from 'constants/app';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataAsianViewElapsedRegularTime,
  getEventsUpdatedDataElapsedAddedTime,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataTimeElapsed,
  getHomeScoreByEventId,
  getIsEventsUpdatedDataByEventId,
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesAddedRegularTime,
  getMarketPricesAsianViewElapsedRegularTime,
  getMarketPricesAwayScore,
  getMarketPricesHomeScore,
  getMarketPricesMatchStatus,
  getMarketPricesRegularTime
} from 'redux/modules/marketsPrices/selectors';
import { getSoccerAddedTime } from 'utils/football';

import styles from './styles.module.scss';

interface AsianViewInPlayTimeProps {
  marketId: string;
  isEventUpdatesData?: boolean;
  eventId?: string;
  isInPlay?: boolean;
}

const AsianViewInPlayTime = ({ marketId, eventId, isEventUpdatesData, isInPlay }: AsianViewInPlayTimeProps) => {
  const { t } = useTranslation();

  const isMarketsPricesInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const homeScore = useSelector(
    isEventUpdatesData && eventId ? getHomeScoreByEventId(eventId) : getMarketPricesHomeScore(marketId)
  );
  const awayScore = useSelector(
    isEventUpdatesData && eventId ? getAwayScoreByEventId(eventId) : getMarketPricesAwayScore(marketId)
  );
  const regularTime = useSelector(
    isEventUpdatesData && eventId ? getEventsUpdatedDataTimeElapsed(eventId) : getMarketPricesRegularTime(marketId)
  );
  const elapsedRegularTime = useSelector(
    isEventUpdatesData && eventId
      ? getEventsUpdatedDataAsianViewElapsedRegularTime(eventId)
      : getMarketPricesAsianViewElapsedRegularTime(marketId)
  );
  const elapsedAddedTime = useSelector(
    isEventUpdatesData && eventId
      ? getEventsUpdatedDataElapsedAddedTime(eventId)
      : getMarketPricesAddedRegularTime(marketId)
  );
  const matchStatus = useSelector(
    isEventUpdatesData && eventId
      ? getEventsUpdatedDataInPlayMatchStatus(eventId)
      : getMarketPricesMatchStatus(marketId)
  );
  const isEventsUpdatedData = useSelector(
    getIsEventsUpdatedDataByEventId(eventId && isEventUpdatesData ? eventId : '')
  );

  const isLiveScoreData =
    (isInPlay || isMarketsPricesInPlay || isEventsUpdatedData) &&
    homeScore !== undefined &&
    awayScore !== undefined &&
    (elapsedRegularTime !== undefined || regularTime !== undefined);

  if (!isLiveScoreData) {
    return null;
  }

  const { time, addedTime } = getSoccerAddedTime(elapsedRegularTime, elapsedAddedTime);

  const isHalfTime = matchStatus === MatchStatuses.HALF_TIME;
  const isFinished = matchStatus === MatchStatuses.END;
  const isFirstHalf =
    isEventUpdatesData && eventId ? matchStatus === MatchStatuses.KICK_OFF : matchStatus === MatchStatuses.FIRST_HALF;
  const isSecondHalf =
    isEventUpdatesData && eventId
      ? matchStatus === MatchStatuses.SECOND_HALF_KICK_OFF
      : matchStatus === MatchStatuses.SECOND_HALF;
  let label = '';

  if (isHalfTime || isFinished) {
    label = `${t(`asianView.labels.inPlayScore.${isHalfTime ? 'HT' : 'END'}`)}`;
  } else if (isFirstHalf || isSecondHalf) {
    label = `${t(`asianView.labels.inPlayScore.${isFirstHalf ? '1H' : '2H'}`)} ${time}'${
      addedTime ? `+${addedTime}'` : ''
    }`;
  }

  return <p className={styles.time}>{label}</p>;
};

export default AsianViewInPlayTime;
