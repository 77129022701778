import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { CookieNames } from 'constants/app';
import { componentsBranding, mobileComponents } from 'constants/branding';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { BetsTypesByPeriods, MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME, MyBetsPeriods } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { setMobileSorting, setSortBy } from 'redux/modules/myBets';
import { SortByFields } from 'redux/modules/myBets/type';
import { MyBetsPeriod, TGetBetAndPageDataParams } from 'types/myBets';

import styles from './styles.module.scss';

type TBetsPeriodTabProps = {
  tab: MyBetsPeriod;
  disabled?: boolean;
};

const BetsPeriodTab = ({ tab, disabled }: TBetsPeriodTabProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setCookie] = useCookies([CookieNames.MY_BETS_TYPES]);

  const { periodType } = useMyBetsFilters();
  const { isMobile, isAsianViewPage } = useDevice();
  const { refreshData } = useMyBetsPagesNavigation();

  const handleChangePeriodTab = () => {
    const dataParams: TGetBetAndPageDataParams = { betsTypes: BetsTypesByPeriods[tab] };

    if (tab === MyBetsPeriods.Settled) {
      localStorage.setItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME, 'true');

      dispatch(
        isMobile
          ? setMobileSorting({ type: SortByFields.DATE, order: 'desc' })
          : setSortBy({ type: SortByFields.DATE, value: 'desc' })
      );

      dataParams.sortBy = { [SortByFields.DATE]: 'desc' };
    } else {
      localStorage.removeItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME);

      dispatch(
        isMobile
          ? setMobileSorting({ type: SortByFields.PLACED_DATE, order: 'desc' })
          : dispatch(setSortBy({ type: SortByFields.PLACED_DATE, value: 'desc' }))
      );

      dataParams.sortBy = { [SortByFields.PLACED_DATE]: 'desc' };
    }

    if (tab !== periodType) {
      navigate(`${isAsianViewPage ? ASIAN_BASE_URL : ''}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${tab.toLowerCase()}/1`);
    }

    setCookie(CookieNames.MY_BETS_TYPES, BetsTypesByPeriods[tab], { path: '/' });

    refreshData(dataParams);
  };

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={handleChangePeriodTab}
      className={classNames(styles.periodTab, {
        [styles.periodTab__selected]: tab === periodType,
        [styles.periodTab__mobile]: isMobile,
        [componentsBranding.TAB]: !isMobile,
        [componentsBranding.ACTIVE]: !isMobile && tab === periodType,
        [mobileComponents.SELECTED]: isMobile && tab === periodType
      })}
    >
      {t(`account.mybets.btns.${tab.toLowerCase()}`)}
    </button>
  );
};

export default BetsPeriodTab;
