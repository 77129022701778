import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Icon from 'components/Icon';
import { ViewsBy } from 'constants/app';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { ViewBy } from 'types';

import styles from './styles.module.scss';

interface SortingByProps {
  /**
   * Determines the sorting type
   */
  sortBy: ViewBy;
  /**
   * Selected sorting type
   */
  selectedSort: ViewBy;
  /**
   * Set sorting type
   */
  setSortBy: (type: ViewBy) => void;
}

const SortingBy = ({ sortBy, selectedSort, setSortBy }: SortingByProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const isActive = sortBy === selectedSort;

  return (
    <button
      onClick={() => setSortBy(sortBy)}
      className={classNames('biab_sort-option ', styles.sortBy, {
        [`${styles.sortBy__active} biab_sort-active`]: isActive
      })}
      disabled={isActive}
    >
      <span>
        {sortBy === ViewsBy.TIME
          ? t('inplay.filters.viewBy.time')
          : t(`${isPNCEnabled ? 'pnc.' : ''}inplay.labels.matched`)}
      </span>
      {isActive ? (
        <Icon iconClass="fa2-caret-icon" fontFamily="fa2" className={styles.iconCaret} />
      ) : (
        <Icon iconClass="fa2-caret-double" fontFamily="fa2" className={styles.iconDoubleCaret} />
      )}
    </button>
  );
};

export default SortingBy;
