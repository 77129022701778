import { useState } from 'react';

export const useForm = <TFormValues extends Record<string, string>>(initialValues: TFormValues) => {
  const [formValues, setFormValues] = useState<TFormValues>(initialValues);

  const handleChange = <TName extends keyof TFormValues>(name: TName, value: TFormValues[TName]) => {
    setFormValues(currValues => ({
      ...currValues,
      [name]: value
    }));
  };

  return { formValues, handleChange };
};
