import classNames from 'classnames';

import styles from './styles.module.scss';

interface ResponsibleBettingAppliedFieldProps {
  label?: string;
  value?: string;
  isMobile: boolean;
  centered?: boolean;
}

const ResponsibleBettingAppliedField = ({
  label = '',
  value = '',
  isMobile,
  centered = true
}: ResponsibleBettingAppliedFieldProps) => (
  <div
    className={classNames(styles.container, {
      [styles.container__mobile]: isMobile
    })}
  >
    <span className={styles.label}>{label}</span>
    <p
      className={classNames(styles.value, {
        [styles.value__mobile]: isMobile,
        [styles.value__centered]: centered && isMobile
      })}
    >
      {value}
    </p>
  </div>
);

export default ResponsibleBettingAppliedField;
