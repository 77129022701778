import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sum, values } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { BetTypesFields, SortByFields } from 'constants/betList';
import { TFailureActionPayload } from 'types';

import { TBetListState, TFiltersFields, TSettledBet } from './type';

export const defaultFilters: TFiltersFields = {
  sortBy: SortByFields.PlacementTime,
  betType: BetTypesFields.AllBets
};

const initialState: TBetListState = {
  settled: [],
  loading: false,
  error: null,
  betsCounter: 0,

  /* Open tab */
  isFiltersSectionOpen: false,
  filters: defaultFilters,
  cancelledBets: [],
  hiddenBets: []
};

const slice = createSlice({
  name: SLICES_NAMES.BET_LIST,
  initialState,
  reducers: {
    fetchSettledBetList: (state, { payload = false }: PayloadAction<boolean | undefined>) => {
      if (!payload) {
        state.loading = true;
      }
    },
    successFetchSettledBetList: (state, { payload }: PayloadAction<TSettledBet[]>) => {
      state.loading = false;
      state.settled = payload;
    },
    failureFetchSettledBetList: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    setBetListCounter: (state, { payload }: PayloadAction<Record<string, number> | undefined>) => {
      state.betsCounter = payload ? sum(values(payload)) : 0;
    },

    /* Open tab */
    setIsFiltersSectionOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isFiltersSectionOpen = payload;
    },
    setFilters: (state, { payload }: PayloadAction<Partial<TFiltersFields>>) => {
      state.filters = { ...state.filters, ...payload };
    },
    resetFilters: state => {
      state.filters = defaultFilters;
    },
    setCancelledBet: (state, { payload }: PayloadAction<number>) => {
      state.cancelledBets = [...state.cancelledBets, payload];
    },
    removeCancelledBet: (state, { payload }: PayloadAction<number>) => {
      state.cancelledBets = state.cancelledBets.filter(offerId => offerId !== payload);
    },
    setHiddenBet: (state, { payload }: PayloadAction<number>) => {
      state.hiddenBets = [...state.hiddenBets, payload];
    }
  }
});

export const {
  fetchSettledBetList,
  successFetchSettledBetList,
  failureFetchSettledBetList,
  setBetListCounter,
  setIsFiltersSectionOpen,
  setFilters,
  resetFilters,
  setCancelledBet,
  removeCancelledBet,
  setHiddenBet
} = slice.actions;

export default slice.reducer;
