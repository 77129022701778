import { createSelector } from '@reduxjs/toolkit';

import { CommissionTypes, Devices, EventViewTypes, SportIds } from 'constants/app';
import { AppState } from 'redux/reducers';
import { DropdownItem } from 'types';
import { BettingDisplaySettingEnabled } from 'types/responsibleBetting';

import { PageFromBackend } from '../pages/types';

export const getProperties = ({ appConfigs }: AppState) => appConfigs.properties;
export const getEventViewSettings = ({ appConfigs }: AppState) => appConfigs.properties.eventViewSettings;
export const getMinAmountToShowOdds = ({ appConfigs }: AppState) => appConfigs.properties.minAmountToShowOdds;
export const getCurrency = ({ appConfigs }: AppState) => appConfigs.currency;
export const getTimezone = ({ appConfigs }: AppState) => appConfigs.timezone;
export const getIsSportIconsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.isSportIconsEnabled;
export const getTimezoneCookieEnabled = ({ appConfigs }: AppState) => appConfigs.properties.timezoneCookieEnabled;

export const getCurrencies = ({ appConfigs }: AppState) => appConfigs.currencies;

export const getCurrenciesDropdownItems = createSelector(getCurrencies, (currencies): DropdownItem[] =>
  currencies
    .map(({ currencyCode }) => ({
      id: currencyCode,
      value: currencyCode,
      label: currencyCode
    }))
    .sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    })
);
export const getCurrencyCode = ({ appConfigs }: AppState) => appConfigs.currency.currencyCode;

export const getPNCEnabledSetting = ({ appConfigs }: AppState) =>
  appConfigs.properties.masterAccountCommissionType === CommissionTypes.PRICES;

export const getConsolidateBets = ({ appConfigs }: AppState) =>
  appConfigs.properties[appConfigs.device === Devices.DESKTOP ? 'desktopSettings' : 'mobileSettings'].consolidateBets;
export const getCashOutPopUp = ({ appConfigs }: AppState) => appConfigs.cashOutPopUp;
export const getCashOutPopUpLoading = ({ appConfigs }: AppState) => appConfigs.cashOutPopUpLoading;
// getLineMarketsSwitchBackLayOnCricket - works only for Sports/Games, not Asian View
export const getLineMarketsSwitchBackLayOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties[appConfigs.device === Devices.DESKTOP ? 'desktopSettings' : 'mobileSettings']
    .lineMarketsSwitchBackLayOnCricket;
// getReplaceBackLayWithUnderOver - works only for Sports/Games, not Asian View
export const getReplaceBackLayWithUnderOver = ({ appConfigs }: AppState) =>
  appConfigs.properties[appConfigs.device === Devices.DESKTOP ? 'desktopSettings' : 'mobileSettings']
    .replaceBackLayWithUnderOver;

export const getFancyMarketsOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties[appConfigs.device === Devices.DESKTOP ? 'desktopSettings' : 'mobileSettings']
    .fancyMarketsOnCricket;

export const getCashOutGetMarketsInterval = ({ appConfigs }: AppState) =>
  appConfigs.properties.cashOutGetMarketsInterval;

export const getCashOutGetStatusInterval = ({ appConfigs }: AppState) => appConfigs.properties.cashOutGetStatusInterval;

export const getTranslation = ({ appConfigs }: AppState) =>
  `${appConfigs.properties.domain}|${appConfigs.properties.language}`;
export const getPwdLink = ({ appConfigs }: AppState) => appConfigs.properties.pwdLink.url;

export const getMultiCurrencySupported = ({ appConfigs }: AppState) => appConfigs.properties.multiCurrencySupported;

export const getIsIframeEnabled = ({ appConfigs }: AppState) => appConfigs.properties.pageServingOptions?.iframeEnabled;

export const getAutoCashOutEnabled = ({ appConfigs }: AppState) => appConfigs.properties.autoCashOutEnabled;

export const getBrandingCSSLoaded = ({ appConfigs }: AppState) => appConfigs.brandingCSSLoaded;

export const getOperator = ({ appConfigs }: AppState) => appConfigs.properties.operator;

export const getDisplayIconsInSubHeader = ({ appConfigs }: AppState) => appConfigs.properties.displayIconsInSubHeader;

export const getIsMobileMenuButtonTextEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.isMobileMenuButtonTextEnabled;

export const getIsResponsibleBettingMenuEnabled = ({ appConfigs }: AppState) => {
  return (
    appConfigs.properties.bettingDisplaySettings.exposureLimitEnabled ||
    appConfigs.properties.bettingDisplaySettings.lossLimitEnabled ||
    appConfigs.properties.bettingDisplaySettings.selfExclusionEnabled ||
    appConfigs.properties.bettingDisplaySettings.reminderEnabled ||
    appConfigs.properties.bettingDisplaySettings.dayLimitEnabled
  );
};

export const getIsUpdatedResponsibleBettingPageEnabled = ({ appConfigs }: AppState) => {
  return (
    appConfigs.properties.bettingDisplaySettings.lossLimitEnabled ||
    appConfigs.properties.bettingDisplaySettings.selfExclusionEnabled ||
    appConfigs.properties.bettingDisplaySettings.reminderEnabled ||
    appConfigs.properties.bettingDisplaySettings.timeLimitEnabled
  );
};

export const getIsShowMarketOdd = ({ appConfigs }: AppState) => appConfigs.properties.marketOdds;
export const getAppDevice = ({ appConfigs }: AppState) => appConfigs.device;
export const getIsMobile = ({ appConfigs }: AppState) => appConfigs.device === Devices.MOBILE;

export const getIsMultiMarket = (sportId: string) =>
  createSelector(getEventViewSettings, eventViewSettings => {
    const eventSettings = eventViewSettings.find(event => event.eventTypeId === sportId);
    return eventSettings?.viewType === EventViewTypes.MULTI_MARKET_VIEW;
  });

export const getIsTop5View = (sportId: string) =>
  createSelector(
    getEventViewSettings,
    eventViewSettings =>
      eventViewSettings.find(event => event.eventTypeId === sportId)?.viewType === EventViewTypes.TOP_5_VIEW
  );

export const getIsTwoColumnView = (sportId: string) =>
  createSelector(
    getEventViewSettings,
    eventViewSettings => eventViewSettings.find(event => event.eventTypeId === sportId)?.twoColumnView
  );

export const getMinSearchQueryLength = ({ appConfigs }: AppState) => Number(appConfigs.properties.minSearchQueryLength);

export const getLanguage = ({ appConfigs }: AppState) => appConfigs.properties.language;
export const getFallbackLang = ({ appConfigs }: AppState) => appConfigs.properties.fallbackLang;
export const getDesktopHeaderHidden = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions.desktopHeaderHidden;
export const getMobileAccountButtonEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions.mobileAccountButtonEnabled;
export const getIsAsianViewEnabled = ({ appConfigs }: AppState) => appConfigs.properties.asianViewEnabled;
export const getCurrentBetsRequestInterval = ({ appConfigs }: AppState) =>
  appConfigs.properties.currentBetsRequestInterval;

export const getIsExchangeGamesEnabled = ({ appConfigs }: AppState) => appConfigs.properties.exchangeGamesEnabled;
export const getIsExchangeSportsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.exchangeSportsEnabled;
export const getIsPassiveSessionEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.bettingDisplaySettings.passiveSessionEnabled;
export const getIsHeadsUpOverHeader = ({ appConfigs }: AppState) =>
  appConfigs.properties.bettingDisplaySettings.headsUpOverHeader;

export const getIsPropertiesLoaded = ({ appConfigs }: AppState) => appConfigs.isPropertiesLoaded;

export const getIsDesktopInfiniteScrollEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.infiniteScrollEnabled;
export const getIsMobileInfiniteScrollEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.infiniteScrollEnabled;

export const getAsianViewMiddleSectionPageSize = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.asianViewMiddleSectionPageSize;

export const getBackendContentPage = ({ appConfigs }: AppState) => appConfigs.backendContentPage;

export const getBettingDisplaySettings = ({ appConfigs }: AppState) => appConfigs.properties.bettingDisplaySettings;

export const getPassiveSessionPositionHeader = ({ appConfigs }: AppState) =>
  appConfigs.properties.bettingDisplaySettings.passiveSessionPositionHeader;

export const getMobileSettingsClassicNavigation = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.classicNavigation;

export const getMobileSettingsClassicNavigationLinks = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.classicNavigationLinks;

export const getMobileSettingsSwipeMenuLinks = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.swipeMenuLinks;

export const getMobileSettingsLoginHeaderButtons = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.loginHeaderButtons;

export const getDesktopSettingsLoginHeaderButtons = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.loginHeaderButtons;
export const getDesktopSettingsAVBalanceToggle = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.asianViewBalanceToggle;
export const getDesktopSettingsPwdChangeBtn = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.pwdChangeBtn;
export const getDesktopSettingsConnectionChecks = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.connectionChecks;
export const getDesktopSettingsFillOrKill = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.fillOrKill;
export const getMobileSettingsFillOrKill = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.fillOrKill;
export const getDesktopSettingsQuickstakeBetslip = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.quickstakeBetslip;
export const getDesktopSettingsInlineBetting = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.inlineBetting;
export const getDesktopSettingsDefStake = ({ appConfigs }: AppState) => appConfigs.properties.desktopSettings.defStake;
export const getMobileSettingsDefStake = ({ appConfigs }: AppState) => appConfigs.properties.mobileSettings.defStake;
export const getDesktopSettingsPlaceBetWithEnterKey = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.placeBetWithEnterKey;
export const getDesktopSettingsConfirmBetsBeforePlace = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.confirmBetsBeforePlace;
export const getHomePageType = ({ appConfigs }: AppState) => appConfigs.properties.homePageType;
export const getInPlayDefTabHighlights = ({ appConfigs }: AppState) => appConfigs.properties.inPlayDefTabHighlights;

export const getBrandingCssVersion = ({ appConfigs }: AppState) => appConfigs.properties.brandingCssVersion;
export const getNewCustomerCookieName = ({ appConfigs }: AppState) => appConfigs.properties.newCustomerCookieName;
export const getPostLoginNotificationEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.postLoginNotificationEnabled;
export const getPropertiesTours = ({ appConfigs }: AppState) => appConfigs.properties.tours;
export const getTokenTypeCookieName = ({ appConfigs }: AppState) => appConfigs.properties.tokenTypeCookieName;
export const getWalletIntegrationType = ({ appConfigs }: AppState) => appConfigs.properties.walletIntegrationType;
export const getDisplayCurrencySymbol = ({ appConfigs }: AppState) => appConfigs.properties.displayCurrencySymbol;
export const getIndianNumberSystemEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.indianNumberSystemEnabled;
export const getSplashScreenEnabled = ({ appConfigs }: AppState) => appConfigs.splashScreenEnabled;
export const getPropertiesLoading = ({ appConfigs }: AppState) => appConfigs.propertiesLoading;

export const getIsCurrentBetsWebSocketEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.currentBetsWsEnabled;

export const getAsianViewLayBettingEnabled = ({ appConfigs }: AppState) =>
  !!appConfigs.properties.asianViewLayBettingEnabled;

export const getDesktopSettingsCompetitionViewSports = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.competitionViewSports;

export const getIsCompetitionViewEnabled = ({ appConfigs }: AppState) => appConfigs.properties.isCompetitionViewEnabled;

export const getExchangeGamesInterval = ({ appConfigs }: AppState) => appConfigs.properties.exchangeGamesInterval;

export const getCurrencyLoading = ({ appConfigs }: AppState) => appConfigs.currencyLoading;
export const getEduPortalEnabled = ({ appConfigs }: AppState) => appConfigs.properties.eduPortalEnabled;
export const getEduPlaycingLayBetPermalink = ({ appConfigs }: AppState) =>
  appConfigs.properties.eduPortalPlacingLayBetDefaultLink;
export const getEduDecimalOddsPermalink = ({ appConfigs }: AppState) =>
  appConfigs.properties.eduPortalUnderstandingDecimalOddsDefaultLink;
export const getBackendPages = ({ appConfigs }: AppState) => appConfigs.pages;
export const getSplashScreenRefreshTimeout = ({ appConfigs }: AppState) => appConfigs.splashScreenRefreshTimeout;
export const getSplashScreenContent = ({ appConfigs }: AppState) => appConfigs.splashScreenContent;
export const getDesktopSettingsHowTo = ({ appConfigs }: AppState) => appConfigs.properties.desktopSettings.howTo;

export const getNonAuthMatchStatisticsEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.nonAuthMatchStatisticsEnabled;
export const getMatchStatUrl = ({ appConfigs }: AppState) => appConfigs.properties.matchStatUrl;
export const getHorseRacingSportPageInterval = ({ appConfigs }: AppState) =>
  appConfigs.properties.horseRacingSportPageInterval;
export const getDefaultQuickStakes = ({ appConfigs }: AppState) => appConfigs.properties.defaultQuickStakes;
export const getHideLiveScoreInHeader = ({ appConfigs }: AppState) => appConfigs.properties.hideLiveScoreInHeader;
export const getIsMobileTimezoneDropdownEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions.mobileTimezoneDropdownEnabled;
export const getDesktopExtendedMarketCellWidth = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopExtendedMarketCellWidth;
export const getMobileMarketCellWidth = ({ appConfigs }: AppState) => appConfigs.properties.mobileMarketCellWidth;
export const getMobileCellWidth = ({ appConfigs }: AppState) => appConfigs.properties.mobileCellWidth;
export const getIsAmericanDateFormatEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.americanDateFormatEnabled;
export const getNonAuthLoginNotificationEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.nonAuthLoginNotificationEnabled;
export const getMarketOddsBannerLocation = ({ appConfigs }: AppState) => appConfigs.properties.marketOddsBannerLocation;
export const getFeaturedSports = ({ appConfigs }: AppState) => appConfigs.properties.featuredSports;
export const getMarketSuspendedTimeToHideSeconds = ({ appConfigs }: AppState) =>
  appConfigs.properties.marketSuspendedTimeToHideSeconds;
export const getHideToursFadePeriodMs = ({ appConfigs }: AppState) => appConfigs.properties.hideToursFadePeriodMs;
export const getMinNavSportIds = ({ appConfigs }: AppState) => appConfigs.properties.minNavSportIds;
export const getHomePageColumnsNumber = ({ appConfigs }: AppState) => appConfigs.properties.homePageColumnsNumber;

export const getTimeformStatisticsEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.timeformStatisticsEnabled;

export const getCountriesWithCustomClothIcon = ({ appConfigs }: AppState) =>
  appConfigs.properties.countriesWithCustomClothIcon;

export const getNumberOfMarketSelectionsScroll = ({ appConfigs }: AppState) =>
  appConfigs.properties.numberOfMarketSelectionsScroll;

export const getIsMarketSelectionsScroll = ({ appConfigs }: AppState) => appConfigs.properties.isMarketSelectionsScroll;
export const getIsHideMarketDepth = ({ appConfigs }: AppState) => appConfigs.properties.hideMarketDepth;
export const getIsCashOutEnabled = ({ appConfigs }: AppState) => appConfigs.properties.cashOutEnabled;
export const getIsDesktopFooterHidden = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions.desktopFooterHidden;
export const getInPlayPaginationSize = ({ appConfigs }: AppState) => appConfigs.properties.inPlayPaginationSize;
export const getRunningBallInterval = ({ appConfigs }: AppState) => appConfigs.properties.runningBallInterval;
export const getMarketOddsBackOnly = ({ appConfigs }: AppState) => appConfigs.properties.marketOddsBackOnly;
export const getNextHorseEnabled = ({ appConfigs }: AppState) => appConfigs.properties.nextHorseEnabled;
export const getCurrencySteps = ({ appConfigs }: AppState) => appConfigs.properties.currencySteps;
export const getIsCustomPopUpEnabled = ({ appConfigs }: AppState) => appConfigs.properties.customPopUpEnabled;
export const getDesktopCompetitionPageSize = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopCompetitionPageSize;
export const getBackendPage =
  (link: PageFromBackend) =>
  ({ appConfigs }: AppState) =>
    appConfigs.pages[link];
export const getIsCurrencySymbolAfterAmount = ({ appConfigs }: AppState) =>
  appConfigs.properties.currencySymbolAfterAmount;
export const getReplaceCurrencySymbolForMatchedAndTooltips = ({ appConfigs }: AppState) =>
  appConfigs.properties.replaceCurrencySymbolForMatchedAndTooltips;
export const getBetslipSpinnerTime = ({ appConfigs }: AppState) => appConfigs.properties.betslipSpinnerTime;
export const getSupportedLocales = ({ appConfigs }: AppState) => appConfigs.properties.supportedLocales;
export const getIsMarketPricesWSEnabled = ({ appConfigs }: AppState) => appConfigs.properties.marketPricesWSEnabled;
export const getReconnectAttempts = ({ appConfigs }: AppState) => appConfigs.properties.reconnectAttempts;
export const getReconnectTimeout = ({ appConfigs }: AppState) => appConfigs.properties.reconnectTimeout;
export const getBetfairCdnImageFeedPath = ({ appConfigs }: AppState) => appConfigs.properties.betfairCdnImageFeedPath;
export const getPageDefaultSorting = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions.defaultSorting;
export const getIsEnabledBettingDisplaySetting =
  (key: BettingDisplaySettingEnabled) =>
  ({ appConfigs }: AppState) =>
    appConfigs.properties.bettingDisplaySettings[key];

export const getMobileSettingsQuickstakeBetslip = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.quickstakeBetslip;
export const getMobileSettingsVirtualKeyboardBetslip = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.virtualKeyboardBetslip;
export const getMobileSettingsTotalWinnings = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.totalWinnings;
export const getMobileSettingsConfirmBetsBeforePlace = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.confirmBetsBeforePlace;
export const getMobileSettingsReplaceBackLayWithUnderOver = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.replaceBackLayWithUnderOver;
export const getMobileSwapColorsFancyMarketsOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.swapColorsFancyMarketsOnCricket;

export const getDesktopSwapColorsFancyMarketsOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.swapColorsFancyMarketsOnCricket;

export const getPositionOfAsianViewMobileNavigationMenu = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.positionOfAsianViewMobileNavigationMenu;

export const getAsianViewMobileNavigationMenu = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.asianViewMobileNavigationMenu;

export const getMobileSettingsLineMarketsSwitchBackLayOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.lineMarketsSwitchBackLayOnCricket;
export const getMobileSettingsAlternativeLayOdds = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.alternativeLayOdds;
export const getMobileSettingsMyBetsLinks = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.myBetsLinks;
export const getMobileSettingsSwipeMenu = ({ appConfigs }: AppState) => appConfigs.properties.mobileSettings.swipeMenu;
export const getMobileSettingsDepositBtn = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.depositBtn;
export const getMobileSettingsPwdChangeBtn = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.pwdChangeBtn;
export const getMobileBackButtonMobile = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.backButtonMobile;

export const getDisplayCustomerCommissionRange = ({ appConfigs }: AppState) =>
  appConfigs.properties.displayCustomerCommissionRange;
export const getDesktopSettingsCollapseSportsBar = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.collapseSportsBar;
export const getDesktopSettingsActiveMarkets = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.activeMarkets;
export const getDesktopSettingsDepositBtn = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.depositBtn;
export const getDesktopSettingsNetOfCommissionBetslip = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.netOfCommissionBetslip;
export const getDesktopSettingsReplaceBackLayWithUnderOver = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.replaceBackLayWithUnderOver;
export const getDesktopSettingsLineMarketsSwitchBackLayOnCricket = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.lineMarketsSwitchBackLayOnCricket;
export const getDesktopSettingsAlternativeLayOdds = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.alternativeLayOdds;
export const getDesktopSettingsTotalWinnings = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.totalWinnings;
export const getDesktopSettingsMyBetsLinks = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.myBetsLinks;

export const getCustomPopUpEnabled = (state: AppState) => state.appConfigs.properties.customPopUpEnabled;
export const getDesktopCashoutPageSize = (state: AppState) => state.appConfigs.properties.desktopCashoutPageSize;

export const getBetsStatusesRequestInterval = (state: AppState) =>
  state.appConfigs.properties.betsStatusesRequestInterval;

export const getMobileSettingsNetOfCommission = (state: AppState) =>
  state.appConfigs.properties.mobileSettings.netOfCommission;

export const getAsianViewCashOutSportsEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.asianViewCashOutSportsEnabled;
export const getCashOutGetQuotesInterval = ({ appConfigs }: AppState) => appConfigs.properties.cashOutGetQuotesInterval;
export const getAutoCashOutWsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.autoCashOutWsEnabled;
export const getCashOutQuoteWsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.cashOutQuoteWsEnabled;
export const getBalanceWsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.balanceWsEnabled;
export const getEventUpdatesWsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.eventUpdatesWsEnabled;
export const getGeneralWsEnabled = ({ appConfigs }: AppState) => appConfigs.properties.generalWsEnabled;

export const getMobileSwipeMenu = ({ appConfigs }: AppState) => appConfigs.properties.mobileSettings.swipeMenu;

export const getIsAsianViewMobileBetListPaginationEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.asianViewMobileBetListPagination;
export const getAsianViewMobileBetListPaginationSize = ({ appConfigs }: AppState) =>
  appConfigs.properties.asianViewMobileBetListPaginationSize;
export const getIsOperatorBettingLimitsEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.operatorBettingLimitsEnabled;

export const getIsOperatorBalanceEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.pageServingOptions?.iframeEnabled && appConfigs.properties.operatorBalanceEnabled;
export const getDesktopHeaderNavigation = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.headerNavigation;

export const getConnectionFilePath = ({ appConfigs }: AppState) => appConfigs.properties.connectionFilePath;
export const getConnectionFileSize = ({ appConfigs }: AppState) => appConfigs.properties.connectionFileSize;
export const getConnectionInterval = ({ appConfigs }: AppState) => appConfigs.properties.connectionInterval;
export const getConnectionMaxSpeed = ({ appConfigs }: AppState) => appConfigs.properties.connectionMaxSpeed;
export const getConnectionMinSpeed = ({ appConfigs }: AppState) => appConfigs.properties.connectionMinSpeed;
export const getDeviceLangDropdownAuth =
  (isMobile: boolean) =>
  ({ appConfigs }: AppState) =>
    isMobile
      ? appConfigs.properties.mobileSettings.langDropdownAuth
      : appConfigs.properties.desktopSettings.langDropdownAuth;
export const getDeviceLangDropdownNoAuth =
  (isMobile: boolean) =>
  ({ appConfigs }: AppState) =>
    isMobile
      ? appConfigs.properties.mobileSettings.langDropdownNoAuth
      : appConfigs.properties.desktopSettings.langDropdownNoAuth;
export const getMobileLangDropdownAuth = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.langDropdownAuth;
export const getMobileLangDropdownNoAuth = ({ appConfigs }: AppState) =>
  appConfigs.properties.mobileSettings.langDropdownNoAuth;

export const getDeviceFooterContent =
  (isMobile: boolean) =>
  ({ appConfigs }: AppState) =>
    isMobile ? appConfigs.properties.mobileSettings.footerContent : appConfigs.properties.desktopSettings.footerContent;

export const getDeviceAsianViewCashOutPaginationButtons =
  (isMobile: boolean) =>
  ({ appConfigs }: AppState) =>
    isMobile
      ? appConfigs.properties.mobileSettings.asianViewCashOutPaginationButtons
      : appConfigs.properties.desktopSettings.asianViewCashOutPaginationButtons;
export const getAsianViewDesktopCashOutPaginationButtons = ({ appConfigs }: AppState) =>
  appConfigs.properties.desktopSettings.asianViewCashOutPaginationButtons;
export const getIsTimeLimitNotificationsEnabled = ({ appConfigs }: AppState) =>
  appConfigs.properties.bettingDisplaySettings.timeLimitEndNotificationEnabled;

export const getIsCollapsibleRightSide = ({ appConfigs }: AppState) => appConfigs.isCollapsibleRightSide;
export const getIsLandscape = ({ appConfigs }: AppState) => appConfigs.isLandscape;
export const getIsSportLayOddsDisabled = (sportId?: string) =>
  createSelector(getProperties, properties =>
    sportId ? !!properties.disabledLayOddsSportIds?.includes(SportIds.toString()) : false
  );

export const getDisabledLayOddsSportIds = ({ appConfigs }: AppState) => appConfigs.properties.disabledLayOddsSportIds;
export const getArePropertiesFromWebSocketLoaded = ({ appConfigs }: AppState) =>
  appConfigs.arePropertiesFromWebSocketLoaded;

export const getCurrencyMinBetSize = ({ appConfigs }: AppState) => appConfigs.currency.minBetSize;
export const getCurrencyMaxBetSize = ({ appConfigs }: AppState) => appConfigs.currency.maxBetSize;
export const getWindowWidth = ({ appConfigs }: AppState) => appConfigs.windowWidth;
export const getWindowHeight = ({ appConfigs }: AppState) => appConfigs.windowHeight;
