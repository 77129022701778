import { useTranslation } from 'react-i18next';

import BetOfferState from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetOfferState/BetOfferState';
import UnmatchedActions from 'pages/mobile/MobileBetListPage/components/OpenBet/components/UnmatchedActions/UnmatchedActions';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './BetDetails.module.scss';

interface BetDetailsProps {
  bet: TCurrentBet;
  isCancelled: boolean;
  hideOfferState?: boolean;
}

const BetDetails = ({ bet, isCancelled, hideOfferState }: BetDetailsProps) => {
  const { t } = useTranslation();
  const { betStake, betProfit, isLayBet, isUnmatchedBet } = useOpenBetData(bet);

  return (
    <div className={styles.details}>
      {!hideOfferState && <BetOfferState bet={bet} />}
      <div className={styles.finance}>
        <div className={styles.finance__item}>
          <span>{t('account.mybets.labels.risk')}</span>
          <span className={styles.finance__value}>{isLayBet ? betProfit : betStake}</span>
        </div>
        <div className={styles.finance__item}>
          <span>{t('account.mybets.labels.toWin')}</span>
          <span className={styles.finance__value}>{isLayBet ? betStake : betProfit}</span>
        </div>
      </div>
      {isUnmatchedBet && <UnmatchedActions bet={bet} isCancelled={isCancelled} />}
    </div>
  );
};

export default BetDetails;
