import classNames from 'classnames';

import MarketScore from './components/MarketScore';
import NoInPlayContent from './components/NoInPlayContent';

import styles from './ScoreWidget.module.scss';

interface InPlayCellProps {
  isInPlay?: boolean;
  eventId?: string;
  marketStartTime?: number | null;
  sportId?: string;
  className?: string;
}

const ScoreWidget = ({ isInPlay, eventId, sportId, marketStartTime, className }: InPlayCellProps) => {
  return (
    <div className={classNames(styles.container, className, 'biab_market-inplay-cell')}>
      <div
        className={classNames(styles.container__content, {
          'biab_in-play': isInPlay
        })}
      >
        {isInPlay && <MarketScore sportId={sportId} eventId={eventId!} />}
        {!isInPlay && <NoInPlayContent marketStartTime={marketStartTime!} />}
      </div>
    </div>
  );
};

export default ScoreWidget;
