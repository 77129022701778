import Icon from 'components/Icon';

import styles from './MobileSelectField.module.scss';

interface MobileSelectFieldProps<TValue> {
  label: string;
  name: string;
  value: TValue;
  options: { label: string; value: TValue; description?: string }[];
  onChange: (value: TValue) => void;
}

const MobileSelectField = <TValue extends string>({
  label,
  name,
  value,
  options,
  onChange
}: MobileSelectFieldProps<TValue>) => {
  return (
    <div className={styles.field}>
      <p className={styles.field__title}>{label}</p>
      <div>
        {options.map(option => (
          <label key={option.value} className={styles.field__item}>
            <div className={styles.field__option}>
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={value === option.value}
                className={styles.field__input}
                onChange={ev => onChange(ev.target.value as TValue)}
              />
              <span className={styles.field__label}>{option.label}</span>
              <Icon iconClass="biab_custom-icon-check-24" size="lg" className={styles.field__icon} />
            </div>
            {option.description && <p className={styles.field__description}>{option.description}</p>}
          </label>
        ))}
      </div>
    </div>
  );
};

export default MobileSelectField;
