import classNames from 'classnames';
import { times } from 'lodash';

import Skeleton from 'components/Skeleton';

import styles from './EventsLoadingSkeleton.module.scss';

const SKELETONS = 2;

const EventsLoadingSkeleton = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <div className={styles.skeletonHeader}>
        <Skeleton className={styles.skeletonHeader__link} />
        <Skeleton className={styles.skeletonHeader__btn} />
      </div>
      <div className={styles.content}>
        {times(SKELETONS, index => (
          <div key={index} className={styles.skeletonItem}>
            <div className={classNames(styles.skeletonItem__header)}>
              <div className={styles.skeletonItem__header__left}>
                <Skeleton className={styles.skeletonItem__rectangle} />
              </div>
              <div className={styles.skeletonItem__header__right}>
                <Skeleton className={styles.skeletonItem__m} />
                <Skeleton className={styles.skeletonItem__m} />
              </div>
            </div>
            <div className={classNames(styles.skeletonItem__market)}>
              <div className={styles.skeletonItem__market__header}>
                <Skeleton className={styles.skeletonItem__m} />
                <Skeleton className={styles.skeletonItem__l} />
              </div>
              <div className={styles.skeletonItem__market__main}>
                <div className={styles.skeletonItem__market__main__left}>
                  <div className={styles.skeletonItem__market__main__left__bet}>
                    <Skeleton className={styles.skeletonItem__l} />
                    <Skeleton className={styles.skeletonItem__m} />
                    <Skeleton className={styles.skeletonItem__m} />
                  </div>
                  <div>
                    <Skeleton className={styles.skeletonItem__s} />
                  </div>
                </div>
                <div className={styles.skeletonItem__market__main__right}>
                  <Skeleton className={styles.skeletonItem__xs} />
                  <Skeleton className={styles.skeletonItem__xs} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsLoadingSkeleton;
