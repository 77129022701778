import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import OpenBet from 'pages/mobile/MobileBetListPage/components/OpenBet/OpenBet';
import NoFilteredBets from 'pages/mobile/MobileBetListPage/components/OpenTab/components/NoFilteredBets/NoFilteredBets';
import { getAreFiltersApplied, getOpenBetsSortedByPlacement } from 'redux/modules/betList/selectors';
import { addBetsGroupedByOfferId } from 'utils/currentBets';

const BetsSortedByPlacement = () => {
  const areFiltersApplied = useSelector(getAreFiltersApplied);
  const currentBets = useSelector(getOpenBetsSortedByPlacement);

  const bets = useMemo(() => addBetsGroupedByOfferId(currentBets), [currentBets]);

  return (
    <>
      {areFiltersApplied && !bets.length && <NoFilteredBets />}
      {bets.map(bet => (
        <OpenBet key={bet.offerId} bet={bet} />
      ))}
    </>
  );
};

export default BetsSortedByPlacement;
