import { useRef, useState } from 'react';
import classNames from 'classnames';
import { unescape } from 'lodash';
import { useTimeout } from 'usehooks-ts';

import Icon from 'components/Icon';
import { betslipBranding, componentsBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './styles.module.scss';

type XOR<T, U> = (T & { [K in keyof U]?: never }) | (U & { [K in keyof T]?: never });

type NotificationProps = {
  type: 'success' | 'info' | 'warning' | 'error';
  className?: string;
  iconClassName?: string;
  isInline?: boolean;
  isDismissible?: boolean;
  onClose?: () => void;
  onClickOutside?: () => void;
};

type Props = NotificationProps & XOR<{ message: string }, { messageContent: JSX.Element }>;

const Notification = ({
  type,
  message,
  messageContent,
  className,
  iconClassName,
  isInline,
  onClose,
  onClickOutside,
  isDismissible = true
}: Props) => {
  const { isMobile } = useDevice();
  const ref = useRef<HTMLDivElement>(null);
  const [isClickOutsideEnabled, setIsClickOutsideEnabled] = useState(false);

  const isErrorType = type === 'warning' || type === 'error';
  const iconClass = (() => {
    let icon = 'success';

    if (isErrorType) {
      icon = 'warning';
    } else if (type === 'info') {
      icon = 'info';
    }

    return `biab_custom-icon-${icon}-circle`;
  })();

  useTimeout(() => {
    setIsClickOutsideEnabled(true);
  }, 500);

  useOnClickOutside(ref, undefined, () => {
    if (isClickOutsideEnabled && onClickOutside) {
      onClickOutside();
    }
  });

  return (
    <div
      ref={ref}
      className={classNames(
        styles.alert,
        componentsBranding.NOTIFICATION,
        {
          [styles.alert__mobile]: isMobile,
          [styles.alert__inline]: isInline,
          [styles.alert__info]: type === 'info',
          [styles.alert__warning]: isErrorType,
          [componentsBranding.ERROR]: isErrorType,
          [mobileComponents.INFO]: isMobile && type === 'info'
        },
        className
      )}
    >
      <i
        className={classNames(iconClass, styles.alert__icon, iconClassName, {
          [betslipBranding.INFO_ICON]: type === 'info',
          [betslipBranding.WARNING_ICON]: isErrorType,
          [betslipBranding.CHECKMARK_ICON]: type === 'success'
        })}
      />
      <p
        className={classNames(styles.alert__message, {
          [styles.alert__message__mobile]: isMobile
        })}
        dangerouslySetInnerHTML={message ? { __html: unescape(message) } : undefined}
      >
        {messageContent}
      </p>
      {isDismissible && onClose && (
        <button onClick={onClose} className={styles.alert__closeBtn}>
          <Icon iconClass="biab_custom-icon-close" size="md" color="inherit" />
        </button>
      )}
    </div>
  );
};

export default Notification;
