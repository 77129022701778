import { TFunction } from 'react-i18next';

import { getToolbarButtons } from 'configs/MobileToolbar';
import { TypeFields } from 'constants/app';
import { NavigationTypes } from 'constants/competitions';
import {
  ACCOUNT_PAGE,
  ASIAN_VIEW,
  BET_LIST_PAGE,
  CASH_OUT,
  CASH_OUT_PAGE,
  CLASSIC_NAV_CLASSES,
  DEFAULT,
  DEFAULT_SPORT_ICON,
  FA_ICON,
  FAVOURITE,
  GAMES_PAGE,
  HOME_PAGE,
  HOW_TO,
  IN_PLAY,
  MY_BETS_PAGE,
  NONE,
  POKER_STAR,
  SEARCH_PAGE,
  SPORT_ICON,
  SPORTS_PAGE
} from 'constants/icons';
import { IconsConfig } from 'constants/iconsConfig';
import {
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_CASH_OUT_URL
} from 'constants/locations';
import { TSuccessFetchCurrency } from 'redux/modules/appConfigs/type';
import { TNestedItem } from 'redux/modules/competitions/type';
import { Stake } from 'redux/modules/user/type';
import { FieldSetting, GetIconName, SubheaderIconsType } from 'types';
import { iosBundleRedirect } from 'utils';
import { validateDesktopSettingsStakeValue, validateStakeValue } from 'utils/validation';

// Desktop navigation
const getLinkIconName = ({ iconName, modifiable, url }: GetIconName, operator: string) => {
  const navigationIcons: SubheaderIconsType =
    IconsConfig.SUBHEADER_ICONS[operator === POKER_STAR ? POKER_STAR : DEFAULT];

  if (!modifiable) {
    switch (url) {
      case '':
        iconName = navigationIcons.homeIcon;
        break;
      case '/':
        iconName = navigationIcons.homeIcon;
        break;
      case IN_PLAY:
        iconName = navigationIcons.inPlayIcon;
        break;
      case CASH_OUT:
        iconName = navigationIcons.cashoutIcon;
        break;
    }
  }

  return iconName;
};

const getSportIconName = (sportId: number | string) => {
  if (sportId) {
    const sportIcon = IconsConfig.SPORT_ICONS[sportId];

    return sportIcon ? sportIcon : IconsConfig.SUBHEADER_ICONS[DEFAULT][DEFAULT_SPORT_ICON];
  }
};

export const getIconName = ({ iconName, sportId, modifiable, url, page }: GetIconName, operator: string) => {
  if (iconName) {
    iconName = getLinkIconName({ iconName, modifiable, url, sportId, page }, operator);

    if (iconName !== NONE) return iconName;
  }

  return getSportIconName(sportId);
};

const getIconByPageName = (name: string, operator: string, isMobile?: boolean) => {
  const navigationIcons = IconsConfig.SUBHEADER_ICONS[operator === POKER_STAR ? POKER_STAR : DEFAULT];

  switch (name) {
    case HOME_PAGE:
      return isMobile ? navigationIcons.homeIconMobile : navigationIcons.homeIcon;
    case SPORTS_PAGE:
      return navigationIcons.sportsIcon;
    case CASH_OUT_PAGE:
      return navigationIcons.cashoutIcon;
    case BET_LIST_PAGE:
    case MY_BETS_PAGE:
      return navigationIcons.myBetsIcon;
    case SEARCH_PAGE:
      return navigationIcons.searchIcon;
    case GAMES_PAGE:
      return navigationIcons.gamesIcon;
    case ACCOUNT_PAGE:
      return navigationIcons.accountIcon;
  }
};

// Mobile navigation
export const getClassicIconName = (
  { url, iconName, sportId, page, isMobile }: GetIconName,
  operator: string,
  favoritesLength: boolean
) => {
  const classicNavigationIcons = IconsConfig.SUBHEADER_ICONS[CLASSIC_NAV_CLASSES];

  if (iconName) {
    if (iconName === DEFAULT) {
      switch (url) {
        case IN_PLAY:
          return classicNavigationIcons[IN_PLAY];
        case HOW_TO:
          return classicNavigationIcons[HOW_TO];
        case ASIAN_VIEW:
          return classicNavigationIcons.asianViewIcon;
        case FAVOURITE:
          if (favoritesLength) {
            return classicNavigationIcons.FAVOURITE_FILLED;
          }
          return classicNavigationIcons[FAVOURITE];
      }

      return getIconByPageName(page, operator, isMobile);
    } else if (iconName === NONE) {
      return classicNavigationIcons[NONE];
    } else {
      return `${operator === POKER_STAR ? classicNavigationIcons[FA_ICON] : ''} ${iconName}`;
    }
  } else if (sportId) {
    const sportIcon = IconsConfig.SPORT_ICONS[sportId];

    return `${classicNavigationIcons[SPORT_ICON]} ${
      sportIcon ? sportIcon : classicNavigationIcons[DEFAULT_SPORT_ICON]
    }`;
  } else {
    return classicNavigationIcons[SPORT_ICON];
  }
};

export const getEnvironmentRootPath = () => {
  return window.environmentConfig?.rootPath || (process.env.REACT_APP_ROOT_PATH as string);
};

export const getDefaultLinkTarget = ({
  url,
  absolutePath = false,
  isIframeEnabled,
  currentTarget,
  openInNewTab = false
}: {
  url: string;
  absolutePath?: boolean;
  isIframeEnabled: boolean;
  currentTarget?: string;
  openInNewTab?: boolean;
}) => {
  if (iosBundleRedirect) {
    return '_self';
  } else if (openInNewTab) {
    return '_blank';
  } else if (isIframeEnabled && absolutePath) {
    const currentRootPath = location.origin + getEnvironmentRootPath();
    if (url.indexOf(currentRootPath) !== 0) {
      return '_top';
    } else if (currentTarget === '_blank') {
      return '_self';
    }
  }

  return currentTarget ?? '_self';
};

export function changeLinkTargetInString({
  htmlString,
  isIframeEnabled
}: {
  htmlString: string;
  isIframeEnabled: boolean;
}): string {
  const regex = /<a([^>]*)>/g;
  if (isIframeEnabled || iosBundleRedirect) {
    const currentRootPath = location.origin + getEnvironmentRootPath();
    return htmlString
      .replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath())
      .replace(regex, function (match, attributes) {
        const hrefRegex = /href="([^"]*)"/;
        const targetRegex = /target="([^"]*)"/;

        const hrefMatch = attributes.match(hrefRegex);
        const targetMatch = attributes.match(targetRegex);

        const href = hrefMatch ? hrefMatch[1] : '';
        let target = targetMatch ? targetMatch[1] : '_self';

        if (href.indexOf(currentRootPath) !== 0) {
          target = '_top';
        } else if (target === '_blank') {
          target = '_self';
        }

        let updatedAttributes = attributes.replace(targetRegex, 'target="' + target + '"');
        if (!updatedAttributes.includes('target=')) {
          updatedAttributes = updatedAttributes + ` target="${target}"`;
        }

        return '<a' + updatedAttributes + '>';
      });
  }

  return htmlString.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? '';
}

export const filterCompetitionViewParents = (
  parents: TNestedItem[],
  competitionViewSports: string[],
  sportId: string | undefined,
  isCompetitionViewEnabled: boolean
) => {
  if (sportId && isCompetitionViewEnabled && !competitionViewSports.includes(sportId)) {
    return parents.filter(item => item.type !== NavigationTypes.COMPETITION && item.type !== NavigationTypes.COUNTRY);
  }
  return parents;
};

export const getStakeValue = (stake?: Stake | null) => {
  return stake ? String(stake.value) : '';
};

export const getStakeDefaultValue = (stake?: Stake | null) => {
  return stake ? stake.defaultValue : false;
};

export const getIconNameForMobileNavigation = (sportId: string | number, operator: string) => {
  if (operator === POKER_STAR) {
    return IconsConfig.NAVIGATION_ICONS[POKER_STAR][sportId] || IconsConfig.NAVIGATION_ICONS.DEFAULT_ICON;
  }
  return IconsConfig.SPORT_ICONS[sportId] || IconsConfig.NAVIGATION_ICONS.DEFAULT_ICON;
};

export const checkUrlLinkFor = (url: string) =>
  url.startsWith(getEnvironmentRootPath()) ? url : getEnvironmentRootPath() + url;

export const getMobileNavigationButton = ({
  operator,
  isLoggedIn,
  isGamesEnabled,
  isAsianView,
  isAsianViewFavourites
}: {
  operator: string;
  isLoggedIn: boolean;
  isGamesEnabled: boolean;
  isAsianView: boolean;
  isAsianViewFavourites: boolean;
}) => {
  if (isAsianView) {
    return [
      {
        type: TypeFields.home,
        text: 'navigation.home',
        icon: 'biab_sport-icon biab_custom-icon-home',
        url: ASIAN_BASE_URL
      },
      {
        type: TypeFields.betList,
        text: 'asianView.labels.betList',
        icon: 'av-icon av-icon-list',
        url: ASIAN_MOBILE_BET_LIST_URL
      },
      {
        type: TypeFields.cashOut,
        text: 'navigation.cashOut',
        icon: 'biab_custom-icon-cash-out',
        url: ASIAN_VIEW_CASH_OUT_URL
      },
      {
        type: TypeFields.favourites,
        text: 'mobileMenu.markets.tabs.favourites',
        icon: isLoggedIn && isAsianViewFavourites ? 'biab_fav-icons-active biab_active' : 'biab_fav-icons-inactive',
        url: ASIAN_MOBILE_FAVOURITES_URL
      }
    ];
  }

  const fields: FieldSetting = {
    home: true,
    sport: true,
    search: true,
    myBets: true,
    cashOut: isLoggedIn,
    game: isGamesEnabled,
    favourites: false,
    more: false,
    betList: true
  };

  return getToolbarButtons(operator).filter(({ type }) => fields[type]);
};

export const getDefaultStakeValidationValue = ({
  value,
  t,
  currency,
  isDesktopSettings
}: {
  value?: string | number;
  currency: TSuccessFetchCurrency;
  t: TFunction;
  isDesktopSettings?: boolean;
}) => {
  if (value) {
    const { translationKey, options } = isDesktopSettings
      ? validateDesktopSettingsStakeValue(value, currency)
      : validateStakeValue(value, currency, 'betting');

    return options ? t(translationKey, options as { N: string }) : t(translationKey);
  }
  return '';
};
