import { OPERATOR, TypeFields } from 'constants/app';
import {
  ACCOUNT_BASE_URL,
  BET_LIST_URL,
  CASH_OUT_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { TypeField } from 'types';

type ToolbarButton = {
  type: TypeField;
  text: string;
  icon: string;
  url: string;
  style?: string;
  isCashOut?: boolean;
};

export const getToolbarButtons = (operator: string): ToolbarButton[] => {
  switch (operator) {
    case OPERATOR.ORBIT:
      return [
        {
          type: TypeFields.home,
          text: 'navigation.home',
          icon: 'ion-ios-home-outline',
          url: HOME_BASE_URL
        },
        {
          type: TypeFields.sport,
          text: 'navigation.sports',
          icon: 'ion-ios-football-outline',
          url: SPORT_BASE_URL
        },
        {
          type: TypeFields.cashOut,
          text: 'navigation.cashOut',
          icon: 'fa biab_cash-out-navigation-icon',
          url: CASH_OUT_BASE_URL,
          style: 'cashOutOrbit',
          isCashOut: true
        },
        {
          type: TypeFields.betList,
          text: 'asianView.labels.betList',
          icon: 'biab_toolbar-icon-my-bets',
          url: BET_LIST_URL
        },
        {
          type: TypeFields.search,
          text: 'mobileMenu.search',
          icon: 'ion-ios-search',
          url: SEARCH_BASE_URL
        },
        {
          type: TypeFields.game,
          text: 'mobileMenu.games',
          icon: 'biab_games-icon-sm',
          url: GAMES_BASE_URL
        }
      ];
    default:
      return [
        {
          type: TypeFields.home,
          text: 'navigation.home',
          icon: 'biab_custom-icon-home',
          url: HOME_BASE_URL
        },
        {
          type: TypeFields.sport,
          text: 'navigation.sports',
          icon: 'ion-ios-football-outline',
          url: SPORT_BASE_URL
        },
        {
          type: TypeFields.cashOut,
          text: 'navigation.cashOut',
          icon: 'fa biab_cash-out-navigation-icon',
          url: CASH_OUT_BASE_URL,
          style: 'cashOut',
          isCashOut: true
        },
        {
          type: TypeFields.betList,
          text: 'asianView.labels.betList',
          icon: 'biab_toolbar-icon-my-bets',
          url: BET_LIST_URL
        },
        {
          type: TypeFields.search,
          text: 'mobileMenu.search',
          icon: 'ion-ios-search',
          url: SEARCH_BASE_URL
        },
        {
          type: TypeFields.game,
          text: 'mobileMenu.games',
          icon: 'biab_games-icon-sm',
          url: GAMES_BASE_URL
        }
      ];
  }
};
