import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MultiselectDropdown from 'components/MultiselectDropdown';
import { CookieNames } from 'constants/app';
import { BetsTypesByPeriods, BetTypes } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { BetType, MyBetsPeriod } from 'types/myBets';

const BetTypesDropdown = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MY_BETS_TYPES]);

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const { isMyBetsPage } = useAccountUrlParams();
  const { periodType, betsTypes, setBetsTypes } = useMyBetsFilters();
  const { isAsianViewSelected } = useAccountProducts();
  const { refreshData } = useMyBetsPagesNavigation();

  const disabled = isMyBetsPage && isCancellingBets;
  const betTypesOptions = (BetsTypesByPeriods[periodType as MyBetsPeriod] ?? [])
    .map(option => {
      return {
        id: option,
        label: t(`account.mybets.btns.${option.toLowerCase()}`),
        isSelected: betsTypes?.includes(option)
      };
    })
    .filter(({ id }) => !isAsianViewSelected || id !== BetTypes.Lapsed);

  const handleOnSelect = (selected: string[]) => {
    if (
      !cookies.BIAB_MY_BETS_TYPES?.length ||
      cookies.BIAB_MY_BETS_TYPES?.length !== selected.length ||
      selected.some(type => !cookies.BIAB_MY_BETS_TYPES.includes(type))
    ) {
      const newBetTypes = selected.length ? (selected as BetType[]) : BetsTypesByPeriods[periodType as MyBetsPeriod];

      setBetsTypes(newBetTypes);

      refreshData({
        betsTypes: newBetTypes
      });
    }
  };
  const getInputLabel = (activeOptions: string[]) => {
    const activeBetTypesNumber = activeOptions.length;
    const totalBetsTypesNumber = betTypesOptions.length;

    const label =
      activeBetTypesNumber === 1
        ? t(`account.mybets.btns.${activeOptions[0]}`)
        : activeBetTypesNumber < totalBetsTypesNumber
        ? `${activeBetTypesNumber} ${t('account.myBets.labels.betTypes')}`
        : t('account.myBets.labels.allBetTypes');

    return label;
  };

  return (
    <MultiselectDropdown
      disabled={disabled}
      options={betTypesOptions}
      onSelect={handleOnSelect}
      getInputLabel={getInputLabel}
      setAllWhenEmpty
    />
  );
};

export default BetTypesDropdown;
