import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewSingleEventOutrightLink from 'components/AsianViewSingleEventOutrightLink';
import CashOutButton from 'components/CashOutButton';
import { AutoCashOutStatuses, CashOutStatuses } from 'constants/app';
import { ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE } from 'constants/asianView';
import { asianViewBetslipBranding as branding, cashOut as cashOutBranding } from 'constants/branding';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getAsianViewDesktopCashOutPaginationButtons,
  getDesktopCashoutPageSize,
  getLanguage,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import {
  asianBetSlipCashOutCleanStatus,
  fetchAsianBetSlipCashOutMarkets,
  removeAsianViewBetSlipAutoCashOutMarket
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipAutoCashOutStatusByMarketId,
  getAsianBetSlipCashOutQuoteCurrencyByMarketId,
  getAsianBetSlipCashOutStatusByMarketId,
  getAsianBetSlipPendingCashOutByMarketId,
  getAsianBetSlipQuoteLiabilityByMarketId
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { asianViewCashOutCleanStatus, removeAsianViewAutoCashOutMarket } from 'redux/modules/asianViewCashOut';
import {
  getAsianViewAutoCashOutStatusByMarketId,
  getAsianViewCashOutQuoteCurrencyByMarketId,
  getAsianViewCashOutStatusByMarketId,
  getAsianViewPendingCashOutByMarketId,
  getAsianViewQuoteLiabilityByMarketId
} from 'redux/modules/asianViewCashOut/selectors';
import { IMarket } from 'types/markets';

import BetsPlacedCounter from '../../BetsPlacedCounter';
import AsianViewCashOutProgressBar from '../AsianViewCashOutProgressBar';
import BetSlipCashOutMessage from '../BetSlipCashOutMessage';

import styles from './styles.module.scss';

interface BetSlipCashOutProps {
  market: IMarket;
  isMobileCashOutPage?: boolean;
}

export const BetSlipCashOutItem = ({ market, isMobileCashOutPage }: BetSlipCashOutProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currencySelector = isMobileCashOutPage
    ? getAsianViewCashOutQuoteCurrencyByMarketId
    : getAsianBetSlipCashOutQuoteCurrencyByMarketId;
  const liabilitySelector = isMobileCashOutPage
    ? getAsianViewQuoteLiabilityByMarketId
    : getAsianBetSlipQuoteLiabilityByMarketId;
  const isPendingCashOutSelector = isMobileCashOutPage
    ? getAsianViewPendingCashOutByMarketId
    : getAsianBetSlipPendingCashOutByMarketId;
  const statusSelector = isMobileCashOutPage
    ? getAsianViewCashOutStatusByMarketId
    : getAsianBetSlipCashOutStatusByMarketId;
  const autoCashOutStatusSelector = isMobileCashOutPage
    ? getAsianViewAutoCashOutStatusByMarketId
    : getAsianBetSlipAutoCashOutStatusByMarketId;
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const asianViewCashOutPaginationButtons = useSelector(getAsianViewDesktopCashOutPaginationButtons);

  const translation = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const currency = useSelector(currencySelector(market.marketId));
  const liability = useSelector(liabilitySelector(market.marketId));
  const isPendingCashOut = useSelector(isPendingCashOutSelector(market.marketId));
  const status = useSelector(statusSelector(market.marketId));
  const autoCashOutStatus = useSelector(autoCashOutStatusSelector(market.marketId));
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const { formattedAmount: liabilityValue } = useFormatCurrency(liability, currency, {
    noRounding: true,
    ignoreFlexibleDisplayFormat: true
  });

  const isAutoCashOutCompleted = autoCashOutStatus === AutoCashOutStatuses.COMPLETED;
  const showCashOutStatusMessage = (status && status !== CashOutStatuses.PENDING) || isAutoCashOutCompleted;

  const handleCleanCashOutStatus = () => {
    if (isMobileCashOutPage) {
      dispatch(asianViewCashOutCleanStatus(market.marketId));
    } else {
      dispatch(asianBetSlipCashOutCleanStatus(market.marketId));

      const asianViewBetSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);

      dispatch(
        fetchAsianBetSlipCashOutMarkets({
          page: asianViewBetSlipCashOutPage ? Number(asianViewBetSlipCashOutPage) : 0,
          size: desktopCashOutPageSize,
          resetPrev: !asianViewBetSlipCashOutPage,
          changePage: asianViewCashOutPaginationButtons
            ? (page: number) => {
                searchParams.set(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, String(page));
                setSearchParams(searchParams);
              }
            : undefined,
          isPaginationEnabled: asianViewCashOutPaginationButtons
        })
      );
    }
    if (isAutoCashOutCompleted) {
      if (isMobileCashOutPage) {
        dispatch(removeAsianViewAutoCashOutMarket(market.marketId));
      } else {
        dispatch(removeAsianViewBetSlipAutoCashOutMarket(market.marketId));
      }
    }
  };

  return (
    <div
      className={classNames(styles.cashOutBetslip__card, branding.CASH_OUT_MARKET, cashOutBranding.CASH_OUT_BTN, {
        [styles.coMobPage__card]: isMobileCashOutPage,
        [styles.coMobPage__card__landscape]: isMobileCashOutPage && isLandscapeView
      })}
      data-market-id={market.marketId}
    >
      <div
        className={classNames(styles.cashOutBetslip__nameWrapper, {
          [styles.coMobPage__name]: isMobileCashOutPage
        })}
      >
        {market.inPlay && (
          <p
            className={classNames('biab_open-bet-live', styles.cashOutBetslip__live, branding.LIVE_LABEL, {
              [styles.cashOutBetslip__live__coMobPage]: isMobileCashOutPage
            })}
          >
            {t('asianView.labels.timeFilter.live')}
          </p>
        )}
        <AsianViewSingleEventOutrightLink
          isOutrightLink={!!market.outrightMarket}
          sportId={market.eventType.id}
          competitionId={market.competition.id}
          marketId={market.marketId}
          eventId={market.event.id}
          competitionName={market.competition.competitionNameTranslations?.[translation]}
          marketName={market.marketNameTranslations?.[language]}
          eventName={market.event.nameTranslations?.[language]}
          className={classNames({
            [styles.cashOutBetslip__card__name]: !isMobileCashOutPage,
            [styles.coMobPage__linkName]: isMobileCashOutPage
          })}
        />
      </div>

      <div
        className={classNames(styles.cashOutBetslip__divider, branding.BET_SEPARATOR, {
          [styles.coMobPage__divider]: isMobileCashOutPage
        })}
      />
      {!market.outrightMarket && (
        <p
          className={classNames({
            [styles.cashOutBetslip__type]: !isMobileCashOutPage,
            [styles.coMobPage__marketName]: isMobileCashOutPage
          })}
        >
          {market.marketNameTranslations?.[language] || market.marketName}
        </p>
      )}
      <BetsPlacedCounter
        marketId={market.marketId}
        containerClassName={classNames({
          [styles.cashOutBetslip__placed]: !isMobileCashOutPage,
          [styles.coMobPage__placed]: isMobileCashOutPage
        })}
      />
      <div
        className={classNames(styles.cashOutBetslip__doubleDivider, branding.BET_SEPARATOR, {
          [styles.coMobPage__dashDivider]: isMobileCashOutPage
        })}
      />
      <div
        className={classNames(styles.cashOutBetslip__liabilityContainer, {
          [styles.coMobPage__liabCont]: isMobileCashOutPage
        })}
      >
        <p
          className={classNames(styles.cashOutBetslip__liabilityText, {
            [styles.coMobPage__liabText]: isMobileCashOutPage
          })}
        >
          {t('asianView.labels.cashOut.liability')}:
        </p>
        <p
          className={classNames(styles.cashOutBetslip__liabilityValue, {
            [styles.coMobPage__LiabValue]: isMobileCashOutPage
          })}
        >
          {liability ? liabilityValue : '--'}
        </p>
      </div>
      {isPendingCashOut && (
        <AsianViewCashOutProgressBar
          className={classNames({ [styles.cashOutBetslip__progressBar]: isMobileCashOutPage })}
        />
      )}
      {!isPendingCashOut && showCashOutStatusMessage && (
        <BetSlipCashOutMessage
          status={isAutoCashOutCompleted ? CashOutStatuses.SUCCESS : status}
          onClose={handleCleanCashOutStatus}
        />
      )}
      {!isPendingCashOut && !showCashOutStatusMessage && (
        <CashOutButton
          marketId={market.marketId}
          partialCashOutEnabled={market.partialCashOutEnabled}
          isAsianBetSlip={!isMobileCashOutPage}
          isAVMobileCashOutPage={isMobileCashOutPage}
          isAsianView
          ignoreFlexibleDisplayFormat
        />
      )}
    </div>
  );
};

export default BetSlipCashOutItem;
