import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchAutoCashOutMarkets, fetchCashOutMarkets, fetchCashOutQuotes } from 'redux/modules/cashOut';
import {
  getCashOutContent,
  getFetchCashOutLoading,
  getFetchCashOutMarketsLoading
} from 'redux/modules/cashOut/selectors';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

interface CashOutIntervalRequestsInjectionProps {
  observedState: unknown[];
}

const CashOutIntervalRequestsInjection = ({ observedState }: CashOutIntervalRequestsInjectionProps) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const cashOutMarketsLoading = useSelector(getFetchCashOutMarketsLoading);
  const fetchCashOutLoading = useSelector(getFetchCashOutLoading);
  const content = useSelector(getCashOutContent);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const { marketIds } = useMemo(() => {
    if (content) {
      return content.reduce(
        (acc: { marketIds: string[] }, item) => {
          return { marketIds: [...acc.marketIds, item.marketId] };
        },
        { marketIds: [] }
      );
    }

    return { marketIds: [] };
  }, [content]);

  const isSubscriptionAvailable = isConnectedGeneralWebSocket && !!subscribeGeneralWebSocketMessages && isLoggedIn;
  const isAvailableCashOutQuoteSubscription =
    isSubscriptionAvailable && cashOutQuoteWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isAvailableAutoCashOutSubscription =
    isSubscriptionAvailable && autoCashOutWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isAvailableCashOutQuoteInterval =
    isLoggedIn && arePropertiesLoaded && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isAvailableAutoCashOutInterval =
    isLoggedIn && arePropertiesLoaded && (!generalWsEnabled || !autoCashOutWsEnabled);

  const cashOutMarketsLoadingRef = useRef(cashOutMarketsLoading);
  const fetchCashOutLoadingRef = useRef(fetchCashOutLoading);
  const generalWsDataRef = useRef<{
    isAvailableCashOutQuoteSubscription: boolean;
    isAvailableAutoCashOutSubscription: boolean;
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    isAvailableAutoCashOutSubscription: isAvailableAutoCashOutSubscription,
    isAvailableCashOutQuoteSubscription: isAvailableCashOutQuoteSubscription,
    subscribeGeneralWebSocketMessages: null
  });

  fetchCashOutLoadingRef.current = fetchCashOutLoading;
  cashOutMarketsLoadingRef.current = cashOutMarketsLoading;
  generalWsDataRef.current = {
    isAvailableAutoCashOutSubscription: isAvailableAutoCashOutSubscription,
    isAvailableCashOutQuoteSubscription: isAvailableCashOutQuoteSubscription,
    subscribeGeneralWebSocketMessages
  };

  const throttledFetchCashOutMarkets = useRef(
    throttle(() => {
      dispatch(
        fetchCashOutMarkets({
          page: 0,
          size: 1000,
          limitPageSize: false
        })
      );
    }, 3000)
  );

  useEffect(() => {
    throttledFetchCashOutMarkets.current();
  }, [observedState]);

  useEffect(() => {
    if (marketIds.length && isAvailableCashOutQuoteInterval) {
      dispatch(fetchCashOutQuotes({ ids: marketIds, firstLoading: true }));

      const cashOutQuotesInterval = setInterval(() => {
        if (!fetchCashOutLoadingRef.current) {
          dispatch(fetchCashOutQuotes({ ids: marketIds }));
        }
      }, cashOutGetQuotesInterval);

      return () => clearInterval(cashOutQuotesInterval);
    }
  }, [marketIds, cashOutGetQuotesInterval, isAvailableCashOutQuoteInterval, dispatch]);

  useEffect(() => {
    if (autoCashOutEnabled && isAvailableAutoCashOutInterval && marketIds.length) {
      const getAutoCashOutMarkets = () => {
        dispatch(fetchAutoCashOutMarkets(marketIds));
      };

      getAutoCashOutMarkets();

      const interval = setInterval(getAutoCashOutMarkets, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [marketIds, isLoggedIn, autoCashOutEnabled, isAvailableAutoCashOutInterval, dispatch]);

  useEffect(() => {
    if (isAvailableCashOutQuoteSubscription && marketIds.length) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuote]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAvailableCashOutQuoteSubscription, marketIds, subscribeGeneralWebSocketMessages]);

  useEffect(() => {
    if (isAvailableAutoCashOutSubscription && marketIds.length) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOut]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAvailableAutoCashOutSubscription, marketIds, subscribeGeneralWebSocketMessages]);

  useEffect(() => {
    return () => {
      const {
        subscribeGeneralWebSocketMessages: subscribeFunc,
        isAvailableCashOutQuoteSubscription: isAvailableCashOutQuote,
        isAvailableAutoCashOutSubscription: isAvailableAutoCashOut
      } = generalWsDataRef.current;

      if (isAvailableCashOutQuote && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.cashOutQuote]: { subscribe: false }
        });
      }

      if (isAvailableAutoCashOut && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOut]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
};

export default CashOutIntervalRequestsInjection;
