import { TEventUpdatedData, TFailureActionPayload } from 'types';
import { AsianViewMarketPrices, IMarketPrices } from 'types/markets';

export type TMarketPrices = {
  [marketId: string]: IMarketPrices | AsianViewMarketPrices;
};

export const MarketsPricesSocketParamsSections = {
  AsianViewMiddleSection: 'AsianViewMiddleSection',
  AsianViewDesktopBetSlip: 'AsianViewDesktopBetSlip',
  AsianViewEventCashOut: 'AsianViewEventCashOut',
  SportsMiddleSection: 'SportsMiddleSection',
  SportsDesktopBetSlip: 'SportsDesktopBetSlip',
  SportsDesktopSelectedBets: 'SportsDesktopSelectedBets',
  SportsNextRaces: 'SportsNextRaces',
  SportsPopularMarkets: 'SportsPopularMarkets',
  SportsBetList: 'SportsBetList',
  AsianViewMiddleSectionHDPEventTabs: 'AsianViewMiddleSectionHDPEventTabs'
} as const;

export type MarketsPricesSocketParamsSectionsKeys =
  typeof MarketsPricesSocketParamsSections[keyof typeof MarketsPricesSocketParamsSections];

export type TMarketsPrices = {
  marketsPrices: TMarketPrices;
  inPlayMarketsIds: string[];
  closedMarketsIds: string[];
  eventsUpdatedData: {
    [eventId: string]: TEventUpdatedData;
  };
  suspendedOrClosedMarketPrices: IMarketPrices | null;
  error: TFailureActionPayload;
  eventsUpdatedDataLoading: boolean;
  stringifiedEventsUpdatedData: string;
  marketsPricesSocketParams: Record<
    MarketsPricesSocketParamsSectionsKeys,
    {
      [marketId: string]: { eventId: string; isVisible: boolean };
    }
  >;
};
