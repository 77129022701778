import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Modal from 'components/Modal';
import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import { ResponsibleBettingButtonTypes } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface ResponsibleBettingModalTemplateProps {
  title: string;
  open: boolean;
  onContinue: () => void;
  onLogout: () => void;
  onClose: () => void;
  children: ReactNode;
}

const ResponsibleBettingModalTemplate = ({
  title,
  open,
  children,
  onContinue,
  onLogout,
  onClose
}: ResponsibleBettingModalTemplateProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return (
    <Modal
      open={open}
      title={title}
      customClassNames={{
        dialog: classNames(styles.rbModalTemplate__dialog, { [styles.rbModalTemplate__dialog__mobile]: isMobile }),
        content: classNames(styles.rbModalTemplate__content, { [styles.rbModalTemplate__content__mobile]: isMobile }),
        header: styles.rbModalTemplate__header,
        title: styles.rbModalTemplate__title,
        body: styles.rbModalTemplate__body
      }}
      onClose={onClose}
      clickableBackground
    >
      <div>
        <div className={styles.rbModalTemplate__alert}>{children}</div>
        <p
          className={styles.rbModalTemplate__confirmation}
          dangerouslySetInnerHTML={{ __html: unescape(t('responsibleBetting.messages.takeBreak')) }}
        />
        <div className={styles.rbModalTemplate__actionsContainer}>
          <ResponsibleBettingBtn
            label={t('responsibleBetting.labels.noContinue')}
            onClick={onContinue}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
            customClass={styles.rbModalTemplate__action}
          />

          <ResponsibleBettingBtn
            label={t('responsibleBetting.labels.yesLogOut')}
            onClick={onLogout}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Primary}
            customClass={styles.rbModalTemplate__action}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ResponsibleBettingModalTemplate;
