import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { mobileIcons } from 'constants/branding';
import { IN_NOT_PLAY, IN_PLAY } from 'constants/tooltip';
import useDevice from 'hooks/useDevice';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface InPlayStatusProps {
  isInPlay: boolean;
  showLabel?: boolean;
  className?: string;
  showIcon?: boolean;
  labelClassName?: string;
}

const InPlayStatus = ({
  isInPlay,
  showLabel = false,
  className,
  showIcon = false,
  labelClassName = ''
}: InPlayStatusProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const title = t(isInPlay ? 'market.inPlay' : 'market.goingInPlay');
  const { translationKey, isEnabled } = useTooltip(isInPlay ? IN_PLAY : IN_NOT_PLAY);

  return (
    <div className={styles.inPlay}>
      <span
        data-tooltip-id={'tooltip'}
        data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
        className={classNames('biab_in-play-icon', styles.inPlay__status, {
          [styles.inPlay__status__showLabel]: showLabel,
          [className ?? '']: className,
          'biab_event-inplay': !className,
          biab_active: isInPlay,
          'cursor-help': isEnabled,
          [mobileIcons.IN_PLAY_SQUARE_ICON]: isMobile
        })}
        title={title}
      >
        <i className="fa2 fa2-inplay" aria-hidden={isInPlay} />
      </span>

      {showLabel && (
        <div
          data-tooltip-id={'tooltip'}
          data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
          className={classNames(
            styles.inPlay__label,
            {
              'biab_in-play-active': isInPlay,
              'biab_in-play-disabled': !isInPlay,
              'cursor-help': isEnabled
            },
            labelClassName
          )}
        >
          {title}
          {showIcon && (
            <i
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
              className={classNames('fa2 fa2-info-circle tooltip-icon', {
                'cursor-help': isEnabled
              })}
            >
              <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
              <span className="path2" />
            </i>
          )}
        </div>
      )}
    </div>
  );
};

export default InPlayStatus;
