import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { unescape } from 'lodash';

import { ACCOUNT_BASE_URL, GAMES_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { MyBetsPeriods, SETTLED_BET_LIST_FETCH_INTERVAL } from 'constants/myBets';
import ListLoadingSkeleton from 'pages/mobile/MobileBetListPage/components/ListLoadingSkeleton/ListLoadingSkeleton';
import { fetchSettledBetList } from 'redux/modules/betList';
import { getIsBetListLoading, getSettledBetList } from 'redux/modules/betList/selectors';

import NoBets from '../NoBets/NoBets';
import SettledBet from '../SettledBet/SettledBet';

import styles from './SettledTab.module.scss';

const SettledTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const settledList = useSelector(getSettledBetList);
  const isBetListLoading = useSelector(getIsBetListLoading);
  const exchangePage = (isGamesPage && GAMES_BASE_URL) || '';
  const myBetsLink = `${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${MyBetsPeriods.Settled.toLowerCase()}/1`;
  const settledBetListInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    dispatch(fetchSettledBetList());

    settledBetListInterval.current = setInterval(() => {
      dispatch(fetchSettledBetList(true));
    }, SETTLED_BET_LIST_FETCH_INTERVAL);

    return () => {
      if (settledBetListInterval.current) {
        clearInterval(settledBetListInterval.current);
      }
    };
  }, []);

  if (!isBetListLoading && settledList.length === 0) {
    return <NoBets />;
  }

  return (
    <div className={styles.list}>
      {isBetListLoading && <ListLoadingSkeleton />}
      {!isBetListLoading && (
        <>
          <div className={styles.viewAll__top}>
            <Link className={styles.viewAll__top__link} to={myBetsLink}>
              {t('account.betList.labels.viewAllSettledBets')}
            </Link>
          </div>
          {settledList.map(bet => (
            <SettledBet key={bet.offerId} bet={bet} />
          ))}
          <div className={styles.viewAll__bottom}>
            <p
              className={styles.viewAll__bottom__text}
              dangerouslySetInnerHTML={{ __html: unescape(t('account.betList.labels.viewAllSettledBets.message')) }}
            />
            <Link className={styles.viewAll__bottom__link} to={myBetsLink}>
              {t('account.betList.labels.viewAllSettledBets')}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SettledTab;
