import ScoreWidget from 'components/ScoreWidget/ScoreWidget';
import { IMarket, TMarketSportInfo } from 'types/markets';

interface InPlayCellProps {
  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;
  /**
   * InPlay status
   */
  isInPlay: boolean;

  /**
   * Information about sport for markets (id, name, etc.)
   */
  sportInfo: TMarketSportInfo | null;
}

const InPlayCell = ({ isInPlay, market, sportInfo }: InPlayCellProps) => {
  return (
    <ScoreWidget
      isInPlay={isInPlay}
      sportId={sportInfo?.id}
      eventId={market.event.id}
      marketStartTime={market.marketStartTime}
    />
  );
};

export default InPlayCell;
