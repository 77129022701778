import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PromotableTooltip from 'components/PromotableTooltip';
import { MOBILE_LIABILITY } from 'constants/automation';
import { BETSLIP_LIABILITY } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from './styles.module.scss';

interface LiabilityProps {
  isLandscape: boolean;
  isConfirmationStep: boolean;
  liability?: number | string;
}

function Liability({ isLandscape, isConfirmationStep, liability }: LiabilityProps) {
  const { t } = useTranslation();

  const { noFormattedAmount: formattedLiability } = useFormatCurrency(liability || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  const renderContent = () => {
    const label = (
      <>
        {t('betslip.labels.liability')}: <span className={styles.liability__value}>{formattedLiability}</span>
      </>
    );

    if (isConfirmationStep) {
      return (
        <PromotableTooltip className={styles.liability__tooltipContainer} tooltipKey={BETSLIP_LIABILITY}>
          {label}
        </PromotableTooltip>
      );
    }

    return label;
  };

  return (
    <div
      className={classNames(styles.liability, {
        [styles.liability__landscape]: isLandscape
      })}
      data-auto={MOBILE_LIABILITY}
    >
      {renderContent()}
    </div>
  );
}

export default Liability;
