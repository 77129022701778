import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SportIds } from 'constants/app';
import { AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import { getIsAsianViewEnabled, getIsExchangeSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';

const useLogOut = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);

  const { isAsianPage } = useMemo(() => ({ isAsianPage: pathname.includes(ASIAN_BASE_URL) }), [pathname]);

  const redirectUrl = useMemo(() => {
    if (!asianViewEnabled && !isExchangeSportsEnabled) {
      return GAMES_BASE_URL;
    }

    return isAsianPage && asianViewEnabled
      ? `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`
      : HOME_BASE_URL;
  }, [asianViewEnabled, isAsianPage, isExchangeSportsEnabled]);

  const logout = () => {
    dispatch(logoutUser(redirectUrl));
  };

  return { logout };
};

export default useLogOut;
