import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MatchStatuses } from 'constants/app';
import { MINUTE_SYMBOL, SCORE_SEPARATOR } from 'constants/inPlay';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataAsianViewElapsedRegularTime,
  getEventsUpdatedDataElapsedAddedTime,
  getEventsUpdatedDataInPlayMatchStatus,
  getHomeScoreByEventId,
  getMarketPricesAddedRegularTime,
  getMarketPricesAsianViewElapsedRegularTime,
  getMarketPricesAwayScore,
  getMarketPricesHomeScore,
  getMarketPricesMatchStatus
} from 'redux/modules/marketsPrices/selectors';
import { getSoccerAddedTime } from 'utils/football';

import styles from './styles.module.scss';

interface LiveScoreProps {
  hideTime?: boolean;
  marketId: string;
  isEventUpdatesData?: boolean;
  eventId?: string;
  highlightGoal?: boolean;
  periodLabelClassName?: string;
}

const LiveScore = ({
  hideTime = false,
  marketId,
  isEventUpdatesData = false,
  eventId,
  highlightGoal = false,
  periodLabelClassName = ''
}: LiveScoreProps) => {
  const { t } = useTranslation();

  const isEventSelector = isEventUpdatesData && eventId;

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const regularTime = useSelector(
    isEventSelector
      ? getEventsUpdatedDataAsianViewElapsedRegularTime(eventId)
      : getMarketPricesAsianViewElapsedRegularTime(marketId)
  );
  const homeScore = useSelector(isEventSelector ? getHomeScoreByEventId(eventId) : getMarketPricesHomeScore(marketId));
  const awayScore = useSelector(isEventSelector ? getAwayScoreByEventId(eventId) : getMarketPricesAwayScore(marketId));
  const matchStatus = useSelector(
    isEventSelector ? getEventsUpdatedDataInPlayMatchStatus(eventId) : getMarketPricesMatchStatus(marketId)
  );
  const elapsedAddedTime = useSelector(
    isEventSelector ? getEventsUpdatedDataElapsedAddedTime(eventId) : getMarketPricesAddedRegularTime(marketId)
  );

  const { time, addedTime } = getSoccerAddedTime(regularTime, elapsedAddedTime);

  const [isHomeScoreChanged, setIsHomeScoreChanged] = useState(false);
  const [isAwayScoreChanged, setIsAwayScoreChanged] = useState(false);

  const prevHomeScore = useRef<undefined | string>(homeScore);
  const prevAwayScore = useRef<undefined | string>(awayScore);

  const resetScoreChanged = () => {
    setIsHomeScoreChanged(false);
    setIsAwayScoreChanged(false);
  };

  const isHalfTime = matchStatus === MatchStatuses.HALF_TIME;
  const isFinished = matchStatus === MatchStatuses.END;
  const isFirstHalf =
    isEventUpdatesData && eventId ? matchStatus === MatchStatuses.KICK_OFF : matchStatus === MatchStatuses.FIRST_HALF;
  const isSecondHalf =
    isEventUpdatesData && eventId
      ? matchStatus === MatchStatuses.SECOND_HALF_KICK_OFF
      : matchStatus === MatchStatuses.SECOND_HALF;
  const isScoreChanged = isHomeScoreChanged || isAwayScoreChanged;

  useEffect(() => {
    if (highlightGoal && homeScore !== undefined && Number(homeScore) > 0 && prevHomeScore.current !== homeScore) {
      prevHomeScore.current = homeScore;
      setIsHomeScoreChanged(true);
    }
  }, [homeScore, highlightGoal]);

  useEffect(() => {
    if (highlightGoal && awayScore !== undefined && Number(awayScore) > 0 && prevAwayScore.current !== awayScore) {
      prevAwayScore.current = awayScore;
      setIsAwayScoreChanged(true);
    }
  }, [awayScore, highlightGoal]);

  return (
    <div className={classNames(styles.liveScore, { [styles.liveScore__mobile]: isMobileAsianView })}>
      {highlightGoal && isScoreChanged && (
        <p className={classNames(styles.score__goalLabel, styles.highlightedGoal)} onAnimationEnd={resetScoreChanged}>
          {t('asianView.labels.goal')}
        </p>
      )}
      <p
        className={classNames(styles.score, {
          [styles.score__mobile]: isMobileAsianView,
          [styles.score__changed]: highlightGoal && isScoreChanged && isMobileAsianView
        })}
      >
        <span
          className={classNames({
            [styles.highlightedGoal]: highlightGoal && isHomeScoreChanged
          })}
        >
          {homeScore}
        </span>
        <span>{SCORE_SEPARATOR}</span>
        <span
          className={classNames({
            [styles.highlightedGoal]: highlightGoal && isAwayScoreChanged
          })}
        >
          {awayScore}
        </span>
      </p>
      {!hideTime && (!highlightGoal || !isScoreChanged) && (
        <>
          {isHalfTime && (
            <p className={classNames(styles.label, styles.periodLabel, periodLabelClassName)}>
              {t('asianView.labels.inPlayScore.HT')}
            </p>
          )}
          {isFinished && (
            <p className={classNames(styles.label, styles.periodLabel, periodLabelClassName)}>
              {t('asianView.labels.inPlayScore.END')}
            </p>
          )}
          {(isFirstHalf || isSecondHalf) && (
            <div className={classNames({ [styles.timeContainer__mobile]: isMobileAsianView })}>
              <p className={styles.label}>{t(`asianView.labels.inPlayScore.${isFirstHalf ? '1H' : '2H'}`)}</p>
              <p className={styles.label}>
                {time}
                {MINUTE_SYMBOL}
                {addedTime ? `+${addedTime}${MINUTE_SYMBOL}` : ''}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LiveScore;
