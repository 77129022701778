import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewInPlayTime from 'components/AsianViewInPlayTime';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { IMarket } from 'types/markets';

import Content from '../Content';

import styles from './styles.module.scss';

type DesktopCashOutListItemProps = {
  markets: IMarket[];
  id: string;
};

const DesktopCashOutListItem = ({ markets, id }: DesktopCashOutListItemProps) => {
  const { t } = useTranslation();

  const language = useSelector(getLanguage);

  const market = markets[0];
  const marketId = market.marketId;
  const eventId = market.event.id;

  // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
  // const isDesktopAsianViewCashOutRunnersOpen = useSelector(
  //   getIsAsianViewCashOutDesktopRunnersOpenedById(
  //     `${market.marketId}__${market.runners[0].selectionId ?? ''}__${market.runners[0].handicap ?? 0}`
  //   )
  // );

  return (
    <>
      <div
        className={classNames(styles.competitionList, {
          [styles.competitionList__inPlay]: !!market?.inPlay
        })}
      >
        <div className={styles.competitionList__firstSection}>
          {id === 'outrights' ? (
            <p className={styles.competitionList__outrights}>{t('asianView.labels.marketLinks.outright')}</p>
          ) : (
            <>
              <AsianViewInPlayCell
                marketId={marketId}
                marketStartTime={market?.marketStartTime}
                containerClassName={styles.competitionList__inPlayCell}
                hideIcon
                hideTime
                // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
                // eventId={market.event.id}
                // isEventUpdatesData
              />
              <AsianViewInPlayTime
                marketId={marketId}
                // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
                // eventId={market.event.id}
                // isEventUpdatesData
              />
              <AsianViewFavouriteStar
                favourite={{
                  entryName: market?.event.nameTranslations![language],
                  entryId: eventId,
                  entryType: FAVORITES_TYPES.event,
                  sportId: market?.eventType.id
                }}
              />
              <p
                className={styles.competitionList__name}
                data-tooltip-id="tooltip"
                data-tooltip-html={market?.event.nameTranslations![language]}
              >
                {market?.event.nameTranslations![language]}
              </p>
            </>
          )}
        </div>

        <div className={styles.competitionList__statistics}>
          {market?.event.videoStreamingEnabled && (
            <VideoStream
              hideLabel
              visible={market?.event.videoStreamingEnabled}
              isInHeader
              eventId={market?.event.id}
            />
          )}
          {market?.event.matchStatEnabled && (
            <AsianViewMatchStatistics matchStatEnabled={market?.event.matchStatEnabled} eventId={market?.event.id} />
          )}
        </div>
      </div>

      <Content
        markets={markets}
        // TODO Don't delete, uncomment once we always have matchStatus in event-updates websockets (GeneralWebSocketSubscriptionTypes.eventInfoUpdates - BIAB-9163)
        // isEnabledMarketPrices={isDesktopAsianViewCashOutRunnersOpen}
      />
    </>
  );
};

export default DesktopCashOutListItem;
