import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { BettingTypes } from 'constants/markets';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { getMobileBettingPLSorting } from 'redux/modules/myBets/selectors';
import { SortByFields, TPLContentItem, TSortByType } from 'redux/modules/myBets/type';

import styles from './styles.module.scss';

interface MobileDescriptionProps {
  item: TPLContentItem;
}
const MobileDescription = ({ item }: MobileDescriptionProps) => {
  const { t } = useTranslation();
  const mobileBettingPlSorting = useSelector(getMobileBettingPLSorting);

  const { date: parsedSettledDate } = useParseMyBetsDates({
    dateToParse: item.settledDate,
    yearHyphen: false,
    divider: ','
  });
  const { date: startDate } = useParseMyBetsDates({
    dateToParse: item.startTime,
    yearHyphen: false,
    divider: ','
  });

  const sortingOptions = [SortByFields.SETTLED_DATE, SortByFields.START_DATE] as TSortByType[];
  const currentSorting =
    mobileBettingPlSorting && sortingOptions.includes(mobileBettingPlSorting.type)
      ? mobileBettingPlSorting.type
      : SortByFields.SETTLED_DATE;

  return (
    <div className={styles.plMobileCell}>
      <span className={styles.eventName}>{item.mainEventName || item.eventName}</span>
      <span className={classNames(styles.marketName, 'biab_market-name')}>
        {item.betType === BettingTypes.GAME
          ? `${t('games.gameId')}: ${item.marketId}`
          : (!item.raceName && item.marketNameWithParents) || item.marketName}
      </span>

      {currentSorting === SortByFields.START_DATE && (
        <span className={styles.startTime}>
          <span className={styles.timeLabel}>{t('account.profitandloss.labels.start')}:&nbsp;</span>
          {startDate}
        </span>
      )}
      {currentSorting === SortByFields.SETTLED_DATE && (
        <span className={styles.settledTime}>
          <span className={styles.timeLabel}>{t('account.profitandloss.labels.settled')}:&nbsp;</span>
          {parsedSettledDate}
        </span>
      )}
    </div>
  );
};

export default MobileDescription;
