import { isArray } from 'lodash';

import { BetsStatusesTypes } from 'constants/app';
import { InlinePlacementActions } from 'constants/inlinePlacement';
import { TSelectedBetsByMarket } from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TInlineSelectedBetsByPageBlock } from 'redux/modules/inlinePlacement/type';

import { TNewWhatValue } from './type';

export const mapperToWhatIf = (currentBets: TCurrentBet[]) => {
  return (isArray(currentBets) ? currentBets : [])
    .filter(i => i.size && i.size != 0)
    .reduce((acc: Record<string, any>, item) => {
      return {
        ...acc,
        [item.marketId]: acc[item.marketId] ? [...acc[item.marketId], item] : [item]
      };
    }, {});
};

export const mapperSelectedBetsToWhatIfValue = (selectedBets: TSelectedBetsByMarket, marketId: string) => {
  const bets: TNewWhatValue[] = [];

  if (Object.keys(selectedBets).length && selectedBets[marketId]) {
    Object.values(selectedBets[marketId]?.bets ?? {})?.forEach(bet => {
      Object.values(bet).forEach(currentBet => {
        if (
          currentBet.size &&
          currentBet.price &&
          parseFloat(currentBet.size.toString()) &&
          parseFloat(currentBet.price.toString())
        ) {
          bets.push({
            marketId,
            type: currentBet.type,
            price: currentBet.price,
            averagePrice: currentBet.price ? +currentBet.price : 0,
            sizeMatched: currentBet.size ? +currentBet.size : 0,
            size: currentBet.size ? +currentBet.size : 0,
            side: currentBet.type,
            marketType: currentBet.marketType!,
            bettingType: currentBet.bettingType!,
            selectionId: currentBet.selectionId,
            handicap: currentBet.handicap ?? 0,
            numberOfWinners: currentBet.numberOfWinners,
            currency: currentBet.currency
          });
        }
      });
    });
  }

  return bets;
};

export const mapperInlinePlacementToWhatIfValue = (
  inlinePlacement: TInlineSelectedBetsByPageBlock,
  marketId: string
) => {
  const bets: TNewWhatValue[] = [];

  if (Object.keys(inlinePlacement).length) {
    Object.values(inlinePlacement).forEach(item => {
      const selectedBets = Object.values(item[marketId]?.bets ?? {});
      const activeSelectedBet = selectedBets.find(
        bet => bet.action === InlinePlacementActions.SELECT || bet.action === InlinePlacementActions.EDIT
      );
      const bet =
        activeSelectedBet ||
        selectedBets.find(betData => !!Number(betData.size)) ||
        selectedBets[selectedBets.length - 1];

      const offers = bet?.offers;
      const price = +(bet?.price || 0);
      const size = +(bet?.size || 0);
      const isProgress = bet?.action === InlinePlacementActions.PROGRESS;
      const isCancelled = bet?.action === InlinePlacementActions.CANCELLED;
      const offer = bet?.placedBet?.offerId && offers?.[bet?.placedBet?.offerId];

      if (!isProgress && !isCancelled) {
        if (offer) {
          if ((offer.price != price || offer.sizePlaced !== size) && offer.offerState !== BetsStatusesTypes.CANCELLED) {
            bets.push({
              marketId,
              side: bet.type,
              type: bet.type,
              price: price,
              sizeMatched: bet.size ? +bet.size : 0,
              averagePrice: bet.price ? +bet.price : 0,
              size: size,
              marketType: bet.marketType!,
              bettingType: bet.bettingType!,
              selectionId: bet.selectionId,
              handicap: bet.handicap ?? 0,
              numberOfWinners: bet.numberOfWinners,
              currency: bet.currency
            });
          }
        } else if (bet && size && price) {
          bets.push({
            marketId,
            side: bet.type,
            type: bet.type,
            price: price,
            sizeMatched: bet.size ? +bet.size : 0,
            averagePrice: bet.price ? +bet.price : 0,
            size: size,
            marketType: bet.marketType!,
            bettingType: bet.bettingType!,
            selectionId: bet.selectionId,
            handicap: bet.handicap ?? 0,
            numberOfWinners: bet.numberOfWinners,
            currency: bet.currency
          });
        }
      }
    });
  }

  return bets;
};

export const mapperUnMatchToWhatIfValue = (
  unMatchBets: TCurrentBet[],
  marketId: string,
  numberOfWinners: number
): TNewWhatValue[] => {
  return unMatchBets
    .filter(
      bet =>
        bet.price !== bet.changedPrice ||
        (bet.changedSize && bet.sizePlaced !== +bet.changedSize && bet.sizeRemaining !== +bet.changedSize)
    )
    .map(bet => ({
      marketId,
      side: bet.side,
      type: bet.side,
      price: bet.changedPrice ? +bet.changedPrice : bet.price ?? 0,
      averagePrice: bet.changedPrice ? +bet.changedPrice : (bet.averagePrice || bet.price) ?? 0,
      size: bet.changedSize ? +bet.changedSize : +bet.sizePlaced ?? 0,
      sizeMatched: bet.changedSize ? +bet.changedSize : +bet.sizePlaced ?? 0,
      marketType: bet.marketType!,
      bettingType: bet.bettingType!,
      selectionId: bet.selectionId!,
      handicap: 0,
      numberOfWinners: numberOfWinners,
      currency: bet.currency!
    }));
};
