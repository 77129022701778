import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SortingField from 'components/BetsTable/components/SortingField';
import { plTableHeader } from 'constants/myBets';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { setSortBy } from 'redux/modules/myBets';
import { bettingPLLoading, getSortValueByType } from 'redux/modules/myBets/selectors';
import { SortByFields, TSortByType } from 'redux/modules/myBets/type';

import styles from './styles.module.scss';

interface TableHeaderProps {
  isSortingDisabled?: boolean;
}

const TableHeader = ({ isSortingDisabled }: TableHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sortingStartDateValue = useSelector(getSortValueByType(SortByFields.START_DATE));
  const sortingSettledDateValue = useSelector(getSortValueByType(SortByFields.SETTLED_DATE));
  const isBettingPLLoading = useSelector(bettingPLLoading);

  const { refreshData } = useMyBetsPagesNavigation();

  const setSortingTypeHandler = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setSortBy({ type: sortBy as TSortByType, value }));
    refreshData({
      sortBy: {
        [sortBy]: value
      }
    });
  };

  return (
    <div className={classNames(styles.wrap, styles.wrap__header)}>
      <div className={classNames(styles.row, styles.row__header)}>
        <div className={classNames(styles.col, styles.col__event)}>{t(plTableHeader.EVENT)}</div>
        <div className={classNames(styles.col, styles.col__market)}>{t(plTableHeader.MARKET)}</div>
        <div className={classNames(styles.col, styles.col__date, styles.col__left)}>
          {
            <SortingField
              sortingValue={sortingStartDateValue}
              align="left"
              sortBy={SortByFields.START_DATE}
              isInactive={!!sortingSettledDateValue}
              onSorting={setSortingTypeHandler}
              isDisabled={isBettingPLLoading || isSortingDisabled}
            >
              {t(plTableHeader.START)}
            </SortingField>
          }
        </div>
        <div className={classNames(styles.col, styles.col__date, styles.col__left)}>
          {
            <SortingField
              sortingValue={sortingSettledDateValue}
              align="left"
              sortBy={SortByFields.SETTLED_DATE}
              isInactive={!!sortingStartDateValue}
              onSorting={setSortingTypeHandler}
              isDisabled={isBettingPLLoading || isSortingDisabled}
            >
              {t(plTableHeader.SETTLED)}
            </SortingField>
          }
        </div>
        <div className={classNames(styles.col, styles.col__pl, styles.col__right)}>{t(plTableHeader.PL)}</div>
      </div>
    </div>
  );
};

export default TableHeader;
