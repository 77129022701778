import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isInteger } from 'lodash';

import { CurrentBetActions, PageBlocks, SportIds } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { BETSLIP_LINE_INT_ODDS } from 'constants/betslip';
import { mobileBetslip as branding, mobileComponents, mobileIcons } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useBetSideLabel } from 'hooks/useLabels';
import useMobilePlacementNotificationMatchedBySystem from 'hooks/useMobilePlacementNotificationMatchedBySystem';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getMobileSettingsFillOrKill } from 'redux/modules/appConfigs/selectors';
import { setCurrentBetCanBeRemoved } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getIsMarketClosed } from 'redux/modules/marketsPrices/selectors';
import { setMobileEditOfferId } from 'redux/modules/myBets';
import { getMobileEditOfferId } from 'redux/modules/myBets/selectors';
import { getUserAccountSettingsFillOrKillEnabled } from 'redux/modules/user/selectors';
import { PageBlock, PlacementPage } from 'types';
import { MatchType } from 'types/bets';

import MobileUnmatchedBetActions from '../MobileUnmatchedBetActions/MobileUnmatchedBetActions';

import styles from './styles.module.scss';

interface MobileOpenBetProps {
  /**
   * Bet
   */
  bet: TCurrentBet;

  /**
   * Match type
   */
  matchType: MatchType;

  /**
   * Is Open bets enabled flag
   */
  isOpenBetsEnabled: boolean;

  /**
   * Is PNC enabled flag
   */
  isPNCEnabled: boolean;

  /**
   * Place where component was added.
   */
  pageBlock: PageBlock;

  /**
   * Currency
   */
  currency: string;

  /**
   * Hide function
   */
  onHide: (matchType: MatchType, offerId: number) => void;
  page?: PlacementPage;
  noHeader: boolean;
}

function MobileOpenBet({
  bet,
  matchType,
  isOpenBetsEnabled,
  isPNCEnabled,
  pageBlock,
  currency,
  onHide,
  page,
  noHeader
}: MobileOpenBetProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const isMarketClosed = useSelector(getIsMarketClosed(bet.marketId));
  const fillOrKill = useSelector(getMobileSettingsFillOrKill);
  const fillOrKillEnabled = useSelector(getUserAccountSettingsFillOrKillEnabled);
  const mobileEditOfferId = useSelector(getMobileEditOfferId);

  const isGame = pageBlock === PageBlocks.GAME;

  const isLineMarket = bet.bettingType === BettingTypes.LINE;

  const priceByMatchType = matchType === MatchTypes.UNMATCHED ? bet.price : bet.averagePriceRounded;
  const minUnitValue = (bet as TCurrentBet).minUnitValue || 0;

  const price =
    !isInteger(priceByMatchType) && isLineMarket && isInteger(bet.interval) && isInteger(minUnitValue)
      ? Math.floor(+(priceByMatchType || 0)) + 1
      : priceByMatchType;

  const isLapsed = bet.offerState === BetsStatusesTypes.LAPSED;
  const showLapsedNotification = matchType === MatchTypes.UNMATCHED && (isLapsed || isMarketClosed);

  let messageKey: string;

  if (matchType === MatchTypes.MATCHED) {
    if (isPNCEnabled && !isGame) {
      if (bet.side === BetSides.Back ? bet.averagePrice > bet.price : bet.averagePrice < bet.price) {
        messageKey = 'betslip.labels.pnc.placedWithBetterOdds';
      } else {
        messageKey = 'betslip.labels.pnc.placed';
      }
    } else {
      messageKey = 'placement.openBets.matched';
    }
  } else {
    messageKey = 'betslip.labels.unmatchedBet';
  }

  const size =
    matchType === MatchTypes.UNMATCHED
      ? (showLapsedNotification ? Number(bet.sizeLapsed) : Number(bet.sizeRemaining)) || 0
      : bet.size || bet.sizeMatched || bet.sizeCancelled || 0;

  const { noFormattedAmount: formattedSize } = useFormatCurrency(size, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  useMobilePlacementNotificationMatchedBySystem({ bet, pageBlock });

  useUpdateEffect(() => {
    if (
      matchType === MatchTypes.UNMATCHED &&
      (bet.action !== CurrentBetActions.EDITING ||
        (fillOrKill && fillOrKillEnabled && bet.action === CurrentBetActions.EDITING)) &&
      (bet.offerState === BetsStatusesTypes.CANCELLED ||
        (Number(bet.sizeCancelled) > 0 && Number(bet.sizeRemaining) === 0))
    ) {
      dispatch(setCurrentBetCanBeRemoved({ offerId: bet.offerId, canBeRemoved: true }));
    }
  }, [bet.sizeCancelled, bet.sizeRemaining, bet.offerState]);

  const renderIcon = () => {
    if (matchType === MatchTypes.MATCHED) {
      if (
        isPNCEnabled && !isGame && bet.side === BetSides.Back
          ? bet.averagePrice > bet.price
          : bet.averagePrice < bet.price
      ) {
        return (
          <i
            className={classNames('biab_custom-icon-success-star-circle', styles.openBet__icon, mobileIcons.STAR_ICON)}
          >
            <span className={classNames('path1', mobileIcons.BG_COLOR)} />
            <span className="path2" />
          </i>
        );
      }

      return (
        <i
          className={classNames('biab_custom-icon-success-circle', styles.openBet__icon, mobileIcons.CHECKMARK_ICON)}
        />
      );
    }

    if (showLapsedNotification) {
      return <i className={classNames('biab_custom-icon-info-circle', styles.openBet__icon, mobileIcons.INFO_ICON)} />;
    }

    return (
      <i
        className={classNames(
          'fa2 fa2-clock-icon',
          styles.openBet__icon__unmatched,
          styles.openBet__icon,
          mobileIcons.CLOCK_ICON,
          mobileIcons.ERROR
        )}
      />
    );
  };

  useEffect(() => {
    if (mobileEditOfferId && mobileEditOfferId === bet.offerId) {
      if (ref.current) {
        ref.current.scrollIntoView({ block: 'start' });
      }

      dispatch(setMobileEditOfferId(null));
    }
  }, [mobileEditOfferId]);

  const isCricketMarket = String(bet.eventTypeId) === SportIds.CRICKET;

  const betSideLabel = useBetSideLabel(bet.side, isLineMarket, isCricketMarket, 'inlinePlacement.labels.');

  return (
    <div
      className={classNames(
        matchType.toLocaleLowerCase(),
        'biab_' + bet.side.toLocaleLowerCase(),
        styles.openBet,
        matchType === MatchTypes.UNMATCHED ? styles.openBet__unmatched : styles.openBet__matched,
        {
          [styles.openBet__topBorder]: noHeader,
          [branding.OPENED_BET]: !showLapsedNotification,
          [mobileComponents.NOTIFICATION]: showLapsedNotification,
          [mobileComponents.INFO_SECONDARY]: showLapsedNotification
        }
      )}
      ref={ref}
    >
      <div className={styles.openBet__header}>
        {renderIcon()}
        <div>
          <p className={styles.openBet__title}>
            {betSideLabel}:{' '}
            <span className={styles.bold}>
              {bet.selectionName} {isLineMarket && !isPNCEnabled ? ` @${BETSLIP_LINE_INT_ODDS}` : ''}
            </span>{' '}
            – {isGame ? bet.eventName : bet.marketName}
          </p>
          <p className={styles.openBet__title}>
            <span className={styles.bold}>
              {formattedSize} @{price}
            </span>{' '}
            – {showLapsedNotification ? t('inlinePlacement.messages.lapsed') : messageKey && t(messageKey)}
          </p>
        </div>
        {!isOpenBetsEnabled && (
          <i
            onClick={() => onHide(matchType, bet.offerId)}
            className={classNames('biab_custom-icon-close', styles.closeIcon)}
          />
        )}
      </div>
      {matchType === MatchTypes.UNMATCHED && !showLapsedNotification && (
        <MobileUnmatchedBetActions bet={bet} page={page} pageBlock={pageBlock} currency={currency} />
      )}
    </div>
  );
}

export default MobileOpenBet;
