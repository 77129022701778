import { FormEvent } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useUnmount } from 'usehooks-ts';

import MobileSelectField from 'components/MobileSelectField/MobileSelectField';
import { CookieNames } from 'constants/app';
import { BetTypesFields, SortByFields } from 'constants/betList';
import { useForm } from 'hooks/useForm';
import BetListFiltersHeader from 'pages/mobile/MobileBetListPage/components/BetListFilters/components/BetListFiltersHeader/BetListFiltersHeader';
import { getIsExchangeGamesEnabled, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setFilters, setIsFiltersSectionOpen } from 'redux/modules/betList';
import { getFiltersFields } from 'redux/modules/betList/selectors';
import { TFiltersFields } from 'redux/modules/betList/type';

import styles from './BetListFilters.module.scss';

const BetListFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filtersFields = useSelector(getFiltersFields);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const { formValues, handleChange } = useForm<TFiltersFields>(filtersFields);
  const [, setCookie, removeCookie] = useCookies([
    CookieNames.BET_LIST_MARKETS_COLLAPSED,
    CookieNames.BET_LIST_SORT_BY,
    CookieNames.BET_LIST_BET_TYPE
  ]);
  const shouldDisplayBetTypesFilter = isPNCEnabled ? isExchangeGamesEnabled : true;

  const sortByOptions = [
    {
      value: SortByFields.PlacementTime,
      label: t('account.betList.labels.sortByPlacementTime'),
      description: t('account.betList.labels.sortByPlacementTime.description')
    },
    {
      value: SortByFields.EventStartTime,
      label: t('account.betList.labels.sortByEventStartTime'),
      description: t('account.betList.labels.sortByEventStartTime.description')
    }
  ];

  const betTypeOptions = [
    {
      value: BetTypesFields.AllBets,
      label: t('betslip.openBets.filter.allBets')
    },
    {
      value: BetTypesFields.Unmatched,
      label: t('account.mybets.labels.types.unmatched')
    },
    {
      value: BetTypesFields.Matched,
      label:
        isPNCEnabled && isExchangeGamesEnabled
          ? t('account.mybets.labels.placed')
          : t('account.mybets.labels.types.matched')
    }
  ];

  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (formValues.sortBy !== filtersFields.sortBy) {
      removeCookie(CookieNames.BET_LIST_MARKETS_COLLAPSED, { path: '/' });
      setCookie(CookieNames.BET_LIST_SORT_BY, formValues.sortBy, { path: '/' });
    }

    if (formValues.betType !== filtersFields.betType) {
      setCookie(CookieNames.BET_LIST_BET_TYPE, formValues.betType, { path: '/' });
    }

    dispatch(setFilters(formValues));
    dispatch(setIsFiltersSectionOpen(false));
  };

  useUnmount(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit}>
        <BetListFiltersHeader areFiltersChanged={!isEqual(formValues, filtersFields)} />
        <div className={styles.filters}>
          <MobileSelectField
            name="sortBy"
            label={t('account.betList.labels.sorting')}
            value={formValues.sortBy}
            options={sortByOptions}
            onChange={value => handleChange('sortBy', value)}
          />
          {shouldDisplayBetTypesFilter && (
            <MobileSelectField
              name="betType"
              label={t('account.myBets.labels.betTypes')}
              value={formValues.betType}
              options={betTypeOptions}
              onChange={value => handleChange('betType', value)}
            />
          )}
        </div>
      </form>
    </section>
  );
};

export default BetListFilters;
