import { MouseEvent as ReactMouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import tooltipStyles from 'components/Tooltip/styles.module.scss';
import { BODY_ID, EventStatuses, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  asianViewMiddleSection as branding,
  asianViewPopUps,
  componentsBranding,
  generalBranding
} from 'constants/branding';
import { BET_LIST_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getAVAllCurrentBetsByEventId } from 'redux/modules/asianViewCurrentBets/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getAllMatchedCurrentBetsByEventId } from 'redux/modules/currentBets/selectors';
import { getEventUpdatedDataStatus } from 'redux/modules/marketsPrices/selectors';
import { closeProfitLossTablePopUp } from 'redux/modules/profitLossTablePopUp';
import {
  getIsProfitLossTableDataFirstLoaded,
  getProfitLossFullTimeTableData,
  getProfitLossHalfTimeTableData,
  getProfitLossTableDataError,
  getProfitLossTableEventName,
  getProfitLossTablePopUpEventId,
  getProfitLossTablePopUpMarketId
} from 'redux/modules/profitLossTablePopUp/selectors';
import { getUserAccountSettingsShowPLTableSwipeMessage } from 'redux/modules/user/selectors';

import MobilePLTableSwipeMessage from './components/MobilePLTableSwipeMessage/MobilePLTableSwipeMessage';
import ProfitLossTable from './components/ProfitLossTable/ProfitLossTable';
import ProfitLossTablePopUpMarkets from './components/ProfitLossTablePopUpMarkets/ProfitLossTablePopUpMarkets';
import ProfitLossTablePopUpRefreshBtn from './components/ProfitLossTablePopUpRefreshBtn/ProfitLossTablePopUpRefreshBtn';

import styles from './ProfitLossTablePopUp.module.scss';

type ProfitLossTablePopUpProps = {
  isAsianView?: boolean;
};

const DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT = 610;
const BOTTOM_SPACE = 20;
const HEADER_AND_PADDINGS_HEIGHT = 80;

const ProfitLossTablePopUp = ({ isAsianView = false }: ProfitLossTablePopUpProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const halfTimeData = useSelector(getProfitLossHalfTimeTableData);
  const fullTimeData = useSelector(getProfitLossFullTimeTableData);
  const eventName = useSelector(getProfitLossTableEventName);
  const error = useSelector(getProfitLossTableDataError);
  const eventId = useSelector(getProfitLossTablePopUpEventId);
  const eventsCount = useSelector(
    isAsianView ? getAVAllCurrentBetsByEventId(eventId) : getAllMatchedCurrentBetsByEventId(eventId, isGameBetSlip)
  );
  const marketId = useSelector(getProfitLossTablePopUpMarketId);
  const isFirstLoaded = useSelector(getIsProfitLossTableDataFirstLoaded);
  const status = useSelector(getEventUpdatedDataStatus(eventId));
  const showSwipeMessage = useSelector(getUserAccountSettingsShowPLTableSwipeMessage);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const { isMobile, isLandscape } = useDevice();

  const [displayedAllScores, setDisplayedAllScores] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({
    top: window.innerHeight / 2,
    left: window.innerWidth / 2
  });
  const [isMoving, setIsMoving] = useState(false);
  const [maxBodyDesktopHeight, setMaxBodyDesktopHeight] = useState(DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT);
  const [areMarketsOpened, setAreMarketsOpened] = useState(false);

  const popUpRef = useRef<HTMLDivElement>(null);
  const mouseMoveHandler = useRef<((e: MouseEvent) => void) | null>(null);
  const tooltipRef = useRef<TooltipRefProps>(null);

  const isBetList = pathname.includes(BET_LIST_URL);
  const eventIds = useMemo(() => (eventId ? [eventId] : []), [eventId]);

  const isHalfTimeData = !!halfTimeData?.length;
  const isFullTimeData = !!fullTimeData?.length;
  const isPLTableData = isHalfTimeData || isFullTimeData;
  const isClosedEvent = status === EventStatuses.COMPLETE;

  const handleMouseDown = (event: ReactMouseEvent<HTMLDivElement>) => {
    const popUp = popUpRef.current;

    if (!popUp) return;

    setIsMoving(true);
    const { left, top } = popUp.getBoundingClientRect();
    const shiftX = event.clientX - left;
    const shiftY = event.clientY - top;

    const moveAt = (pageX: number, pageY: number) => {
      setPopUpPosition({
        left: pageX - shiftX,
        top: pageY - shiftY
      });
    };

    moveAt(event.pageX, event.pageY);

    const handleMouseMove = (moveEvent: MouseEvent) => {
      moveAt(moveEvent.pageX, moveEvent.pageY);
    };
    mouseMoveHandler.current = handleMouseMove;

    window.addEventListener('mousemove', handleMouseMove);
  };

  const removeMouseMoveHandler = () => {
    if (mouseMoveHandler.current) {
      setIsMoving(false);
      window.removeEventListener('mousemove', mouseMoveHandler.current);
      mouseMoveHandler.current = null;
    }
  };

  const closePopUp = () => {
    dispatch(closeProfitLossTablePopUp());
  };

  const handleCloseTooltip = () => {
    if (tooltipRef.current?.isOpen) {
      tooltipRef.current?.close();
    }
  };

  useOnClickOutside(popUpRef, () => {
    if (isMobile) {
      closePopUp();
    }
  });

  useEffect(() => {
    if (popUpRef.current) {
      setPopUpPosition({
        left: window.innerWidth / 2 - popUpRef.current.clientWidth / 2,
        top: window.innerHeight / 2 - popUpRef.current.clientHeight / 2
      });
    }
  }, [isPLTableData, eventId]);

  useEffect(() => {
    if (isClosedEvent) {
      closePopUp();
    }
  }, [isClosedEvent]);

  useEffect(() => {
    if (!areMarketsOpened) {
      if (maxBodyDesktopHeight !== DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT) {
        setMaxBodyDesktopHeight(DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT);
      }
    } else {
      if (popUpRef.current) {
        const remainingSpace = window.innerHeight - popUpPosition.top - popUpRef.current.clientHeight;

        if (remainingSpace < 20) {
          setMaxBodyDesktopHeight(window.innerHeight - popUpPosition.top - BOTTOM_SPACE - HEADER_AND_PADDINGS_HEIGHT);
        } else if (maxBodyDesktopHeight !== DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT) {
          setMaxBodyDesktopHeight(DEFAULT_DESKTOP_POP_UP_BODY_MAX_HEIGHT);
        }
      }
    }
  }, [areMarketsOpened]);

  return createPortal(
    <>
      {eventIds.length && (
        <EventsUpdatesInjection
          eventIds={eventIds}
          subscriptionType={GeneralWebSocketSubscriptionTypes.eventUpdatesPL}
          isAsianView={isAsianView}
        />
      )}
      {isMobile && (
        <>
          <div className={styles.popUp__backdrop} />
          {!isIframeEnabled && <BodyClassName className="disableScroll" />}
        </>
      )}
      <div
        className={classNames(
          'biab_modal',
          styles.popUp__wrapper,
          asianViewPopUps.POP_UP,
          componentsBranding.PL_TABLE_POP_UP,
          {
            [styles.popUp__wrapper__mobile]: isMobile
          }
        )}
      >
        <div
          ref={popUpRef}
          className={classNames(styles.popUp, {
            [styles.popUp__expandedTables]: displayedAllScores && !isMobile,
            [styles.popUp__desktop]: !isMobile,
            [styles.popUp__invisible]: !popUpRef.current,
            [styles.popUp__desktop__grabbing]: isMoving,
            [styles.popUp__mobile]: isMobile,
            [styles.popUp__betlist]: isBetList
          })}
          onMouseDown={isMobile ? undefined : handleMouseDown}
          onMouseUp={isMobile ? undefined : removeMouseMoveHandler}
          onMouseLeave={isMobile ? undefined : removeMouseMoveHandler}
          onDragStart={() => false}
          style={isMobile ? undefined : popUpPosition}
          data-market-id={marketId}
          data-event-id={eventId}
          data-market-prices={true}
          onTouchStart={isMobile ? handleCloseTooltip : undefined}
          onPointerDown={isMobile ? handleCloseTooltip : undefined}
        >
          <div
            className={classNames(styles.popUp__header, {
              [styles.popUp__header__mobile]: isMobile,
              [generalBranding.MODAL_HEADER]: !isAsianView,
              [asianViewPopUps.POP_UP_HEADER]: isAsianView
            })}
          >
            {!isFirstLoaded ? (
              <Skeleton className={styles.popUp__skeleton__eventName} />
            ) : (
              <p
                className={classNames('biab_modal-title', styles.popUp__teamNames, {
                  [styles.popUp__teamNames__text]: isAsianView && isMobile,
                  [styles.popUp__teamNames__text__mobile]: isAsianView && isMobile
                })}
              >
                {eventName}
              </p>
            )}
            <button className={styles.popUp__closeIcon__btn} onClick={() => dispatch(closeProfitLossTablePopUp())}>
              <i
                className={classNames('biab_custom-icon-close', styles.popUp__closeIcon, {
                  [styles.popUp__closeIcon__mobile]: isMobile
                })}
              />
            </button>
          </div>
          <div
            className={classNames(styles.popUp__body, asianViewPopUps.POP_UP_BODY)}
            style={!isMobile ? { maxHeight: maxBodyDesktopHeight } : undefined}
          >
            <div className={styles.popUp__betsCountWrapper}>
              <p className={styles.popUp__betsCountWrapper__count}>
                {t('asianView.labels.plTable.unsettledBets')}{' '}
                <span className={styles.popUp__medium}>{eventsCount}</span>
              </p>
              <ProfitLossTablePopUpRefreshBtn isAsianView={isAsianView} />
            </div>
            {isMobile && !isLandscape && showSwipeMessage && <MobilePLTableSwipeMessage />}
            {!isFirstLoaded ? (
              <Skeleton className={styles.popUp__skeleton__data} />
            ) : isPLTableData && !error ? (
              <>
                {isFullTimeData && (
                  <ProfitLossTable
                    data={fullTimeData}
                    type="fullTime"
                    displayedAllScores={displayedAllScores}
                    onCloseTooltip={handleCloseTooltip}
                  />
                )}
                {isHalfTimeData && (
                  <ProfitLossTable
                    data={halfTimeData}
                    type="halfTime"
                    displayedAllScores={displayedAllScores}
                    onCloseTooltip={handleCloseTooltip}
                  />
                )}
                {!isMobile && (
                  <button
                    className={classNames(styles.popUp__toggleShowScore, componentsBranding.TERTIARY_BTN)}
                    onClick={() => setDisplayedAllScores(prevState => !prevState)}
                  >
                    <i
                      className={classNames('av-icon', styles.popUp__toggleShowScore__icon, {
                        'av-icon-arrows-down': !displayedAllScores,
                        'av-icon-arrows-up': displayedAllScores
                      })}
                    />
                    <span className={styles.popUp__toggleShowScore__text}>
                      {t(`asianView.labels.plTable.showScores.${displayedAllScores ? 'less' : 'more'}`)}
                    </span>
                  </button>
                )}
              </>
            ) : (
              <div className={classNames(styles.popUp__emptyData, componentsBranding.PL_TABLE_NO_DATA)}>
                {t('asianView.labels.plTable.noData')}
              </div>
            )}
            <ProfitLossTablePopUpMarkets
              areMarketsOpened={areMarketsOpened}
              setAreMarketsOpened={setAreMarketsOpened}
            />
          </div>
        </div>
        {isMobile && <div className={styles.popUp__emptySpace} />}
      </div>
      <ReactTooltip
        id="pl-table-cell-tooltip"
        place="top"
        className={tooltipStyles.tooltip}
        classNameArrow={tooltipStyles.arrow}
        openOnClick={isMobile}
        delayShow={200}
        ref={tooltipRef}
      />
    </>,
    document.getElementById(BODY_ID)!
  );
};

export default ProfitLossTablePopUp;
