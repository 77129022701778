import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';
import { componentsBranding } from 'constants/branding';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { TMultiselectDropdownItem } from 'types';

import MultiselectDropdownItem from './components/MultiselectDropdownItem';

import styles from './styles.module.scss';

type TMultiSelectDropdownProps = {
  options: TMultiselectDropdownItem[];
  onSelect: (options: string[]) => void;
  children?: ReactNode;
  disabled?: boolean;
  setAllWhenEmpty?: boolean;
  getInputLabel?: (activeOptions: string[]) => string;
};

const MultiselectDropdown = ({
  options,
  onSelect,
  getInputLabel,
  children,
  disabled,
  setAllWhenEmpty
}: TMultiSelectDropdownProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [optionsList, setSelectedOptions] = useState(options);

  const isAllSelected = optionsList.every(({ isSelected }) => isSelected);
  const isSomeSelected = optionsList.some(({ isSelected }) => isSelected);

  const handleToggleDropdown = () => {
    if (isOpen) {
      applySelectChanges();
    }

    setIsOpen(!isOpen);
  };

  const handleSelectAll = () => {
    setSelectedOptions(optionsList.map(o => ({ ...o, isSelected: !isAllSelected })));
  };

  const handleSelectOption = (option: TMultiselectDropdownItem) => {
    setSelectedOptions(optionsList.map(o => (o.label === option.label ? option : o)));
    option.onSelect?.(option);
  };

  const applySelectChanges = () => {
    if (setAllWhenEmpty && !isSomeSelected) {
      setSelectedOptions(options);
    }

    onSelect(optionsList.filter(({ isSelected }) => isSelected).map(({ id }) => id));
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      applySelectChanges();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(options);
    }
  }, [isOpen]);

  return (
    <div className={styles.wrap} ref={dropdownRef}>
      <div
        className={classNames(styles.placeholder, {
          [styles.placeholder__open]: isOpen,
          [styles.placeholder__disabled]: disabled
        })}
        onClick={!disabled ? handleToggleDropdown : undefined}
      >
        {getInputLabel?.(optionsList.filter(({ isSelected }) => isSelected).map(({ id }) => id)) || children}
      </div>
      {isOpen && (
        <ul className={styles.list}>
          <li className={classNames(styles.option, componentsBranding.DROPDOWN_ITEM)}>
            <Checkbox checked={isAllSelected} halfChecked={isSomeSelected && !isAllSelected} onChange={handleSelectAll}>
              {t(`account.mybets.labels.${isAllSelected ? 'clearAll' : 'selectAll'}`)}
            </Checkbox>
          </li>
          <li className={styles.divider}>
            <hr />
          </li>
          {optionsList.map(option => (
            <MultiselectDropdownItem
              option={option}
              key={option.label}
              handleSelectOption={handleSelectOption}
              customClass={styles.option}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiselectDropdown;
