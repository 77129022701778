import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { subHeader as branding } from 'constants/branding';
import useLogOut from 'hooks/useLogOut';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getDisplayIconsInSubHeader, getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { TAccountNavigation } from 'types/navigation';

import DesktopNavigationMyAccountItem from '../DesktopNavigationMyAccountItem';

import styles from './styles.module.scss';

interface SubmenuProps {
  title: string;
  rightIcon: string;
  isShowLogout: boolean;
  data: Array<TAccountNavigation>;
  isSettingsEnabled: boolean;
}

const DesktopNavigationMyAccount = ({ rightIcon, data, title, isShowLogout, isSettingsEnabled }: SubmenuProps) => {
  const { t } = useTranslation();
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);
  const { logout } = useLogOut();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const closeSubMenuOnItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className={classNames(styles.headerNavigationItem, branding.SUBHEADER_WRAPPER)}>
      <button
        className={classNames('biab_navigation-link', styles.title, branding.SUBHEADER_HOLDER, { biab_active: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {displayIconsInSubHeader && rightIcon && <i className={classNames(rightIcon, styles.rightIcon)} />}
        {t(title)}
        <i className={classNames(`fa2 fa2-arrow-${isOpen ? 'up' : 'down'}`, styles.headerNavigationItem__icon)} />
      </button>

      <div className={styles.itemContainer}>
        {isOpen && (
          <div
            className={classNames(styles.submenu, branding.SUBHEADER_MENU, {
              [styles.submenu__noSettings]: !isSettingsEnabled
            })}
          >
            {data.map(item => (
              <DesktopNavigationMyAccountItem key={item.code} item={item} onClose={closeSubMenuOnItemClick} />
            ))}
            {isShowLogout && !isIframeEnabled && (
              <div>
                <a onClick={logout} className={classNames(styles.logout, branding.SUBHEADER_ITEM)}>
                  {t('header.logout')}
                  <i className="biab_custom-icon-logout" />
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopNavigationMyAccount;
