import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ElementNames, MAX_DOUBLE_DIGIT_NUMBER, MAX_TRIPLE_DIGIT_NUMBER, SportIds } from 'constants/app';
import { TypeFields } from 'constants/app';
import { ASIAN_VIEW_PAGE_PARAM, AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import { AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM } from 'constants/betList';
import { asianViewMiddleSection, mobileBranding } from 'constants/branding';
import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_CASH_OUT_URL,
  ASIAN_VIEW_MORE_URL,
  BET_LIST_URL,
  CUSTOM_PAGES_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL
} from 'constants/locations';
import useAsianCashOut from 'hooks/useAsianCashOut';
import useElementSize from 'hooks/useElementSize';
import { useMobileNavigation } from 'hooks/useMobileNavigation';
import useOnClickOutside from 'hooks/useOnClickOutside';
import usePostMessage from 'hooks/usePostMessage';
import {
  getAsianViewMobileNavigationMenu,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsMobileMenuButtonTextEnabled,
  getOperator,
  getPositionOfAsianViewMobileNavigationMenu,
  getWindowHeight
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight, setIsAsianViewMenuOpen } from 'redux/modules/appSettings';
import { getElementHeightByName, getIsAsianViewMenuOpen } from 'redux/modules/appSettings/selectors';
import {
  getIsAsianViewNotFoundView,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { getAsianViewCashOutsAmount } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getAVMatchedCurrentBetsCount } from 'redux/modules/asianViewCurrentBets/selectors';
import { getIsAsianViewFavourites } from 'redux/modules/asianViewFavourites/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getBetListCounter } from 'redux/modules/betList/selectors';
import { getIsSelfExclusionEnabled, getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { TypeField } from 'types';
import { getMobileNavigationButton } from 'utils/navigation';

import AsianMoreLink from './components/AsianMoreLink';

import styles from './styles.module.scss';

type MobileToolbarProps = {
  noActiveItems?: boolean;
};

const NON_AUTH_DISABLED_AV_MENU_ITEMS = [
  TypeFields.favourites,
  TypeFields.myBets,
  TypeFields.cashOut,
  TypeFields.betList
] as TypeField[];

const NavIcon = ({
  icon,
  isAsianViewPage,
  style,
  type
}: {
  isAsianViewPage: boolean;
  style: string;
  icon: string;
  type: TypeField;
}) => {
  return (
    <i
      className={classNames(
        icon,
        styles.item__icon,
        {
          [styles.betListIcon]: type === TypeFields.betList && isAsianViewPage,
          [styles.betListMobileIcon]: type === TypeFields.betList && !isAsianViewPage,
          [styles.item__icon__asianView]: isAsianViewPage
        },
        styles[style]
      )}
      data-class-nav-icon={icon}
    />
  );
};

const MobileToolbar = ({ noActiveItems = false }: MobileToolbarProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { timeFilter, marketLink } = useParams();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const operator = useSelector(getOperator);
  const isMobileMenuButtonTextEnabled = useSelector(getIsMobileMenuButtonTextEnabled);
  const isAsianViewFavourites = useSelector(getIsAsianViewFavourites);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isAsianViewMenuOpen = useSelector(getIsAsianViewMenuOpen);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);
  const cashOutCounter = useSelector(getAsianViewCashOutsAmount);
  const mobileAsianSubHeaderHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_ASIAN_SUB_HEADER));
  const mobileHeaderHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_HEADER_HEIGHT));
  const asianMiddleSectionHeight = useSelector(getElementHeightByName(ElementNames.ASIAN_MIDDLE_SECTION));
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const asianViewOpenBetsCount = useSelector(getAVMatchedCurrentBetsCount);
  const mobileBetListCounter = useSelector(getBetListCounter);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);
  const windowHeight = useSelector(getWindowHeight);

  const { isDefaultInPlayLandingPath } = useMobileNavigation();
  const isCashOutEnabled = useAsianCashOut();
  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_FOOTER_NAV_HEIGHT, height }));
    }
  });
  const { placeBetLogin } = usePostMessage();

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isAccountPage = pathname.includes(ACCOUNT_BASE_URL);
  const isCustomPage = pathname.includes(CUSTOM_PAGES_BASE_URL);
  const isMorePage = pathname.includes(ASIAN_VIEW_MORE_URL);
  const showAsianCashOutCounter = isLoggedIn && isMobileAsianView && isAsianViewPage;
  const pageSearchParam = searchParams.get(ASIAN_VIEW_PAGE_PARAM);
  const pageParam = pageSearchParam ? `&${ASIAN_VIEW_PAGE_PARAM}=0` : '';
  const isOutrightFromBetListMobile = searchParams.get(AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM) === 'true';

  const isDrawer =
    isMobileAsianView &&
    !isAccountPage &&
    !isCustomPage &&
    isAsianViewPage &&
    (isLandscapeAsianView || (positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu));
  const isVisible = isAsianViewMenuOpen || isLandscapeAsianView;
  const isFavorite = pathname?.includes(ASIAN_MOBILE_FAVOURITES_URL);
  const isCashOutUrl = pathname?.includes(ASIAN_VIEW_CASH_OUT_URL);
  const isBetList = pathname?.includes(BET_LIST_URL);
  const isAsianMore = pathname === ASIAN_VIEW_MORE_URL;
  const isAsianBetList = pathname === ASIAN_MOBILE_BET_LIST_URL;
  const isGamesPage = pathname.includes(GAMES_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  let wrapperStyles = {};

  if (isDrawer) {
    if (!isLandscapeAsianView) {
      wrapperStyles = { height: `calc(100dvh - ${mobileAsianSubHeaderHeight}px)`, overflow: 'auto' };
    } else if (windowHeight - mobileHeaderHeight > asianMiddleSectionHeight) {
      wrapperStyles = { height: `calc(100dvh - ${mobileHeaderHeight}px)` };
    } else {
      const subheaderHeight = timeFilter && marketLink ? mobileAsianSubHeaderHeight : 0;
      wrapperStyles = { height: asianMiddleSectionHeight + subheaderHeight };
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setIsAsianViewMenuOpen(false));
      dispatch(setElementHeight({ name: ElementNames.MOBILE_FOOTER_NAV_HEIGHT, height: 0 }));
    };
  }, []);

  const isAsianView = isAsianViewEnabled && isAsianViewPage;

  const buttons = getMobileNavigationButton({
    operator,
    isLoggedIn,
    isGamesEnabled,
    isAsianView,
    isAsianViewFavourites
  });

  const getIsActive = (url: string, type: TypeField) => {
    if (
      isAsianViewPage &&
      (isFavorite || isAsianMore || isCashOutUrl || isAsianBetList || isOutrightFromBetListMobile) &&
      type === TypeFields.home
    ) {
      return false;
    }
    if ((isDefaultInPlayLandingPath || pathname === HOME_BASE_URL) && url === HOME_BASE_URL) {
      return true;
    }

    if (pathname !== HOME_BASE_URL && url !== HOME_BASE_URL) {
      return pathname.includes(url);
    }

    return false;
  };

  const handleClickLink = (type: TypeField) => (e: SyntheticEvent) => {
    if (NON_AUTH_DISABLED_AV_MENU_ITEMS.includes(type) && !isLoggedIn) {
      e.preventDefault();
      placeBetLogin();
    }
    if (isDrawer && isAsianViewMenuOpen) {
      dispatch(setIsAsianViewMenuOpen(false));
    }
  };

  const callback = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const el = componentRef?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      if (isDrawer && isAsianViewMenuOpen) {
        dispatch(setIsAsianViewMenuOpen(false));
      }
    },
    [isAsianViewMenuOpen, isDrawer]
  );
  useOnClickOutside(componentRef, undefined, callback);

  return !isSelfExclusionEnabled && !isTimeOutEnabled ? (
    <>
      {isDrawer && (
        <div
          className={classNames(styles.overlay, {
            [styles.overlay__visible]: isAsianViewMenuOpen && !isLandscapeAsianView
          })}
        />
      )}
      <div
        ref={componentRef}
        className={classNames('biab_mobile-footer-nav js-mobile-footer-nav', styles.mobile_toolbar, {
          [styles.drawer__menu]: isDrawer && isLandscapeAsianView,
          [styles.drawer]: isDrawer,
          [asianViewMiddleSection.AV_MENU]: isAsianViewPage,
          [styles.drawer__hide]: isDrawer && !isVisible,
          [styles.asianViewToolbar]: isAsianViewPage
        })}
        style={wrapperStyles}
      >
        {buttons.map(({ style = '', isCashOut = false, icon, text, type, url }) => {
          const isActiveUrl = getIsActive(url, type);
          const linkUrl =
            isAsianViewPage && type === TypeFields.home && url === ASIAN_BASE_URL
              ? `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}?${pageParam}`
              : url.includes(ACCOUNT_BASE_URL)
              ? `${exchangePage}${url}`
              : url;

          if (url.includes(ASIAN_VIEW_CASH_OUT_URL) && !isCashOutEnabled) {
            return null;
          }

          const isDisabled = NON_AUTH_DISABLED_AV_MENU_ITEMS.includes(type) && !isLoggedIn && isAsianViewPage;
          const isCashOutCounter = showAsianCashOutCounter && type === TypeFields.cashOut && cashOutCounter > 0;
          const betListCounter = isAsianViewPage ? asianViewOpenBetsCount : mobileBetListCounter;
          const isBetListCounter =
            isLoggedIn && betListCounter > 0 && (type === TypeFields.myBets || type === TypeFields.betList);
          const hasCounter = isCashOutCounter || isBetListCounter;
          const counterValue = (isCashOutCounter && cashOutCounter) || (isBetListCounter && betListCounter) || 0;

          return (
            <div
              key={type}
              className={classNames('biab_mobile-nav-link', {
                biab_active: isActiveUrl && !noActiveItems && (!isAsianViewPage || !isAsianViewNotFoundView),
                'biab_cashout biab_cash-out-tab': isCashOut,
                biab_disabled: isDisabled,
                [styles.disabledItem]: isDisabled
              })}
            >
              <NavLink to={linkUrl} key={type} className="biab_plural-mobile-link" onClick={handleClickLink(type)}>
                <div
                  className={classNames(styles.item, style, 'biab_mobile-nav-links', {
                    'biab_mobile-nav-link-text': isAsianViewPage
                  })}
                >
                  {hasCounter ? (
                    <div className={styles.iconWrapper}>
                      <NavIcon isAsianViewPage={isAsianViewPage} style={style} icon={icon} type={type} />
                      <div
                        className={classNames(styles.counter, {
                          [asianViewMiddleSection.MENU_COUNTER]: isAsianViewPage,
                          [mobileBranding.NAV_COUNTER]: !isAsianViewPage,
                          [styles.counter__right]: mobileBetListCounter,
                          [styles.counter__moreThanDoubleDigitNumber]: counterValue > MAX_DOUBLE_DIGIT_NUMBER,
                          [styles.counter__moreThanDoubleDigitNumberDrawer]:
                            counterValue > MAX_DOUBLE_DIGIT_NUMBER &&
                            positionOfAsianViewMobileNavigationMenu &&
                            !isLandscapeAsianView,
                          [styles.counter__moreThanTripleDigitNumberDrawer]:
                            counterValue > MAX_TRIPLE_DIGIT_NUMBER &&
                            positionOfAsianViewMobileNavigationMenu &&
                            !isLandscapeAsianView
                        })}
                      >
                        <p>{counterValue > 999 ? '999+' : counterValue}</p>
                      </div>
                    </div>
                  ) : (
                    <NavIcon isAsianViewPage={isAsianViewPage} style={style} icon={icon} type={type} />
                  )}
                  {isMobileMenuButtonTextEnabled && (
                    <span
                      className={classNames(styles.itemLabel, style, {
                        'biab_mobile-nav-link-text': !isAsianViewPage
                      })}
                    >
                      {t(text)}
                    </span>
                  )}
                </div>
              </NavLink>
            </div>
          );
        })}
        {isAsianViewEnabled && isAsianViewPage && (
          <AsianMoreLink
            className={classNames('biab_mobile-nav-link', {
              biab_active: isMorePage,
              [styles.asianMoreLink]: !isDrawer && !isLandscapeAsianView,
              [styles.asianMoreLink__drawerItem]: isDrawer && !isLandscapeAsianView
            })}
          />
        )}
      </div>
    </>
  ) : null;
};

export default MobileToolbar;
