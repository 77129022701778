import { memo, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import QuickBetting from 'components/AsianViewPageModule/components/QuickBetting';
import { CookieNames, MarketStatuses, SportIds } from 'constants/app';
import {
  ASIAN_VIEW_BET_SLIP_BET_IDENTIFIER_SEPARATOR,
  AsianBetslipTabs,
  AsianViewSelectedBetStates
} from 'constants/asianView';
import { BetDatabaseNames } from 'constants/bets';
import { asianViewIcons, asianViewMiddleSection as branding } from 'constants/branding';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { ASIAN_VIEW_MARKET_SUSPENDED } from 'constants/tooltip';
import useAdjHandicap from 'hooks/useAdjHandicap';
import { useCurrency } from 'hooks/useCurrency';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import usePostMessage from 'hooks/usePostMessage';
import useTooltip from 'hooks/useTooltip';
import { getLanguage, getMinAmountToShowOdds, getTranslation } from 'redux/modules/appConfigs/selectors';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { getAsianEvent, getAsianMarket, getAsianRunner, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { setSelectedBet, setSelectedTab } from 'redux/modules/asianViewBetslip';
import { getIsAsianSelectedBetExist, getIsBetslipSelectedTab } from 'redux/modules/asianViewBetslip/selectors';
import { removeMobileSelectedBet, setMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import { getIsAsianMobileSelectedBetByCell } from 'redux/modules/asianViewMobileBetslip/selectors';
import { removeQuickBet, setQuickBet } from 'redux/modules/asianViewQuickBetting';
import { getAsianQuickBettingItem } from 'redux/modules/asianViewQuickBetting/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setCollapseNewState } from 'redux/modules/collapse';
import { ASIAN_VIEW_BETSLIP_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';
import { getOpenedStateByGroup } from 'redux/modules/collapse/selectors';
import {
  getBetAmount,
  getBetOdds,
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesBettingTypeById,
  getMarketPricesFirstKeyLineAdjustedHandicap,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesScoreById,
  getMarketPricesSecondKeyLineAdjustedHandicap
} from 'redux/modules/marketsPrices/selectors';
import { getUserAsianViewQuickBetting, getUserDefaultStakeValue } from 'redux/modules/user/selectors';
import { MarketStatus } from 'types';
import { AsianViewMarketLink } from 'types/asianView';
import { THandicap } from 'types/bets';
import { BetSide } from 'types/myBets';
import { getBooleanValue } from 'utils';
import { getSelectedBetIdentifier, replaceHandicaps } from 'utils/asianView';
import { calculateLiability } from 'utils/liability';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

export interface OddsCellProps {
  betType: BetSide;
  marketCurrency?: string;
  marketStatus?: MarketStatus;
  isSingleLine?: boolean;
  competitionId: string;
  eventId: string;
  marketId: string;
  selectionId: string;
  handicap?: THandicap;
  oddsLabel?: string;
  betslipId?: string;
  runnerIndex?: number;
  isExpandedCoupon?: boolean;
}

const OddsCell = ({
  betType,
  marketStatus,
  marketCurrency,
  isSingleLine = false,
  competitionId,
  eventId,
  marketId,
  selectionId,
  handicap,
  oddsLabel = '',
  betslipId,
  runnerIndex,
  isExpandedCoupon = false
}: OddsCellProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { marketLink, sportId = SportIds.SOCCER } = useParams();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_QUICK_BETTING]);

  const prevHandicap = useRef<THandicap | null | undefined>(handicap);

  const betParams = { marketId, selectionId, handicap, betType };
  const betIdentifier = getSelectedBetIdentifier(betParams);
  const prevMobileBetIdentifier = getSelectedBetIdentifier({ ...betParams, handicap: prevHandicap.current });
  const isBack = betType === BetSides.Back;
  const betName = isBack ? BetDatabaseNames.BACK : BetDatabaseNames.LAY;

  const language = useSelector(getLanguage);
  const translation = useSelector(getTranslation);
  const oddsType = useSelector(getOddsType);
  const minAmountToShowOdds = useSelector(getMinAmountToShowOdds);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isSelectedBetExist = useSelector(getIsAsianSelectedBetExist(betIdentifier));
  const quickBet = useSelector(getAsianQuickBettingItem(betIdentifier));
  const isMobileSelectedBet = useSelector(getIsAsianMobileSelectedBetByCell({ betslipId, identifier: betIdentifier }));
  const prevIsMobileSelectedBet = useSelector(
    getIsAsianMobileSelectedBetByCell({ betslipId, identifier: prevMobileBetIdentifier })
  );
  const score = useSelector(getMarketPricesScoreById(marketId));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const isUserQuickBetting = useSelector(getUserAsianViewQuickBetting);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const event = useSelector(getAsianEvent(eventId));
  const market = useSelector(getAsianMarket(eventId, marketId));
  const runner = useSelector(getAsianRunner(eventId, marketId, Number(selectionId), handicap));
  const isExpandedBetSlip = useSelector(
    getOpenedStateByGroup(LEFT_PANEL_COLLAPSE_GROUP_ASIAN, ASIAN_VIEW_BETSLIP_COLLAPSE)
  );
  const isBetSlipSelectedTab = useSelector(getIsBetslipSelectedTab);
  const bettingType = useSelector(getMarketPricesBettingTypeById(marketId));
  const defaultStake = useSelector(getUserDefaultStakeValue);
  const amount = useSelector(getBetAmount(marketId, +selectionId, Number(handicap), betName, 0));
  let odds = useSelector(getBetOdds(marketId, +selectionId, Number(handicap), betName, 0));
  const isSelectionLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(marketId, +selectionId, Number(handicap))
  );
  const asianHandicapFirstKeyLineAdjHandicap = useSelector(getMarketPricesFirstKeyLineAdjustedHandicap(marketId));
  const asianHandicapSecondKeyLineAdjHandicap = useSelector(getMarketPricesSecondKeyLineAdjustedHandicap(marketId));

  const { translationKey: suspendedLabelKey, isEnabled: isSuspendedLabelTooltipEnabled } =
    useTooltip(ASIAN_VIEW_MARKET_SUSPENDED);

  const adjKLHandicap =
    runnerIndex === 0 ? asianHandicapFirstKeyLineAdjHandicap : asianHandicapSecondKeyLineAdjHandicap;

  const { isMobile } = useDevice();
  const { placeBetLogin } = usePostMessage();
  const currency = useCurrency(marketCurrency);
  const isQuickBetting = isLoggedIn ? isUserQuickBetting : getBooleanValue(cookies.ASIAN_VIEW_QUICK_BETTING);

  const { defStake } = useDeviceSettings();

  const [isHighlighted, setIsHighlighted] = useState(false);

  const isGoingUp = useRef(false);

  const isDefaultFormat = currency?.displayFormat === 1;

  const getIsSelected = () => {
    if (isMobileAsianView) {
      return isMobileSelectedBet;
    }

    return isQuickBetting ? quickBet && quickBet.isExpandedCoupon === isExpandedCoupon : isSelectedBetExist;
  };

  const isSelected = getIsSelected();

  const prevOdds = useRef<null | number | undefined>(null);

  if (!isDefaultFormat) {
    if (Math.ceil(amount ?? 0) <= minAmountToShowOdds) {
      odds = 0;
    }
  } else {
    if (Math.floor(amount ?? 0) <= minAmountToShowOdds) {
      odds = 0;
    }
  }

  const isPrevOddsLower = !!(prevOdds.current && prevOdds.current < (odds || 0));
  const isSuspended = marketStatus === MarketStatuses.SUSPENDED;

  if (prevOdds.current !== null && odds !== prevOdds.current) {
    isGoingUp.current = isBack ? isPrevOddsLower : !isPrevOddsLower;
    setIsHighlighted(true);
  }

  prevOdds.current = odds;
  const showLockIcon = !odds || isSuspended || isSelectionLocked;

  const isAsianHandicap = market?.marketType === MARKET_TYPES.asianHandicap;
  const isGoalLines = market?.marketType === MARKET_TYPES.altTotalGoals;
  const adjHandicap = useAdjHandicap({ marketId: market?.id, marketType: market?.marketType, handicap, runnerIndex });

  const selectBetHandler = () => {
    if (showLockIcon) {
      return;
    }

    if (!isLoggedIn) {
      placeBetLogin();
      return;
    }

    const size = defStake ? defaultStake || '' : '';
    const profit = calculateLiability(odds, size, {
      marketType: market?.marketType,
      bettingType: bettingType,
      betType: betType
    });
    const adjHandicapParam = isAsianHandicap ? { adjHandicap, adjKLHandicap } : {};
    const selectionName = runner?.translations[language] ?? '';
    const selectionNameUpdated = isAsianHandicap
      ? replaceHandicaps(selectionName, handicap, adjHandicap)
      : selectionName;
    const dateTime = new Date().getTime();

    const bet = {
      competitionId,
      eventId,
      marketId,
      selectionId: Number(selectionId),
      handicap,
      betType,
      price: odds,
      size,
      profit,
      score,
      marketLink: marketLink as AsianViewMarketLink,
      sportId,
      isInPlay,
      oddsType,
      bettingType,
      marketType: market?.marketType,
      competitionName: (event?.competitionTranslations?.[translation] || event?.translations?.[translation]) ?? '',
      eventName: event?.translations[translation],
      marketName: market?.translations[translation],
      selectionName: selectionNameUpdated,
      runnerIndex,
      isExpandedCoupon,
      state: AsianViewSelectedBetStates.selected,
      identifier: betIdentifier,
      dateTime,
      fullIdentifier: `${betIdentifier}${ASIAN_VIEW_BET_SLIP_BET_IDENTIFIER_SEPARATOR}${dateTime}`,
      ...adjHandicapParam
    };

    if (isMobile) {
      dispatch(setMobileSelectedBet({ ...bet, betslipId }));
    } else {
      dispatch(isQuickBetting ? setQuickBet(bet) : setSelectedBet(bet));
    }

    if (!isExpandedBetSlip && !isQuickBetting) {
      dispatch(
        setCollapseNewState({
          group: LEFT_PANEL_COLLAPSE_GROUP_ASIAN,
          collapse: ASIAN_VIEW_BETSLIP_COLLAPSE,
          openedState: true
        })
      );
    }

    if (!isBetSlipSelectedTab && !isQuickBetting) {
      dispatch(setSelectedTab(AsianBetslipTabs.BET_SLIP));
    }
  };

  useEffect(() => {
    if (isMobile && isGoalLines && prevIsMobileSelectedBet && prevHandicap.current !== handicap) {
      dispatch(removeMobileSelectedBet({ betslipId }));
    }

    prevHandicap.current = handicap;

    return () => {
      if (isMobile && isSelected) {
        dispatch(removeMobileSelectedBet({ betslipId }));
      }
    };
  }, [handicap]);

  useEffect(() => {
    return () => {
      // Remove quick bet with the relative cell when key line changes
      if (quickBet) {
        dispatch(removeQuickBet(quickBet.identifier));
      }
    };
  }, []);

  return (
    <span>
      <button
        onClick={selectBetHandler}
        data-cell-id={selectionId}
        data-tooltip-id={'tooltip'}
        data-tooltip-html={isSuspendedLabelTooltipEnabled && isSuspended ? t(suspendedLabelKey) : ''}
        className={classNames(
          `biab_asian-view-market-${betType.toLocaleLowerCase()}`,
          styles[`marketCell__odds__${betType.toLocaleLowerCase()}`],
          {
            [styles.marketCell__odds__small]: isSingleLine,
            [styles.locked]: showLockIcon && !isSuspended,
            [styles.suspended]: isSuspended,
            [styles.mobileSize]: isMobileAsianView,
            [styles.selected]: isSelected && !showLockIcon,
            [styles.highlighted]: isHighlighted,
            [styles.goingUp]: isHighlighted && isGoingUp.current,
            [styles.goingDown]: isHighlighted && !isGoingUp.current,
            'biab_going-up': isHighlighted && isGoingUp.current,
            'biab_going-down': isHighlighted && !isGoingUp.current,
            biab_highlighted: isHighlighted,
            [branding.BACK_CELL]: isBack && !showLockIcon,
            [branding.LAY_CELL]: !isBack && !showLockIcon,
            [branding.SELECTED_CELL]: isSelected && !showLockIcon,
            [branding.MORE_PROFITABLE_CELL]: isHighlighted && isGoingUp.current && !showLockIcon,
            [branding.LESS_PROFITABLE_CELL]: isHighlighted && !isGoingUp.current && !showLockIcon,
            [branding.LOCKED_CELL]: showLockIcon
          }
        )}
        disabled={showLockIcon && !isSuspended && !isMobile}
        onAnimationEnd={() => setIsHighlighted(false)}
      >
        {oddsLabel && !showLockIcon && (
          <div className={classNames('biab_asian-view-odds-label', styles.oddsLabel)}>{oddsLabel}</div>
        )}
        {!showLockIcon && <div className={styles.oddsValue}>{convertOdds(odds || ' ', oddsType)}</div>}
        {showLockIcon && (
          <i
            className={classNames('biab_lock-icon fa2', styles.lockIcon, asianViewIcons.LOCKED_CELL_ICON, {
              ['fa2-lock']: !isSuspended,
              ['fa2-clock-16']: isSuspended && !isMobile,
              ['fa2-clock-24']: isSuspended && isMobile
            })}
          />
        )}
        {!showLockIcon && isHighlighted && (
          <i
            className={classNames(
              `fa2 fa2-sort-${isGoingUp.current ? 'up' : 'down'} biab_indicator`,
              styles.indicator,
              {
                [styles.indicator__up]: isHighlighted && isGoingUp.current,
                [styles.indicator__down]: isHighlighted && !isGoingUp.current,
                [branding.MORE_PROFITABLE_INDICATOR]: isHighlighted && isGoingUp.current,
                [branding.LESS_PROFITABLE_INDICATOR]: isHighlighted && !isGoingUp.current
              }
            )}
          />
        )}
      </button>
      {isQuickBetting && isSelected && !isMobile && <QuickBetting betIdentifier={betIdentifier} />}
    </span>
  );
};

export default memo(OddsCell);
