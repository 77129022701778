import { memo, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { animated, useSpring } from '@react-spring/web';
import classNames from 'classnames';

import CashOutButton from 'components/CashOutButton';
import Icon from 'components/Icon';
import { CookieNames } from 'constants/app';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import OpenBet from 'pages/mobile/MobileBetListPage/components/OpenBet/OpenBet';
import { getCashOutByMarketId } from 'redux/modules/cashOut/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { addBetsGroupedByOfferId, getBetsAmountWithConsolidation } from 'utils/currentBets';

import styles from './BetMarket.module.scss';

interface BetMarketProps {
  bets: TCurrentBet[];
  marketId: string;
}

const BetMarket = ({ bets, marketId }: BetMarketProps) => {
  const { t } = useTranslation();
  const currentCashOut = useSelector(getCashOutByMarketId(marketId));
  const [cookies, setCookie] = useCookies([CookieNames.BET_LIST_MARKETS_COLLAPSED]);
  const collapsedList: string[] = cookies.BIAB_BET_LIST_MARKETS_COLLAPSED?.split(',') ?? [];
  const isCollapsedCookieValue = collapsedList.includes(marketId);

  const isToggled = useRef(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedCookieValue);

  const [contentRef, { height: contentHeight }] = useMeasure();
  const contentStyles = useSpring({
    from: { height: 0 },
    to: { height: isCollapsed ? 0 : contentHeight || 'auto' },
    config: { duration: 200 },
    immediate: !isToggled.current
  });
  const commonBetData = bets[0];
  const { marketName, marketLink, formatCurrencySetting, isEachWay } = useOpenBetData(commonBetData);
  const { noFormattedAmount: marketLiability } = useFormatCurrency(
    commonBetData.marketLiability,
    commonBetData.currency,
    formatCurrencySetting
  );

  const marketBets = useMemo(() => {
    return addBetsGroupedByOfferId(bets);
  }, [bets]);

  const toggleCollapse = () => {
    let newList: string[];

    if (!isCollapsedCookieValue) {
      newList = [...collapsedList, marketId];
    } else {
      newList = collapsedList.filter((item: string) => item !== marketId);
    }

    isToggled.current = true;

    setCookie(CookieNames.BET_LIST_MARKETS_COLLAPSED, newList.join(), { path: '/' });
    setIsCollapsed(currIsCollapsed => !currIsCollapsed);
  };

  return (
    <div className={styles.market}>
      <header className={styles.market__header}>
        <div className={styles.market__info}>
          <Link to={marketLink} className={classNames(styles.market__name)}>
            {marketName}
          </Link>
          {isEachWay && (
            <span className={styles.market__details}>
              {t('market.each.way.termsNoPref', {
                odds: commonBetData.eachWayDivisor,
                places: commonBetData.numberOfWinners
              })}
            </span>
          )}
          <span className={styles.market__details}>
            {t('account.betList.labels.betsAndExposure', {
              N: getBetsAmountWithConsolidation(marketBets),
              amount: marketLiability
            })}
          </span>
        </div>
        <button className={styles.market__collapseBtn} onClick={toggleCollapse}>
          <Icon
            fontFamily="fa2"
            iconClass="fa2-chevron-down-icon"
            className={classNames(styles.market__collapseIcon, {
              [styles.market__collapseIcon__collapsed]: !isCollapsed
            })}
            size="lg"
          />
        </button>
      </header>
      <animated.div style={{ overflow: 'hidden', ...contentStyles }}>
        <div ref={contentRef} className={styles.market__bets}>
          {marketBets.map(bet => (
            <OpenBet key={bet.offerId} bet={bet} isEmbedded />
          ))}
        </div>
      </animated.div>
      {currentCashOut && (
        <div className={styles.market__footer}>
          <CashOutButton
            marketId={marketId}
            partialCashOutEnabled={commonBetData.partialCashOutEnabled}
            buttonClassName={styles.market__cashOutBtn}
            settingsBtnClassName={styles.market__cashOutSettings}
            containerClassName={styles.market__cashOutContainer}
            containerContentClassName={styles.market__cashOutContainerContent}
            hideLiability
          />
        </div>
      )}
    </div>
  );
};

export default memo(BetMarket);
