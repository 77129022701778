import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, map } from 'lodash';

import { BetsStatusesTypes, SLICES_NAMES } from 'constants/app';
import {
  ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE,
  AsianBettingActions,
  AsianPlacedQuickBettingStatuses
} from 'constants/asianView';
import { BetSides } from 'constants/myBets';
import { AsianViewBetStatusResponse } from 'redux/modules/asianViewBetslip/type';
import { isPlacedBetStatusPlaced } from 'utils/asianView';

import {
  AsianPlacementStatus,
  TAsianFailurePlaceQuickBetPayload,
  TAsianPlaceQuickBetPayload,
  TAsianQuickBet,
  TAsianQuickPlacedBet,
  TAsianViewQuickBettingState,
  TPlacedQuickBetsByMarket
} from './type';

const initialState: TAsianViewQuickBettingState = {
  quickBets: {},
  placedBets: {},
  activePlacedBetIndex: 0,
  isPlacedBetsLoading: false
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_QUICK_BETTING,
  initialState,
  reducers: {
    setQuickBet: (state, { payload }: PayloadAction<TAsianQuickBet>) => {
      const { identifier, price } = payload;

      if (state.quickBets[identifier]) {
        const { action, isExpandedCoupon } = state.quickBets[identifier];
        if (isExpandedCoupon !== payload.isExpandedCoupon) {
          state.quickBets[identifier].isExpandedCoupon = payload.isExpandedCoupon;
        } else {
          if (action !== AsianBettingActions.PROGRESS && action !== AsianBettingActions.HIDDEN) {
            delete state.quickBets[identifier];
          } else if (action === AsianBettingActions.PROGRESS) {
            state.quickBets[identifier].action = AsianBettingActions.HIDDEN;
          }
        }
      } else {
        map(state.quickBets, (bet, betId) => {
          if (bet.action !== AsianBettingActions.PROGRESS && bet.action !== AsianBettingActions.HIDDEN) {
            delete state.quickBets[betId];
          } else if (bet.action === AsianBettingActions.PROGRESS) {
            state.quickBets[betId].action = AsianBettingActions.HIDDEN;
          }
        });

        state.quickBets[identifier] = {
          ...payload,
          identifier,
          initPrice: price,
          betUuid: identifier,
          order: new Date().getTime(),
          action: AsianBettingActions.PLACE
        };
      }
    },
    updateQuickBet: (state, { payload }: PayloadAction<{ identifier: string; data: Partial<TAsianQuickBet> }>) => {
      if (payload.identifier && state.quickBets[payload.identifier]) {
        Object.assign(state.quickBets[payload.identifier], payload.data);
      }
    },
    removeQuickBet: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload && state.quickBets[payload]) {
        delete state.quickBets[payload];
      }
    },
    clearQuickBets: state => {
      state.quickBets = {};
    },
    clearQuickLayBets: state => {
      const bets = Object.keys(state.quickBets);

      bets.forEach(identifier => {
        if (identifier.includes(BetSides.Lay)) {
          delete state.quickBets[identifier];
        }
      });
    },
    placeQuickBet: (state, { payload }: PayloadAction<TAsianPlaceQuickBetPayload>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.entries(payload.data).forEach(([, selectedBets]) => {
        selectedBets.forEach(selectedBet => {
          if (state.quickBets[payload.identifier]) {
            state.placedBets[selectedBet.betUuid] = {
              ...state.quickBets[payload.identifier],
              status: AsianPlacedQuickBettingStatuses.PLACE,
              updateTime: new Date().getTime()
            };

            delete state.quickBets[payload.identifier];
            placedBetsFromStorage[selectedBet.betUuid] = { ...state.placedBets[selectedBet.betUuid] };
          }
        });
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    successPlaceQuickBet: (state, { payload }: PayloadAction<TPlacedQuickBetsByMarket>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.entries(payload).forEach(([marketId, { status, exception, identifier: betIdentifier, offerIds }]) => {
        if (betIdentifier && placedBetsFromStorage[betIdentifier]) {
          delete placedBetsFromStorage[betIdentifier];
        }

        if (status === AsianPlacementStatus.OK) {
          Object.entries(offerIds ?? {}).forEach(([identifier, offerId]) => {
            if (state.placedBets[identifier]) {
              state.placedBets[identifier].offerId = offerId;

              /** Placed bets */
              state.placedBets[offerId] = {
                ...state.placedBets[identifier],
                offerId,
                status: AsianPlacedQuickBettingStatuses.CREATED,
                order: 0,
                updateTime: state.placedBets[identifier]?.updateTime || new Date().getTime()
              };

              /** Remove placed bet that was created before offerId was received */
              if (betIdentifier && state.placedBets[betIdentifier]) {
                delete state.placedBets[betIdentifier];
              }

              /** Set Quick bet to Storage */
              placedBetsFromStorage[offerId] = { ...state.placedBets[offerId] };
              localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
            }
          });
        } else if (status === AsianPlacementStatus.FAIL) {
          if (betIdentifier && state.placedBets[betIdentifier]) {
            /** Placed bets */
            state.placedBets[betIdentifier] = {
              ...state.placedBets[betIdentifier],
              identifier: betIdentifier,
              status: AsianPlacedQuickBettingStatuses.ERROR,
              placementError: exception?.message ?? '',
              order: new Date().getTime(),
              updateTime: state.placedBets[betIdentifier]?.updateTime || new Date().getTime()
            };

            /** Set Quick bet to Storage */
            placedBetsFromStorage[betIdentifier] = { ...state.placedBets[betIdentifier] };
            localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
          }
        }
      });
    },
    failurePlaceQuickBet: (
      state,
      { payload }: PayloadAction<{ data: TAsianFailurePlaceQuickBetPayload; error: string }>
    ) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.keys(payload.data).forEach(marketId => {
        payload.data[marketId].forEach(({ betUuid }) => {
          if (betUuid && state.placedBets[betUuid]) {
            state.placedBets[betUuid].placementError = payload.error;

            /** Placed bets */
            state.placedBets[betUuid] = {
              ...state.placedBets[betUuid],
              identifier: betUuid,
              status: AsianPlacedQuickBettingStatuses.ERROR,
              placementError: payload?.error ?? '',
              order: new Date().getTime(),
              updateTime: state.placedBets[betUuid]?.updateTime || new Date().getTime()
            };

            /** Set Quick bet to Storage */
            placedBetsFromStorage[betUuid] = { ...state.placedBets[betUuid] };
            localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
          }
        });
      });
    },
    fetchQuickBetStatuses: (state, { payload }: PayloadAction<{ offerIds: number[] }>) => {
      payload.offerIds.forEach(offerId => {
        state.placedBets[offerId].isLoading = true;
      });
    },
    successFetchQuickBetStatuses: (state, { payload }: PayloadAction<AsianViewBetStatusResponse>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.keys(payload).forEach(offerId => {
        state.placedBets[offerId].isLoading = false;

        if (isPlacedBetStatusPlaced(payload[offerId].state) || payload[offerId].exception) {
          if (state.placedBets[offerId]) {
            state.placedBets[offerId].order = new Date().getTime();
            state.isPlacedBetsLoading = true;

            if (payload[offerId].exception) {
              state.placedBets[offerId].status = AsianPlacedQuickBettingStatuses.ERROR;
              state.placedBets[offerId].placementError = payload[offerId].exception?.message ?? '';
            } else {
              state.placedBets[offerId].offerStatus = payload[offerId].state;
              state.placedBets[offerId].status =
                payload[offerId].state === BetsStatusesTypes.EXPIRED
                  ? AsianPlacedQuickBettingStatuses.ERROR
                  : AsianPlacedQuickBettingStatuses.PLACED;
            }

            if (state.placedBets[offerId].status === AsianPlacedQuickBettingStatuses.ERROR) {
              state.placedBets[offerId].isHidden = false;
              state.placedBets[offerId].noAnimation = false;
            }

            const findBet = Object.values(state.quickBets).find(bet => +(bet.offerId ?? 0) === +offerId);

            if (findBet && findBet.identifier) {
              delete state.quickBets[findBet.identifier];
            }
          }

          /** Update Quick bet in Storage */
          if (placedBetsFromStorage[offerId]) {
            placedBetsFromStorage[offerId] = state.placedBets[offerId];
          }

          localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
        }
      });
    },
    failureFetchQuickBetStatuses: (state, { payload }: PayloadAction<{ offerIds: number[]; error: any }>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      payload.offerIds.forEach(offerId => {
        if (state.placedBets[offerId]) {
          state.placedBets[offerId].status = AsianPlacedQuickBettingStatuses.ERROR;
          state.placedBets[offerId].placementError = payload.error?.message;
          state.placedBets[offerId].order = new Date().getTime();
          state.placedBets[offerId].isHidden = false;
          state.placedBets[offerId].noAnimation = false;
          state.isPlacedBetsLoading = true;

          const findBet = Object.values(state.quickBets).find(bet => +(bet.offerId ?? 0) === +offerId);

          if (findBet && findBet.identifier) {
            delete state.quickBets[findBet.identifier];
          }
        }

        /** Update Quick bet in Storage */
        if (placedBetsFromStorage[offerId]) {
          placedBetsFromStorage[offerId].status = AsianPlacedQuickBettingStatuses.ERROR;
          placedBetsFromStorage[offerId].isHidden = false;
          placedBetsFromStorage[offerId].noAnimation = false;
          placedBetsFromStorage[offerId].placementError = payload.error?.message;
        }

        localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
      });
    },
    removePlacedQuickBet: (state, { payload }: PayloadAction<number | string>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      if (payload && state.placedBets[payload]) {
        delete state.placedBets[payload];
      }

      const placedBetsAmount = Object.keys(state.placedBets).length;

      if (placedBetsAmount && placedBetsAmount <= state.activePlacedBetIndex) {
        state.activePlacedBetIndex = placedBetsAmount - 1;
      }

      /** Update Quick bet in Storage */
      delete placedBetsFromStorage[payload];
      localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    updatePlacedQuickBet: (state, { payload }: PayloadAction<TAsianQuickPlacedBet>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      if (payload && state.placedBets[payload.identifier]) {
        state.placedBets[payload.identifier] = { ...payload };

        /** Update Quick bet in Storage */
        placedBetsFromStorage[payload.identifier] = state.placedBets[payload.identifier];
        localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
      } else if (payload && payload.offerId && state.placedBets[payload.offerId]) {
        state.placedBets[payload.offerId] = { ...payload };

        /** Update Quick bet in Storage */
        placedBetsFromStorage[payload.offerId] = state.placedBets[payload.offerId];
        localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
      }
    },
    setActivePlacedBetIndex: (state, { payload }: PayloadAction<number>) => {
      if (Object.keys(state.placedBets).length >= payload) {
        state.activePlacedBetIndex = payload;
      }
    },
    setIsPlacedBetsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlacedBetsLoading = payload;
    },
    loadPlacedQuickBetsFromStorage: state => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE);
      const placedBetsFromStorage: Record<string, TAsianQuickPlacedBet> = placedBetsFromStorageValue
        ? JSON.parse(placedBetsFromStorageValue)
        : {};

      Object.entries(placedBetsFromStorage).forEach(([identifier, bet]) => {
        if (!bet.offerId) {
          delete placedBetsFromStorage[identifier];
        }
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));

      if (isEmpty(state.placedBets) && !isEmpty(placedBetsFromStorage)) {
        state.placedBets = placedBetsFromStorage;
      }
    }
  }
});

export const {
  removeQuickBet,
  setQuickBet,
  updateQuickBet,
  placeQuickBet,
  successPlaceQuickBet,
  failurePlaceQuickBet,
  removePlacedQuickBet,
  updatePlacedQuickBet,
  clearQuickBets,
  clearQuickLayBets,
  setActivePlacedBetIndex,
  setIsPlacedBetsLoading,
  fetchQuickBetStatuses,
  successFetchQuickBetStatuses,
  failureFetchQuickBetStatuses,
  loadPlacedQuickBetsFromStorage
} = slice.actions;

export default slice.reducer;
