import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import LiveScoreName from 'components/LiveScoreName';
import MarketDropdown from 'components/MarketDropdown';
import MatchStatistics from 'components/MatchStatistics';
import MobileEventWidgets from 'components/MobileEventWidgets';
import tooltipStyle from 'components/Tooltip/styles.module.scss';
import VideoStream from 'components/VideoStream';
import { Devices, MatchStatuses, PageBlocks, SportIds } from 'constants/app';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useHideLabelsOnCollision from 'hooks/useHideLabelsOnCollision';
import useSoccerTimeByEventId from 'hooks/useSoccerTime';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { TFavorite } from 'redux/modules/favorites/type';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataElapsedAddedTime,
  getEventsUpdatedDataEventTypeId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getEventsUpdatedDataStringifiedUpdateDetails,
  getEventsUpdatedDataTimeElapsed,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';
import { EventScorePeriod, PageBlock, TEventExtraData } from 'types';
import {
  convertTeam,
  getDistance,
  getEventPenaltyData,
  getEventPosition,
  getGoalsEvents,
  soccerCards,
  soccerGoal
} from 'utils/football';
import { getSoccerLabel } from 'utils/market';

import styles from './styles.module.scss';

type EventHeaderSoccerProps = {
  pageBlock?: PageBlock;
  isCloseMarket?: boolean;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  videoStreamingEnabled?: boolean;
  eventId: string;
  matchStatEnabled?: boolean;
  sportId: string;
};

const minuteSymbol = '’';

const EventHeaderSoccer = ({
  pageBlock,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled = false,
  sportId
}: EventHeaderSoccerProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const elapsedAddedTime = useSelector(getEventsUpdatedDataElapsedAddedTime(eventId));
  const timeElapsed = useSelector(getEventsUpdatedDataTimeElapsed(eventId));
  const period = useSelector(getEventsUpdatedDataPeriod(eventId));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(eventId));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(eventId));
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const eventTypeId = useSelector(getEventsUpdatedDataEventTypeId(eventId));
  const stringifiedUpdateDetails = useSelector(getEventsUpdatedDataStringifiedUpdateDetails(eventId));

  useBackButtonInIFrame();
  const showIFrameBackButton = useBackButtonInIFrame();

  const isMobile = device === Devices.MOBILE;

  const updateDetails = useMemo<TEventExtraData[]>(
    () => (stringifiedUpdateDetails ? JSON.parse(stringifiedUpdateDetails) : []),
    [stringifiedUpdateDetails]
  );

  const { homeGoals, awayGoals } = getGoalsEvents(updateDetails);

  const isFinished = inPlayMatchStatus === MatchStatuses.END || isCloseMarket;

  const duringFirstMatch = getDistance(timeElapsed, 'first');
  const duringSecondMatch =
    (inPlayMatchStatus === MatchStatuses.KICK_OFF || inPlayMatchStatus === MatchStatuses.HALF_TIME) &&
    period !== EventScorePeriod.EXTRA
      ? 0
      : getDistance(timeElapsed - (elapsedAddedTime || 0), 'second');
  const duringExtraFirstMatch = getDistance(timeElapsed, 'extra_first');
  const duringExtraSecondMatch =
    inPlayMatchStatus === MatchStatuses.KICK_OFF || inPlayMatchStatus === MatchStatuses.HALF_TIME
      ? 0
      : getDistance(timeElapsed - (elapsedAddedTime || 0), 'extra_second');
  const events = getEventPosition(updateDetails);
  const penaltyData = getEventPenaltyData(updateDetails);

  const { time: elapsedTime, addedTime } = useSoccerTimeByEventId(eventId);

  const label = getSoccerLabel({
    t,
    elapsedRegularTime: elapsedTime,
    elapsedAddedTime: addedTime,
    inPlayMatchStatus,
    period
  });

  const isSearchType = pageBlock === PageBlocks.SEARCH;
  const isShowFavoriteOnLeft = (isSearchType && !isMobile && !isCloseMarket) || (isMobile && !isCloseMarket);
  const hasBackButton = isMobile && !isSearchType && showIFrameBackButton;
  const hasPenalty = penaltyData.hasPenalty || inPlayMatchStatus === MatchStatuses.PENALTY_KICK_OFF;

  const { sportWrapperRef, isIntersect, rightSideContainerRef, centerWrapperRef, teamNameRef } =
    useHideLabelsOnCollision(true);

  const bars = [
    { events: events.first, isExtra: false, duringMatch: duringFirstMatch },
    { events: events.second, isExtra: false, duringMatch: duringSecondMatch },
    ...(period === EventScorePeriod.EXTRA
      ? [
          { events: events.extra_first, isExtra: true, duringMatch: duringExtraFirstMatch },
          { events: events.extra_second, isExtra: true, duringMatch: duringExtraSecondMatch }
        ]
      : [])
  ];

  return (
    <>
      <div className={classNames({ biab_market: isSearchType })} data-inplay-widget="soccer">
        <div
          className={classNames('biab_market-title biab_event-name-inplay-time', styles.soccer__container)}
          ref={sportWrapperRef}
        >
          <div className={styles.soccer__headerContainer}>
            <div
              className={classNames(styles.eventHeader__minSide, {
                [styles.eventHeader__hasBackButton]: hasBackButton
              })}
              style={{
                minWidth: (rightSideContainerRef.current?.clientWidth || 0) + 12
              }}
            >
              {hasBackButton && <BackButton />}
              {!hasBackButton && isShowFavoriteOnLeft && (
                <div className={styles.eventHeader__favoriteLeft}>
                  <FavoriteStar entryData={favoriteData} />
                </div>
              )}
            </div>

            <div className={classNames('biab_running-ball-widget biab_soccer', styles.soccer)} ref={centerWrapperRef}>
              <div className={classNames(styles.soccer__teamNameWrapper, styles.soccer__first)}>
                <div className={classNames('biab_team-name', styles.soccer__teamName)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={homeName}
                    eventId={eventId}
                  />
                </div>
                <div className={classNames('biab_team-name', styles.soccer__teamName, styles.soccer__hideSecondName)}>
                  {awayName}
                </div>
                {!!homeGoals.length && (
                  <div className={classNames('biab_goals', styles.soccer__homeScore)}>
                    <i className="biab_running-ball-icon biab_rb-football-icon" />
                    <div className="biab_goals">
                      {homeGoals.map(
                        (item, index) => `${item.matchTime}${minuteSymbol}${index !== homeGoals.length - 1 ? ', ' : ''}`
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className={classNames('biab_score-wrap', styles.soccer__scoreWrap)}>
                <div className={classNames('biab_score', styles.soccer__score)}>
                  <span className={isFinished ? (homeScore <= awayScore ? 'biab_loser' : 'biab_winner') : ''}>
                    {homeScore}
                  </span>
                  <span className={classNames(styles.soccer__diver, { biab_loser: isFinished })}>-</span>
                  <span className={isFinished ? (homeScore >= awayScore ? 'biab_loser' : 'biab_winner') : ''}>
                    {awayScore}
                  </span>
                </div>
                <div className={classNames('biab_time', styles.soccer__time)}>{label}</div>
              </div>

              <div className={styles.soccer__teamNameWrapper}>
                <div className={classNames('biab_team-name', styles.soccer__teamName, styles.soccer__teamName__away)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={awayName}
                    eventId={eventId}
                    ref={teamNameRef}
                  />
                </div>
                <div className={classNames('biab_team-name', styles.soccer__teamName, styles.soccer__hideSecondName)}>
                  {homeName}
                </div>
                {!!awayGoals.length && (
                  <div className={classNames('biab_goals', styles.soccer__awayScore)}>
                    <i className="biab_running-ball-icon biab_rb-football-icon" />
                    <div className="biab_goals'">
                      {awayGoals.map(
                        (item, index) => `${item.matchTime}${minuteSymbol}${index !== awayGoals.length - 1 ? ', ' : ''}`
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(styles.eventHeader__minSide, styles.eventHeader__minSide__right)}>
              <div className={styles.eventHeader__widgetsContainer} ref={rightSideContainerRef}>
                {!isMobile && !isCloseMarket && (
                  <>
                    {(videoStreamingEnabled || matchStatEnabled) && (
                      <div
                        className={classNames(styles.eventHeader__widgets, {
                          [styles.eventHeader__widgets__vertical]: isIntersect
                        })}
                      >
                        {videoStreamingEnabled && (
                          <VideoStream
                            hideLabel={isIntersect}
                            visible={videoStreamingEnabled}
                            isInHeader
                            eventId={eventId}
                          />
                        )}
                        {matchStatEnabled && (
                          <MatchStatistics
                            isSoccer={sportId === SportIds.SOCCER}
                            visible={matchStatEnabled}
                            isInHeader
                            eventId={eventId}
                            hideLabel={isIntersect}
                          />
                        )}
                      </div>
                    )}

                    {!isSearchType && (
                      <FavoriteStar entryData={favoriteData} iconClassName={styles.eventHeader__favouriteIcon} />
                    )}
                  </>
                )}
              </div>
              <div className={styles.marketDropdown}>
                {isMobile && <MarketDropdown topPosition={113} isSoccerInPlay />}
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('biab_running-ball-wrapper', styles.matchContainer, {
            [styles.matchContainer__penalty]: hasPenalty
          })}
        >
          {!hasPenalty && (
            <>
              {bars.map((bar, index) => {
                return (
                  <div
                    key={index}
                    className={classNames('biab_running-ball-half-time', styles.match, {
                      [styles.match__extra]: bar.isExtra
                    })}
                  >
                    <div className={classNames('biab_empty-bar', styles.empty)} />
                    <div className={classNames('biab_bar', styles.line)} style={{ width: `${bar.duringMatch}%` }} />
                    {bar.events.map(item => {
                      return (
                        <div
                          key={`${item.team}-${item.matchTime}`}
                          data-tooltip-id="tooltip-soccer"
                          data-tooltip-team={item.team}
                          data-tooltip-time={item.matchTime}
                          data-tooltip-type={item.updateType}
                          className={classNames(styles.event, styles[convertTeam(item.team)])}
                          style={{ left: `calc(${+item.footballWidth <= 100 ? item.footballWidth : 100}% - 7px)` }}
                        >
                          {item.updateType === soccerGoal && (
                            <i
                              className={classNames('biab_running-ball-icon biab_rb-football-icon', styles.goalWhite)}
                            />
                          )}
                          {soccerCards.includes(item.updateType) && (
                            <div className={classNames(styles.event, styles[item.updateType])} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
          {hasPenalty && (
            <>
              <div className={classNames(styles.penalty__wrapper)}>
                {penaltyData.home.map((penalty, index) => (
                  <i
                    key={index}
                    className={classNames('biab_penalty', styles.penalty__score, {
                      [styles.penalty__score__scored]: penalty === 'scored',
                      [styles.penalty__score__missed]: penalty === 'missed'
                    })}
                  />
                ))}
              </div>
              <div className={classNames('biab_score', styles.penalty__scores)}>{penaltyData.score}</div>
              <div className={classNames(styles.penalty__wrapper)}>
                {penaltyData.away.map((penalty, index) => (
                  <i
                    key={index}
                    className={classNames('biab_penalty', styles.penalty__score, {
                      [styles.penalty__scored]: penalty === 'scored',
                      [styles.penalty__missed]: penalty === 'missed'
                    })}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
        <MobileEventWidgets
          videoStreamingEnabled={videoStreamingEnabled}
          matchStatEnabled={matchStatEnabled}
          eventId={eventId}
          sportId={sportId}
        />
      )}
      <ReactTooltip
        id="tooltip-soccer"
        className={tooltipStyle.tooltip}
        classNameArrow={tooltipStyle.arrow}
        render={({ activeAnchor }) => {
          const team = convertTeam(activeAnchor?.getAttribute('data-tooltip-team')) as 'home' | 'away';
          const teamName = team === 'home' ? homeName : awayName;
          const type = activeAnchor?.getAttribute('data-tooltip-type') || '';
          const time = activeAnchor?.getAttribute('data-tooltip-time') || '';

          return (
            <div className={styles.soccer__tooltip}>
              <div className={styles.soccer__tooltip__time}>{time.toString()}'</div>
              <div className={styles.soccer__tooltip__name}>
                {type === soccerGoal ? (
                  <i className="biab_running-ball-icon biab_rb-football-icon" />
                ) : (
                  <div className={classNames(styles.soccer__tooltip__card, styles[type])} />
                )}
                <div>{teamName}</div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default EventHeaderSoccer;
