import { useSelector } from 'react-redux';

import { PlaceBetsStates } from 'constants/betslip';
import {
  getAsianActiveSelectedBets,
  getAsianSelectedBetsList,
  getPlaceBetsState
} from 'redux/modules/asianViewBetslip/selectors';

import AVSelectedBet from '../AVSelectedBet';

const AVSelectedBets = () => {
  const selectedBets = useSelector(getAsianSelectedBetsList);
  const placeBetsState = useSelector(getPlaceBetsState);

  if (placeBetsState === PlaceBetsStates.CONFIRM) {
    return <AVConfirmationSelectedBets />;
  }

  return (
    <>
      {selectedBets.map((bet, index) => (
        <AVSelectedBet key={bet.fullIdentifier} bet={bet} index={index} />
      ))}
    </>
  );
};

const AVConfirmationSelectedBets = () => {
  const selectedBets = useSelector(getAsianActiveSelectedBets(true));

  return (
    <>
      {selectedBets.map((bet, idx) => (
        <AVSelectedBet key={bet.fullIdentifier} bet={bet} index={idx} />
      ))}
    </>
  );
};

export default AVSelectedBets;
