import { useTranslation } from 'react-i18next';

import Calculators from 'components/Calc/Calculators';
import LinkButton from 'components/LinkButton/Index';
import { ArticleTypes } from 'constants/educationPortal';
import { EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import ArticlesList from 'pages/EducationalPortal/components/ArticlesList/ArticlesList';

import styles from './styles.module.scss';

const EducationalPortalHomePage = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return (
    <main className={styles.content}>
      {isMobile && (
        <section className={styles.viewAllSection}>
          <h2>{t('education.labels.guides')}</h2>
          <LinkButton to={EDUCATIONAL_PORTAL_GUIDES_URL}>{t('education.labels.viewAll')}</LinkButton>
        </section>
      )}
      <ArticlesList
        title={t('education.labels.startYourJourney')}
        cardTitleSize={isMobile ? 'large' : 'medium'}
        hideViewAllButton={isMobile}
        horizontalScroll={isMobile}
        type={ArticleTypes.BEGINNER}
      />
      <Calculators />
    </main>
  );
};

export default EducationalPortalHomePage;
