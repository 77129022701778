import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'components/Icon';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import { SportIds } from 'constants/app';
import { mobileIcons } from 'constants/branding';
import BetOfferState from 'pages/mobile/MobileBetListPage/components/OpenBet/components/BetOfferState/BetOfferState';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './BetSelection.module.scss';

interface BetSelectionProps {
  bet: TCurrentBet;
  hideInPlayIcon?: boolean;
  hidePlTable?: boolean;
  hideExtendedInfo?: boolean;
  withOfferState?: boolean;
}

const BetSelection = ({ bet, hideInPlayIcon, hidePlTable, hideExtendedInfo, withOfferState }: BetSelectionProps) => {
  const { t } = useTranslation();
  const { betSide, betOdds, marketLink, marketName, selectionName, isGameBet, isEachWay, isUnmatchedBet } =
    useOpenBetData(bet);

  return (
    <div className={styles.description}>
      <div className={styles.description__left}>
        <div>
          {!isGameBet && (bet.inPlay || bet.eventInPlay) && !hideInPlayIcon && (
            <Icon
              fontFamily="fa2"
              iconClass="fa2-inplay"
              className={classNames(
                styles.description__inPlayIcon,
                mobileIcons.IN_PLAY_SQUARE_ICON,
                mobileIcons.ACTIVE
              )}
            />
          )}
          <span className={styles.description__name}>{selectionName}</span>
        </div>
        {!hideExtendedInfo && (
          <Link className={classNames(styles.description__market)} to={marketLink}>
            {marketName}
          </Link>
        )}
        {!hideExtendedInfo && isEachWay && (
          <span className={styles.description__ew}>
            {t('market.each.way.termsNoPref', {
              odds: bet.eachWayDivisor,
              places: bet.numberOfWinners
            })}
          </span>
        )}
        {!hideExtendedInfo && <span className={styles.description__event}>{bet.mainEventName || bet.eventName}</span>}
        {withOfferState && <BetOfferState bet={bet} />}
      </div>
      <div className={styles.description__right}>
        <span className={styles.description__odds}>@{betOdds}</span>
        <span className={styles.description__type}>{betSide}</span>
        {!isUnmatchedBet &&
          bet.isPNLAvailable &&
          String(bet.eventTypeId) === SportIds.SOCCER &&
          !bet.isOutright &&
          !hidePlTable && (
            <div className={styles.description__pl}>
              <ProfitLossTablePopUpButton isActive eventId={bet.eventId} marketId={bet.marketId} isAsianView={false} />
            </div>
          )}
      </div>
    </div>
  );
};

export default BetSelection;
