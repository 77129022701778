import { createSelector } from '@reduxjs/toolkit';
import { sortBy, values } from 'lodash';

import { BetsStatusesTypes } from 'constants/app';
import { AsianBetslipTabs, AsianViewSelectedBetStates } from 'constants/asianView';
import { getAreAsianCurrentBetsLoaded } from 'redux/modules/asianViewCurrentBets/selectors';
import { AppState } from 'redux/reducers';
import { getIsAsianViewBetActive } from 'utils/asianView';

const getAsianViewBetslip = (state: AppState) => state.asianViewBetslip;

export const getAsianViewBetslipSelectedTab = ({ asianViewBetslip }: AppState) => asianViewBetslip.selectedTab;
export const getIsAsianViewBetSlipCashOutTabOpened = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.selectedTab === AsianBetslipTabs.CASH_OUT;

export const getAsianSelectedBets = ({ asianViewBetslip }: AppState) => asianViewBetslip.selectedBets;
export const getAsianViewBetSlipStatuses = ({ asianViewBetslip }: AppState) => asianViewBetslip.statuses;

export const getAsianSelectedBetsList = createSelector(getAsianViewBetslip, ({ selectedBets }) =>
  sortBy(Object.values(selectedBets), 'order').reverse()
);

export const getAsianActiveSelectedBets = (ignoreValidationMessage?: boolean) =>
  createSelector(getAsianSelectedBetsList, selectedBets =>
    selectedBets.filter(bet => getIsAsianViewBetActive({ bet, ignoreValidationMessage }))
  );

export const getAsianSelectedBetsAmount = createSelector(
  getAsianSelectedBets,
  selectedBets => Object.keys(selectedBets).length
);

export const getPlaceBetsState = ({ asianViewBetslip }: AppState) => asianViewBetslip.placeBetsState;

export const getIsAsianSelectedBetExist =
  (identifier: string) =>
  ({ asianViewBetslip }: AppState) => {
    return Object.values(asianViewBetslip.selectedBets).some(({ state, identifier: betIdentifier }) => {
      return identifier === betIdentifier && state === AsianViewSelectedBetStates.selected;
    });
  };

export const getAreAsianViewBetsStatusesLoaded = ({ asianViewBetslip }: AppState) => asianViewBetslip.areStatusesLoaded;
export const getAsianViewBetsStatusesLoading = ({ asianViewBetslip }: AppState) => asianViewBetslip.statusesLoading;
export const getAsianViewOfferIdsToGetBetStatuses = createSelector(
  getAsianSelectedBets,
  getAreAsianCurrentBetsLoaded,
  getAsianViewBetSlipStatuses,
  (selectedBets, areCurrentBetsLoaded, statuses) => {
    if (areCurrentBetsLoaded && Object.keys(selectedBets).length) {
      return Object.values(selectedBets)
        .filter(({ offerId, betStatus, betStatusError }) => {
          return (
            typeof offerId === 'number' &&
            (!statuses[offerId]?.status || statuses[offerId].status === BetsStatusesTypes.PENDING) &&
            (!betStatus || betStatus === BetsStatusesTypes.PENDING) &&
            !betStatusError
          );
        })
        .map(({ offerId }) => offerId) as number[];
    }

    return [];
  }
);
export const getAsianViewBetStatusByOfferId =
  (offerId?: number | null) =>
  ({ asianViewBetslip }: AppState) =>
    offerId ? asianViewBetslip.statuses[offerId]?.status : undefined;
export const getAsianViewBetStatusErrorByOfferId =
  (offerId?: number) =>
  ({ asianViewBetslip }: AppState) =>
    offerId ? asianViewBetslip.statuses[offerId]?.error : undefined;
export const getAsianViewBetOfferIdByBetUuid =
  (marketId: string, betUuid: string) =>
  ({ asianViewBetslip }: AppState) =>
    asianViewBetslip.placedBetsByMarket[marketId]?.offerIds?.[betUuid];

export const getAsianViewMatchedSelectedBetsIdentifiers = createSelector(
  getAsianViewBetslip,
  ({ selectedBets, placedBetsByMarket, statuses }) => {
    return values(selectedBets)
      .filter(({ marketId, fullIdentifier, betStatus }) => {
        const betOfferId = placedBetsByMarket[marketId]?.offerIds?.[fullIdentifier];
        const statusData = betOfferId ? statuses[betOfferId] : undefined;

        return statusData?.status === BetsStatusesTypes.MATCHED || betStatus === BetsStatusesTypes.MATCHED;
      })
      .map(({ fullIdentifier }) => fullIdentifier);
  }
);

export const getIsBetslipSelectedTab = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.selectedTab === AsianBetslipTabs.BET_SLIP;

export const getIsSelectedBetsLimitNotification = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.isSelectedBetsLimitNotification;

export const getLiabilityByMarket = ({ asianViewBetslip }: AppState) => asianViewBetslip.liabilityByMarket;

export const getBetslipRGErrorMessage = ({ asianViewBetslip }: AppState) => asianViewBetslip.rgErrorMessage;

export const getAvSelectedBetsWithValidationMessage = createSelector(getAsianViewBetslip, ({ selectedBets }) => {
  return values(selectedBets)
    .filter(({ validationMessage }) => !!validationMessage)
    .map(({ fullIdentifier }) => fullIdentifier);
});
