import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import LinkButton from 'components/LinkButton/Index';
import { MAX_CONTENT_WIDTH_SM } from 'constants/educationPortal';
import { CALC_FRACTIONAL_ODDS_URL, CALC_LAY_BETTING_URL, EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import {
  getEduDecimalOddsPermalink,
  getEduPlaycingLayBetPermalink,
  getLanguage
} from 'redux/modules/appConfigs/selectors';
import { getWindowWidth } from 'redux/modules/appConfigs/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import FractionalOddsCalculator from '../FractionalOddsCalculator';
import LayBetCalculator from '../LayBetCalculator';

import styles from './styles.module.scss';

const Calculators = () => {
  const { t } = useTranslation();
  const { isMobile: isMobileWidth } = useDevice();
  const rootPath = getEnvironmentRootPath();
  const language = useSelector(getLanguage);
  const eduDecimalOddsPermalink = useSelector(getEduDecimalOddsPermalink);
  const placingLayBetPermalink = useSelector(getEduPlaycingLayBetPermalink);
  const windowWidth = useSelector(getWindowWidth);

  const isMobile = isMobileWidth || windowWidth < MAX_CONTENT_WIDTH_SM;

  const onOpenFractionalCalc = () => {
    window.open(
      `${location.origin}${rootPath}${CALC_FRACTIONAL_ODDS_URL}`,
      '_blank',
      'toolbar=no,scrollbars=no,resizable=no,width=500,height=140'
    );
  };

  const onOpenLayBetCalc = () => {
    window.open(
      `${location.origin}${rootPath}${CALC_LAY_BETTING_URL}`,
      '_blank',
      'toolbar=no,scrollbars=no,resizable=no,width=500,height=410'
    );
  };

  return (
    <div className={classNames(styles.container, { [styles.container__mobile]: isMobile })}>
      <div className={classNames(styles.wrapper, { [styles.wrapper__mobile]: isMobile })}>
        {!isMobile && (
          <button className={styles.expandBtn}>
            <i onClick={onOpenFractionalCalc} className={'fa2 fa2-expand-32'} />
          </button>
        )}
        <div className={styles.description}>
          <p className={classNames(styles.description__title, { [styles.description__title__mobile]: isMobile })}>
            {t('education.labels.oddsMadeEasy')}
          </p>
          <p
            className={classNames(styles.description__text, styles.description__text__center)}
            dangerouslySetInnerHTML={{ __html: t('education.labels.oddsMadeEasy.description') }}
          />
        </div>
        <FractionalOddsCalculator />
        <LinkButton
          sameUrlPush
          to={`${EDUCATIONAL_PORTAL_GUIDES_URL}/${language}/${eduDecimalOddsPermalink}`}
          className={classNames(styles.viewMore, { [styles.viewMore__mobile]: isMobile })}
        >
          {t('education.labels.viewMore')}
        </LinkButton>
      </div>
      <div
        className={classNames(styles.wrapper, { [styles.wrapper__row]: !isMobile, [styles.wrapper__mobile]: isMobile })}
      >
        {!isMobile && (
          <button className={styles.expandBtn}>
            <i onClick={onOpenLayBetCalc} className={'fa2 fa2-expand-32'} />
          </button>
        )}
        <div className={classNames(styles.description, { [styles.description__desktop]: !isMobile })}>
          <p className={classNames(styles.description__title, { [styles.description__title__mobile]: isMobile })}>
            {t('education.labels.masterLayBetting')}
          </p>
          <p
            className={classNames(styles.description__text, { [styles.description__text__center]: isMobile })}
            dangerouslySetInnerHTML={{ __html: t('education.labels.masterLayBetting.description') }}
          />
          {!isMobile && (
            <LinkButton
              sameUrlPush
              to={`${EDUCATIONAL_PORTAL_GUIDES_URL}/${language}/${placingLayBetPermalink}`}
              className={classNames(styles.viewMore, { [styles.viewMore__topMargin]: !isMobile })}
            >
              {t('education.labels.viewMore')}
            </LinkButton>
          )}
        </div>
        <LayBetCalculator />
        {isMobile && (
          <LinkButton
            sameUrlPush
            to={`${EDUCATIONAL_PORTAL_GUIDES_URL}/${language}/${placingLayBetPermalink}`}
            className={classNames(styles.viewMore, styles.viewMore__mobile)}
          >
            {t('education.labels.viewMore')}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default Calculators;
