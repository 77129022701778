import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';

import Dropdown from 'components/Dropdown';
import { CookieNames } from 'constants/app';
import { componentsBranding } from 'constants/branding';
import {
  BetsTypesByPeriods,
  BetTypeValues,
  ExchangeTypes,
  PLBetTypes,
  PLExchangeTypes,
  TExchangeTypes,
  TPLExchangeTypes
} from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import dropdownStyles from 'pages/MyBetsPages/dropdown.module.scss';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { setEventTypeFilter } from 'redux/modules/myBets';
import { DropdownItem } from 'types';
import { MyBetsPeriod, TGetBetAndPageDataParams } from 'types/myBets';

export type ProductsDropdownProps = {
  refreshData: (params: TGetBetAndPageDataParams) => void;
  availableProducts: string[];
};

const ProductsDropdown = ({ refreshData, availableProducts }: ProductsDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { periodType } = useParams();
  const [cookies, setCookies] = useCookies([
    CookieNames.EXCHANGE_TYPE_NEW,
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.MY_BETS_TYPES
  ]);

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const { isMyBetsPage, isProfitLossPage } = useAccountUrlParams();

  const cookieKey = isProfitLossPage ? CookieNames.PROFITANDLOSS_BETTYPE : CookieNames.EXCHANGE_TYPE_NEW;
  const translationKey = isProfitLossPage ? 'account.profitandloss.labels.' : 'asianView.labels.account.exchangeType.';
  const products = availableProducts.map(tab => {
    return { id: tab, value: tab, label: t(`${translationKey}${isProfitLossPage ? tab.toLowerCase() : tab}`) };
  });

  const defaultProduct =
    (isProfitLossPage
      ? (cookies.PROFITANDLOSS_BETTYPE as TPLExchangeTypes)
      : (cookies.EXCHANGE_TYPE_NEW as TExchangeTypes)) || availableProducts[0];

  const defaultProductItem = products.find(({ id }) => id === defaultProduct);

  const handleSetCurrentProduct = (productItem: DropdownItem) => {
    if (productItem?.id !== defaultProduct) {
      setCookies(cookieKey, productItem.id, { path: '/' });
      if (isProfitLossPage) {
        dispatch(setEventTypeFilter({ id: '', label: '', value: '' } as DropdownItem));
      }

      if (isMyBetsPage) {
        setCookies(CookieNames.MY_BETS_TYPES, BetsTypesByPeriods[capitalize(periodType) as MyBetsPeriod], {
          path: '/'
        });
      }

      const currentBetType =
        productItem.id === PLExchangeTypes[PLBetTypes.Games] ? BetTypeValues.Games : BetTypeValues.Sports;
      refreshData({
        asianView: productItem?.id === ExchangeTypes.AsianView,
        betType: currentBetType,
        eventTypeId: '',
        search: null
      });
    }
  };

  const dropdownBranding = {
    dropdownItems: componentsBranding.DROPDOWN_LIST,
    dropdownItem: componentsBranding.DROPDOWN_ITEM
  };

  return (
    <Dropdown
      defaultValue={defaultProductItem}
      data={products}
      onSelectOption={handleSetCurrentProduct}
      classes={dropdownStyles}
      brandingClasses={dropdownBranding}
      disabled={isCancellingBets && isMyBetsPage}
    />
  );
};

export default ProductsDropdown;
