import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetMarketsInterval,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getDesktopCashoutPageSize,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getRunningBallInterval,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchAutoCashOutMarkets, fetchCashOutMarkets, fetchCashOutQuotes } from 'redux/modules/cashOut';
import {
  getCashOutContent,
  getFetchCashOutLoading,
  getFetchCashOutMarketsLoading
} from 'redux/modules/cashOut/selectors';
import { fetchEventsUpdatedData } from 'redux/modules/marketsPrices';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

interface CashOutPagesIntervalRequestsInjectionProps {
  pageSize?: number;
}

const CashOutPagesIntervalRequestsInjection = ({ pageSize }: CashOutPagesIntervalRequestsInjectionProps) => {
  const dispatch = useDispatch();
  const { page: currentPage } = useParams();

  const cashOutGetMarketsInterval = useSelector(getCashOutGetMarketsInterval);
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const cashOutMarketsLoading = useSelector(getFetchCashOutMarketsLoading);
  const fetchCashOutLoading = useSelector(getFetchCashOutLoading);
  const runningBallInterval = useSelector(getRunningBallInterval);
  const content = useSelector(getCashOutContent);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const { eventIds, marketIds } = useMemo(() => {
    if (content) {
      return content.reduce(
        (acc: { eventIds: string[]; marketIds: string[] }, item) => {
          return { eventIds: [...acc.eventIds, item.event.id], marketIds: [...acc.marketIds, item.marketId] };
        },
        { eventIds: [], marketIds: [] }
      );
    }

    return { eventIds: [], marketIds: [] };
  }, [content]);

  const isSubscriptionAvailable = isConnectedGeneralWebSocket && !!subscribeGeneralWebSocketMessages && isLoggedIn;
  const isAvailableCashOutQuoteSubscription =
    isSubscriptionAvailable && cashOutQuoteWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isAvailableAutoCashOutSubscription =
    isSubscriptionAvailable && autoCashOutWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isAvailableCashOutQuoteInterval =
    isLoggedIn && arePropertiesLoaded && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isAvailableAutoCashOutInterval =
    isLoggedIn && arePropertiesLoaded && (!generalWsEnabled || !autoCashOutWsEnabled);

  const cashOutMarketsLoadingRef = useRef(cashOutMarketsLoading);
  const fetchCashOutLoadingRef = useRef(fetchCashOutLoading);
  const generalWsDataRef = useRef<{
    isAvailableCashOutQuoteSubscription: boolean;
    isAvailableAutoCashOutSubscription: boolean;
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    isAvailableAutoCashOutSubscription: isAvailableAutoCashOutSubscription,
    isAvailableCashOutQuoteSubscription: isAvailableCashOutQuoteSubscription,
    subscribeGeneralWebSocketMessages: null
  });

  fetchCashOutLoadingRef.current = fetchCashOutLoading;
  cashOutMarketsLoadingRef.current = cashOutMarketsLoading;
  generalWsDataRef.current = {
    isAvailableAutoCashOutSubscription: isAvailableAutoCashOutSubscription,
    isAvailableCashOutQuoteSubscription: isAvailableCashOutQuoteSubscription,
    subscribeGeneralWebSocketMessages
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        fetchCashOutMarkets({
          page: currentPage ? parseInt(currentPage) - 1 : 0,
          size: pageSize || desktopCashOutPageSize,
          withLoader: true,
          limitPageSize: pageSize ? false : undefined
        })
      );

      const eventInterval = setInterval(() => {
        if (!cashOutMarketsLoadingRef.current) {
          dispatch(
            fetchCashOutMarkets({
              page: currentPage ? parseInt(currentPage) - 1 : 0,
              isUpdated: true,
              size: pageSize || desktopCashOutPageSize,
              limitPageSize: pageSize ? false : undefined
            })
          );
        }
      }, cashOutGetMarketsInterval);

      return () => clearInterval(eventInterval);
    }
  }, [currentPage, cashOutGetMarketsInterval, isLoggedIn, language, timezone]);

  useEffect(() => {
    if (eventIds.length) {
      dispatch(fetchEventsUpdatedData(eventIds));

      const eventUpdatesInterval = setInterval(() => {
        dispatch(fetchEventsUpdatedData(eventIds));
      }, runningBallInterval);

      return () => clearInterval(eventUpdatesInterval);
    }
  }, [eventIds, runningBallInterval, language, timezone]);

  useEffect(() => {
    if (marketIds.length && isAvailableCashOutQuoteInterval) {
      dispatch(fetchCashOutQuotes({ ids: marketIds, firstLoading: true }));

      const cashOutQuotesInterval = setInterval(() => {
        if (!fetchCashOutLoadingRef.current) {
          dispatch(fetchCashOutQuotes({ ids: marketIds }));
        }
      }, cashOutGetQuotesInterval);

      return () => clearInterval(cashOutQuotesInterval);
    }
  }, [marketIds, cashOutGetQuotesInterval, isAvailableCashOutQuoteInterval, language, timezone]);

  useEffect(() => {
    if (autoCashOutEnabled && isAvailableAutoCashOutInterval && marketIds.length) {
      const getAutoCashOutMarkets = () => {
        dispatch(fetchAutoCashOutMarkets(marketIds));
      };

      getAutoCashOutMarkets();

      const interval = setInterval(getAutoCashOutMarkets, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [marketIds, isLoggedIn, language, timezone, autoCashOutEnabled, isAvailableAutoCashOutInterval]);

  useEffect(() => {
    if (isAvailableCashOutQuoteSubscription && marketIds.length) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuote]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAvailableCashOutQuoteSubscription, marketIds]);

  useEffect(() => {
    if (isAvailableAutoCashOutSubscription && marketIds.length) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOut]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAvailableAutoCashOutSubscription, marketIds]);

  useEffect(() => {
    if (isSubscriptionAvailable) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.eventUpdates]: {
          subscribe: true,
          eventIds
        }
      });
    }
  }, [isSubscriptionAvailable, eventIds]);

  useEffect(() => {
    return () => {
      const {
        subscribeGeneralWebSocketMessages: subscribeFunc,
        isAvailableCashOutQuoteSubscription: isAvailableCashOutQuote,
        isAvailableAutoCashOutSubscription: isAvailableAutoCashOut
      } = generalWsDataRef.current;

      if (isAvailableCashOutQuote && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.cashOutQuote]: { subscribe: false }
        });
      }

      if (isAvailableAutoCashOut && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOut]: { subscribe: false }
        });
      }

      if (subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.eventUpdates]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
};

export default CashOutPagesIntervalRequestsInjection;
