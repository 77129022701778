import { useSelector } from 'react-redux';

import { MatchStatuses, SOCCER_EXTRA_PERIOD_TIME, SOCCER_PERIOD_TIME } from 'constants/app';
import {
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getEventsUpdatedDataTimeElapsed
} from 'redux/modules/marketsPrices/selectors';
import { EventScorePeriod } from 'types';

const useSoccerTimeByEventId = (eventId: string) => {
  const timeElapsed = useSelector(getEventsUpdatedDataTimeElapsed(eventId));
  const period = useSelector(getEventsUpdatedDataPeriod(eventId));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));

  const isExtra = period === EventScorePeriod.EXTRA;
  const isFirstHalf = inPlayMatchStatus === MatchStatuses.KICK_OFF || inPlayMatchStatus === MatchStatuses.HALF_TIME;
  const time = isExtra ? timeElapsed - 2 * SOCCER_PERIOD_TIME : timeElapsed;
  const periodTime = isExtra ? SOCCER_EXTRA_PERIOD_TIME : SOCCER_PERIOD_TIME;
  const currentPeriodTime = isFirstHalf ? time : time - periodTime;
  const addedTime = (currentPeriodTime % periodTime) * Math.floor(currentPeriodTime / periodTime);

  return { time: timeElapsed - addedTime, addedTime };
};

export default useSoccerTimeByEventId;
