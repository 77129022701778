import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import Icon from 'components/Icon';
import { MarketStatuses, PageBlocks, SportIds } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import { BETSLIP_BTN_REMOVE } from 'constants/automation';
import { BetDatabaseNames } from 'constants/bets';
import { BetFocusFields, BETSLIP_LINE_INT_ODDS, PlaceBetsStates, tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { GAME_TYPES } from 'constants/games';
import { BetSides } from 'constants/myBets';
import useConfirmBets from 'hooks/useConfirmBets';
import { useCurrency } from 'hooks/useCurrency';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useLayColumn from 'hooks/useLayColumn';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { usePreviousValue } from 'hooks/usePrevious';
import { getCurrency, getMarketOddsBackOnly, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { removeSelectedBet, setPlaceBetsState, updateSelectedBet } from 'redux/modules/betslip';
import {
  getBetslipPlaceBetsState,
  getIsGameBetSlip,
  getIsSelectedBetsPlacementValid,
  getSelectedBetIndex,
  getSelectedBetsAmount,
  getSelectedBetsAmountByMarket,
  getSelectedMarketRunnersNames
} from 'redux/modules/betslip/selectors';
import {
  SelectedBetState,
  TBetslipFormProps,
  TSelectedBet,
  TSelectedBetUpdateData,
  TSizeValidation
} from 'redux/modules/betslip/type';
import { getPlacedBetStatusByOfferId } from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesType } from 'redux/modules/betsStatuses/type';
import { getCurrentGameIsLayOddsDisabled } from 'redux/modules/games/selectors';
import {
  getBetOdds,
  getIsBettingEnabledByMarketPricesId,
  getMarketPricesCurrency,
  getMarketPricesRunnerLockedBySelectionId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { removePlacedSelectedBet, removePlacedSelectedMarket } from 'redux/modules/placement';
import { getPlacedSelectedBetStatus } from 'redux/modules/placement/selectors';
import { getPlacedSelectedMarketStatus } from 'redux/modules/placement/selectors';
import { getPlacedSelectedBetOfferId } from 'redux/modules/placement/selectors';
import { PlacementStatus } from 'redux/modules/placement/type';
import { getAccountSettings, getIsQuickStakesEnabled, getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { formatBestPrice } from 'utils/betslip';
import { getLineRangeParams } from 'utils/betValidation';
import { calculateLiability, calculateSize } from 'utils/liability';
import { precisionFormat, validateSize } from 'utils/size';

import BetForm from '../BetForm';
import BetLabels from '../BetLabels';
import CurrencyMessage from '../CurrencyMessage';
import QuickBets from '../QuickBets';
import SelectedBetError from '../SelectedBetError';
import SelectedBetNotification from '../SelectedBetNotification';
import SelectedBetOddsNotificationMessage from '../SelectedBetOddsNotificationContent/SelectedBetOddsNotificationContent';

import styles from './styles.module.scss';

const SelectedBet = ({ bet }: { bet: TSelectedBet }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const accountSettings = useSelector(getAccountSettings);
  const isPlacementValid = useSelector(getIsSelectedBetsPlacementValid);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const selectedBetsAmount = useSelector(getSelectedBetsAmount);
  const marketCurrency = useSelector(getMarketPricesCurrency(bet.marketId));
  const defaultCurrency = useSelector(getCurrency);
  const currency = useCurrency(marketCurrency);
  const marketStatus = useSelector(getStatusByMarketPricesId(bet.marketId));
  const isBettingEnabled = useSelector(getIsBettingEnabledByMarketPricesId(bet.marketId));
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, +(bet.handicap ?? 0))
  );
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);
  const placedSelectedBetStatus = useSelector(getPlacedSelectedBetStatus(bet.betUuid ?? ''));
  const placedSelectedMarketStatus = useSelector(getPlacedSelectedMarketStatus(bet.marketId));
  const placedSelectedBetOfferId = useSelector(getPlacedSelectedBetOfferId(bet.betUuid ?? ''));
  const betStatusByOfferId = useSelector(getPlacedBetStatusByOfferId(placedSelectedBetOfferId));
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const runnersNames = useSelector(getSelectedMarketRunnersNames(bet.marketId));
  const isGamesLayOddsDisabled = useSelector(
    getCurrentGameIsLayOddsDisabled(isGameBetSlip && bet.type === BetSides.Lay)
  );
  const selectedBetsAmountByMarket = useSelector(getSelectedBetsAmountByMarket(bet.marketId));
  const betIndex = useSelector(getSelectedBetIndex(bet.betUuid ?? ''));
  const marketOddsBackOnly = useSelector(getMarketOddsBackOnly);
  const isQuickBetsEnabled = useSelector(getIsQuickStakesEnabled);

  const { isConfirmBetsBeforePlacement, isConfirmBetsEnabled } = useConfirmBets();
  const { placeBetWithEnterKey, swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();
  const prevAutoRefreshIsEnabled = usePreviousValue(autoRefreshIsEnabled);
  const { isLayColumnEnabled } = useLayColumn({
    sportId: bet.type === BetSides.Lay && !isGameBetSlip ? bet.sportId || bet.eventTypeId : undefined,
    marketId: bet.type === BetSides.Lay && !isGameBetSlip ? bet.marketId : undefined,
    bettingType: bet.type === BetSides.Lay && !isGameBetSlip ? bet.bettingType : undefined
  });

  const [quickBetFocus, setQuickBetFocus] = useState(false);
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: bet.marketId,
    eventId: bet.eventId,
    section: MarketsPricesSocketParamsSections.SportsDesktopBetSlip
  });

  const { marketId, betUuid, type } = bet;
  const handicap = +(bet.handicap || 0);
  const isBack = bet.type === BetSides.Back;
  const betName = isBack ? BetDatabaseNames.BACK : BetDatabaseNames.LAY;
  const bestPrice = useSelector(getBetOdds(bet.marketId, bet.selectionId, handicap, betName, 0));
  const bestPriceFormatted = formatBestPrice(bestPrice, bet.marketType, bet.bettingType ?? '');
  const isCurrencyChanged = isMultiCurrencyChanged(marketCurrency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isPlacementEnabled = isLoggedIn && isPlacementValid;
  const { isLineMarket } = getLineRangeParams(bet);
  const isCricket = bet.sportId === SportIds.CRICKET;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;
  const isSuspended = marketStatus === MarketStatuses.SUSPENDED;
  const isLayOddsDisabled = isGameBetSlip ? isGamesLayOddsDisabled : !isLayColumnEnabled;
  const isSelectionLocked = isPNCEnabled && (Number(bestPrice) === 0 || bestPrice === null);
  const isDisabled =
    isSuspended ||
    isLocked ||
    isSelectionLocked ||
    (!isUndefined(isBettingEnabled) && !isBettingEnabled) ||
    isLayOddsDisabled ||
    (!!bet.isPopularMarket && marketOddsBackOnly);
  const isPrevOddsLower = !isDisabled && !!(bet.price && +bet.price < (bestPrice || 0));
  const isGoingUp = !isDisabled && isBack ? isPrevOddsLower : !isPrevOddsLower;
  const isOddsChangedIndicatorsEnabled =
    isPNCEnabled &&
    autoRefreshIsEnabled &&
    !isGameBetSlip &&
    !!bet.price &&
    !!bestPrice &&
    bet.price !== bestPrice &&
    !isDisabled;
  const showOddsChangedIndicators = isOddsChangedIndicatorsEnabled && placeBetsState !== PlaceBetsStates.CONFIRM;
  const isOddsDown = isOddsChangedIndicatorsEnabled && !isGoingUp;
  const priceValue = isPNCEnabled && autoRefreshIsEnabled && !isGameBetSlip && !isDisabled ? bestPrice : bet.price;
  const isLastSelectedBetOfMarket = selectedBetsAmountByMarket === 1;

  const getSelectionName = () => {
    if (bet.pageBlock === PageBlocks.FANCY_VIEW || bet.pageBlock === PageBlocks.FANCY_TOP_VIEW || isLineMarket) {
      return `${bet.selectionName} @${BETSLIP_LINE_INT_ODDS}`;
    } else {
      return bet.selectionName;
    }
  };

  useEffect(() => {
    if (isLayOddsDisabled) {
      dispatch(removeSelectedBet(bet));
      if (bet.betUuid) {
        dispatch(removePlacedSelectedBet({ betUuid: bet.betUuid }));
      }
    }
  }, [isLayOddsDisabled]);

  useEffect(() => {
    dispatch(updateSelectedBet({ marketId, betUuid, type, isDisabled }));
  }, [isDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (placedSelectedBetStatus && placedSelectedBetStatus === PlacementStatus.FAIL) {
      dispatch(updateSelectedBet({ marketId, betUuid, type, state: SelectedBetState.ERROR }));
    }
  }, [placedSelectedBetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (placedSelectedMarketStatus) {
      if (placedSelectedMarketStatus === PlacementStatus.FAIL) {
        dispatch(updateSelectedBet({ marketId, betUuid, type, state: SelectedBetState.ERROR }));
      } else if (bet.betUuid) {
        dispatch(removeSelectedBet(bet));
        dispatch(removePlacedSelectedBet({ betUuid: bet.betUuid }));
      }
    }
  }, [placedSelectedMarketStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (betStatusByOfferId && betStatusByOfferId !== BetsStatusesTypes.PENDING) {
      if (([BetsStatusesTypes.PLACED, BetsStatusesTypes.MATCHED] as BetsStatusesType[]).includes(betStatusByOfferId)) {
        dispatch(removeSelectedBet(bet));
      } else {
        dispatch(updateSelectedBet({ marketId, betUuid, type, state: SelectedBetState.ERROR }));
      }
    }
  }, [betStatusByOfferId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!autoRefreshIsEnabled && prevAutoRefreshIsEnabled) {
      dispatch(updateSelectedBet({ marketId, betUuid, type, price: bet.actualPrice }));
    }
  }, [autoRefreshIsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (betStatusByOfferId === BetsStatusesTypes.CANCELLED) {
      dispatch(
        updateSelectedBet({
          type: bet.type,
          marketId: bet.marketId,
          betUuid: bet.betUuid,
          isCancelled: true
        })
      );
    }
  }, [betStatusByOfferId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (priceValue && placeBetsState !== PlaceBetsStates.CONFIRM && !isDisabled) {
      const updatedData: TSelectedBetUpdateData = {
        marketId,
        betUuid,
        type
      };

      if (priceValue && bet.size) {
        updatedData.profit = calculateLiability(priceValue, bet.size, bet);
      }

      if (isPNCEnabled && !isGameBetSlip && autoRefreshIsEnabled) {
        updatedData.actualPrice = bestPrice;
      }

      dispatch(updateSelectedBet(updatedData));
    }
  }, [priceValue, autoRefreshIsEnabled, isDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (placeBetsState === PlaceBetsStates.CONFIRM && isPNCEnabled && !isGameBetSlip && autoRefreshIsEnabled) {
      dispatch(
        updateSelectedBet({
          marketId,
          betUuid,
          type,
          actualPrice: bestPrice
        })
      );
    }
  }, [placeBetsState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOddsDown !== bet.isLowerPrice) {
      dispatch(updateSelectedBet({ marketId, betUuid, type, isLowerPrice: isOddsDown }));
    }
  }, [isOddsDown]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPNCEnabled && bet.isCancelled) {
      dispatch(removeSelectedBet(bet));
    }
  }, [bet.isCancelled]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemoveBetClick = () => {
    dispatch(removeSelectedBet(bet));

    if (bet.betUuid) {
      dispatch(removePlacedSelectedBet({ betUuid: bet.betUuid }));

      if (isLastSelectedBetOfMarket) {
        dispatch(removePlacedSelectedMarket({ marketId: bet.marketId }));
      }
    }
  };

  const onFormChanged = ({
    price,
    size,
    profit,
    isPriceValid,
    isSizeValid,
    priceErrorMessage,
    sizeErrorMessage,
    focusedField
  }: TBetslipFormProps) => {
    const updatedData: TSelectedBetUpdateData = {
      marketId,
      betUuid,
      type,
      ...(!isUndefined(price) ? { price } : {}),
      ...(!isUndefined(size) ? { size } : {}),
      ...(!isUndefined(profit) ? { profit } : {}),
      ...(!isUndefined(isPriceValid) ? { isPriceValid } : {}),
      ...(!isUndefined(isSizeValid) ? { isSizeValid } : {}),
      ...(priceErrorMessage ? { validationMessage: { text: priceErrorMessage, field: 'price' } } : {}),
      ...(sizeErrorMessage ? { validationMessage: { text: sizeErrorMessage, field: 'size' } } : {}),
      ...(bet.price !== price ? { isPriceDirty: true } : {}),
      ...(!isUndefined(focusedField) ? { focusedField } : {})
    };

    if (isUndefined(profit) && !isUndefined(price) && !isUndefined(bet.size) && bet.size !== '') {
      updatedData.profit = calculateLiability(price, bet.size, bet);
    } else if (isUndefined(profit) && !isUndefined(size) && priceValue !== '') {
      updatedData.profit = calculateLiability(priceValue, size, bet);
    }

    if (!isUndefined(profit) && isUndefined(size)) {
      updatedData.size = calculateSize(priceValue, profit, bet);

      const validation: TSizeValidation = validateSize({
        size: updatedData.size,
        betType: bet.type,
        currency,
        defaultCurrency,
        currencyCode: bet.currency,
        isOperatorBettingLimitsEnabled: false,
        isGame: isGameBetSlip
      });

      updatedData.isSizeValid = validation.isValid;
    }

    if (
      bet.validationMessage?.text &&
      ((!isUndefined(price) && price !== bet.price) ||
        (!isUndefined(size) && size !== bet.size) ||
        (!isUndefined(profit) && profit !== bet.profit))
    ) {
      updatedData.validationMessage = null;
    }

    if (updatedData.size === '') {
      updatedData.isSizeValid = false;
    }

    dispatch(updateSelectedBet(updatedData));
  };

  const onEnterClick = () => {
    if (isPlaceBetsWithEnterKeyEnabled && isPlacementEnabled) {
      if (placeBetsState === PlaceBetsStates.SELECT) {
        if (isConfirmBetsEnabled && isConfirmBetsBeforePlacement) {
          dispatch(setPlaceBetsState(PlaceBetsStates.CONFIRM));
        } else {
          dispatch(setPlaceBetsState(PlaceBetsStates.PLACE));
        }
      }
    }
  };

  const cancelBtnTabIndex = selectedBetsAmount * tabulation.BET_TABS + betIndex + tabulation.PLACE_BTN_ORDER + 1;
  const disabledQuickBets =
    placeBetsState === PlaceBetsStates.CONFIRM || placeBetsState === PlaceBetsStates.CONFIRM_REMOVING;

  const isProgressState = bet.state === SelectedBetState.PROGRESS;

  const handlerQuickBets = useCallback(
    (value: string | number) => {
      const size = precisionFormat(+(bet.size || 0) + +value);
      dispatch(
        updateSelectedBet({
          marketId,
          betUuid,
          type,
          size,
          profit: calculateLiability(priceValue, size, bet),
          focusedField: BetFocusFields.SIZE
        })
      );

      const validation: TSizeValidation = validateSize({
        size,
        betType: bet.type,
        currency: currency,
        defaultCurrency,
        currencyCode: marketCurrency,
        isOperatorBettingLimitsEnabled: false,
        isGame: isGameBetSlip
      });

      onFormChanged({
        size: validation.validValue,
        isSizeValid: validation.isValid,
        ...(validation.errorMessage
          ? { sizeErrorMessage: t(validation.errorMessage.text, validation.errorMessage.params ?? {}) }
          : {})
      });
    },
    [bet] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div
      className={classNames(
        styles.bet,
        styles[`bet__${bet.type.toLowerCase()}`],
        branding.BET_CONTENT,
        branding[bet.type],
        {
          [styles.bet__disabled]: isDisabled,
          [styles.bet__hidden]: isProgressState,
          [branding.DISABLED]: isDisabled,
          [branding.FANCY_SWAP]: isFancySwapColors
        }
      )}
      data-bet-type={bet.type.toLowerCase()}
      data-selection-id={bet.selectionId}
      data-handicap={bet.handicap}
      data-market-id={bet.marketId}
      data-event-id={bet.eventId}
      data-market-prices={true}
      ref={ref}
    >
      {isMultiCurrencyMessage ? (
        <CurrencyMessage marketCurrency={marketCurrency} />
      ) : (
        <>
          <div className={styles.bet__inner}>
            <div>
              <div className={styles.betSelectionName}>
                {placeBetsState === PlaceBetsStates.SELECT && (
                  <button
                    type="button"
                    className={classNames(styles.betRemoveIcon, branding.REMOVE_SELECTED_BET_ICON)}
                    onClick={onRemoveBetClick}
                    tabIndex={cancelBtnTabIndex}
                    data-auto={BETSLIP_BTN_REMOVE}
                  >
                    <Icon iconClass="biab_custom-icon-close" size="xs" />
                  </button>
                )}
                <span className={styles.betSelectionName__label}>{getSelectionName()}</span>
              </div>
              {isGameBetSlip && bet.gameType !== GAME_TYPES.HILO && (
                <p className={classNames(styles.youAreLabel, branding.SECONDARY_TEXT)}>
                  {`${t(`games.betslip.labels.baccarat.${bet.type.toLowerCase()}`)} ${bet.selectionName}`}
                </p>
              )}
            </div>
            <BetForm
              betUuid={bet.betUuid}
              betPrice={bet.price}
              betPrevPrice={bet.price}
              betSize={bet.size}
              betProfit={bet.profit}
              betType={bet.type}
              betError={bet.error}
              bestPrice={bestPriceFormatted}
              betActualPrice={bet.actualPrice}
              bettingType={bet.bettingType ?? ''}
              marketId={bet.marketId}
              marketType={bet.marketType ?? ''}
              lineRangeInfo={bet.lineRangeInfo ?? null}
              eachWayDivisor={bet.eachWayDivisor ?? null}
              priceLadderDescription={bet.priceLadderDescription ?? null}
              mode={isDisabled ? PlaceBetsStates.DISABLED : placeBetsState}
              onFormChanged={onFormChanged}
              currency={marketCurrency}
              betIndex={betIndex}
              totalTabFields={tabulation.BET_TABS}
              focusedField={bet.focusedField}
              onEnterClick={onEnterClick}
              showOddsChangedIndicators={showOddsChangedIndicators}
              isSelectedBet
              isPriceDirty={bet.isPriceDirty}
              customClassNames={{ pncIndicator: styles.pncIndicator }}
              isSizeHighlighted={quickBetFocus}
              isDisabled={isDisabled}
            />
          </div>
          {!isDisabled && (
            <>
              {isQuickBetsEnabled && (
                <QuickBets
                  handler={handlerQuickBets}
                  isDisabled={disabledQuickBets}
                  onFocusChange={setQuickBetFocus}
                  hasTooltip={quickBetFocus}
                />
              )}
              <BetLabels
                price={bet.price}
                size={bet.size}
                handicap={bet.handicap}
                betType={bet.type}
                gameType={bet.gameType}
                eventTypeId={bet.sportId ?? ''}
                bettingType={bet.bettingType ?? ''}
                marketType={bet.marketType ?? ''}
                lineRangeInfo={bet.lineRangeInfo ?? null}
                eachWayDivisor={bet.eachWayDivisor ?? null}
                handicapType={bet.lineSide}
                selectionName={bet.selectionName}
                eventName={bet.eventName}
                isSwapColors={isFancySwapColors}
                runnersNames={runnersNames}
              />
            </>
          )}
          {isDisabled && <SelectedBetNotification message={t('betslip.labels.unavailableSelectionNotification')} />}
          {showOddsChangedIndicators && (
            <SelectedBetNotification
              message={
                <SelectedBetOddsNotificationMessage
                  isGoingUp={isGoingUp}
                  isLineMarket={isLineMarket}
                  odds={bestPriceFormatted}
                  initPrice={bet.price}
                />
              }
            />
          )}
          <SelectedBetError bet={bet} />
        </>
      )}
    </div>
  );
};

export default SelectedBet;
