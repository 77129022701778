import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { mobileComponents, mobileIcons } from 'constants/branding';
import { TMobileSelectOption } from 'types/myBets';

import styles from './styles.module.scss';

type TMobileSelectProps = {
  title: string;
  defaultOptions: TMobileSelectOption[];
  maxNumberToShow?: number;
  onChangeCallback: (options: TMobileSelectOption[], isChanged: boolean) => void;
};

const MobileSelect = ({ title, defaultOptions, maxNumberToShow, onChangeCallback }: TMobileSelectProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<TMobileSelectOption[]>(defaultOptions);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const isChanged = options.some(({ isSelected, defaultIsSelected }) => isSelected !== defaultIsSelected);

  const isShowMoreBtn = maxNumberToShow !== undefined && options.length > maxNumberToShow;

  const toggleOptionHandler = (ev: SyntheticEvent<HTMLLIElement>, id: string) => {
    setOptions(options.map(option => ({ ...option, isSelected: option.id === id })));
  };

  useEffect(() => {
    onChangeCallback(options, isChanged);
  }, [options, isChanged]);

  useEffect(() => {
    setIsCollapsed(true);
    setOptions(defaultOptions);
  }, [title]);

  return (
    <section className={styles.wrap}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
      </div>
      <ul className={styles.list}>
        {options.map(({ id, isSelected, label, tooltip, tooltipClassName }, index) => (
          <li
            className={classNames(styles.item, {
              [styles.item__selected]: isSelected,
              [styles.item__hide]: isCollapsed && isShowMoreBtn && index >= +maxNumberToShow
            })}
            key={id}
            onClick={ev => toggleOptionHandler(ev, id)}
          >
            <span className={styles.label}>
              {label}
              {tooltip && (
                <i
                  data-tooltip-id="tooltip"
                  data-tooltip-html={tooltip}
                  data-tooltip-class-name={tooltipClassName}
                  className={classNames('biab_custom-icon-info-24', styles.icon, mobileIcons.TOOLTIP)}
                  onClick={e => e.stopPropagation()}
                >
                  <span className={classNames('path1', styles.iconBg, mobileIcons.TOOLTIP_BG)} />
                  <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
                  <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
                </i>
              )}
            </span>
            {isSelected && <i className={classNames('biab_custom-icon-check-24', styles.icon)} />}
          </li>
        ))}
      </ul>
      {isShowMoreBtn && (
        <button
          className={classNames(styles.showMoreToggle, mobileComponents.BUTTON, mobileComponents.TEXT)}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <i
            className={classNames('av-icon', styles.toggle__icon, {
              'av-icon-arrows-down': isCollapsed,
              'av-icon-arrows-up': !isCollapsed
            })}
          />
          {t(`account.labels.show${isCollapsed ? 'More' : 'Less'}`)}
        </button>
      )}
    </section>
  );
};

export default MobileSelect;
