import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { IconsConfig } from 'constants/iconsConfig';
import useSoccerTimeByEventId from 'hooks/useSoccerTime';
import { getHomePageColumnsNumber } from 'redux/modules/appConfigs/selectors';
import { removeEventUpdatedDataByEventId } from 'redux/modules/marketsPrices';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getHomeScoreByEventId,
  getIsEventsUpdatedDataByEventId
} from 'redux/modules/marketsPrices/selectors';
import { IMarket } from 'types/markets';
import { getSoccerLabel } from 'utils/market';

import HeaderTime from '../HeaderTime';

import styles from './styles.module.scss';

const TitleMarket = ({ market }: { market: IMarket }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEventUpdatedData = useSelector(getIsEventsUpdatedDataByEventId(market?.event?.id));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(market?.event?.id));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(market?.event?.id));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(market?.event?.id));
  const homeScore = useSelector(getHomeScoreByEventId(market?.event?.id));
  const awayScore = useSelector(getAwayScoreByEventId(market?.event?.id));
  const period = useSelector(getEventsUpdatedDataPeriod(market?.event?.id));
  const homePageColumnsNumber = useSelector(getHomePageColumnsNumber);

  const isShorVariant = homePageColumnsNumber === 2;

  const { time, addedTime } = useSoccerTimeByEventId(market?.event?.id);

  const score = isEventUpdatedData
    ? getSoccerLabel({
        t,
        elapsedRegularTime: time,
        elapsedAddedTime: addedTime,
        inPlayMatchStatus,
        period
      })
    : '';

  useEffect(() => {
    return () => {
      dispatch(removeEventUpdatedDataByEventId(market?.event?.id));
    };
  }, []);

  return (
    <div className={classNames('biab_event-market-title', styles.marketHeader)}>
      <div
        className={classNames(styles.marketHeader__name, {
          [styles.marketHeaderSingle]: isShorVariant && isEventUpdatedData,
          [styles.inPlayName]: isEventUpdatedData
        })}
      >
        <i className={classNames('biab_br-sport-icon', IconsConfig.SPORT_ICONS[market.eventType.id])} />
        <div
          className={classNames({
            'biab_running-ball-list': isEventUpdatedData,
            [styles.marketHeader__title]: !isShorVariant,
            [styles.marketHeader__inPlayName]: isEventUpdatedData
          })}
        >
          {isEventUpdatedData ? (
            <>
              <div className={classNames('biab_running-ball-list-time', styles.time)}>{score}</div>
              <span>
                <span className={classNames('biab_running-ball-list-team biab_eventName', styles.name)}>
                  {homeName}
                </span>
                <span className={classNames('biab_running-ball-list-score', styles.score)}>
                  {`${homeScore}-${awayScore}`}
                </span>
                <span className={classNames('biab_running-ball-list-team biab_eventName', styles.name)}>
                  {awayName}
                </span>
              </span>
            </>
          ) : (
            <div>{market.event.name}</div>
          )}
          {!isShorVariant && <div>&nbsp;-&nbsp;</div>}
          <div
            className={classNames('biab_event-market-title', styles.marketHeader__marketName, {
              [styles.marketHeader__marketName__live]: isEventUpdatedData && isShorVariant
            })}
          >
            {market.marketName}
          </div>
        </div>
      </div>

      <div className={styles.marketHeader__time}>
        {!isEventUpdatedData && (
          <HeaderTime
            date={market.marketStartTime}
            isShortVariant={isShorVariant}
            inPlay={market.inPlay}
            sportId={market?.eventType?.id}
          />
        )}
      </div>
    </div>
  );
};

export default TitleMarket;
