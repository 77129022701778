import { useDispatch, useSelector } from 'react-redux';
import { To, useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Button from 'components/Button';
import SearchBar from 'components/Edu/SearchBar';
import IconLinkButton from 'components/IconLinkButton';
import { mobileAccountPagesBranding } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { resetSearchArticlesResults, setIsSearchActive } from 'redux/modules/educationalPortal';
import { getIsSearchActive } from 'redux/modules/educationalPortal/selectors';

import styles from './styles.module.scss';

interface MobileSubheaderProps {
  title: string;
  backUrl?: To;
  withHomeBtn?: boolean;
  rightContent?: JSX.Element;
  hasSearch?: boolean;
  onClickBackUrl?: () => void;
}

const MobileSubheader = ({
  title,
  backUrl,
  withHomeBtn,
  rightContent,
  hasSearch = false,
  onClickBackUrl
}: MobileSubheaderProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSearchActive = useSelector(getIsSearchActive);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const isGamesPage = pathname.includes(GAMES_BASE_URL) || (!isSportsEnabled && !isAsianViewEnabled);
  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL) || (!isSportsEnabled && !isGamesEnabled);
  const basePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;

  if (hasSearch && isSearchActive) return <SearchBar />;

  const handleClickSearch = () => {
    dispatch(setIsSearchActive(true));
    dispatch(resetSearchArticlesResults());

    const searchValue = searchParams.get(PARAMS_SEARCH_KEY);

    if (searchValue) {
      searchParams.delete(PARAMS_SEARCH_KEY);
      setSearchParams(searchParams, { replace: true });
    }
  };

  return (
    <div className={classNames(styles.container, mobileAccountPagesBranding.SUBHEADER)}>
      <IconLinkButton
        url={withHomeBtn ? basePage : backUrl || '../'}
        icon={withHomeBtn ? 'biab_custom-icon-home' : 'biab_custom-icon-chevron-left-24'}
        className={mobileAccountPagesBranding.BUTTON}
        onClick={onClickBackUrl}
      />
      <h4 className={styles.title}>{title}</h4>
      {hasSearch && (
        <Button
          onClick={handleClickSearch}
          variant="outline"
          className={classNames(styles.searchBtn, mobileAccountPagesBranding.BUTTON)}
        >
          <i className={classNames('biab_custom-icon-search-24', styles.searchBtn__icon)} />
        </Button>
      )}
      {rightContent || (!hasSearch && <div className={styles.emptyBlock} />)}
    </div>
  );
};

export default MobileSubheader;
