import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { AsianBettingActions } from 'constants/asianView';
import { updateQuickBet } from 'redux/modules/asianViewQuickBetting';

import styles from './styles.module.scss';

interface IMessage {
  identifier: string | undefined;
  message: string;
  customClassName?: string;
}
const Message = ({ identifier, message, customClassName = '' }: IMessage) => {
  const dispatch = useDispatch();
  const closeHandler = () => {
    if (identifier) {
      dispatch(
        updateQuickBet({
          identifier,
          data: { validationMessage: '', action: AsianBettingActions.PLACE }
        })
      );
    }
  };

  if (!message) {
    return null;
  }

  return (
    <div className={classNames(styles.wrap, styles.wrap__error, customClassName)}>
      <i className={classNames(styles.icon, 'fa2 fa2-warning-icon')} />
      <span dangerouslySetInnerHTML={{ __html: unescape(message) }} />
      <i onClick={closeHandler} className={classNames('biab_tour-icon-close', styles.closeIcon)} />
    </div>
  );
};

export default Message;
