import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Notification from 'components/Notification';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { removeCancelledBet, setHiddenBet } from 'redux/modules/betList';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './BetCancelledNotification.module.scss';

interface BetCancelledNotificationProps {
  bet: TCurrentBet;
  isPartiallyMatched?: boolean;
}

const BetCancelledNotification = ({ bet, isPartiallyMatched }: BetCancelledNotificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { betStake, betOdds, betSide, marketName, selectionName } = useOpenBetData(bet);

  const hideCancelledBet = () => {
    if (isPartiallyMatched) {
      dispatch(removeCancelledBet(bet.offerId));
    } else {
      dispatch(setHiddenBet(bet.offerId));
    }
  };

  const messageContent = (
    <>
      {betSide}: <span>{selectionName}</span> - {marketName}
      <br />
      <span>{betStake}</span> <span>@{betOdds}</span> - {t('account.mybets.labels.cancelled')}
    </>
  );

  return (
    <Notification
      type="info"
      messageContent={messageContent}
      className={styles.notification}
      onClose={hideCancelledBet}
    />
  );
};

export default BetCancelledNotification;
