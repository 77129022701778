import { useTranslation } from 'react-i18next';

import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './BetInfo.module.scss';

interface BetInfoProps {
  bet: TCurrentBet;
}

const BetInfo = ({ bet }: BetInfoProps) => {
  const { t } = useTranslation();
  const { isConsolidated, isGameBet } = useOpenBetData(bet);

  const { date: placedDate } = useParseMyBetsDates({
    dateToParse: bet.placedDate!,
    divider: ','
  });

  return (
    <div className={styles.info}>
      {isConsolidated && t('betslip.labels.betsConsolidated', { N: bet.combinedAmount })}
      {!isConsolidated && (
        <>
          <span>
            {t('account.mybets.labels.refID')}: {bet.offerId}
          </span>{' '}
          /{' '}
          <span>
            {t('account.mybets.labels.placed')}: {placedDate}
          </span>
          {!isGameBet && bet.triggeredByCashOut && (
            <>
              {' '}
              / <span>{t('account.mybets.labels.triggeredByCashOut')}</span>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BetInfo;
