import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUnmount, useWindowSize } from 'usehooks-ts';

import SEO from 'components/SEO';
import { ElementNames, MetaPages } from 'constants/app';
import { ACCOUNT_BASE_URL, GAMES_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { BetListTabs } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import OpenTab from 'pages/mobile/MobileBetListPage/components/OpenTab/OpenTab';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';

import SettledTab from './components/SettledTab/SettledTab';

import styles from './MobileBetListPage.module.scss';

const MobileBetListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isDesktop } = useDevice();
  const { tab = BetListTabs.Open } = useParams();
  const { height: windowHeight } = useWindowSize();

  const [componentRef, { height }] = useElementSize<HTMLDivElement>();
  const mobileHeaderHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const mobileFooterNavHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_FOOTER_NAV_HEIGHT));

  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const isOpenTab = tab === BetListTabs.Open;
  const exchangePage = (isGamesPage && GAMES_BASE_URL) || '';
  const myBetsLink = `${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${
    isOpenTab ? BetListTabs.Open : BetListTabs.Settled
  }/1`;

  useEffect(() => {
    if (isDesktop) {
      navigate(myBetsLink);
    }
  }, [isDesktop, myBetsLink, navigate]);

  useEffect(() => {
    dispatch(
      setElementHeight({
        name: ElementNames.MOBILE_PAGE_HEIGHT,
        height: height + mobileHeaderHeight
      })
    );
  }, [height, mobileHeaderHeight, dispatch]);

  useUnmount(() => {
    dispatch(setElementHeight({ name: ElementNames.MOBILE_PAGE_HEIGHT, height: 0 }));
  });

  return (
    <div
      className={styles.wrapper}
      ref={componentRef}
      style={{ minHeight: windowHeight - mobileHeaderHeight - mobileFooterNavHeight }}
    >
      <Helmet>
        <title data-rh="true">{t('general.page.title')}</title>
      </Helmet>
      {isOpenTab && <OpenTab />}
      {!isOpenTab && <SettledTab />}
    </div>
  );
};

export default MobileBetListPage;
