import classNames from 'classnames';

import BFZeroPriceChangeIndicator from 'components/Betslip/components/BetForm/components/BFZeroPriceChangeIndicator/BFZeroPriceChangeIndicator';
import PromotableTooltip from 'components/PromotableTooltip';

import styles from './NoEditableInput.module.scss';

type NoEditableInputProps = {
  id?: string;
  label: string;
  isLandscape: boolean;
  showOddsChangedIndicators?: boolean;
  value?: number | string;
  isGoingUp?: boolean;
  tooltipData?: { key: string; options?: Record<string, string> } | null;
};

const NoEditableInput = ({
  id,
  label,
  isLandscape,
  tooltipData,
  value = '',
  showOddsChangedIndicators = false,
  isGoingUp = false
}: NoEditableInputProps) => {
  return (
    <div
      className={classNames(styles.noEditable, {
        [styles.noEditable__landscape]: isLandscape
      })}
      data-auto={id}
    >
      {showOddsChangedIndicators && (
        <BFZeroPriceChangeIndicator
          isGoingUp={isGoingUp}
          price={value}
          customClassName={styles.noEditable__indicator}
        />
      )}
      <p className={styles.noEditable__label}>{label}</p>
      <p className={styles.noEditable__value}>{value}</p>
      {tooltipData && (
        <PromotableTooltip
          className={styles.noEditable__tooltipIcon}
          tooltipKey={tooltipData.key}
          translationOptions={tooltipData.options}
        />
      )}
    </div>
  );
};

export default NoEditableInput;
