import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import { setSelectedAllLeagues } from 'redux/modules/asianViewLeaguesFilter/index';
import { getIsAllLeaguesSelected } from 'redux/modules/asianViewLeaguesFilter/selectors';

import styles from './styles.module.scss';

const LeaguesSelectAll = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAllSelected = useSelector(getIsAllLeaguesSelected);

  const selectAllHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAllLeagues(e.target.checked));
  };

  return (
    <Checkbox checked={isAllSelected} className={styles.selectAll} onChange={selectAllHandler}>
      <span>{t('asianView.labels.leaguesFilter.selectAll')}</span>
    </Checkbox>
  );
};

export default LeaguesSelectAll;
