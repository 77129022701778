import { KeyboardEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { INLINE_BTN_CLOSE } from 'constants/automation';
import { BetFocusFields, KEY_CODES } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { InlinePlacementActions, InlinePlacementButtonActionTypes } from 'constants/inlinePlacement';
import { BettingTypes } from 'constants/markets';
import { useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { BetFocusField } from 'redux/modules/betslip/type';
import { removeInlineSelectedBet, updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { InlinePlacementButtonActionType } from 'types/inlinePlacement';
import { formatBestPrice } from 'utils/betslip';
import { getBetIdentityData } from 'utils/inlinePlacement';
import { calculateLiability } from 'utils/liability';

import styles from './styles.module.scss';

type TInlineBetRemoveProps = {
  bet: TInlineSelectedBet;
};
const InlineBetRemove = ({ bet }: TInlineBetRemoveProps) => {
  const dispatch = useDispatch();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isDisabled = useIsDisabledInlineSelectedBet(bet);

  const isSelectState = bet.action === InlinePlacementActions.SELECT;
  const isConfirmState = bet.action === InlinePlacementActions.CONFIRM;
  const isPlacedState = bet.action === InlinePlacementActions.PLACED;
  const isEditState = bet.action === InlinePlacementActions.EDIT;
  const isCancelledState = bet.action === InlinePlacementActions.CANCELLED;
  const isRemovedState = bet.action === InlinePlacementActions.REMOVED;

  const isPlacedBet = !!bet.placedBet;
  const isLineMarket = bet.bettingType === BettingTypes.LINE;

  const isCloseBtn =
    isSelectState ||
    isPlacedState ||
    isEditState ||
    isCancelledState ||
    isRemovedState ||
    (isConfirmState && isDisabled);

  const betIdData = getBetIdentityData(bet);

  const isFocused =
    bet.focusedButton === InlinePlacementButtonActionTypes.CANCEL ||
    bet.focusedButton === InlinePlacementButtonActionTypes.CANCEL_EDIT;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isFocused && buttonRef.current !== null) {
      buttonRef.current.focus();
    }
  }, [isFocused]);

  const onRemoveBetClick = () => {
    if (isPlacedBet && (isEditState || isConfirmState)) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          price: isLineMarket
            ? formatBestPrice(+(bet.placedBet?.handicap ?? 0), bet.marketType, bet.bettingType ?? '')
            : bet.placedBet?.price,
          size: bet.placedBet?.sizeRemaining,
          profit: calculateLiability(bet.placedBet?.price, bet.placedBet?.sizeRemaining, bet),
          action: InlinePlacementActions.PLACED
        })
      );
    } else {
      dispatch(removeInlineSelectedBet(bet));
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onRemoveBetClick();
    } else if (e.key === KEY_CODES.TAB) {
      e.preventDefault();

      let focusedField: BetFocusField | null = null;
      let focusedButton: InlinePlacementButtonActionType | null = null;

      if (isSelectState || isEditState) {
        focusedField = !isPNCEnabled ? BetFocusFields.PRICE : BetFocusFields.SIZE;
      } else if (isPlacedState) {
        focusedButton = InlinePlacementButtonActionTypes.CANCEL_UNMATCHED;
      }

      dispatch(updateInlineSelectedBet({ ...getBetIdentityData(bet), focusedField, focusedButton }));
    }
  };

  if (!isCloseBtn) {
    return null;
  }

  return (
    <button
      ref={buttonRef}
      type="button"
      className={classNames(styles.closeBtn, branding.REMOVE_SELECTED_BET_ICON, {
        [styles.closeBtn__dark]: isPlacedState || isCancelledState
      })}
      onClick={onRemoveBetClick}
      onKeyDown={keyDownHandler}
      data-auto={INLINE_BTN_CLOSE}
    >
      <i className="biab_custom-icon-close" />
    </button>
  );
};

export default InlineBetRemove;
