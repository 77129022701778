import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { mobileAccountPagesBranding } from 'constants/branding';
import { setIsFiltersSectionOpen } from 'redux/modules/betList';

import styles from './BetListFiltersHeader.module.scss';

interface BetListFiltersHeaderProps {
  areFiltersChanged: boolean;
}

const BetListFiltersHeader = ({ areFiltersChanged }: BetListFiltersHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeFilters = () => {
    dispatch(setIsFiltersSectionOpen(false));
  };

  return (
    <section className={classNames(styles.header, mobileAccountPagesBranding.FILTERS)}>
      <Button variant="outline" className={styles.btn} onClick={closeFilters}>
        <Icon iconClass="biab_custom-icon-chevron-left-24" size="lg" color="inherit" />
      </Button>
      <h2 className={styles.title}>{t('account.labels.filters')}</h2>
      <Button
        type="submit"
        variant="outline"
        className={classNames(styles.btn, {
          [styles.btn__disabled]: !areFiltersChanged,
          [styles.btn__active]: areFiltersChanged,
          [mobileAccountPagesBranding.APPLY_BUTTON]: areFiltersChanged
        })}
        disabled={!areFiltersChanged}
      >
        <Icon iconClass="biab_custom-icon-check-24" size="lg" color="inherit" />
      </Button>
    </section>
  );
};

export default BetListFiltersHeader;
