import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';
import { useUnmount } from 'usehooks-ts';

import FilterNetCommission from 'components/BettingProfitAndLoss/components/FilterNetCommission/FilterNetCommission';
import Footer from 'components/Footer';
import { ElementNames } from 'constants/app';
import { ExchangeTypes } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useElementSize from 'hooks/useElementSize';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { getIsExchangeGamesEnabled, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { resetMobileFilters } from 'redux/modules/myBets';
import { getProductMobileFilter } from 'redux/modules/myBets/selectors';

import MobileDatePickers from './components/MobileDatePickers';
import MobileFiltersAccountStatementSorting from './components/MobileFiltersAccountStatementSorting';
import MobileFiltersBetTypes from './components/MobileFiltersBetTypes';
import MobileFiltersCurrencies from './components/MobileFiltersCurrencies';
import MobileFiltersEventTypes from './components/MobileFiltersEventTypes';
import MobileFiltersProducts from './components/MobileFiltersProducts';
import MobileFiltersSorting from './components/MobileFiltersSorting';
import MobileFiltersTimeDisplayFormat from './components/MobileFiltersTimeDisplayFormat';
import MobileTransactionsTypes from './components/MobileTransactionsTypes';

import styles from './styles.module.scss';

type MobileAccountFiltersProps = {
  selectedTab: string;
};

type FilterProps = {
  isSportsGamesProduct: boolean;
  isAsianViewTab: boolean;
  isCurrentPeriod: boolean;
  isPNCEnabled: boolean;
  isExchangeGamesEnabled: boolean;
};

const MY_BETS_FILTERS: Record<string, (props: FilterProps) => JSX.Element | null> = {
  mybets: ({ isSportsGamesProduct, isCurrentPeriod, isPNCEnabled, isExchangeGamesEnabled }: FilterProps) => (
    <>
      {(!isCurrentPeriod || (isSportsGamesProduct && (!isPNCEnabled || isExchangeGamesEnabled))) && (
        <MobileFiltersBetTypes />
      )}
    </>
  ),
  profitandloss: () => null,
  statement: ({ isAsianViewTab }: FilterProps) => <MobileTransactionsTypes isAsianViewTab={isAsianViewTab} />
};

const MobileAccountFilters = ({ selectedTab }: MobileAccountFiltersProps) => {
  const dispatch = useDispatch();

  const { isProfitLossPage } = useAccountUrlParams();
  const { isProductDropdownEnabled, selectedProduct } = useAccountProducts();
  const productMobileFilter = useSelector(getProductMobileFilter);
  const footerHeight = useSelector(getElementHeightByName(ElementNames.FOOTER_HEIGHT));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { product, isCurrentPeriod } = useMyBetsFilters();

  const isAsianViewTab = (productMobileFilter || selectedProduct) === ExchangeTypes.AsianView;
  const isSportsGamesProduct = !isUndefined(productMobileFilter)
    ? productMobileFilter === ExchangeTypes.SportsGames
    : product === ExchangeTypes.SportsGames;

  const [wrapperRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: height - footerHeight }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useUnmount(() => {
    dispatch(resetMobileFilters());
    window.scrollTo(0, 0);
  });

  return (
    <section ref={wrapperRef} className={styles.content}>
      {isProductDropdownEnabled && <MobileFiltersProducts />}
      <MobileDatePickers />
      {isProfitLossPage && <FilterNetCommission />}
      {isProfitLossPage && <MobileFiltersEventTypes />}
      {MY_BETS_FILTERS[selectedTab]({
        isCurrentPeriod,
        isSportsGamesProduct,
        isAsianViewTab,
        isPNCEnabled,
        isExchangeGamesEnabled
      })}
      {isAsianViewTab && <MobileFiltersTimeDisplayFormat />}
      {isMultiCurrencySupported && !isAsianViewTab && <MobileFiltersCurrencies />}
      {selectedTab === 'statement' && <MobileFiltersAccountStatementSorting />}
      {(isProfitLossPage || (selectedTab === 'mybets' && !isCurrentPeriod)) && <MobileFiltersSorting />}
      <Footer />
    </section>
  );
};

export default MobileAccountFilters;
