import classNames from 'classnames';
import { times } from 'lodash';

import Skeleton from 'components/Skeleton';

import styles from './ListLoadingSkeleton.module.scss';

const SKELETONS = 2;

interface ListLoadingSkeletonProps {
  isOpenTab?: boolean;
}

const ListLoadingSkeleton = ({ isOpenTab }: ListLoadingSkeletonProps) => {
  return (
    <div className={styles.skeletonWrapper}>
      <div className={styles.skeletonHeader}>
        <Skeleton className={styles.skeletonHeader__link} />
        {isOpenTab && <Skeleton className={styles.skeletonHeader__btn} />}
      </div>
      {times(SKELETONS, index => (
        <div key={index} className={styles.skeletonItem}>
          <div
            className={classNames(styles.skeletonItem__top, {
              [styles.skeletonItem__top__divided]: isOpenTab
            })}
          >
            <div className={styles.skeletonItem__top__left}>
              <Skeleton className={styles.skeletonItem__l} />
              <Skeleton className={styles.skeletonItem__m} />
              <Skeleton className={styles.skeletonItem__m} />
            </div>
            <div className={styles.skeletonItem__top__right}>
              <Skeleton className={styles.skeletonItem__s} />
              <Skeleton className={styles.skeletonItem__s} />
            </div>
          </div>
          <div
            className={classNames(styles.skeletonItem__bottom, {
              [styles.skeletonItem__bottom__divided]: isOpenTab
            })}
          >
            <div className={styles.skeletonItem__bottom__main}>
              <Skeleton className={styles.skeletonItem__l} />
              {isOpenTab && <Skeleton className={styles.skeletonItem__m} />}
              {!isOpenTab && <Skeleton className={styles.skeletonItem__l} />}
            </div>
            {isOpenTab && (
              <div className={styles.skeletonItem__bottom__secondary}>
                <Skeleton className={styles.skeletonItem__l} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListLoadingSkeleton;
