import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchSettledBetList, fetchSettledBetList, successFetchSettledBetList } from './index';
import { TSettledBetsResponse } from './type';

export function* getSettledBetListWatcher() {
  try {
    const response: TSettledBetsResponse = yield call(api.betList.getSettledBetList);
    yield put(successFetchSettledBetList(response.content));
  } catch (error: any) {
    yield put(failureFetchSettledBetList(error as Error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchSettledBetList.type, getSettledBetListWatcher)]);
}
