import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { PROGRESS_BAR_INTERVAL } from 'constants/app';
import { betslipBranding } from 'constants/branding';

import styles from './ProgressBar.module.scss';

const STOP_PROGRESS_VALUE = 90;
const PROGRESS_STEP_VALUE = 10;

interface ProgressBarProps {
  label: string;
  className?: string;
}

const ProgressBar = ({ label, className }: ProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const progressIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!progressIntervalRef.current && progress !== STOP_PROGRESS_VALUE) {
      progressIntervalRef.current = setInterval(() => {
        setProgress(currProgress => currProgress + PROGRESS_STEP_VALUE);
      }, PROGRESS_BAR_INTERVAL);
    }

    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (progressIntervalRef.current && progress === STOP_PROGRESS_VALUE) {
      clearInterval(progressIntervalRef.current);
    }
  }, [progress]);

  return (
    <div className={classNames(styles.progress__container, className, betslipBranding.UNMATCHED_PROGRESS)}>
      <div
        className={classNames(styles.progress__bar, betslipBranding.UNMATCHED_PROGRESS_BAR)}
        style={{ width: `${progress}%` }}
      />
      <p className={styles.progress__label}>{label}</p>
    </div>
  );
};

export default ProgressBar;
