import { useCookies } from 'react-cookie';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useUnmount } from 'usehooks-ts';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { CookieNames } from 'constants/app';
import { SortByFields } from 'constants/betList';
import { ACCOUNT_BASE_URL, GAMES_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { MyBetsPeriods } from 'constants/myBets';
import useOnMountEffect from 'hooks/useOnMountEffect';
import BetListFilters from 'pages/mobile/MobileBetListPage/components/BetListFilters/BetListFilters';
import EventsLoadingSkeleton from 'pages/mobile/MobileBetListPage/components/EventsLoadingSkeleton/EventsLoadingSkeleton';
import ListLoadingSkeleton from 'pages/mobile/MobileBetListPage/components/ListLoadingSkeleton/ListLoadingSkeleton';
import NoBets from 'pages/mobile/MobileBetListPage/components/NoBets/NoBets';
import BetEventsSortedByStartTime from 'pages/mobile/MobileBetListPage/components/OpenTab/components/BetEventsSortedByStartTime/BetEventsSortedByStartTime';
import BetsSortedByPlacement from 'pages/mobile/MobileBetListPage/components/OpenTab/components/BetsSortedByPlacement/BetsSortedByPlacement';
import { setFilters, setIsFiltersSectionOpen } from 'redux/modules/betList';
import {
  getAreFiltersApplied,
  getIsFiltersSectionOpen,
  getOpenBets,
  getSortByFilterValue
} from 'redux/modules/betList/selectors';
import { setCurrentBetsLoading } from 'redux/modules/currentBets';
import { getCurrentBetsLoading } from 'redux/modules/currentBets/selectors';

import styles from './OpenTab.module.scss';

const OpenTab = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isFiltersSectionOpen = useSelector(getIsFiltersSectionOpen);
  const areFiltersApplied = useSelector(getAreFiltersApplied);
  const sortByFilterValue = useSelector(getSortByFilterValue);
  const isBetListLoading = useSelector(getCurrentBetsLoading);
  const currentBets = useSelector(getOpenBets);
  const [cookies] = useCookies([CookieNames.BET_LIST_SORT_BY, CookieNames.BET_LIST_BET_TYPE]);

  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const exchangePage = (isGamesPage && GAMES_BASE_URL) || '';
  const myBetsLink = `${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${MyBetsPeriods.Open.toLowerCase()}/1`;
  const isSortedByEventStartTime = sortByFilterValue === SortByFields.EventStartTime;

  useOnMountEffect(() => {
    dispatch(setCurrentBetsLoading(true));

    if (cookies.BIAB_BET_LIST_BET_TYPE) {
      dispatch(setFilters({ betType: cookies.BIAB_BET_LIST_BET_TYPE }));
    }

    if (cookies.BIAB_BET_LIST_SORT_BY) {
      dispatch(setFilters({ sortBy: cookies.BIAB_BET_LIST_SORT_BY }));
    }
  });

  useUnmount(() => {
    if (isFiltersSectionOpen) {
      dispatch(setIsFiltersSectionOpen(false));
    }
  });

  if (!isBetListLoading && currentBets.length === 0) {
    return <NoBets />;
  }

  return (
    <div className={styles.container}>
      {isBetListLoading && (
        <>
          {sortByFilterValue === SortByFields.EventStartTime && <EventsLoadingSkeleton />}
          {sortByFilterValue !== SortByFields.EventStartTime && <ListLoadingSkeleton isOpenTab />}
        </>
      )}
      {!isBetListLoading && !isFiltersSectionOpen && (
        <>
          <div className={styles.header}>
            <Link className={styles.header__link} to={myBetsLink}>
              {t('account.betList.labels.viewMyBets')}
            </Link>
            <Button
              variant="tertiary"
              onClick={() => dispatch(setIsFiltersSectionOpen(true))}
              className={classNames(styles.header__filterBtn, {
                [styles.header__filterBtn__active]: areFiltersApplied
              })}
            >
              <Icon iconClass="biab_custom-icon-filter-24" size="lg" color="inherit" />
            </Button>
          </div>
          {!isSortedByEventStartTime && <BetsSortedByPlacement />}
          {isSortedByEventStartTime && <BetEventsSortedByStartTime />}
        </>
      )}
      {isFiltersSectionOpen && createPortal(<BetListFilters />, document.getElementById('biab_body')!)}
    </div>
  );
};

export default OpenTab;
