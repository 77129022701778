import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';

import HeaderAccountNavigation from 'components/HeaderAccountNavigation';
import { GAMES_BASE_URL } from 'constants/locations';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { removeAllGamesSelectedBets } from 'redux/modules/betslip';
import { getIsSelectedBets } from 'redux/modules/betslip/selectors';
import { fetchGames } from 'redux/modules/games';
import { getGameList } from 'redux/modules/games/selectors';
import { getGamesNavigationLinks, serializeGamesData } from 'utils/games';

import styles from './styles.module.scss';

const DesktopNavigationGames = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gameId = '' } = useParams();

  const gameList = useSelector(getGameList);
  const domainLanguage = useSelector(getTranslation);
  const isSelectedBets = useSelector(getIsSelectedBets);

  const currentGame = useMemo(() => gameList.find(game => `${game.channelId}` === gameId), [gameId, gameList]);
  const gamesNavigation = useMemo(() => {
    if (gameList) {
      const groupedGames = serializeGamesData(gameList);
      return getGamesNavigationLinks(groupedGames);
    }

    return [];
  }, [gameList, domainLanguage]);

  const handleClickLink = () => {
    if (isSelectedBets) {
      dispatch(removeAllGamesSelectedBets());
    }
  };

  useEffect(() => {
    dispatch(fetchGames());
  }, [domainLanguage]);

  return (
    <div className={classNames('biab_games-header-navigation', 'biab_navigation-links', styles.headerMain)}>
      <ul className={classNames('biab_navigation-links', styles.headerNavigationLinks)}>
        <li className={styles.headerNavigationItem}>
          <NavLink
            end
            to={GAMES_BASE_URL}
            title={t('header.home')}
            className={({ isActive }) =>
              classNames(
                {
                  biab_active: isActive
                },
                'biab_home-link',
                styles.headerNavigationItem__home
              )
            }
          >
            <i className="fa2 fa2-home-icon" />
          </NavLink>
        </li>
        {gamesNavigation?.map(({ name, id, url, gameType }) => (
          <li className={styles.headerNavigationItem} key={id}>
            <NavLink
              end
              to={url}
              title={t(name)}
              className={() =>
                classNames({
                  [`${styles.active} biab_active`]: currentGame?.gameType === gameType
                })
              }
              onClick={handleClickLink}
            >
              <span>{t(name)}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <HeaderAccountNavigation />
    </div>
  );
};

export default DesktopNavigationGames;
