import { useTranslation } from 'react-i18next';

import styles from './NoFilteredBets.module.scss';

const NoFilteredBets = () => {
  const { t } = useTranslation();
  return <p className={styles.container}>{t('account.mybets.messages.noData')}</p>;
};

export default NoFilteredBets;
