import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { mobileBranding } from 'constants/branding';
import { BET_LIST_URL, GAMES_BASE_URL } from 'constants/locations';
import { BetListTabs } from 'constants/myBets';

import styles from './Tabs.module.scss';

const Tabs = () => {
  const { t } = useTranslation();
  const { tab = BetListTabs.Open } = useParams();
  const { pathname } = useLocation();
  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const exchangePage = (isGamesPage && GAMES_BASE_URL) || '';

  return (
    <div className={classNames(styles.tabs, mobileBranding.TABS_NAV)}>
      <Link
        className={classNames(styles.tab, {
          [styles.tab__active]: tab == BetListTabs.Open,
          [mobileBranding.ACTIVE]: tab == BetListTabs.Open
        })}
        to={`${exchangePage}${BET_LIST_URL}/${BetListTabs.Open}`}
      >
        {t('account.mybets.btns.open')}
      </Link>
      <Link
        className={classNames(styles.tab, {
          [styles.tab__active]: tab !== BetListTabs.Open,
          [mobileBranding.ACTIVE]: tab !== BetListTabs.Open
        })}
        to={`${exchangePage}${BET_LIST_URL}/${BetListTabs.Settled}`}
      >
        {t('account.mybets.btns.settled')}
      </Link>
    </div>
  );
};

export default Tabs;
