import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { ElementNames } from 'constants/app';
import { SEARCH_DEBOUNCE_DELAY } from 'constants/search';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useDevice from 'hooks/useDevice';
import { getMinSearchQueryLength } from 'redux/modules/appConfigs/selectors';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { fetchSearchArticles } from 'redux/modules/educationalPortal';
import {
  getIsSearchActive,
  getIsSearchArticlesLoading,
  getSearchArticles
} from 'redux/modules/educationalPortal/selectors';

import SearchItem from '../SearchItem/SearchItem';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDING = 10;

const SearchResults = ({ searchValue, onSelectItem }: { searchValue?: string; onSelectItem?: () => void }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const minSearchQueryLength = useSelector(getMinSearchQueryLength);
  const articles = useSelector(getSearchArticles);
  const isLoading = useSelector(getIsSearchArticlesLoading);
  const isActiveSearch = useSelector(getIsSearchActive);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const mobileFooterNavHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_FOOTER_NAV_HEIGHT));

  const [hasInitiatedSearch, setHasInitiatedSearch] = useState(false);

  const { isMobile, isDesktop } = useDevice();

  const query = (isDesktop ? searchValue : searchParams.get(PARAMS_SEARCH_KEY)) || '';
  const isValidQuery = !!query && query.length >= minSearchQueryLength;
  const hasResults = articles.length > 0 && !isLoading && isValidQuery;
  const hasLoading = isLoading || (!hasInitiatedSearch && isValidQuery);
  const showStartTypingYourQuery = isMobile && !isValidQuery && isActiveSearch;

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setHasInitiatedSearch(value.length >= minSearchQueryLength);
      dispatch(fetchSearchArticles({ query: value }));
    }, SEARCH_DEBOUNCE_DELAY)
  ).current;

  useEffect(() => {
    if (query && minSearchQueryLength && query.length >= minSearchQueryLength) {
      debouncedSearch(query);
    }
  }, [query]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  if ((!query || query.length < minSearchQueryLength) && isDesktop) {
    return null;
  }

  return (
    <div
      style={
        isMobile
          ? { minHeight: `calc(100dvh - ${mobileFooterNavHeight + headerHeight + TOP_AND_BOTTOM_PADDING}px)` }
          : undefined
      }
      className={classNames(styles.wrapper, {
        [styles.wrapper__desktop]: isDesktop,
        [styles.minHeight]: isLoading || !hasResults
      })}
    >
      {showStartTypingYourQuery ? (
        <p className={styles.startTyping}>{t('education.labels.search.startTyping')}</p>
      ) : (
        <>
          {(hasLoading || !hasResults) && (
            <div className={classNames({ [styles.noResults]: !hasResults })}>
              {hasLoading ? (
                <>
                  <i className={classNames('fa fa-spinner fa-pulse fa-fw', styles.loading__spinner)} />
                  <span className={styles.noResults__text}>{t('education.labels.search.searchingForArticles')}</span>
                </>
              ) : (
                <>
                  <span
                    className={classNames(styles.noResults__title, {
                      [styles.noResults__title__mobile]: isMobile
                    })}
                  >
                    {t('education.labels.search.noResults')}
                  </span>
                  <span
                    className={classNames(styles.noResults__text, {
                      [styles.noResults__text__mobile]: isMobile
                    })}
                  >
                    {t('education.labels.search.tryAgain')}
                  </span>
                </>
              )}
            </div>
          )}
        </>
      )}

      {hasResults && (
        <div className={styles.results}>
          {articles.map(article => (
            <SearchItem key={article.permalink} article={article} onSelectItem={onSelectItem} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
