import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import StepperInput from 'components/StepperInput';
import { ASIAN_BETSLIP_PLACEHOLDERS } from 'constants/asianView';
import { InputFieldTypes } from 'constants/betslip';
import { mobileComponents } from 'constants/branding';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getCurrency, getCurrencySteps } from 'redux/modules/appConfigs/selectors';
import { updateAVMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianMobileUpdateData
} from 'redux/modules/asianViewMobileBetslip/type';
import { InputFieldType } from 'types/betslip';
import { getSizeStepValue } from 'utils/asianView';
import { round } from 'utils/liability';

export interface IMobileInputControl {
  type: InputFieldType;
  bet: TAsianMobileSelectedBet;
  updateMobileBet: ({ size, profit, focusedField }: TAsianMobileUpdateData) => void;
  validateInput?: () => void;
}

const MobileInputControl = ({ bet, type, updateMobileBet, validateInput }: IMobileInputControl) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currencySteps = useSelector(getCurrencySteps);
  const defaultCurrency = useSelector(getCurrency);

  const placeholder = ASIAN_BETSLIP_PLACEHOLDERS[type]?.[bet.betType] ?? '';
  const isSizeField = type === InputFieldTypes.SIZE;
  const inputProp = isSizeField ? 'size' : 'profit';
  const inputValue = (isSizeField ? bet.size : bet.profit) ?? '';
  const inputValueInt = +(inputValue || 0);
  const { minBetSize: minSize } = defaultCurrency;
  const { subStep, addStep } = getSizeStepValue({ currencySteps, sizeValue: inputValue, step: defaultCurrency.step });
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;
  const isValidationError =
    !!bet.validationMessage && bet.action === AsianViewMobileBetActions.VALIDATION && type === InputFieldTypes.SIZE;
  const isFocused = bet.focusedField === type;

  useUpdateEffect(() => {
    if (!isTouched) {
      setIsTouched(true);
    }
  }, [inputValue]);

  const subStepHandler = () =>
    updateMobileBet({
      [inputProp]: inputValueInt > subStep ? round(inputValueInt - subStep) : '',
      focusedField: type
    });

  const addStepHandler = () =>
    updateMobileBet({ [inputProp]: inputValueInt ? round(inputValueInt + addStep) : minSize, focusedField: type });

  const setFocus = () => {
    if (bet.betslipId) {
      dispatch(updateAVMobileSelectedBet({ betslipId: bet.betslipId, data: { focusedField: type } }));
    }
  };

  const validateInputValue = () => {
    if (
      validateInput &&
      isTouched &&
      inputValue !== '' &&
      (type === InputFieldTypes.SIZE || type === InputFieldTypes.PROFIT)
    ) {
      validateInput();
    }
  };

  return (
    <StepperInput
      value={inputValue}
      label={t(placeholder)}
      id={`${type}_${bet.identifier}`}
      onIncrease={addStepHandler}
      onDecrease={subStepHandler}
      onFocus={setFocus}
      onBlur={validateInputValue}
      onClick={setFocus}
      isFocused={isFocused}
      isInvalid={isValidationError}
      commonClassName={classNames(mobileComponents.INPUT, {
        [mobileComponents.FOCUS]: isFocused && !isConfirm,
        [mobileComponents.ERROR]: isValidationError
      })}
      isDisabled={isConfirm}
      isInputReadOnly
    />
  );
};

export default MobileInputControl;
