import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import BetMax from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMax';
import BetMin from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMin';
import Input, { IInput } from 'components/AsianViewBetslip/components/AVSelectedBet/components/Input';
import AVChangedOddsNotification from 'components/AVChangedOddsNotification/AVChangedOddsNotification';
import { MarketStatuses } from 'constants/app';
import { AsianBettingActions } from 'constants/asianView';
import { InputFieldTypes } from 'constants/betslip';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import useAdjHandicap from 'hooks/useAdjHandicap';
import useAsianOdds from 'hooks/useAsianOdds';
import useAsianViewPlacementHighlighting from 'hooks/useAsianViewPlacementHighlighting';
import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMinMaxSize from 'hooks/useMinMaxSize';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { getAsianMarket } from 'redux/modules/asianView/selectors';
import { AsianViewSizeValidationType, TAsianViewPlaceBetErrorMessageId } from 'redux/modules/asianViewBetslip/type';
import { removeQuickBet, updateQuickBet } from 'redux/modules/asianViewQuickBetting';
import { TAsianQuickBet, TAsianQuickUpdatedBet } from 'redux/modules/asianViewQuickBetting/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesBettingTypeById,
  getMarketPricesCurrency,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { AsianBettingAction } from 'types/asianView';
import { getBetPayout, replaceHandicaps } from 'utils/asianView';
import { calculateLiability, calculateSize } from 'utils/liability';
import { getMarketTypes } from 'utils/market';
import convertOdds from 'utils/oddsConverter';
import { validateSize } from 'utils/size';

import Info from './components/Info';
import Message from './components/Message';

import styles from './styles.module.scss';

interface IQuickBettingForm {
  bet: TAsianQuickBet;
}

const QuickBettingForm = ({ bet }: IQuickBettingForm) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const oddsType = useSelector(getOddsType);
  const market = useSelector(getAsianMarket(bet.eventId, bet.marketId));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(bet.marketId));
  const defaultCurrency = useSelector(getCurrency);
  const currencyCode = useSelector(getMarketPricesCurrency(bet.marketId));
  const bettingType = useSelector(getMarketPricesBettingTypeById(bet.marketId));
  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const userAsianViewAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, +(bet.handicap ?? 0))
  );

  const [isStakeHovered, setIsStakeHovered] = useState(false);

  const sizeInputRef = useRef<HTMLInputElement>(null);
  const betAction = useRef(bet.action); // Don't remove, we need it for correct work of input onBlur handler (we have setTimeout there) and click on 'Place Bet' button
  betAction.current = bet.action;

  const adjHandicap = useAdjHandicap(bet);
  const betPrice = useAsianOdds({ bet });
  const odds = useOddsDisplayFormat(bet);
  const { minSizeFormatted, maxSizeFormatted } = useMinMaxSize({ marketId: bet.marketId });
  const currency = useCurrency(currencyCode);

  const isConfirm = bet.action === AsianBettingActions.CONFIRM;
  const { isAsianHandicap } = getMarketTypes(market?.marketType ?? '', bettingType);
  const isSuspended = status === MarketStatuses.SUSPENDED;
  const oddsTypeLabel = t(`asianView.labels.betslip.oddsType.${oddsType}`);
  const isBack = bet.betType === BetSides.Back;
  const scoreLabel = isAsianHandicap && bet.score ? `(${bet.score})` : '';
  const payout = getBetPayout(bet);
  const isPrevOddsLower = !!(bet.initPrice && +bet.initPrice < (odds || 0));
  const isGoingUp = isBack ? isPrevOddsLower : !isPrevOddsLower;
  const hasAutoRefresh = userAsianViewAutoRefresh && !isSuspended && !isConfirm;

  const { noFormattedAmount: payoutFormatted } = useFormatCurrency(payout, currencyCode, {
    isCheckIndian: true,
    noRounding: true
  });

  const isProgress = bet.action === AsianBettingActions.PROGRESS;
  const isPlace = bet.action === AsianBettingActions.PLACE;
  const isValidation = bet.action === AsianBettingActions.VALIDATION;
  const isEditEnabled = isPlace || isValidation;
  const isDisabledBet = !odds || isSuspended || isLocked;
  const isOddsRefreshEnabled = hasAutoRefresh && !isProgress;
  const isOddsUp = isOddsRefreshEnabled && isGoingUp && !isDisabledBet;
  const isOddsDown = isOddsRefreshEnabled && !isGoingUp && !isDisabledBet;
  const minMaxBetDisplayType = 'column';

  const { areOddsHighlighted } = useAsianViewPlacementHighlighting({
    odds,
    isDisabledBet,
    hasAutoRefresh: isOddsRefreshEnabled && odds !== bet.initPrice
  });

  const setValidationMessage = ({
    message,
    validationType,
    messageId
  }: {
    message: string;
    validationType?: AsianViewSizeValidationType;
    messageId?: null | TAsianViewPlaceBetErrorMessageId;
  }) => {
    let action: AsianBettingAction | undefined;

    if (betAction.current !== AsianBettingActions.CONFIRM) {
      action = !message ? AsianBettingActions.PLACE : AsianBettingActions.VALIDATION;
    }

    dispatch(
      updateQuickBet({
        identifier: bet.identifier,
        data: {
          validationMessage: message,
          ...(!isUndefined(validationType) ? { sizeValidationType: validationType } : {}),
          ...(!isUndefined(messageId) ? { validationMessageId: messageId } : {}),
          ...(action ? { action } : {})
        }
      })
    );
  };

  const updateBet = ({ price, size, profit, action }: TAsianQuickUpdatedBet) => {
    if (!isDisabledBet) {
      const updateData: TAsianQuickUpdatedBet = { size: bet.size, profit: bet.profit };

      if (action) {
        updateData.action = action;
      }

      if (!isUndefined(profit)) {
        updateData.size = calculateSize(betPrice, profit);
        updateData.profit = profit;
      } else if (!isUndefined(size)) {
        updateData.size = size;
        updateData.profit = calculateLiability(betPrice, size, bet);
      } else if (!isUndefined(price)) {
        updateData.price = price;
        updateData.profit = calculateLiability(price, bet.size, bet);
        updateData.prevPrice = bet.price;
      }

      const validation = validateSize({
        size: updateData.size,
        betType: bet.betType,
        currency,
        defaultCurrency,
        currencyCode,
        isOperatorBettingLimitsEnabled
      });

      if (!validation.isValid && validation.errorMessage?.text) {
        updateData.validationMessage = t(validation.errorMessage?.text, validation.errorMessage.params ?? {});
        updateData.sizeValidationType = validation.type;
        updateData.validationMessageId = validation.messageId;
      } else if (action !== AsianBettingActions.VALIDATION) {
        updateData.validationMessage = '';
        updateData.sizeValidationType = null;
        updateData.validationMessageId = null;
      }

      updateData.isValid = validation.isValid;
      updateData.validSizeValue = validation.validValue;

      dispatch(updateQuickBet({ identifier: bet.identifier, data: { ...updateData, isInPlay } }));
    }
  };

  const isReadOnly =
    isDisabledBet || (bet.action !== AsianBettingActions.PLACE && bet.action !== AsianBettingActions.VALIDATION);

  const inputProps: Omit<IInput, 'type'> = {
    currencyCode,
    sizeInputRef,
    isHovered: isStakeHovered,
    ...bet,
    readOnly: isReadOnly,
    updateBet,
    setValidationMessage,
    minSizeFormatted,
    maxSizeFormatted,
    disabled: isDisabledBet
  };

  useEffect(() => {
    if (betPrice && bet.size) {
      updateBet({ price: betPrice });
    }
  }, []);

  useEffect(() => {
    if (isOddsRefreshEnabled && betPrice && betPrice !== bet.price && !isProgress) {
      updateBet({ price: betPrice });
    }
  }, [isOddsRefreshEnabled, betPrice, bet.price, isProgress]);

  useEffect(() => {
    if (bet.isInPlay !== isInPlay && !isDisabledBet) {
      dispatch(updateQuickBet({ identifier: bet.identifier, data: { isInPlay } }));
    }
  }, [isInPlay, isDisabledBet]);

  useEffect(() => {
    if (isAsianHandicap && adjHandicap !== bet.adjHandicap) {
      dispatch(
        updateQuickBet({
          identifier: bet.identifier,
          data: { adjHandicap, selectionName: replaceHandicaps(bet.selectionName, bet.adjHandicap, adjHandicap) }
        })
      );
    }
  }, [isAsianHandicap, adjHandicap]);

  useEffect(() => {
    if (isOddsDown !== bet.isLowerPrice) {
      dispatch(updateQuickBet({ identifier: bet.identifier, data: { isLowerPrice: isOddsDown } }));
    }
  }, [isOddsDown]);

  useEffect(() => {
    if (isValidation && !bet.isValid) {
      updateBet({ action: AsianBettingActions.VALIDATION });
    }
  }, [isValidation, bet.isValid]);

  return (
    <div className={styles.bet}>
      <div className={styles.bet__marketName}>
        <span>{`${bet.marketName} - ${bet.selectionName} ${scoreLabel}`}</span>
        <i
          onClick={() => dispatch(removeQuickBet(bet.identifier))}
          className={classNames('biab_tour-icon-close', styles.closeIcon)}
        />
      </div>
      <div className={classNames(styles.bet__info, styles.bet__info__center)}>
        <div
          className={classNames(styles.bet__odds, {
            [styles.bet__odds__up]: isOddsUp && areOddsHighlighted,
            [styles.bet__odds__down]: isOddsDown && areOddsHighlighted,
            [branding.PRICE_MORE]: isOddsUp && areOddsHighlighted,
            [branding.PRICE_LESS]: isOddsDown && areOddsHighlighted
          })}
        >
          @<span>{convertOdds((isOddsRefreshEnabled ? betPrice : bet.price) || ' ', oddsType)}</span> ({oddsTypeLabel})
          {isOddsRefreshEnabled && !isDisabledBet && areOddsHighlighted && (
            <i className={`fa2 fa2-sort-${isGoingUp ? 'up' : 'down'} biab_indicator`} />
          )}
        </div>
        <span>{t(isBack ? 'asianView.labels.betslip.backBetFor' : 'asianView.labels.betslip.layBetAgainst')}</span>
      </div>
      <div className={styles.bet__form}>
        <Input type={isBack ? InputFieldTypes.SIZE : InputFieldTypes.PROFIT} {...inputProps} />
        <Input type={isBack ? InputFieldTypes.PROFIT : InputFieldTypes.SIZE} {...inputProps} />
      </div>
      {!isDisabledBet && isEditEnabled && (
        <div className={styles.bet__info}>
          <BetMin marketId={bet.marketId} displayType={minMaxBetDisplayType} />
          <BetMax
            bet={bet}
            sizeInputRef={sizeInputRef}
            setIsHovered={setIsStakeHovered}
            setValidationMessage={setValidationMessage}
            updateBet={updateBet}
            displayType={minMaxBetDisplayType}
          />
        </div>
      )}
      <div className={classNames(styles.bet__info, styles.bet__payout, branding.BET_SEPARATOR)}>
        <span>{t('asianView.labels.betslip.payout')}:</span>
        <span>{payoutFormatted}</span>
      </div>
      {!isDisabledBet && bet.validationMessage && (
        <Message identifier={bet.identifier} message={bet.validationMessage} customClassName={branding.ERROR_MSG} />
      )}
      {isOddsRefreshEnabled && !isSuspended && !isDisabledBet && odds !== bet.initPrice && (
        <Info
          message={<AVChangedOddsNotification isGoingUp={isGoingUp} odds={odds} initPrice={bet.initPrice} />}
          customClassName={branding.ODDS_CHANGED_MSG}
        />
      )}
      {isDisabledBet && (
        <Info message={t('asianView.labels.selectedBet.unavailable')} customClassName={branding.LOCKED_BET_MSG} />
      )}
    </div>
  );
};

export default QuickBettingForm;
