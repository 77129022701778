import classNames from 'classnames';

import { accountPagesBranding } from 'constants/branding';
import useOpenBetData from 'pages/mobile/MobileBetListPage/components/OpenBet/hooks/useOpenBetData';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './BetOfferState.module.scss';

interface BetOfferStateProps {
  bet: TCurrentBet;
}

const BetOfferState = ({ bet }: BetOfferStateProps) => {
  const { isNegativeBet, isPositiveBet, betOfferState } = useOpenBetData(bet);

  return (
    <div
      className={classNames(styles.status, accountPagesBranding.BET_STATUS, {
        [styles.status__positive]: isPositiveBet,
        [styles.status__negative]: isNegativeBet,
        [accountPagesBranding.POSITIVE]: isPositiveBet,
        [accountPagesBranding.NEGATIVE]: isNegativeBet
      })}
    >
      <i className={classNames('biab_custom-icon-dot', accountPagesBranding.BET_STATUS_DOT)} />
      {betOfferState}
    </div>
  );
};

export default BetOfferState;
