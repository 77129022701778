export const INLINE_BTN = 'inline_btn_';
export const INLINE_BTN_CLOSE = 'inline_btn_close';
export const BETSLIP_BTN_REMOVE = 'betslip_btn_remove';
export const BETSLIP_BTN_REMOVE_ALL = 'betslip_btn_remove_all';
export const BETSLIP_BTN_PLACE = 'betslip_btn_place';
export const BETSLIP_BTN_CONFIRM_REMOVAL = 'betslip_btn_confirm_removal';
export const BETSLIP_BTN_CANCEL_REMOVAL = 'betslip_btn_cancel_removal';
export const BETSLIP_BTN_EDIT = 'betslip_btn_edit';
export const BETSLIP_BTN_CONFIRM = 'betslip_btn_confirm';
export const OPEN_BET_BTN_CANCEL = 'open_bet_btn_cancel';
export const OPEN_BET_BTN_UPDATE = 'open_bet_btn_update';
export const OPEN_BET_BTN_TAKE = 'open_bet_btn_take';
export const MOBILE_INPUT_PRICE = 'mobile-input-price';
export const MOBILE_INPUT_SIZE = 'mobile-input-size';
export const MOBILE_INPUT_LIABILITY = 'mobile-input-liability';
export const MOBILE_PAYOUT = 'mobile-payout';
export const MOBILE_LIABILITY = 'mobile-liability';
