import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { times } from 'lodash';

import useDevice from 'hooks/useDevice';
import { bettingPLLoading } from 'redux/modules/myBets/selectors';
import { TPLContentItem } from 'redux/modules/myBets/type';

import ContentItem from './components/ContentItem';
import MobileDescription from './components/MobileDescription';
import MobileProfit from './components/MobileProfit';
import TableHeader from './components/TableHeader';
import TableSkeleton from './components/TableSkeleton';

import styles from './styles.module.scss';

interface PLTableProps {
  content: TPLContentItem[];
  setRowHighLighted: Dispatch<SetStateAction<number | undefined>>;
  rowHighlighted?: number;
}

const PLTable = ({ content, rowHighlighted, setRowHighLighted }: PLTableProps) => {
  const SKELETONS = 4;
  const { t } = useTranslation();

  const { isDesktop, isMobile } = useDevice();
  const isLoading = useSelector(bettingPLLoading);

  const highlightRow = (index: number) => {
    setRowHighLighted(index);
  };

  return (
    <>
      <div className="biab_table-holder">
        {isDesktop ? (
          <div className={classNames('biab_table biab_mybets', styles.table)}>
            <TableHeader isSortingDisabled={!content?.length} />
            {isLoading && times(SKELETONS, index => <TableSkeleton key={index} />)}
            {!isLoading &&
              content?.length > 0 &&
              content.map((el: TPLContentItem) => <ContentItem key={el.marketId + el.profit} item={el} />)}
          </div>
        ) : (
          <>
            {isLoading && times(SKELETONS, index => <TableSkeleton key={index} />)}
            {!isLoading &&
              content?.length > 0 &&
              content.map((el: TPLContentItem, index: number) => (
                <div
                  key={el.marketId + el.profit}
                  onClick={() => highlightRow(index)}
                  className={classNames(styles.mobileAccountItem, { [styles.highlighted]: index === rowHighlighted })}
                >
                  <MobileDescription item={el} />
                  <MobileProfit profit={el.profit} />
                </div>
              ))}
          </>
        )}
      </div>
      {content?.length <= 0 && (
        <div
          className={classNames(styles.noData, {
            [styles.noData__mobile]: isMobile
          })}
        >
          {t('account.mybets.messages.noData')}
        </div>
      )}
    </>
  );
};

export default PLTable;
