import { RefObject, useEffect, useState } from 'react';

const useStickyElement = (containerEl: HTMLElement | null, elementRef: RefObject<HTMLElement>): boolean => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerEl || !elementRef.current) return;

      const containerRect = containerEl.getBoundingClientRect();
      const elementRect = elementRef.current.getBoundingClientRect();

      setIsSticky(elementRect.top <= containerRect.top);
    };

    containerEl?.addEventListener('scroll', handleScroll);

    return () => containerEl?.removeEventListener('scroll', handleScroll);
  }, [containerEl, elementRef]);

  return isSticky;
};

export default useStickyElement;
